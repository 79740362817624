import {useState} from 'react'

export const usePaginationNew = (pagination, items, total_pages, current_page, toPage) => {

  const itemsForPage = items ? items : 10;

  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [paginationManage, setPaginationManage] = useState({
    currentPagination: current_page - 1,
    paginationStart: current_page - 1,
    paginationEnd: total_pages,
  });

  const checkFirst = () => 
    paginationManage.currentPagination === 0 ? true : false;

  const checkLast = () => 
   paginationManage.currentPagination === total_pages;


  const goToPreviousPage = () => {
    if(current_page !== 1){
      toPage(current_page - 1)  
    }
    
    // let { currentPagination, paginationStart } = paginationManage;

    // if (currentPagination > 0) {
    //   let newStart = paginationStart - itemsForPage;

    //   setPaginationManage({
    //     currentPagination: currentPagination - 1,
    //     paginationStart: newStart,
    //     paginationEnd: newStart + itemsForPage,
    //   });
    // }
  };

  const goToNextPage = () => {
    if(current_page !== total_pages){
      toPage(current_page + 1)  
    }
    
    // let { currentPagination, paginationEnd } = paginationManage;
  
    // if (currentPagination < Math.ceil(pagination.length / itemsForPage) - 1) {
    //   let newEnd = paginationEnd + itemsForPage;

    //   setPaginationManage({
    //     currentPagination: currentPagination + 1,
    //     paginationStart: paginationEnd,
    //     paginationEnd: newEnd,
    //   });
    // }
  };

  const goToFirstPage = () => {
    toPage(1)
    // setPaginationManage({
    //   currentPagination: 0,
    //   paginationStart: 0,
    //   paginationEnd: itemsForPage,
    // });
  };

  const goToLastPage = () => {
    toPage(total_pages)
  };

  const goToPage = (index) => {
    toPage(index + 1)
    // let current = itemsForPage * index;

    // setPaginationManage({
    //   currentPagination: index,
    //   paginationStart: current,
    //   paginationEnd: current + itemsForPage,
    // });
  };

  const reset = () => {
    toPage(1)
    // setPaginationManage({
    //   currentPagination: 0,
    //   paginationStart: 0,
    //   paginationEnd: itemsForPage,
    // });
  }

  return {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset
  }
}
