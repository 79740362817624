import React from "react";
import {gsap, Elastic} from 'gsap';
import Draggable from 'gsap/Draggable';

//Components
import Smile from './Smile'
import SurveyIpsativeTooltip from './SurveyIpsativeTooltip';

export default class ImagesIpsativesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isComplete: this.props.complete ? this.props.complete : false,
      currentButtonYPos: 0,
      secondaryColor: getComputedStyle(document.documentElement).getPropertyValue('--secondary-color')
    };
  }

  componentDidMount() {
    this.showDragMe();
    this.init();
  }

  checkPreviousSelectedItem(){
    if(Object.keys(this.props.selectedItems).length > 0){
      let temp_values = []
      // Object.entries(this.props.itemsShown).forEach(([key, value]) => {
      //   temp_values.push(value)
      // });

      // let selected = temp_values.map(function(e) { return e.value; }).indexOf(this.props.selectedItems[0].value);
      // if(selected != -1){
      //   this.setState({currentButtonYPos: selected == 0 ? -40 : 40}, () => this.activeContentSelected())    
      // }
      try{
        this.setState({currentButtonYPos: Object.entries(this.props.selectedItems)[0][0] === '0' ? -40 : 40}, () => this.activeContentSelected())  
      }catch(e){
        console.log("error")
      }
      

    }
  }

  init() {
    let that = this;
    let button = document.querySelector('.c-ipsative__button');

    gsap.registerPlugin(Draggable);
    Draggable.create(".c-ipsative__button", {
      type:"x, y",
      bounds: document.querySelector(".c-ipsative__wrapper-button"),
      onDragStart:function(e) {
        console.log(that.props.selectedItems)
        if(Object.keys(that.props.selectedItems).length > 0){
          that.setState({isComplete: false}, () => that.reset())    
        } 
        console.log("started")
      },
      onClick:function() {  
        console.log("clicked");
      },
      onDragEnd:function(e) {
        that.setState({ 
          currentButtonYPos:  this.y,
        }, () => that.complete(that.state));
      }
    });
  }

  complete(state) {
    !state.isComplete && this.activeContentSelected()
  }

  centered() {
    gsap.to('.c-ipsative__button', {
      x: 0,
      y: 0,
      duration: 1,
      ease:Elastic.easeOut.config(1, 0.3)
    })
  }
  reset(){
    let blockFirst = document.querySelector('.c-ipsative__block:first-child');
    let blockLast = document.querySelector('.c-ipsative__block:last-child');
    let button = document.querySelector('.c-ipsative__button');

    blockFirst.classList.remove('no-selected')
    blockFirst.classList.remove('selected');  
    blockLast.classList.remove('no-selected')
    blockLast.classList.remove('selected');  
    button.classList.remove('active');
  }
  activeContentSelected() {
    let blockFirst = document.querySelector('.c-ipsative__block:first-child');
    let blockLast = document.querySelector('.c-ipsative__block:last-child');
    let button = document.querySelector('.c-ipsative__button');

    if (this.state.currentButtonYPos < -36) {

      blockFirst.classList.add('selected');
      blockLast.classList.remove('selected')
      blockLast.classList.add('no-selected');

      gsap.to('.c-ipsative__button', {
        x: 0,
        y: -128,
        duration: 1,
        ease:Elastic.easeOut.config(1, 0.3)
      })

      button.classList.add('active');
      this.setState({ isComplete: true });

      this.submit(0);

    } else if (this.state.currentButtonYPos > 36) {

      blockLast.classList.add('selected');
      blockFirst.classList.remove('selected')
      blockFirst.classList.add('no-selected');

      gsap.to('.c-ipsative__button', {
        x: 0,
        y: 128,
        duration: 1,
        ease:Elastic.easeOut.config(1, 0.3)
      })

      button.classList.add('active');
      this.setState({ isComplete: true });

      this.submit(1);

    } else {
      this.centered();
    }

  }

  submit(competence) {
    let button = document.querySelector('.c-ipsative__button');
    button.classList.add('disabled');
    setTimeout(() => this.props.submit(competence), 300);
  }

  showDragMe() {
    if (this.props.showHint) {
      let tooltip = document.querySelector('.c-ipsative__tooltip');
      setTimeout(() => {
        if (tooltip !== null) {
         
          let that = this;
          gsap.to('.c-ipsative__tooltip', {
            scale: 1,
            y: -85,
            x: 0,
            opacity: 1,
            duration: 1,
            ease:Elastic.easeOut.config(1, 0.3),
            onComplete: () => setTimeout(() => that.hideDragMe(), 2000),
          })
        }
      }, 1000)
    }
  }

  hideDragMe() {
    let tooltip = document.querySelector('.c-ipsative__tooltip');
      let that = this;
      if (tooltip !== null) {
        gsap.to('.c-ipsative__tooltip', {
          scale: 0,
          y: 4,
          x: 36,
          opacity: 0,
          duration: 1,
          ease:Elastic.easeOut.config(1, 0.3),
          onComplete: () => {
            setTimeout(() => {
              tooltip.remove()
            }, 1000)
          },
        })
      }

    }

  render() {
    return (
      <div className="c-ipsative__container-button">
        <button
          type="button"
          className={`c-ipsative__button ${this.state.isComplete ? 'active' : ''}`}
        >
          {
            this.props.showHint &&
              <SurveyIpsativeTooltip text="Drag me!" />
          }
          <Smile
            color={!this.state.isComplete ? "black" : this.state.secondaryColor}
            outsideColor={!this.state.isComplete ? "black" : this.state.secondaryColor}
            emojiColor={"white"}
            withCircleEyes={true}
            withoutMouth={!this.state.isComplete}
          />
        </button>
      </div>
    );
  }
};

