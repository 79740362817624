// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Modal = (props) => {

  const [modalClasses, setModalClasses] = useState("c-modal");

  useEffect(()=>{
    handleClasses()
    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [props.isVisible])

  const handleClasses = () => {

    // Modal
    setModalClasses(
      classNames(
        "c-modal", 
        props.isVisible && "is-visible",
        props.small && "c-modal--small",
        props.medium && "c-modal--medium",
        props.className && props.className
      )
    )

    //Body
    if (props.isVisible) {
      let body = document.body
      if (getComputedStyle(body).overflow !== 'hidden') {
        body.style.overflow = 'hidden'
      }
    }
  }

  return (
    <div className={modalClasses}>
      <div className="c-modal__container">
        <div className="c-modal__content">{props.children}</div>
      </div>
    </div>
  );

}

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
};

export default Modal;
