import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import Input from "../../components/Input";
import ProcedureItem from "./components/ProcedureItem";
import Pagination from "../../components/Pagination";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import Spinner from "../../components/Spinner";
import Roles from "../../config/roles";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// CustomHook
import { usePagination } from "../../hooks/usePagination";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchProceduresIndex,
  fetchProcedureCreate,
} from "../admin/actions";
import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const ProceduresHome = (props) => {
  const [modals, setModals] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalMsg, setModalMsg] = useState("");
  const [procedureData, setProcedureData] = useState(undefined);
  const [valueSearch, setValueSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(null);
  const [procedureIdSelected, setProcedureIdSelected] = useState(undefined);
  const [biodataOptions, setBiodataOptions] = useState([]);

  useEffect(() => {
    props.fetchProceduresIndex(props.match.params.organizationId, 1, 5000, () =>setLoading(false));
  }, []);

  useEffect(() => {
    props.organizations.length === 0 && props.fetchOrganizations();
    if(props.organization === undefined || ""+props.organization.data.id !== ""+props.match.params.organizationId){
      props.fetchOrganization(
      props.match.params.organizationId,
      1,
      5000,
      () => "",
      true
      );
      props.proceduresIndex === undefined && props.fetchProceduresIndex(props.match.params.organizationId, 1, 5000, () =>
        setLoading(false)
      );
    }
  }, [props.match.params.organizationId]);

  const proceduresFiltered = () => {
    return props.proceduresIndex.filter(
      (value) =>
        value.title.toLowerCase().indexOf(valueSearch.toLowerCase(), 0) !== -1
    );
  };

  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
  } = usePagination(props.proceduresIndex ? proceduresFiltered() : [], 8);


  useEffect(() => {
    setCurrentPage(-1);
    setTimeout(() => setCurrentPage(paginationManage.currentPagination), 500);
  }, [paginationManage.currentPagination]);

  const selectProcedure = (id) => {
    let selectId = undefined;
    if (procedureIdSelected !== id) {
      selectId = id;
    }
    setProcedureIdSelected(selectId);
  };

  const actionModals = (procedure, type) => {
    if (type === "delete") {
      setModalMsg("Are you sure you want to delete the process?");
    } else if (type === "createProcedure") {
      setModalMsg("The process has been created successfully!");
    } else if (type === "invite") {
      if (procedure.survey !== "") {
        setModalMsg("The users has been added successfully!");
      } else {
        type = "success";
        setModalMsg(
          "You must have a assessment assigned to be able to invite users. You can assign a assessment by editing the process"
        );
      }
    }
    setModals(type);
    setProcedureData(procedure);
  };

  const selectAction = (id) => {
    props.history.push(`/admin/organizations/${id}/procedures`);
  };

  const getAssessments = () => {
    let bio = props.organization.data.biodata_options
    if(bio.filter((e) => e.value === "email").length == 0){
      bio.unshift({text: "Password", value: "password"})
      bio.unshift({text: "Email", value: "email"})  
      setBiodataOptions(bio)
    }
    
    

    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => actionModals({}, "createProcedure")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals("success"),
        setModalMsg("The organization has been created successfully!"),
        props.fetchOrganizations();
    });
  };

  const submitCreate = (data) => {
    data["title"] = data["name"]
    delete data["name"]; 
    props.fetchProcedureCreate(props.match.params.organizationId, data, () => {
        setModals("success"),
        setModalMsg("The process has been created successfully!"),
        props.fetchProceduresIndex(props.match.params.organizationId, 1, 5000, () =>
          setLoading(false)
        );
      }, () => "");
  };

  const getPrepareFoldersCreate = () => {
    let folders = []
    props.organization.data.folders.forEach(function(item, index) {
      folders.push({"id": item.id, "text": item.name})
    });
    // let folders = .map((item) => {"id": item.id, "text": item.name} )
    return folders;
  }
  return (
    <>
      <motion.section
        className="o-wrapper o-wrapper--row"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {props.organizations &&
          props.organizations.length > 0 &&
          props.organization &&
          props.organization.data && (
            <Aside
              history={props.history}
              label={"Select organization:"}
              showSelect={true}
              organizations={props.organizations && props.organizations}
              organization={props.organization}
              selectAction={(id) => selectAction(id)}
              createOrganization={() => getAssessments()}
              selectOrganization={() => setModals("selectOrganization")}                          
              showModalSession={() => {
                setModals("closeSession"),
                  setModalMsg("Are you sure you want to logout?");
              }}
            />
          )}

        {props.organization && props.organization.data && (
          <div className="o-container">
            <h1 key={"_name_id_"+props.organization.data.id} className="o-text-align o-text-align--left">
              {props.organization.data.name}
            </h1>

            {props.organization &&
              props.organization.user_role !== Roles.admin.READER && (
                <button
                  key={"buttons-folder"}
                  type="button"
                  className={
                    "c-button c-button--margin-zero o-margin--top-size-l o-margin--bottom-size-xl"
                  }
                  onClick={() => getAssessments()}
                >
                  <span className="c-button__text">New process</span>
                </button>
              )}
            {props.proceduresIndex && props.proceduresIndex.length > 0 && (
              <div className="o-margin--top-size-l o-margin--bottom-size-l">
                <form className="o-width--max-width-500">
                  <Input
                    className="c-input"
                    name="search"
                    label="Search procedures:"
                    search={true}
                    errorLabel="This field can not be blank"
                    minCharacters={5}
                    type="text"
                    value={valueSearch}
                    onChange={(e) => setValueSearch(e.currentTarget.value)}
                  />
                </form>
              </div>
            )}

            {props.proceduresIndex && proceduresFiltered().length > 0 && (
              <div className="c-pagination__wrapper">
                <AnimatePresence exitBeforeEnter>
                  {currentPage === paginationManage.currentPagination && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="o-grid o-grid--two-four o-margin--bottom-size-xl"
                    >
                      {proceduresFiltered()
                        .slice(
                          paginationManage.paginationStart,
                          paginationManage.paginationEnd
                        )
                        .map((procedure, index) => {
                          let selected =
                            procedureIdSelected === index ? "show" : "hidden";
                          return (
                            <ProcedureItem
                              key={procedure.id}
                              index={index}
                              alignLeft={true}
                              name={procedure.title}
                              canManage={
                                props.organization &&
                                props.organization.user_role !==
                                Roles.admin.READER
                              }
                              goTo={() =>
                                props.history.push(
                                  `/admin/organizations/${props.organization.data.id}/procedures/${procedure.id}`
                                )
                              }
                              selected={selected}
                              selectProcedure={(index) => selectProcedure(index)}
                            />
                          );
                        })}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            )}
            {props.proceduresIndex && props.proceduresIndex.length === 0 && (
              <h2 className="o-text-align--center o-margin--top-size-xxl">
                Currently there are no process
              </h2>
            )}

            {props.proceduresIndex && proceduresFiltered().length > itemsForPage && (
              <Pagination
                marginTop={true}
                paginationManage={paginationManage}
                pagination={proceduresFiltered()}
                goToPage={(index) => goToPage(index)}
                goToLastPage={() => goToLastPage()}
                goToFirstPage={() => goToFirstPage()}
                goToNextPage={() => goToNextPage()}
                goToPreviousPage={() => goToPreviousPage()}
                itemsForPage={itemsForPage}
                isFirst={() => checkFirst()}
                isLast={() => checkLast()}
              />
            )}
          </div>
        )}

        {["createProcedure", "createOrganization", "edit"].includes(modals) && (
          <ModalCreate
            showModal={true}
            closeModal={() => setModals("")}
            editAction={(data) =>
              modals === "edit"
                ? submitEdit(data)
                : modals === "createProcedure"
                  ? submitCreate(data)
                  : submitCreateOrganization(data)
            }
            defaultData={procedureData}
            type={modals}
            title={
              modals !== "edit"
                ? modals === "createProcedure"
                  ? "New process:"
                  : "New organization:"
                : "Edit process"
            }
            // assessments={getPrepareFoldersCreate()}
            assessments = {props.organization.data.assessments}
            biodataOptions={biodataOptions}
          />
        )}
      {["success"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={modalMsg}
          action={() => setModals("")}
        />
      )}        
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
        {loading && <Spinner />}
      </motion.section>
    </>
  );
};

ProceduresHome.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
    state,
    proceduresIndex: state.admin.proceduresIndex,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchProceduresIndex,
  fetchProcedureCreate,
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresHome);
