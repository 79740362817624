import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationWellcomeAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width="428px"
        height="428px"
        viewBox="0 0 428 428"
      >
        <g fill="none" fillRule="nonzero">
      <path
        fill="#A0616A"
        d="M217.829 279.39c38.366 0 69.468-31.103 69.468-69.469 0-38.367-31.102-69.469-69.468-69.469-38.367 0-69.469 31.102-69.469 69.469 0 38.366 31.102 69.469 69.469 69.469Z"
      />
      <path
        fill="#A0616A"
        d="m160.329 294.945 14.482-37.008 74.819-11.263 8.039 81.921-75.624 1.609z"
      />
      <path
        fill="#2F2E41"
        d="M287.97 222.738c-.901 3.612-2.724 12.212-5.57 12.255-2.325.034-4.551-37.277-2.228-43.448 2.668-7.09-4.344-16.059-9.625-20.663-9.497-8.278-30.577 9.497-52.013 1.445-.568-.213-.455-.189-7.224-2.89-20.828-8.31-21.923-8.55-23.117-8.668-10.097-.999-22.419 7.263-27.451 17.337-5.23 10.472-.86 19.454-5.779 44.789-.573 2.953-1.473 7.162-2.89 7.224-2.589.113-5.117-13.716-5.779-17.338-2.774-15.174-5.448-29.804 0-46.233.595-1.792 4.257-8.443 11.559-21.672 10.518-19.058 15.808-28.622 17.337-30.341 10.965-12.33 30.922-23.578 47.678-18.782 8.47 2.424 6.951 6.321 17.338 10.113 18.51 6.759 34.598-1.507 37.565 4.335 1.571 3.094-2.826 5.645-2.89 11.558-.109 10.146 12.723 13.078 20.227 23.117 4.034 5.397 10.94 5.455-7.138 77.862Z"
      />
      <path
        fill="#3C5896"
        d="M374.033 356.085a217.823 217.823 0 0 1-8.713 9.236C324.902 405.739 271.16 428 214 428c-49.405 0-96.252-16.629-134.152-47.241-.025-.015-.04-.032-.065-.056a213.937 213.937 0 0 1-14.594-12.92c.089-.708.186-1.408.29-2.1.66-4.666 1.392-9.116 2.212-13.21 6.718-33.564 95.11-68.802 97.411-69.719.032-.008.048-.016.048-.016s11.376 41.931 60.121 41.392c33.202-.362 26.766-41.392 26.766-41.392s.402.08 1.159.241c9.589 2.036 76.171 16.661 102.438 36.622 8.005 6.082 13.749 19.035 17.876 34.481.177.66.346 1.335.523 2.003Z"
      />
      <path
        fill="#3F3D56"
        d="M365.321 62.68C324.902 22.262 271.161 0 214 0S103.098 22.262 62.68 62.68C22.26 103.098 0 156.84 0 214c0 52.173 18.544 101.497 52.527 140.444a215.253 215.253 0 0 0 10.152 10.877c.83.828 1.666 1.657 2.51 2.462a213.937 213.937 0 0 0 14.595 12.92c.023.024.04.041.064.056C117.748 411.371 164.595 428 214 428c57.161 0 110.902-22.261 151.321-62.679a219.114 219.114 0 0 0 8.713-9.236C408.925 316.889 428 266.913 428 214c0-57.16-22.261-110.902-62.679-151.32Zm8.189 291.402a213.393 213.393 0 0 1-20.378 20.258 212.507 212.507 0 0 1-45.503 30.273 209.856 209.856 0 0 1-17.78 7.78c-23.564 9.043-49.139 13.998-75.849 13.998-25.777 0-50.491-4.617-73.363-13.065a209.802 209.802 0 0 1-27.177-12.277 211.063 211.063 0 0 1-23.057-14.425 212.518 212.518 0 0 1-24.924-20.941 214.125 214.125 0 0 1-12.462-13.298C20.99 315.184 1.61 266.816 1.61 214 1.609 96.888 96.887 1.609 214 1.609c117.113 0 212.391 95.279 212.391 212.391 0 53.629-19.976 102.672-52.881 140.082Z"
      />
    </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
