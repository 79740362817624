import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import {DICTIONARY} from "../../lib/dictionaries";

// Actions
import {fetchProcedureClient, postProcedureClient} from "./actions"
// Config
import routes from "../../config/routes";

// Components
import LinkAnchor from "../../components/LinkAnchor";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Datepicker from "../../components/Datepicker";
import Checkbox from "../../components/Checkbox";
import MultiAnswers from "../../components/MultiAnswers";
import RadioButton from "../../components/RadioButton";
import UploadFileInput from "../../components/uploadFileInput";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import File from "../../components/File";
import Spinner from "../../components/Spinner";

import IllustrationWarning from "../../components/IllustrationWarning";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

let fileResult = [];

let fileRefRadiobutton = 0;

const SanitasBiodataProcedure = (props) => {

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.killer_questions);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectValue, setSelectValue] = useState({});

  const refs = useRef([]); // or an {}
  refs.current = []; // or an {}

  const _submit = () => {
    setSubmitting(true)
    setLoading(true)
    let validated = _validateData()
    if(validated){
      props.submit(form)
    }else{
      setLoading(false)
      setSubmitting(false)
    }
  }

  const _validateData = () => {
    let errors = {}

    if(form['age'] === undefined || form['age'] === ""){
      errors['age'] = "La pregunta es obligatoria"
    }
    if(form['education'] === undefined || form['education'] === ""){
      errors['education'] = "La pregunta es obligatoria"
    }
    if(form['insurance_experience'] === undefined || form['insurance_experience'] === ""){
      errors['insurance_experience'] = "La pregunta es obligatoria"
    }
    if(form['insurance_experience'] === true && form['insurance_experience_years'] === undefined || form['insurance_experience_years'] === ""){
      errors['insurance_experience_years'] = "La pregunta es obligatoria"
    }
    if(form['insurance_commercial'] === undefined || form['insurance_commercial'] === ""){
      errors['insurance_commercial'] = "La pregunta es obligatoria"
    }
    if(form['insurance_commercial'] === true && form['insurance_commercial_years'] === undefined || form['insurance_commercial_years'] === ""){
      errors['insurance_commercial_years'] = "La pregunta es obligatoria"
    }
    if(form['insurance_experience_sector'] === undefined || form['insurance_experience_sector'] === ""){
      errors['insurance_experience_sector'] = "La pregunta es obligatoria"
    }
    if(form['insurance_experience_sector'] === "Otros (Detallar)" && form['insurance_experience_sector_other'] === undefined || form['insurance_experience_sector_other'] === ""){
      errors['insurance_experience_sector_other'] = "La pregunta es obligatoria"
    }
    if(form['experience_objectives'] === undefined || form['experience_objectives'] === ""){
      errors['experience_objectives'] = "La pregunta es obligatoria"
    }
    if(form['self_employment'] === undefined || form['self_employment'] === ""){
      errors['self_employment'] = "La pregunta es obligatoria"
    }
    setErrors(errors)
    return Object.keys(errors).length == 0
  }

  const _prepareData = () => {
    let new_data = []
    
  }

  return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {
          !loading && 
          <div className="o-container">
            <div className="o-col o-pos-rel">
              <form className="o-width--full-width">
                <div>
                  <p className="o-margin--top-size-l u-text--headingH6">Información personal</p>
                  <p>Edad</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["age"]}
                    options={Array.from({length: 100}, (_, i) => ({"text": i, "value": i}))}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['age']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                   {
                    errors["age"] !== undefined && 
                      <span className="c-input__error" style={{position: 'relative', top: -5}}>
                        {errors["age"]}
                      </span>
                    }
                </div>
                <div>
                  <hr className="o-margin--top-size-xl" />
                  <p className="o-margin--top-size-l u-text--headingH6">Formación</p>
                  <p>Estudios</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["education"]}
                    options={[
                      {"text": "Educación secundaria/ ESO", "value": "Educación secundaria/ ESO"},
                      {"text": "FP", "value": "FP"},
                      {"text": "Bachillerato", "value": "Bachillerato"},
                      {"text": "Formación Universitaria", "value": "Formación Universitaria"},
                      {"text": "Máster / Doctorado", "value": "Máster / Doctorado"},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['education']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                    errors["education"] !== undefined && 
                      <span className="c-input__error" style={{position: 'relative', top: -5}}>
                        {errors["education"]}
                      </span>
                    }
                </div>
                <div>
                  <hr className="o-margin--top-size-xl" />
                  <p className="o-margin--top-size-l u-text--headingH6">Experiencia</p>
                  <p>Experiencia en seguros</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["insurance_experience"]}
                    options={[
                      {"text": "Sí", "value": true},
                      {"text": "No", "value": false},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['insurance_experience']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                    errors["insurance_experience"] !== undefined && 
                      <span className="c-input__error" style={{position: 'relative', top: -5}}>
                        {errors["insurance_experience"]}
                      </span>
                    }
                  { form['insurance_experience'] == true && 
                    <>
                      <p>Nº años experiencia en seguros</p>
                      <Select
                        withoutInput={true}
                        valueSelected={form["insurance_experience_years"]}
                        options={Array.from({length: 100}, (_, i) => ({"text": i, "value": i}))}
                        optionClick={(text, value, data) => {
                          setForm({...form, ['insurance_experience_years']: value})
                        }}
                        wrapperClassName='c-procedure__select'
                      />
                      {
                      errors["insurance_experience_years"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["insurance_experience_years"]}
                        </span>
                      } 
                    </>
                  }
                  <p>Experiencia comercial</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["insurance_commercial"]}
                    options={[
                      {"text": "Sí", "value": true},
                      {"text": "No", "value": false},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['insurance_commercial']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                    errors["insurance_commercial"] !== undefined && 
                      <span className="c-input__error" style={{position: 'relative', top: -5}}>
                        {errors["insurance_commercial"]}
                      </span>
                    }
                  { form['insurance_commercial'] == true && 
                    <>
                      <p>Nº años experiencia comercial</p>
                      <Select
                        withoutInput={true}
                        valueSelected={form["insurance_commercial_years"]}
                        options={Array.from({length: 100}, (_, i) => ({"text": i, "value": i}))}
                        optionClick={(text, value, data) => {
                          setForm({...form, ['insurance_commercial_years']: value})
                        }}
                        wrapperClassName='c-procedure__select'
                      /> 
                      {
                      errors["insurance_commercial_years"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["insurance_commercial_years"]}
                        </span>
                      }
                    </>
                  } 
                  <p>Sector de la experiencia comercial más extensa</p>                 
                  <Select
                    withoutInput={true}
                    valueSelected={form["insurance_experience_sector"]}
                    options={[
                      {text: "Agroalimentario", value: "Agroalimentario"},
                      {text: "Alarmas y seguridad", value: "Alarmas y seguridad"},
                      {text: "Automoción", value: "Automoción"},
                      {text: "Banca ", value: "Banca "},
                      {text: "Call center y centros de llamadas", value: "Call center y centros de llamadas"},
                      {text: "Editorial", value: "Editorial"},
                      {text: "Educación", value: "Educación"},
                      {text: "Energia", value: "Energia"},
                      {text: "Gran consumo y alimentación", value: "Gran consumo y alimentación"},
                      {text: "Hostelería y restaurantes", value: "Hostelería y restaurantes"},
                      {text: "Importación y exportación", value: "Importación y exportación"},
                      {text: "Industria farmaceútica", value: "Industria farmaceútica"},
                      {text: "Inmobiliario ", value: "Inmobiliario "},
                      {text: "Materiales para la construcción", value: "Materiales para la construcción"},
                      {text: "Publicidad, RRPP y eventos", value: "Publicidad, RRPP y eventos"},
                      {text: "Salud y bienestar", value: "Salud y bienestar"},
                      {text: "Seguros", value: "Seguros"},
                      {text: "Servicios online", value: "Servicios online"},
                      {text: "Telecomunicaciones", value: "Telecomunicaciones"},
                      {text: "Otros (Detallar)", value: "Otros (Detallar)"},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['insurance_experience_sector']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                      errors["insurance_experience_sector"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["insurance_experience_sector"]}
                        </span>
                      }
                  { form['insurance_experience_sector'] == "Otros (Detallar)" && 
                    <>
                      <Input
                        key={"other"}
                        label={"Detallar otros:"}
                        value={form["insurance_experience_sector_other"]}
                        wrapperClassName={'o-height--initial'}
                        onChange={(e) => {
                          setForm({...form, ["insurance_experience_sector_other"]: e.target.value})
                        }}
                      />

                      {
                        errors["insurance_experience_sector_other"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["insurance_experience_sector_other"]}
                        </span>
                      }
                    </>
                  } 
                  <p>Experiencia trabajando por objetivos</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["experience_objectives"]}
                    options={[
                      {"text": "Sí", "value": true},
                      {"text": "No", "value": false},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['experience_objectives']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                        errors["experience_objectives"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["experience_objectives"]}
                        </span>
                      }
                  <p>¿Has emprendido o iniciado un proyecto de autoempleo anteriormente?</p>
                  <Select
                    withoutInput={true}
                    valueSelected={form["self_employment"]}
                    options={[
                      {"text": "Sí", "value": true},
                      {"text": "No", "value": false},
                    ]}
                    optionClick={(text, value, data) => {
                      setForm({...form, ['self_employment']: value})
                    }}
                    wrapperClassName='c-procedure__select'
                  />
                  {
                        errors["self_employment"] !== undefined && 
                        <span className="c-input__error" style={{position: 'relative', top: -5}}>
                          {errors["self_employment"]}
                        </span>
                      }
                </div>
              </form>
            </div>
            <div className="c-button__wrapper o-margin--top-size-xl">
              <button
                type="button"
                className="c-button c-button--margin-zero"
                // disabled={Object.keys(errors).length !== 0}
                onClick={() => submitting ? "" : _submit()}
              >
                <span className="c-button__text">Continuar</span>
              </button>
            </div>
          </div>

        }
        { showModal && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={true}
          closeModalInfoAction={() => setShowModal(false)}
          text={"Revisa los errores, por favor."}
          action={() => setShowModal(false)}
          primaryButtonText={"Ok"}
        />
      )}
      {loading && <Spinner />}
    </motion.section>
  )
};

SanitasBiodataProcedure.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
  };
};

const mapDispatchToProps = {
  fetchProcedureClient,
  postProcedureClient
};

export default connect(mapStateToProps, mapDispatchToProps)(SanitasBiodataProcedure);
