import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Close from "../Close";
import Less from "../Less";
import File from "../File";
import UploadFileButton from "../UploadFileButton";
import Select from "../Select";

const ModalInvitationFolder = (props) => {
  const [invitationData, setInvitationData] = useState([]);
  const [disabledButtom, setDisabledButtom] = useState(true);
  const [file, setFile] = useState(false);

  useEffect(() => {
    setInvitationData([{ email: "", language: "es" }]);
  }, []);

  const addInvitation = () => {
    setInvitationData([...invitationData, { email: "", language: "es" }]);
  };

  const deleteInvitation = (index) => {
    let deleteInvitation = invitationData;
    deleteInvitation.splice(index, 1);
    setInvitationData([...deleteInvitation]);
  };

  const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const getInvitationValue = (type, index, value) => {
    let addValue = invitationData;

    if(type === 'email') {
      addValue[index].email = value;
      if (validateEmail(value) || value === "") {
        addValue[index].error = "";
        setDisabledButtom(false);
      } else {
        addValue[index].error =
          "El formato del email debe ser correcto: example@example.com";
        setDisabledButtom(true);
      }
    }
    if(type === 'language') addValue[index].language = value;

    setInvitationData([...addValue]);
  };

  const showIcons = (item, index) => {
    return (index === 0 && invitationData.length === 1) ||
      (invitationData.length > 1 && index === invitationData.length - 1) ? (
      <div
        className="o-cursor--pointer"
        onClick={() =>
          item.email !== "" && item.error === "" ? addInvitation() : {}
        }
      >
        <Close rotation={true} />
      </div>
    ) : (
      <div
        className="o-cursor--pointer"
        onClick={() => deleteInvitation(index)}
      >
        <Less />
      </div>
    );
  };

  const sendData = (invitationData) => {
    invitationData.map((value) => delete value.error);
    props.invitationAction(invitationData, file);
  };

  const checkEmails = () => {
    
    let isEmails = invitationData.filter((item) => item.email !== "");
    return checkErrors().length === 0 && isEmails.length > 0;
  };

  const checkErrors = () => {
    return invitationData.filter(
      (item) => item.error && item.error !== ""
    );
  }

  const checkData = () => {
    return (
      (checkEmails() && !disabledButtom) ||
      (file && checkErrors().length === 0)
    );
  };

  return (
    <Modal isVisible={props.showModalInvitationFolder} medium>
      <div className="c-modal-invitation">
        <form>
          <h2 className="o-margin--bottom-size-l">Invite user:</h2>

          {invitationData.map((item, index) => {
            return (
              <motion.div
                key={"invitation_" + index}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="o-display--flex o-display--flex-v-center"
              >
                <div className="o-col--1/2 o-padding--right">
                  <Input
                    label="Email"
                    type="email"
                    isRequired
                    errorLabel={item.error}
                    showError
                    autoCorrect={"off"}
                    autoCapitalize={"none"}
                    minCharacters={""}
                    value={item.email}
                    onChange={(e) => getInvitationValue('email', index, e.currentTarget.value)}
                    onChangeValidation={(value) => {}}
                  />
                </div>
                <div className="o-col--1/2 o-padding--left">
                  <Select
                    label="Idioma"
                    withoutInput
                    isRequired
                    valueSelected={item.language}
                    options={[{text: "Spanish", value: "es"}, {text: "English", value: "en"}, {text: "Català", value: "cat"}, {text: "Portugués", value: "pt"},  {text: "Deutsch", value: "de"}, {text: "Croatian", value: "hr"}]}
                    optionClick={(text, value) => getInvitationValue('language', index, value)}
                    style={{ zIndex: 1005 }}
                    tick
                  />
                </div>

                <div className="o-margin--left-size-l">
                  {showIcons(item, index)}
                </div>
              </motion.div>
            );
          })}
        </form>
        <div className="c-modal-invitation--align-items">
          <p className="o-margin--right-size-l">
          From here you can import an excel file:
          </p>

          <UploadFileButton
            getFile={(file) => setFile(file)}
            textButton={"Import"}
          />
        </div>
        {file && file.original_filename && (
          <div className="c-modal-invitation__info-file">
            <File width={"36px"} height={"46px"} />
            <p className="c-modal-invitation__info-file-text">
              {file.original_filename}
            </p>
          </div>
        )}

        <p className="c-modal-invitation__small-text">
          *You can download an example template by clicking
          <a
            className="c-modal-invitation__small-text-underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://t4-stag.s3.eu-west-3.amazonaws.com/example_files/import_users_template.xlsx"
          >
            here
          </a>
        </p>
      </div>
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => {
            props.closeInvitationFolder();
          }}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        <button
          type="button"
          disabled={!checkData()}
          className="c-button c-modal__button"
          onClick={() => sendData(invitationData)}
        >
          <span className="c-button__text">Confirm</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalInvitationFolder;
