import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

//Components
import Button from "../components/Button";
import IllustrationHeadLaptop from "../components/IllustrationHeadLaptop";


const NoMobileView = (props) => {
  return (
    <motion.section
      className="o-wrapper c-no-mobile-view"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h2 className="c-no-mobile-view__title">Por favor, utiliza un ordenador o tablet para visualizar este contenido.</h2>
      <IllustrationHeadLaptop />
    </motion.section>
  )
}

NoMobileView.propTypes = {};

export default NoMobileView;
