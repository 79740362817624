import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import classNames from "classnames";

// Actions
import { fetchLogin } from "./actions";

// Selectors
import { isAuthenticated } from "../../selector";
import internalization from "../../config/localize"

// Components
import LinkAnchor from "../../components/LinkAnchor";
import Input from "../../components/Input";
import Button from "../../components/Button";
import IllustrationWellcome from "../../components/IllustrationWellcome";
import IllustrationWellcomeAdmin from "../../components/IllustrationWellcomeAdmin";
import ServerError from "../../components/ServerError";
import Modal from "../../components/Modal";
import detectLanguage from "../../helpers/detectLanguage";

// Config
import routes from "../../config/routes";

const timeouts = { email: null, password: null };

// var languageUserAgent = /^es/.test(navigator.language || navigator.userLanguage || "en") ? "es" : "en";
var languageUserAgent = detectLanguage()
internalization.setLanguage(languageUserAgent)

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
        password: "",
      },
      validations: {
        email: {
          min: 5,
          valid: false,
        },
        password: {
          min: 8,
          valid: false,
        },
      },
      errors: {
        email: null,
        password: null,
      },
      serverErrors: "",
      isSubmitButtonDisabled: true,
      isModalVisible: false,
      modalErrorTitle: "",
      modalErrorDescription: "",
      isAdmin: this.props.location.pathname === "/admin/signin"
    };
  }

  componentDidMount(){ 
    if(this.props.history.location && this.props.history.location.state && this.props.history.location.state.hasOwnProperty("message_error")){
      this.setState({modalErrorTitle: this.props.history.location.state.message_error.title, modalErrorDescription: this.props.history.location.state.message_error.description, isModalVisible: true})
      this.props.history.replace()
    }
    if(this.props.authenticated){
      this.props.history.push("/")
    }    
  }

  static getDerivedStateFromProps(props, state) {
    if (props.errors && props.errors.length) { state.serverErrors = props.errors }
    state.isSubmitButtonDisabled = state.submitting || !Object.values(state.validations).every((h) => Object.values(h)[1] === true);
    return state;
  }

  _validateMin = (type, value, state) => {
    clearTimeout(timeouts[type]);

    if (value.length < state.validations[type].min) {
      timeouts[type] = setTimeout(() => {
        this.setState({
          validations: {
            ...state.validations,
            [type]: { ...state.validations[type], valid: false },
          },
          errors: {
            ...state.errors,
            [type]: `Mínimo ${state.validations[type].min} caracteres`,
          },
        });
      }, 1000);
    } else {
      this.setState({
        validations: {
          ...state.validations,
          [type]: { ...state.validations[type], valid: true },
        },
        errors: { ...state.errors, [type]: null },
      });
    }
  };

  _validateEmail = (email, state) => {

    // const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
    !emailPattern.test(email) &&
      this.setState({
        errors: { ...state.errors, email: "Email no es válido" },
      });
  };

  _validate = (type, value, state) => {
    this._validateMin(type, value, state);
  };

  _setValues = (value, type, state) => {
    this.setState({
      data: { ...state.data, [type]: value },
      serverErrors: ""
    });
  };

  _submit = (data) => {
    this.props.fetchLogin(data, (response) => this._success(response), (message) => this._error(message));
  };

  _success = (response) => {
    let pathname = routes.client.surveys

    if(response.data && response.data.attributes && response.data.attributes.admin){
      pathname = "/admin"
    }
    else{
      try{
        pathname = (this.props.history.location && Object.keys(this.props.history.location.state).length > 0 && Object.keys(this.props.history.location.state.from).length > 0) ? this.props.history.location.state.from.pathname : routes.client.surveys
      }catch(e){
        pathname = routes.client.surveys
      }  
    }
    
    
    this.props.history.push(pathname);
  }

  _error = (message) => {
    if(message){
      this.setState({serverErrors: message})  
    }
  }
  _showPasssword = (state) => {
    this.setState({ showPassword: !state.showPassword });
  };

  _titleClasses = () => {
    return classNames(!this.state.isAdmin && "o-margin--bottom-size-l");
  }

  render = () => {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {!this.props.authenticated && (
          <React.Fragment>
            <div className="o-container o-display--flex@lg-and-up o-display--flex-row-reverse@lg-and-up">
              <div className="o-col--1/2@lg">
                <h1 className={this._titleClasses()}>{internalization.signin.title}</h1>
                {
                  this.state.isAdmin &&
                    <h3 className="o-margin--bottom-size-l">{internalization.signin.title_backoffice}</h3>
                }
                {
                  this.state.isAdmin ?

                <p className="o-margin--bottom-size-l"dangerouslySetInnerHTML={{__html: internalization.signin.subtitle_backoffice }}></p>
                :
                <p className="o-margin--bottom-size-l" dangerouslySetInnerHTML={{__html: internalization.signin.subtitle }}></p>
                }
                <form>
                  <Input
                    className="c-input--white-bg"
                    label="Email"
                    type="email"
                    errorLabel={this.state.errors.email}
                    showError={true}
                    autoCorrect={"off"}
                    autoCapitalize={"none"}
                    isRequired={true}
                    minCharacters={this.state.validations.email.min}
                    value={this.state.data.email}
                    onChange={(e) => {
                      this._setValues(
                        e.currentTarget.value,
                        "email",
                        this.state
                      );
                    }}
                    onChangeValidation={(value) => {
                      this._validate("email", value, this.state)
                      this._validateEmail(value, this.state)
                    }}
                  />
                  <Input
                    className="c-input--white-bg"
                    label={internalization.form.password}
                    errorLabel={this.state.errors.password}
                    showError={true}
                    isRequired={true}
                    minCharacters={this.state.validations.password.min}
                    type="password"
                    value={this.state.data.password}
                    onChange={(e) => {
                      this._setValues(
                        e.currentTarget.value,
                        "password",
                        this.state
                      );
                    }}
                    onChangeValidation={(value) =>
                      this._validate("password", value, this.state)
                    }
                  />
                  {this.state.serverErrors.length ? (
                    <ServerError message={this.state.serverErrors} />
                  ) : (
                    []
                  )}
                  <LinkAnchor
                    href={routes.client.rescuePassword}
                    text={internalization.signin.forgot_password}
                    className="c-link o-margin--top-size-l"
                  />
                  <div className="c-button__wrapper">
                    <Button
                      name="login"
                      type="submit"
                      text={internalization.buttons.send}
                      disabled={this.state.isSubmitButtonDisabled}
                      onClick={() => this._submit(this.state.data)}
                    />
                  </div>
                </form>
              </div>
              <div className="o-col--1/2@lg o-display--flex o-display--flex-v-center o-display--flex-h-right@md-only u-hide@sm-and-down o-margin--top-size-xxl@md-only">
                {
                  this.state.isAdmin ?
                    <IllustrationWellcomeAdmin />
                    :
                    <IllustrationWellcome/>
                }
              </div>
            </div>
            {
              this.state.isModalVisible &&
              <Modal isVisible={this.state.isModalVisible}>
                <div className="hola">
                  <h2 className="o-margin--bottom-size-l">{this.state.modalErrorTitle}</h2>
                  <p dangerouslySetInnerHTML={{__html: this.state.modalErrorDescription}}></p>
                    <div className="c-button__wrapper">
                      <Button
                        className="c-button o-margin--right-auto o-margin--left-auto"
                        type="button"
                        text="Cerrar"
                        onClick={() => 
                            this.setState({isModalVisible: false})
                        }
                      />
                    </div>
                </div>
              </Modal>
            }
          </React.Fragment>
        )}
      </motion.section>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    authenticated: isAuthenticated(state),
    errors: state.global.errors && state.global.errors.login,
  };
};

const mapDispatchToProps = {
  fetchLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
