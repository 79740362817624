import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { connect } from "react-redux";

// Components
import Aside from "../../components/Aside";
import EmailsNew from "../../components/EmailsNew";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// Actions
import {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsCreate,
  fetchEmailsUpdate,
  fetchEmailsProcedureCreate,
  fetchEmailsProcedureUpdate  
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const EmailsCreate = (props) => {
  const [customEmail, setCustomEmail] = useState({});
  const [modalMsg, setModalMsg] = useState("");
  const [modals, setModals] = useState("");
  const TYPE_EMAIL = "invitation";
  const COLORS_CHAIN = ["#CD5C5C", "#DC143C", "#B22222"];

  const FORM_EMAIL = [
    { name: "title", label: "Template name" },
    { name: "subject_mail", label: "Subject" },
    {
      name: "body",
      value: props.location.state.editEmail
        ? props.location.state.editEmail.body
        : "",
    },
    { name: "label", label: "Tags" },
    { name: "language", label: "Language"},
  ];

  useEffect(() => {
    props.organizations.length === 0 && props.fetchOrganizations();

    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);
    if (props.location.state.editEmail) {
      setCustomEmail({
        label: props.location.state.editEmail.label,
        name: props.location.state.editEmail.name,
        title: props.location.state.editEmail.title,
        body: props.location.state.editEmail.body,
        subject_mail: props.location.state.editEmail.subject_mail,
        type_mail: props.location.state.editEmail.type_mail,
        language: props.location.state.editEmail.language,
      });
    }
  }, []);

  const getValueInput = (e) => {
    setCustomEmail({
      ...customEmail,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const getCustomEmail = (model) => {
    setCustomEmail({
      ...customEmail,
      ["body"]: model,
    });
  };

  const modalCreateEmail = (body_content) => {
    getCustomEmail(body_content)
    setModals("infoCreateEmail");
  };

  const sendEmail = (body_content) => {
    
    let folderId = props.location.state.fromFolder
      ? props.location.state.fromFolder.folderId
      : "";

    let _customEmail = customEmail;


    

    if(body_content !== undefined && body_content !== null){
      _customEmail["body"] = body_content
    }


    if( _customEmail.label !== undefined && !Array.isArray(_customEmail.label) && _customEmail.label !== ""){
      _customEmail.label = _customEmail.label.split(",")
    }else if(_customEmail.label !== undefined && _customEmail.label === "") {
      _customEmail.label = []
    }

    _customEmail.name = _customEmail.title
    _customEmail.type = TYPE_EMAIL;
    _customEmail.colors_chain = COLORS_CHAIN;

    if (props.location.state.editEmail) {
      updateEmail(folderId, _customEmail);
    } else {
      createEmail(folderId, _customEmail);
    }
  };

  const createEmail = (folderId, _customEmail) => {
    if(props.location.state.fromOwnerableType == "Procedure"){
      props.fetchEmailsProcedureCreate(
        props.match.params.organizationId,
        props.location.state.fromOwnerableId,
        { custom_mail: _customEmail },
        () => {
          setModals("");
          props.history.push({
            pathname: `/admin/organizations/${props.match.params.organizationId}/procedures/${props.location.state.fromOwnerableId}/emails`});
        }
      );
    }else{
      props.fetchEmailsCreate(
        props.match.params.organizationId,
        folderId,
        { custom_mail: _customEmail },
        () => {
          setModals("");
          props.history.push({
            pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.location.state.fromOwnerableId}/emails`,
            state: {
              folderId: props.location.state.fromFolder
                ? props.location.state.fromFolder.folderId
                : false,
              folderName: props.location.state.fromFolder
                ? props.location.state.fromFolder.folderName
                : false,
            },
          });
        }
      );  
    }
    
  };

  const updateEmail = (folderId, _customEmail) => {
    if(props.location.state.fromOwnerableType == "Procedure"){
      props.fetchEmailsProcedureUpdate(
        props.match.params.organizationId,
        props.location.state.fromOwnerableId,
        props.location.state.editEmail.id,
        { custom_mail: _customEmail },
        () => {
          setModals("");
          props.history.goBack();
        }
      );

    }
    else{
      props.fetchEmailsUpdate(
        props.match.params.organizationId,
        folderId,
        props.location.state.editEmail.id,
        { custom_mail: _customEmail },
        () => {
          setModals("");
          props.history.goBack();
        }
      );

    }
  };

  const titleModalCreateEmail = () => {
    if (Object.keys(props.location.state.fromFolder).length > 0) {
      return `The email will be created in the folder: ${props.location.state.fromFolder.folderName}`;
    }
    else if(props.location.state.fromOwnerableType == "Procedure") {
      return `The email will be created in the procedure: ${props.location.state.ownerableTitle}`;
    }
    else {
      return `
      The email will be created in the organization: ${props.location.state.organizationName}`;
    }
  };

  const getTitle = () => {
    if (props.location.state.editEmail) {
      return "Edit email";
    } else {
      return "New email";
    }
  };

  const getTags = (tags) => {
    setCustomEmail({
      ...customEmail,
      ["label"]: tags.join(),
    });
  };

  const actionModals = (type) => {
    setModals(type);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => actionModals("createOrganization")
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals("success"),
      setModalMsg("The organization has been created successfully"),
      props.fetchOrganizations();
    });
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}/emails`);
  };

   // Logout
   const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const actionModal = (type) => {
    if(type === "infoCreateEmail"){
      sendEmail()
    }else if(type === "closeSession"){
      closeSession()
    }else {
      setModals("")
    }
  }

  return (
    <motion.section
      className="o-wrapper o-wrapper--row"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.organizations.length > 0 &&
        props.organization &&
        props.organization.data &&(
        <Aside
          history={props.history}
          label={"Select organization:"}
          showSelect={true}
          organizations={props.organizations}
          organization={props.organization}
          selectAction={(id) => changeOrganization(id)}
          createOrganization={() => getAssessments()}
          selectOrganization={() => setModals("selectOrganization")}
          showModalSession={() =>
            {setModals("closeSession"),
            setModalMsg("Are you sure you want to logout?")}
          }
        />
      )}

      <div className="o-container">
        <h1 className="o-margin--bottom-size-xl">{getTitle()}</h1>
        <EmailsNew
          editEmail={props.location.state.editEmail ? true : false}
          value={customEmail}
          getValueInput={(e) => getValueInput(e)}
          getTags={(tags) => getTags(tags)}
          getCustomEmail={(model) => getCustomEmail(model)}
          createEmail={(body_content) => modalCreateEmail(body_content)}
          formEmail={FORM_EMAIL}
          updateEmail={(body_content) => {
            sendEmail(body_content);
          }}
          setCustomValue={(name, value) => setCustomEmail({
              ...customEmail,
              [name]: value,
            })}
        />
      </div>
      {["createOrganization"].includes(modals) && (
        <ModalCreate
          showModal={["createOrganization"].includes(modals)}
          closeModal={() => setModals("")}
          editAction={(data) => submitCreateOrganization(data)}
          type={modals}
          title={"New Organization:"}
          assessments={props.assessments}
          biodataOptions={props.organization.data.biodata_options}
        />
      )}
      {["success", "infoCreateEmail", "closeSession"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={
            modals === "infoCreateEmail" ? titleModalCreateEmail() : modalMsg
          }
          action={() =>
           actionModal(modals)
          }
        />
      )}
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
    </motion.section>
  );
};

const mapStateToProps = (state, props) => {
  return {
    organizations: getOrganizations(state, props),
    organization: getOrganization(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchOrganization,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchEmailsCreate,
  fetchEmailsProcedureCreate,
  fetchEmailsUpdate,
  fetchEmailsProcedureUpdate,
  fetchLogout
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsCreate);
