import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";
import Tree from "../Tree";
import Select from "../Select";

const ModalMoveUsers = (props) => {
  const [folders, setFolders] = useState([]);
  const [optionsFolders, setOptionsFolders] = useState([]);
  const [data, setData] = useState({});
  let props_temp = {medium: true}
  if(props.secondButton == true){
    props_temp = {medium: true}
  }

  const showFolders = (folders) => {
  	setFolders(folders)
  	let options = folders.map((f) => {return ({value: f.id, text: f.name}) })
  	setOptionsFolders(options)
  }

  return (
    <Modal isVisible={true} {...props_temp}>
      <h2 className="o-text-align--center">{props.text}</h2>
      <p style={{marginTop: 10}} className="o-text-align--left o-margin--bottom-size-l">
      	Select Organization to move
      </p>
      {
        props.organizations && props.organizations.length > 0 &&
          <Tree 
            title="Organizations" 
            treeData={props.organizations} 
            goTo={(node) => {setData({...data, destination_organization_id: node.organization.id}); props.getFolders(node.organization.id, (folders) => showFolders(folders))} }
          />
      }
      <div style={{width: 300}}>
	      {
	      	folders.length > 0 &&
	      	<>
	      	<p style={{marginTop: 10}} className="o-text-align--left o-margin--bottom-size-l">
      			Select Organization to move
      		</p>
	      	<Select
	        errorLabel=""
	        withoutInput={true}
	        defaultValue={"Select Organization"}
		        options={optionsFolders}
		        optionClick={(text, value) => {setData({...data, destination_folder_id: value}) }}
		      />
		     	</>
	      }
      </div>
      <div className="c-modal__buttons-wrapper">
        {!props.noButtonCancel && (
          <button
            type="button"
            className="c-button c-button--cancel c-modal__button"
            onClick={() => props.closeModalInfoAction()}
          >
            <span className="c-button__text">Cancel</span>
          </button>
        )}

        {props.secondButton == true && (
          <button
            type="button"
            className="c-button c-modal__button"
            onClick={() => props.secondAction()}
            >
            <span className="c-button__text">{props.secondButtonText}</span>
          </button>
          )
        }
        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.action(data)}
        >
          <span className="c-button__text">{props.primaryButtonText ? props.primaryButtonText : 'Confirm'}</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalMoveUsers;
