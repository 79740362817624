import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

import {DICTIONARY} from "../../lib/dictionaries";

// Actions
import {fetchProcedureClient, postProcedureClient} from "./actions"
// Config
import routes from "../../config/routes";

// Components
import LinkAnchor from "../../components/LinkAnchor";
import Select from "../../components/Select";
import Input from "../../components/Input";
import Datepicker from "../../components/Datepicker";
import Checkbox from "../../components/Checkbox";
import MultiAnswers from "../../components/MultiAnswers";
import RadioButton from "../../components/RadioButton";
import UploadFileInput from "../../components/uploadFileInput";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import File from "../../components/File";
import Spinner from "../../components/Spinner";

import IllustrationWarning from "../../components/IllustrationWarning";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

let fileResult = [];

let fileRefRadiobutton = 0;

const ProcedureClient = (props) => {

  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props.killer_questions);
  const [form, setForm] = useState({files: {}});
  const [errors, setErrors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [active, setActive] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectValue, setSelectValue] = useState({});

  const refs = useRef([]); // or an {}
  refs.current = []; // or an {}

  const _submit = () => {
    setSubmitting(true)
    setLoading(true)
    let validated = _validateData()
    if(validated){
      let dataPrepared = _prepareData()
      let dataForm = {
        slug: data.slug,
        email: email,
        procedure_user: dataPrepared,
        token: props.token
        // procedure_user: [{id: 1, value: [{title: "Lluís"}], type: "Text"}, {id: 6, value: [{title: "Periodismo", score: 1, file:"id1"}, {title: "Marketing", score:1, file: "id2"}], killer: true, type: "Checkbox"}, {id: 7, value:  [{title: "Sí", score: 1}]}]
      }
      props.postProcedureClient(
        dataForm,
        (responseJSON) => props.navigate(responseJSON),
        () => alert("ko")
      );      
    }
    else{
      setShowModal(true)
      setSubmitting(false)
      setLoading(false)
    }      
  }

  const _validateData = () => {
    let requireds = data.killer_questions.filter((element) => element.required == true )
    let errors = {}
    requireds.forEach((element, index) => {

      if(form[element.id] != undefined){
        if(element.item_format_style === "Checkbox" && element.acreditable == true && element.acreditable_required == true){
          form[element.id].forEach((value, index_2) => {
            let option = element.options.filter((e) => e.key == value)[0]
            if(option["acreditable"] != false && (form["files"][element.id] == undefined || form["files"][element.id][value] == undefined)){
              errors[element.id] = "Falta ficheros para la sección"
            }
            
          })
          
        }
        if(element.item_format_style === "Radio" && element.acreditable == true && element.acreditable_required == true && !["", "no", "ninguna_anteriores"].includes(form[element.id])){
          if(form["files"][element.id] == undefined || form["files"][element.id][element.id] === undefined){
            errors[element.id] = "Falta fichero para la sección"
          }
        }

        if(element.item_format_style === "Text" && form[element.id].trim() === ''){
          errors[element.id] = "La pregunta es obligatoria"
        }

        if(element.item_format_style === "Date" && form[element.id] === ''){
          errors[element.id] = "La pregunta es obligatoria"
        }
        
        if(element.item_format_style === "Text" && element.key == "email"){
          var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if(!re.test(form[element.id])){
            errors[element.id] = "El email debe ser válido"
          }
        }

      }else{
        errors[element.id] = "La pregunta es obligatoria"
      }
    })
    setErrors(errors)
    return Object.keys(errors).length == 0
  }

  const _prepareData = () => {
    let new_data = []
    Object.keys(form).forEach((key, index) => {
      let question = data.killer_questions.filter((element) => element.id == key )[0]
      if(question == undefined) return;  
      if(question.item_format_style === "Checkbox"){
        if(question.acreditable){
          let values = []
          form[key].forEach((value, index_2) => {
            // console.log("file->", form["files"][question.id][value])  
            let option = question.options.filter((option) => option.key == value )[0]
            if(form["files"][question.id] !== undefined){
              values.push({key: value, value: option.title, file: form["files"][question.id][value]})  
            }
            else{
              values.push({key: value, value: option.title})
            }
            
          })
          new_data.push({id: question.id, value: values})         
        }
        else{

        }
      }
      if(question.item_format_style === "File"){
        new_data.push({id: question.id, value: [{value: question.title, file: form[key]}]})  
      }

      if(question.item_format_style === "Radio"){
        let option = question.options.filter((option) => option.key == form[key] )[0]
        let file_temp = ""
        if(form["files"][question.id] != undefined && form["files"][question.id][question.id] !== undefined){
          new_data.push({id: question.id, value: [{key: option.key, value: option.title, file: form["files"][question.id][question.id]}]})
        }
        else{
          new_data.push({id: question.id, value: [{key: option.key, value: option.title}]})
        }
        
      }

      if(question.item_format_style === "Text" || question.item_format_style === "TextArea"){
        new_data.push({id: question.id, title: question.title, value: [{value: form[key]}]})  
      }

      if(question.item_format_style === "Date"){
        let date = form[key]
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        let new_date =  day + "/" + month + "/" + year;

        new_data.push({id: question.id, title: question.title, value: [{value: new_date}]})  
      }
    })

    return new_data
  }
  const _handleFiles = (element, key, e) => {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files[0] !== undefined) {
      fileIntermediate = {
        original_filename: e.target.files[0].name,
        content_type: e.target.files[0].type,
      };
    }
    
    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];
      fileResult.push(fileIntermediate);
      if(key !== ""){
        let files = form["files"]
        let files_id = files[element.id]
        files = {...files, [element.id]: {...files_id, [key]:fileIntermediate} }
        setForm({...form, ["files"]: files})

      }
      else{
        setForm({...form, [element.id]: fileIntermediate})
      }
      // setFile(fileIntermediate)
      // props.getFile(fileIntermediate);
    });

    if (e.target.files[0] !== undefined) {
      fr.readAsDataURL(e.target.files[0]);
    }
  };

  const _isSelected = (elementId, optionTitle) => {
    // let question = responses.find(element => element.id === elementId);
    // if(question !== undefined) {
    //   return question.value[0].title === optionTitle;
    // }
  };
  const _deleteFile = (id, option_key) => {
    let files = form["files"]
    let files_id = files[id]
    files = {...files, [id]: {...files_id, [option_key]: undefined} }
    setForm({...form, ["files"]: files})
  }

  const handleClickUploadFile = (e, index) => {
    e.preventDefault();
    refs.current[index].click();
  };

  const addToRefs = (el, index) => {
   if (el && !refs.current.includes(el)) {
      refs.current[index] = el;
   }
  };
  return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {
          !active && !loading && (
            <React.Fragment>

              <div className='o-text-align--center o-margin--bottom-size-xs'>
                <IllustrationWarning />
              </div>

              <h3 className='o-text-align--center o-color--info o-margin--bottom-size-xl'>Proceso no activo</h3>

              <div className="c-button__wrapper o-margin--top-size-l">
                <button
                  type="button"
                  className="c-button c-button--margin-zero"
                  onClick={() => window.location.href = "/"}
                >
                  <span className="c-button__text">Ok</span>
                </button>
              </div>

            </React.Fragment>
          )
        }
        {
          active && !loading && 
          <div className="o-container">
            <div className="u-width">
              <h1 className="o-margin--bottom-size-l">{data && Object.keys(data).length > 0 ? data.title : ""}</h1>
              <p className="o-margin--bottom-size-l u-text--headingH6">{data && Object.keys(data).length > 0 ? "Please fill the form" : "Loading..."}</p>
            </div>
            <div className="o-col o-pos-rel">
              <form className="o-width--full-width">
                {
                  data && Object.keys(data).length > 0 && data.killer_questions.length > 0 &&
                  data.killer_questions.map((element, index) => {
                    if(element.item_format_style == "Label"){
                      return (
                          <p className='o-margin--bottom-size-m u-text--headingH6'> {element.title} </p>
                        )
                    }
                    if(element.item_format_style == "Text"){
                      return (
                        <div className='o-margin--bottom-size-m'>
                          <Input
                            key={element.id}
                            label={element.title}
                            // minCharacters={8}
                            errorLabel={errors[element.id]}
                            showError={errors[element.id]}              
                            // hintLabel={internalization.form.password_length}
                            // showHint={state.error_object["password_show_error"] ? false : true}              
                            // type="password"
                            value={form[element.id]}
                            onChange={(e) => {
                              if(element.key == "email"){
                                setEmail(e.target.value)
                                props.setEmail(e.target.value)
                              }
                              setForm({...form, [element.id]: e.target.value})
                            }}
                          />
                        </div>
                      )
                    }
                    if(element.item_format_style == "TextArea"){
                      return (
                        <div className='o-margin--bottom-size-l'>
                          <Input
                            key={element.id}
                            label={element.title}
                            // minCharacters={8}
                            errorLabel={errors[element.id]}
                            showError={errors[element.id]}                         
                            // hintLabel={internalization.form.password_length}
                            // showHint={state.error_object["password_show_error"] ? false : true}              
                            // type="password"
                            type="textarea"
                            value={form[element.id]}
                            onChange={(e) => setForm({...form, [element.id]: e.target.value})}
                          />
                        </div>
                      )
                    }                  
                    if(element.item_format_style == "Radio"){
                      fileRefRadiobutton ++;
                      return (
                        <div key={element.id} style={{paddingLeft: element.grouped == true ? 20 : 0}} className={element.options.length > 3 ? '' :'o-margin--bottom-size-l'}>

                          <label className="c-input__label">{element.title}</label>
                          <div 
                            className={element.options.length > 3 ? "c-procedure-radio_button__options" : "c-radio-button__options c-procedure-radio_button__options"}
                            style={element.options.length > 3 ? { alignItems:  'flex-start' } : {}}
                          >
                            <div>
                              {
                                element.options.length <= 3 && element.options.map((option, index) => {
                                  return (
                                    <RadioButton
                                      key={index}
                                      text={option.title}
                                      isSelected={form[element.id] === option.key}
                                      onClick={() => setForm({...form, [element.id]: option.key})}
                                      editable={true}
                                    />
                                  )
                                })
                              }
                            </div>
                              {
                                element.options.length > 3 && (
                                  <Select
                                    withoutInput={true}
                                    hasError={(value) => {}}
                                    erroLabel='Error'
                                    valueSelected={selectValue[element.id] && selectValue[element.id].value}
                                    options={element.options.map((option, index) => { 
                                      return { value: index, text: option.title, option }
                                    })}
                                    optionClick={(text, value, data) => {
                                      setSelectValue({...selectValue, [element.id]:{text, value}})
                                      setForm({...form, [element.id]: data.option.key})
                                    }}
                                    wrapperClassName='c-procedure__select'
                                  />
                                )
                              }
                            {
                              element.acreditable && 
                                <div className={element.options.length > 3 ?'c-procedure__upload-file o-margin--top-size-xxs o-margin--bottom-size-xl' : 'c-procedure__upload-file'}>
                                  <input ref={(ref) => addToRefs(ref, index)} type='file' className="c-upload-file-input__add-file-input" onChange={(e) => _handleFiles(element, element.id, e)} />
                                  <div className="c-upload-file-input__add-file" style={form[element.id] && !["", "no", "ninguna_anteriores"].includes(form[element.id]) ? {} : {opacity: 0.4}} onClick={(e) => form[element.id] && !["", "no", "ninguna_anteriores"].includes(form[element.id]) ? handleClickUploadFile(e, index) : ""}>
                                    <File width="20px" height="23px" color={form["files"][element.id] && form["files"][element.id][element.id] ? "#76b0eb" : "#121A22"} />
                                    <span className="c-upload-file-input__add-file-hint">{form["files"][element.id] && form["files"][element.id][element.id] ? form["files"][element.id][element.id]["original_filename"] : "Añade un archivo"}</span>
                                  </div>
                                  {
                                    form["files"][element.id] && form["files"][element.id][element.id] &&
                                      <span onClick={(e) => {e.preventDefault();e.stopPropagation(); _deleteFile(element.id, element.id, form)}} className="c-multi-answer__add-file-hint o-cursor--pointer">X</span>
                                  }
                                </div>
                              } 
                          </div>
                          { errors[element.id] !== undefined && 
                          <span className="c-input__error">
                            {errors[element.id]}
                          </span>
                          }                     
                        </div>
                      )
                    }
                    if(element.item_format_style == "Checkbox"){
                      return (
                        <div className='o-margin--bottom-size-m'>
                          <MultiAnswers 
                            key={element.id}
                            editable={true}
                            data={element}
                            handleFiles={(e, key) => _handleFiles(element, key, e)}
                            selectedElements={(keys) => setForm({...form, [element.id]: keys}) }
                            filesUplodaded={form["files"][element.id]}
                            deleteFile={(key) => _deleteFile(element.id, key, form)}
                          />
                          { errors[element.id] !== undefined && 
                            <span className="c-input__error">
                              {errors[element.id]}
                            </span>
                          }
                        </div>
                      )
                    }

                    if(element.item_format_style == "File"){
                      return (
                        <div className='o-margin--bottom-size-l'>
                          <UploadFileInput 
                            key={element.id}
                            editable={true}
                            data={element}
                            fileText={form[element.id] ? form[element.id]["original_filename"] : "Añade un archivo"}
                            handleFiles={(e) => _handleFiles(element, "", e)}
                          />
                          { errors[element.id] !== undefined && 
                            <span className="c-input__error">
                              {errors[element.id]}
                            </span>
                          }
                        </div>
                      )
                    }

                    if(element.item_format_style == "Date"){
                      return (
                        <div className='o-margin--bottom-size-l'>
                          <Datepicker
                            isRequired={false}
                            hasTime={false}
                            initDateValue={""}
                            label={element.title}
                            showError={errors[element.id]}
                            errorLabel={errors[element.id]}
                            onChange={(dateValue) => setForm({...form, [element.id]: dateValue})}
                          />
                          { errors[element.id] &&
                            <span className="c-input__error">
                              {errors[element.id]}
                            </span>
                          }
                        </div>
                      )
                    }                    
                  })
                }
              </form>
            </div>
            <div className="c-button__wrapper o-margin--top-size-xl">
              <button
                type="button"
                className="c-button c-button--margin-zero"
                onClick={() => submitting ? "" : _submit()}
              >
                <span className="c-button__text">Submit</span>
              </button>
            </div>
          </div>

        }
        { showModal && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={true}
          closeModalInfoAction={() => setShowModal(false)}
          text={"Revisa los errores, por favor."}
          action={() => setShowModal(false)}
          primaryButtonText={"Ok"}
        />
      )}
      {loading && <Spinner />}
    </motion.section>
  )
};

ProcedureClient.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
  };
};

const mapDispatchToProps = {
  fetchProcedureClient,
  postProcedureClient
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureClient);
