import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import ImagesIpsativesButton from "../../../../components/ImagesIpsativesButton";
import SurveyImagesIpsative from "../../../../components/SurveyImagesIpsative";


const SurveyImagesIpsativeItem = (props) => {

  const [itemsShown, setItemsShown] = useState({});
  const _renderItems = (index, itemData) => {
    switch(itemData.item_format_style) {
      case 3:
        return []
      case 5:
        return _starsInputs(index, itemData)
      default:
        return []
    }
  }

  const _submit = (competence) => {
    props.handleClick({ [competence]: props.questionData.items[competence] })
  }  

  return (
      <div className="o-container o-pos-rel c-ipsative">
          {
            props.submitting &&
            <CompetenceSuccess />
          }
          {
            !props.submitting &&
            <React.Fragment>
              <div className="c-ipsative__wrapper">
                <SurveyImagesIpsative items={props.questionData.items || []}/>    
                {/*
                  Object.entries(props.questionData.items).map((item, index) => {
                    {if(Object.keys(itemsShown).length == 0) setItemsShown(item[1])}
                    return (
                      <SurveyImagesIpsative key={"survey_image_"+index} items={props.questionData.items || []}/>    
                    )                    
                  })  
                */}
              </div>
              <div className="c-ipsative__wrapper-button">
                <ImagesIpsativesButton showHint={props.step === 0} selectedItems={props.selectedItems} itemsShown={props.questionData.items} isFirstQuestion={props.isFirstQuestion} submit={(competence) => _submit(competence)} />
              </div>
            </React.Fragment>
          }
      </div>

  )
}


export default SurveyImagesIpsativeItem;
