// Dependencies
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

import Input from "../../../components/Input";
import Eye from "../../../components/Eye";
import Select from "../../../components/Select";

const ProcedureUserEvaluation = (props) => {

  const [scoreValue, setScoreValue] = useState({})
  const [commentValue, setCommentValue] = useState({})
  const [showInputError, setShowInputError] = useState(false)
  const [evaluationData, setEvaluationData] = useState(null)

  useEffect(() => {
    // console.log("props.data.data", props.data.data)
    // setEvaluationData(props.data.data[0][0])
  }, [])

  useEffect(() => {
    if(evaluationData) console.log(evaluationData)
  }, [evaluationData])

  useEffect(() => {
    console.log('Score updated', scoreValue)
  }, [scoreValue])

  const getScore = (score, index, index_2, min = 0, max = 10) => {
    let scoreValue_temp = scoreValue
    if(scoreValue_temp[index] !== undefined){
      let temp_2 = scoreValue_temp[index]
      scoreValue_temp[index][index_2] = score
      
      setScoreValue({...scoreValue, [index]: {[index_2]: score} })
    }else{
      setScoreValue({...scoreValue, [index]: {[index_2]: score} })
    }

    if(score > max || score < min) {
      setShowInputError(true)
    } else {
      setShowInputError(false)
      
    }
  }

  const getComment = (event, comment) => {
    comment && setCommentValue(comment, commentValue)
  }

  const _submit = (evaluation, index, index_2) => {
    let score = (scoreValue[index] !== undefined && scoreValue[index][index_2] !== undefined) ? scoreValue[index][index_2] : evaluation.value
    let notes = (commentValue[index] !== undefined && commentValue[index][index_2] !== undefined) ? commentValue[index][index_2] : evaluation.notes

    props.setAnswer(evaluation.organization_id, evaluation.folder_id, evaluation.user_folder_id, {answer_index: evaluation.answer_index, value: score , notes: notes}, () => "", () => alert("ko"))
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {
        props.data.data.map((evaluation_all, index) => {
          return (
            evaluation_all.map((evaluation, index_2) => {
            return (
              <div className='c-procedure--evaluation'>
                <form>
                  <h6  className='c-procedure--evaluation__title' dangerouslySetInnerHTML={{__html: evaluation.title }}></h6>
                  <div className='c-procedure--evaluation__input-wrapper'>
                    {
                      evaluation.select_input != undefined && evaluation.select_input.length > 0 &&
                      <Select
                        style={{width: 200}}
                        withoutInput={true}
                        tick={true}
                        label={"Score"}
                        isRequired={true}
                        hasError={(value) => {}}
                        errorLabel={"Debe seleccionar una opción"}
                        defaultValue={scoreValue[index] !== undefined ? scoreValue[index][index_2] : evaluation.value == "" ? "" : evaluation.value}
                        valueSelected={scoreValue[index] !== undefined ? scoreValue[index][index_2] : evaluation.value == "" ? "" : evaluation.value}
                        options={evaluation.select_input.map((value) => { return { value: value[0], text: value[1] }})}
                        optionClick={(text, value) => getScore(value, index, index_2, 0, 10) }
                      />
                    }
                    {
                      evaluation.select_input == undefined &&
                      <Input
                        name="score"
                        label="Score"
                        errorLabel={`La puntuación deber estar entre ${evaluation.min}-${evaluation.max}`}
                        showError={showInputError}
                        minCharacters={1}
                        type="number"
                        value={scoreValue[index] !== undefined ? scoreValue[index][index_2] : evaluation.value == "" ? "" : evaluation.value}
                        onChange={(e, value) => getScore(value, index, index_2, evaluation.min, evaluation.max)}
                        disabled={false}
                        className='c-procedure--evaluation__input'
                        showHint={!showInputError}
                        hintLabel={`La puntuación deber estar entre ${evaluation.min}-${evaluation.max}`}
                      />

                    }
                    <a href={evaluation.link} target='_blank' className='c-procedure--evaluation__see-report'>
                      <Eye slashed={false}/>
                      <span className='o-margin--left-size-xs'>Show PDF</span>
                    </a>
                  </div>
                  <div className={'c-procedure--evaluation__textarea-wrapper'}>
                    <Input
                      name="score"
                      label="Notes"
                      minCharacters={1}
                      type="textarea"
                      value={evaluation.notes == "" ? commentValue[index] !== undefined ? commentValue[index][index_2] : "" : evaluation.notes}
                      onChange={(e, value) => setCommentValue({...commentValue, [index]: {[index_2]: value} })}
                    />
                  </div>
                    <button
                      type="button"
                      className="c-button c-procedure--evaluation__button"
                      disabled={showInputError}
                      onClick={() => _submit(evaluation, index, index_2)}
                    >
                      <span className="c-button__text">Save</span>
                    </button>
                </form>

              </div>            
              )
            })
          )
        })
      }
    </motion.div>
  );
};

export default ProcedureUserEvaluation;
