// Dependencies
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import Download from "../../../components/Download";
import Export from "../../../components/Export";

const ProcedureUserKillerQuestions = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {
        props.data && props.data.data &&
          <React.Fragment>
            <p>{props.data.data.passed ? "PASSED" : "NOT PASSED"}</p>
            <br/>
            <div className="c-folder-info__button o-margin--bottom-size-xl">
              <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
                <button
                  type="button"
                  className="c-button c-button--margin-zero"
                  onClick={() => {
                    props.fetchKillerExport(props.data.data.procedure_user_id);
                  }}
                >
                  <div className="c-button__icon">
                    <Export width="20px" height="30px" />
                  </div>
                  <span className="c-button__text">Export answers</span>
                </button>
                <button
                  type="button"
                  className="c-button c-button--margin-zero"
                  onClick={() => {
                    props.fetchKillerFiles(props.data.data.procedure_user_id);
                  }}
                >
                  <div className="c-button__icon">
                    <Download width="20px" height="30px" />
                  </div>
                  <span className="c-button__text">Downlaod answers files</span>
                </button>
              </div>
            </div>   
          </React.Fragment>    
      }
    </motion.div>
  );
};

export default ProcedureUserKillerQuestions;
