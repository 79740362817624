import React from "react";

export default class BubbleBg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="none"
        className="c-bubble"
      >
        <defs>
          <radialGradient
            cx="22.2316078%"
            cy="16.9067383%"
            fx="22.2316078%"
            fy="16.9067383%"
            r="92.5144872%"
            gradientTransform="translate(0.222316,0.169067),scale(0.802582,1.000000),rotate(-19.886436),scale(1.000000,0.998681),translate(-0.222316,-0.169067)"
            id="radialGradient-1"
          >
            <stop stopColor="#FFFFFF" offset="0%"></stop>
            <stop stopColor={this.props.secondColor ? this.props.secondColor : "#ECF0F1"} offset="100%"></stop>
          </radialGradient>
        </defs>
        <path
          d="M505.03421,0 C-207.836139,574.423062 -166.028699,924.5008 630.456531,1050.23321 C1426.94176,1175.96563 1856.4529,903.385635 1918.98995,232.493231 L1920,2.34314822 L505.03421,0 Z"
          className="c-bubble__path"
          fill="url(#radialGradient-1)"
        ></path>
      </svg>
    );
  }
}
