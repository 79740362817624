import React from 'react';
import PropTypes from "prop-types";

// Components
import RatingStar from "../../../../components/RatingStar";

const SurveyIpsativeItem = (props) => {
  const _handleRatingStar = (index, data, selected) => {
    return props.handleClick({...selected, [index]: data});
  };

  const _starsInputs = (index, itemData) => {
    return (
      <div className="c-answer" key={`answer-${index}`}>
        <div className="c-answer__indicator">
          <span className="c-answer__indicator-text">{itemData.text}</span>
        </div>
        <span className="c-answer__text"> {itemData.name} </span>
        <RatingStar
          key={index}
          values={itemData.items}
          currentValue={props.selectedItems[index] || {}}
          onClick={(e, i, val) =>
            _handleRatingStar(index, val, props.selectedItems)
          }
        />
      </div>
    )
  }

  const _renderItems = (index, itemData) => {
    switch(itemData.item_format_style) {
      case 3:
        return []
      case 5:
        return _starsInputs(index, itemData)
      default:
        return []
    }
  }

  return (
    Object.entries(props.questionData.items).map((arr) => {
      return (_renderItems(arr[0], arr[1]))
    })
  )
}

SurveyIpsativeItem.propTypes = {
  questionData: PropTypes.object,
  selectedItems: PropTypes.object,
  renderSelect: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default SurveyIpsativeItem;
