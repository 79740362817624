import React, { useEffect, useState } from "react";
import classNames from 'classnames'

// Components
import Modal from "../Modal";
import TableVideos from "../TableVideos";
import { motion } from "framer-motion";

const ModalShowVideo = (props) => {
  let props_temp = {small: true}
  if(props.secondButton == true){
    props_temp = {medium: true}
  }
  console.log("ModalShowVideo", props)


  const titleClasses = () => {
    let cl = classNames(
      "o-text-align--center",
      props.text &&'o-margin--bottom-size-l' 
    )
    return cl
  }

  return (
    <Modal isVisible={true}>
      <h5 className={titleClasses()}>{props.text}</h5>
      <div className='o-aspect-ratio--16/9'>
        <video width="100%" height="100%" controls>
          <source src={props.data.url} type="video/webm" />
        </video>
      </div>
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Close</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalShowVideo;
