import React from "react";
import classNames from "classnames";

const Hamburger = (props) => {

  let classNameHamburger = classNames(
    "c-hamburger", 
    props.classNames && props.classNames
  );


  return (
    <svg
      className={classNameHamburger}
      width={props.width ? props.width : "14px"}
      height={props.height ? props.height : "14px"}
      viewBox="0 0 14 14"
    >
      <g fill="#FDFDFD" fillRule="nonzero">
      <rect width={14} height={2} rx={1} />
      <rect width={14} height={2} y={6} rx={1} />
      <rect width={14} height={2} y={12} rx={1} />
    </g>
    </svg>
  );
};

export default Hamburger;



