// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IllustrationOval = (props) => {
  let classNameOval = classNames("c-illustration--oval", props.className && props.className);

  return (
    <svg
      className={classNameOval}
      width="213px"
      height="214px"
      viewBox="0 0 213 214"
    >
      <path
        d="M110.602666,207.565716 C153.849494,236.258775 213,157.394275 213,82 C213,6.60572512 164.970185,0 122,0 C66.0899899,0 0,7.40625 0,45.3657143 C0,83.3251786 42.383607,95.783265 67.3558386,120.434051 C91.8281516,144.591354 67.3558386,178.872657 110.602666,207.565716 Z"
        id="Oval-Copy-4"
        fill="#74B9FF"
      ></path>
    </svg>
  );
};

IllustrationOval.propTypes = {
  className: PropTypes.string,
};

export default IllustrationOval;
