import React, { useEffect, useState } from "react";
import Tree from "../Tree";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Multiselect from "../Multiselect";

const ModalSelectOrganization = (props) => {
  
  const goTo = (node) => {
    document.body.classList.add("s-admin");
    props.history.push(`/admin/organizations/${node.organization.id}`)
  }
  return (
    <Modal isVisible={true} big>
      <h2 className="o-text-align--left o-margin--bottom-size-l">
      Select Organization
      </h2>
      {
        props.organizations && props.organizations.length > 0 &&
          <Tree 
            title="Organizations" 
            treeData={props.organizations} 
            goTo={(node) => goTo(node)}
          />
      }
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Cancel</span>
        </button>

      </div>
    </Modal>
  );
};

export default ModalSelectOrganization;
