// Dependencies
import React from "react";
import PropTypes from "prop-types";
import anime from "animejs/lib/anime.es.js";

export default class BubbleImageBg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this._handleBubble();
  };

  _handleBubble = () => {
    anime({
      targets: ".c-bubble__path",
      d: [
        {
          value:
            "M893.536208,-2.27373675e-13 L0,-2.27373675e-13 L0,1080 L1921.00654,1080 L1921.00654,147.359375 C1919.98135,472.104167 1789.35417,660.46875 1529.125,712.453125 C1138.78125,790.429688 1134.98437,909.015625 914.609375,939.84375 C694.234375,970.671875 171.625,778.96875 201.96875,408.34375 C222.197917,161.260417 452.720403,25.1458333 893.536208,-2.27373675e-13 Z",
        },
      ],
      easing: this.props.easing ? this.props.easing : "linear",
      duration: this.props.duration ? this.props.duration : 1000,
      delay: this.props.delay ? this.props.delay : 0,
      loop: false,
    });
    anime({
      targets: ".c-bubble__background",
      top: "0px",
      easing: "easeInOutQuad",
      duration: this.props.backgroundDuration
        ? this.props.backgroundDuration
        : 750,
      delay: this.props.backgroundDelay ? this.props.backgroundDelay : 850,
    });
  };
  render() {
    return (
      <>
        <img className="c-bubble__image" src={this.props.image} />
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 1920 1080"
          preserveAspectRatio="none"
          className="c-bubble__background"
        >
          <path
            d="M893.536208,1 L0,1 L0,1081 L1921,1081 L1920.89844,1 C1868.83594,2.28125 1787.75781,2.28125 1677.66406,1 C1512.52344,-0.921875 1575.14062,0.609375 1355.90625,1 C1136.67188,1.390625 1117.31641,0.5859375 1061.57812,1 C1024.41927,1.27604167 968.405299,1.27604167 893.536208,1 Z"
            id="Path-2"
            fill="#FFFFFF"
            className="c-bubble__path"
          ></path>
        </svg>
      </>
    );
  }
  static propTypes = {
    image: PropTypes.string,
    duration: PropTypes.number,
    delay: PropTypes.number,
    easing: PropTypes.string,
  };
}
