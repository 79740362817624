import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Chevron = (props) => {
  const chevronClasses = () => {
    let chevronClass = classNames(
      "c-chevron",
      props.orientation === "up" && "c-chevron--up",
      props.orientation === "right" && "c-chevron--right",
      props.orientation === "left" && "c-chevron--left",
      props.className && props.className
    );
    return chevronClass;
  };

  return (
    <svg
      onClick={() => props.action && props.action()}
      width={props.width ? props.width : "16px"}
      height={props.height ? props.height : "9px"}
      viewBox="0 0 16 9"
      className={chevronClasses()}
    >
      <path
        fill="#3C5896"
        fillRule="evenodd"
        d="M8.53 8.53a.75.75 0 0 1-1.06 0l-7-7A.75.75 0 0 1 1.53.47L8 6.94 14.47.47a.75.75 0 1 1 1.06 1.06l-7 7Z"
      />
    </svg>
  );
};

Chevron.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string,
  white: PropTypes.bool,
};

export default Chevron;
