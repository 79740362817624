export const FETCH_SCORES = 'FETCH_SCORES';
export const FETCH_INVITATION = 'FETCH_INVITATION';
export const FETCH_INVITATION_REGISTRATION = 'FETCH_INVITATION_REGISTRATION';
export const FETCH_UPDATE_PROFILE = 'FETCH_UPDATE_PROFILE';
export const FETCH_SHOW_PROFILE = 'FETCH_SHOW_PROFILE';
export const FETCH_UPDATE_PROFILE_OK = 'FETCH_UPDATE_PROFILE_OK';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const SET_SPECIAL = "SET_SPECIAL"
/** Actions to reducers */


export function setOrganizationSpecial(special) {
  return {
    type: SET_SPECIAL,
    special
  }
}


export function fetchUpdateProfileOk(data) {
  return {
    type: FETCH_UPDATE_PROFILE_OK,
    data: {
      ...data
    }
  }
}

export function setUserLanguage(user_language) {
  return {
    type: SET_USER_LANGUAGE,
    user_language: user_language
  }
}


/** Actions to sagas */

export function fetchScores(callback) {
  return {
    type: FETCH_SCORES,
    data: {
      callback
    }
  }
}

export function fetchShowProfile(userId,  success, error) {
  return {
    type: FETCH_SHOW_PROFILE,
    data: {
      userId: userId,
      success: success,
      error: error
    }
  }
}

export function fetchUpdateProfile(userId, data, success, error) {
  return {
    type: FETCH_UPDATE_PROFILE,
    data: {
      userId: userId,
      profile: data,
      success: success,
      error: error
    }
  }
}

export function fetchInvitation(token, success, error) {
  return {
    type: FETCH_INVITATION,
    data: {
      token: token,
      success: success,
      error: error
    }
  }
}

export function fetchInvitationRegistration(token, attributes, success, error) {
  return {
    type: FETCH_INVITATION_REGISTRATION,
    data: {
      attributes,
      token,
      success,
      error
    }
  }
}
