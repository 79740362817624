import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import DateTimePicker from 'react-datetime-picker';

export default class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    state.date = state.date || props.initDateValue || null;

    return state
  };

  _onDatePickerChange = (date, state, props) => {
    this.setState({ date }, () => props.onChange(date));
  };

  render() {
    let inputWrapperClasses = ["c-datepicker__wrapper s-react-date-picker"];
    this.props.wrapperClassName &&
      inputWrapperClasses.push(this.props.wrapperClassName);

    let inputClasses = ["c-datepicker"];
    this.props.className && inputClasses.push(this.props.className);

    let labelClasses = ["c-datepicker__label"];
    this.props.disabled && labelClasses.push("o-disabled");
    this.props.classNameLabel && labelClasses.push(this.props.classNameLabel)

    return (
      <div className={inputWrapperClasses.join(" ")}>
        <label className={labelClasses.join(" ")}>
          {
            this.props.isRequired &&
              <span class="o-color--red">*{" "}</span>
          }
          {this.props.label}
        </label>
        {
          !this.props.hasTime && (

            <DatePicker
              onChange={(date) =>
                  this._onDatePickerChange(date, this.state, this.props)
              }
              value={this.state.date}
              className={inputClasses.join(" ")}
              disabled={this.props.disabled}
            />

          )
        }
        {
          this.props.hasTime && (

            <DateTimePicker
              isRequired={this.props.isRequired}
              value={this.props.value}
              label={"Start date"}
              errorLabel={""}
              onChange={this.props.onChange}
              className={inputClasses.join(" ")}
              />
          )
        }
      </div>
    );
  }

  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    isRequired: PropTypes.bool.isRequired,
    errorLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
  };
}
