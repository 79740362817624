export const FETCH_PASSWORD = 'FETCH_PASSWORD';
export const FETCH_PASSWORD_OK = 'FETCH_PASSWORD_OK';
export const FETCH_RESET_PASSWORD = 'FETCH_RESET_PASSWORD';
export const FETCH_RESET_PASSWORD_OK = 'FETCH_RESET_PASSWORD_OK';
export const FETCH_RESET_PASSWORD_ERROR = 'FETCH_RESET_PASSWORD_ERROR';

/** Actions to reducers */
export function fetchPasswordOk(data) {
  return {
    type: FETCH_PASSWORD_OK,
    ...data
  }
}

export function fetchResetPasswordOk(data) {
  return {
    type: FETCH_RESET_PASSWORD_OK,
    ...data
  }
}

export function fetchResetPasswordError() {
  return {
    type: FETCH_RESET_PASSWORD_ERROR,
  }
}

/** Actions to sagas */
export function fetchPassword(data, success, error) {
  return {
    type: FETCH_PASSWORD,
    data: {
      password: data,
      success: success,
      error: error
    }
  }
}

export function fetchResetPassword(data, success, error) {
  return {
    type: FETCH_RESET_PASSWORD,
    data: {
      password: data,
      success: success,
      error: error
    }
  }
}
