import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
//Components
import Select from "../../components/Select";
import Tree from "../../components/Tree";
// Actions
import { fetchOrganizations } from "../admin/actions";

// Selectors
import { isAuthenticated } from "../../selector";
import { getOrganizations } from "./selector";

const selectVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const OrganizationHome = (props) => {

  const [hasOrganization, setHasOrganization] = useState(false);

  useEffect(() => {
    let is_admin = localStorage.getItem('admin')

    if(is_admin == "false"){
      window.location.href = "/"
    }

    if (!props.organizations.length) props.fetchOrganizations();

    let menu = document.querySelector(".c-menu")
    menu && menu.classList.add("c-menu--hidden")
    let menuOff = document.querySelector(".c-menu-off")
    menuOff && menuOff.classList.add("c-menu-off--show")

    return () => menuOff && menuOff.classList.remove("c-menu-off--show")

  }, [])

  useEffect(() => {
    props.organizations.length > 0 && renderOrganizations(); 

    let menu = document.querySelector(".c-menu")
    menu && menu.classList.add("c-menu--hidden")
    let menuOff = document.querySelector(".c-menu-off")
    menuOff && menuOff.classList.add("c-menu-off--show")

    return () => menuOff && menuOff.classList.remove("c-menu-off--show")

  }, [props.organizations])


  const renderOrganizations = () => {
    const organizationsCounter = props.organizations.length;
    if (organizationsCounter == 1) {
      document.body.classList.add("s-admin");
      props.history.push(`/admin/organizations/${props.organizations[0].id}`)
    } else {
      organizationsCounter > 1 && setHasOrganization(true)
    }
  };

  const renderSelect = () => {
    return (
      <Select
        errorLabel=""
        withoutInput={true}
        defaultValue={"Select Organization"}
        options={organizationsData()}
        optionClick={(text, value) => {
          document.body.classList.add("s-admin");
          props.history.push(`/admin/organizations/${value}`)
        }}
      />
    )
  };

  const organizationsData = () => {
    return props.organizations.map((org, index) => {
      return {value: org.id, text: org.name};
    })
  }

  const goTo = (node) => {
    document.body.classList.add("s-admin");
    props.history.push(`/admin/organizations/${node.organization.id}`)
  }
  return (
    <motion.section
      className="o-wrapper"
      initial={{opacity: 0}}
      animate={{opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className="o-container o-display--flex@lg-and-up">
        <div className="o-col--1/2@lg">
          <h1 className="o-margin--bottom-size-l">
            Hello!
          </h1>
          {
            props.organizations.length > 0 && hasOrganization  &&
              <AnimatePresence>
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={selectVariants}
                >
                  <h3 className="o-margin--bottom-size-l">Do you want to see your organizations?</h3>
                  {/*renderSelect()*/} 
                  {
                    props.organizations_select && props.organizations_select.length > 0 &&
                      <Tree 
                        title="Organizations" 
                        treeData={props.organizations_select} 
                        goTo={(node) => goTo(node)}
                      />
                  }
                </motion.div>
              </AnimatePresence>
          }
        </div>
      </div>
      
    </motion.section>
  );

};

OrganizationHome.propTypes = {
  organizations: PropTypes.array,
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

const mapStateToProps = (state, props) => {
  return {
    authenticated: isAuthenticated(state),
    organizations: getOrganizations(state, props),
    organizations_select: state.admin.organizations_select
  };
};

const mapDispatchToProps = {
  fetchOrganizations,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationHome);
