export const REDIRECT_TO = 'REDIRECT_TO';
export const SET_ERRORS_OK = 'SET_ERRORS_OK';

/** Actions to reducers */
export function setErrors(name, errors) {
  return {
    type: SET_ERRORS_OK,
    data: {
      name,
      errors
    }
  }
}

/** Actions to middleware */
export function redirectTo(url, locale, action, forced, params) {
  return {
    type: REDIRECT_TO,
    data: {
      url,
      locale,
      action,
      forced,
      params
    }
  }
}
