import React, { useEffect,useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Api from "../../packs/services/api";
import classNames from "classnames";
import { connect } from "react-redux";

// Components
import Header from "./Header";
import Footer from "./Footer";
import Spinner from "./Spinner";

const Wrapper = (props) => {

  const [isAdmin, setIsAdmin] = useState(false);
  const [hasSpinner, setHasSpinner] = useState(false);
  
  useEffect(() => {
    let pathname = props.history.location.pathname;
    let isAdmin = pathname.includes("/admin/");
    let uri = pathname.split("/")[2];
    if(isAdmin) {
      setIsAdmin(true)
      if(pathname.includes("/admin/signin")) {
        document.body.classList.remove("s-admin");
      }else {
        document.body.classList.add("s-admin");
      }
    }
  }, [props.history.location.pathname])

  useEffect(() => {
    Api.init(props.store, props.csrfToken);
  }, [props.client])

  Api.init(props.store, props.csrfToken);
  return (
    <main>
      {
        hasSpinner && <Spinner />
      }
      <Header url_home={isAdmin ? "/admin" : "/"} history={props.history} location={location} key={props.history.location.pathname} />
      {props.children}
      <Footer />
    </main>
  );

}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    client: state.client,
  };
};


export default connect(mapStateToProps, null)(Wrapper);

