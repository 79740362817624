// Dependencies
import React from "react";
import PropTypes from "prop-types";

const Search = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g fill="none" fillRule="evenodd" stroke="#3C5896" strokeWidth={1.5}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m13 13 6 6" />
      <path d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
    </g>
    </svg>
  );
};

Search.propTypes = {
  className: PropTypes.string,
};

export default Search;
