export const FETCH_SURVEY = 'FETCH_SURVEY';
export const FETCH_SURVEYS = 'FETCH_SURVEYS';
export const FETCH_SURVEY_OK = 'FETCH_SURVEY_OK';
export const FETCH_SURVEYS_OK = 'FETCH_SURVEYS_OK';
export const FETCH_CREATE_SCORE = 'FETCH_CREATE_SCORE';
export const FETCH_RESET_SCORE = 'FETCH_RESET_SCORE';
export const FETCH_SURVEY_SCORE = 'FETCH_SURVEY_SCORE';
export const FETCH_SURVEY_MODULE = 'FETCH_SURVEY_MODULE';
export const SET_USER_FOLDER_LANGUAGE = 'SET_USER_FOLDER_LANGUAGE';
export {FETCH_LOGOUT} from '../login/actions'
/** Actions to reducers  */

export const SET_SPECIAL = "SET_SPECIAL"
/** Actions to reducers */


export function setOrganizationSpecial(special) {
  return {
    type: SET_SPECIAL,
    special
  }
}

export function fetchSurveyOk(data) {
  return {
    type: FETCH_SURVEY_OK,
    data: {
      ...data
    }
  }
}

export function fetchSurveysOk(data) {
  return {
    type: FETCH_SURVEYS_OK,
    data: {
      ...data
    }
  }
}

export function fetchSurveyScore(data) {
  return {
    type: FETCH_SURVEY_SCORE,
    data: {
      ...data
    }
  }
}

/** Actions to sagas */

export function fetchSurveys(callback) {
  return {
    type: FETCH_SURVEYS,
    data: {
      callback
    }
  }
}

export function setUserFolderLanguage(
  userFolderId,
  data,
  success
) {
  return {
    type: SET_USER_FOLDER_LANGUAGE,
    data: {
      userFolderId,
      user_folder: data,
      success,
    },
  };
}

export function fetchSurvey(token, callback) {
  return {
    type: FETCH_SURVEY,
    data: {
      token,
      callback
    }
  }
}

export function fetchSurveyModule(token, module_id, success, error) {
  return {
    type: FETCH_SURVEY_MODULE,
    data: {
      token,
      module_id,
      success,
      error
    }
  }
}

export function fetchCreateScore(token, answer, success, error) {
  return {
    type: FETCH_CREATE_SCORE,
    data: {
      token,
      answer,
      success,
      error
    }
  }
}

export function fetchResetScore(organizationId, folder_id, id, callback) {
  return {
    type: FETCH_RESET_SCORE,
    data: {
      organizationId,
      folder_id,
      id,
      callback
    }
  }
}
