import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import LinkAnchor from "../components/LinkAnchor";

export default class Disclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  handleCheckbox = (checkbox, state) => {
    this.setState({ checked: !state.checked });
  };

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          <div className="o-display--flex o-display--flex-v-center o-display--flex-column-direction">
            <h1 className="o-margin--bottom-size-l">Antes de continuar</h1>
            <h3 className="o-margin--bottom-size-giga">
              ¿Has leído nuestra{" "}
              <LinkAnchor
                className="o-text-align--center c-link"
                href={routes.client.disclaimer}
                text="política de protección de datos"
              />
              ?
            </h3>

            <Checkbox
              text="Lorem ipsum dolor sit amet"
              onClick={(checkbox) => this.handleCheckbox(checkbox, this.state)}
              checked={this.state.checked}
            />
            <LinkAnchor
              text="Aceptar"
              href="/"
              type="button"
              isDisabled={!this.state.checked}
            />
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
