import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";

const ModalShowList = (props) => {
  return (
    <Modal isVisible={props.showModal} small>
      <h4 className="o-margin--bottom-size-l o-text-align--center">
        {props.title}
      </h4>
      <div className="c-list-assessments__wrapper">
        <ul className="c-list-assessments">
          {props.assessments.map((item, index) => {
            return <li className="c-list-assessments__item">{item.text}</li>;
          })}
        </ul>
      </div>
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.action()}
        >
          <span className="c-button__text">Close</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalShowList;
