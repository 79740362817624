import {call, put, takeLatest, all} from 'redux-saga/effects';
import { FETCH_LOGIN, FETCH_LOGOUT, fetchLoginOk, fetchLogoutOk } from './actions';
import Api from '../../services/api';
import { setErrors } from "../../actions";
// Config
import routes from '../../config/routes';

function * fetchLoginSaga(action) {
  try {
    let response = yield call(Api.handlePost, routes.session.create, {}, action.data);

    if(response.status) {
      yield put(fetchLoginOk(response));
      if (action.data && action.data.success){ action.data.success(response) }
    } else {
      yield put(setErrors('login', response.message))
      action.data.error("Error en la contraseña o en el email")
    }
  } catch (error) {
  }
}

function * fetchLogoutSaga(action) {
  try {
    let response = yield call(Api.handleDelete, routes.session.destroy, {}, {});

    if(response.status) {
      yield put(fetchLogoutOk(response));
      if (action.data && action.data.success){ action.data.success() }
    } else {
      if (action.data && action.data.error){ action.data.error() }
    }
  } catch (error) {
    if (action.data && action.data.error){ action.data.error() }
  }
}


export function * watchAuthentication() {
  yield all([
    takeLatest(FETCH_LOGIN, fetchLoginSaga),
    takeLatest(FETCH_LOGOUT, fetchLogoutSaga)
  ])
}
