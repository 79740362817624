import {
  FETCH_ORGANIZATIONS_OK,
  FETCH_ORGANIZATION_OK,
  FETCH_ORGANIZATION_GET_ASSESSMENTS_OK,
  FETCH_FOLDER_INDEX_OK,
  FETCH_FOLDER_OK,
  FETCH_FOLDER_CREATE_OK,
  FETCH_FOLDER_UPDATE_OK,
  FETCH_FOLDER_DESTROY_OK,
  FETCH_FOLDER_DESTROY_USER_OK,
  FETCH_FOLDER_SEND_INVITATION_USER_OK,
  FETCH_FOLDER_UPDATE_CHART_OK,
  FETCH_EMAILS_FROM_ORGANIZATION_OK,
  FETCH_EMAILS_DESTROY_FROM_ORGANIZATION_OK,
  FETCH_EMAILS_DESTROY_FROM_FOLDER_OK,
  FETCH_ADMINS_OK,
  FETCH_ADMIN_DESTROY_OK,
  FETCH_LOGS_FROM_ORGANIZATION_OK,
  FETCH_PROCEDURES_INDEX_OK,
  FETCH_PROCEDURE_CREATE_OK,
  FETCH_PROCEDURE_OK,
  FETCH_PROCEDURE_USER_OK,
} from "./actions";

export default function adminReducer(state = {}, action) {
  let folders = state.organization && state.organization.data.folders;

  switch (action.type) {
    case FETCH_ORGANIZATIONS_OK:
      return { ...state, ...state.admin, organizations: action.data.organizations, organizations_select: action.data.organizations_select };
    case FETCH_ORGANIZATION_OK:

      return {
        ...state,
        ...state.admin,
        organization: {
          data: action.data.organization,
          totals: action.data.totals,
          totals_description: action.data.totals_description,
          action: action.data.action,
          graphs: action.data.graphs,
          user_role: action.data.organization.user_role,
        },
        assessments: action.data.organization.assessments
      };
    case FETCH_ORGANIZATION_GET_ASSESSMENTS_OK:

      return {
        ...state,
        ...state.admin,
        assessments: action.data.data,
      };
    case FETCH_FOLDER_INDEX_OK:
      return { ...state, ...state.admin, folderIndex: action.data };
    case FETCH_FOLDER_OK:
      return { ...state, ...state.admin, folder: action.data };
    case FETCH_FOLDER_CREATE_OK:
      folders.push(action.data);

      return {
        ...state,
        organization: { ...state.organization, folders: folders },
      };
    case FETCH_FOLDER_UPDATE_OK:
      let folder = folders.find((fol) => fol.id == action.data.id);

      if (folder) folder = action.data;

      return {
        ...state,
        organization: { ...state.organization, folders: folders },
      };
    case FETCH_FOLDER_DESTROY_OK:
      folders = folders.filter((fol) => {
        return fol.id != action.data;
      });

      return {
        ...state,
        organization: { ...state.organization, folders: folders },
      };
    case FETCH_FOLDER_DESTROY_USER_OK:
      return { ...state, ...state.admin, folder: action.data };
    case FETCH_FOLDER_UPDATE_CHART_OK:
      return { ...state, ...state.admin, chart: action.data.data.graphs };
    // case FETCH_FOLDER_SEND_INVITATION_USER_OK:
    //   return { ...state, ...state.admin, folder: action.data };
    case FETCH_EMAILS_FROM_ORGANIZATION_OK:
      return { ...state, ...state.admin, emailsOrganization: action.data };
    case FETCH_EMAILS_DESTROY_FROM_ORGANIZATION_OK:
      return { ...state, ...state.admin, emailsOrganization: action.data };
    case FETCH_ADMINS_OK:
      return { ...state, ...state.admin, admins: action.data };
    case FETCH_ADMIN_DESTROY_OK:
      return { ...state, ...state.admin, admins: action.data };
    case FETCH_LOGS_FROM_ORGANIZATION_OK:
      return { ...state, ...state.admin, logsOrganization: action.data };
    case FETCH_PROCEDURES_INDEX_OK:
      return { ...state, ...state.admin, proceduresIndex: action.data };
    case FETCH_PROCEDURE_CREATE_OK:
      procedures.push(action.data);

      return {
        ...state,
        organization: { ...state.organization, procedures: procedures },
      };
    case FETCH_PROCEDURE_OK:
      return { ...state, ...state.admin, procedure: action.data };
    case FETCH_PROCEDURE_USER_OK:
      return { ...state, ...state.admin, procedureUser: action.data };
    default:
      return state;
  }
}
