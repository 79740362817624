import React, { useEffect, useState } from "react";
import classNames from "classnames";

import TreeNode from "./TreeNode";
import Chevron from '../components/Chevron'

function Tree(props) {

  const [open, setOpen] = useState(false)

  const openClose = () => {
    setOpen(!open)
  }

  const wrapperClass = () => {
    let cl = classNames(
      "c-tree__wrapper",
      props.title && 'is-main',
      open && 'is-open'
    )
    return cl
  }

  return (
    <div className={wrapperClass()}>
      {
        props.title && <span className="c-tree__title" onClick={openClose}>{props.title} <Chevron orientation={open ? 'up' : null}/></span>
      }
      <ul className="c-tree">
        {props.treeData.map((node) => (
          <TreeNode node={node} key={node.organization.id} goTo={(node) => props.goTo(node)}/>
        ))}
      </ul>
    </div>
  );
}

export default Tree;

