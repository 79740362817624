import React, { useState, useEffect, useRef } from "react";

// Components
import Input from "./Input";
import Close from "./Close";
import Filter from "./Filter";
import Multiselect from "./Multiselect";
import Arrow from "./Arrow";
import Envelope from "./Envelope";

const EmailsFilter = (props) => {
  const [resetFilter, setResetFilter] = useState(0);
  const [filterEmails, setFilterEmails] = useState([]);
  const refItemTags = useRef([]);

  useEffect(() => {
    if (props.optionFolder) {
      let deleteFolderName = filterEmails.filter(
        (folder) => typeof folder === "number"
      );
      setFilterEmails([...deleteFolderName, props.optionFolder]);
    }
  }, [props.optionFolder]);

  const getFilterTags = (options) => {
    let _options = JSON.parse(JSON.stringify(options));

    let tagsText = options.map((item) => props.tagsFilter[item].text);

    if (filterEmails.findIndex((item) => item === props.optionFolder) !== -1) {
      let folderName = filterEmails.filter(
        (item) => item === props.optionFolder
      );
      _options.push(folderName[0]);
    }
    setFilterEmails(_options);
    props.getFiltersTags(tagsText);
  };

  const deleteFilterFolder = () => {
    let newFilters = filterEmails.filter((item) => item !== props.optionFolder);
    props.getEmailsOrganization();
    setResetFilter(resetFilter + 1);
    setFilterEmails(newFilters);
  };

  const deleteFilterTag = (item) => {
    let _filterEmails = JSON.parse(JSON.stringify(filterEmails));
    let newTags = _filterEmails.filter(
      (tag) => tag !== item && tag !== props.optionFolder
    );
    let tagsText = newTags.map((tag) => props.tagsFilter[tag].text);
    setFilterEmails(newTags);
    refItemTags.current.map((ref) => {
      if (ref.id === item + "") {
        ref.click();
      }
    });
    props.getFiltersTags(tagsText);
  };

  return (
    <div className="c-block-title o-margin--bottom-size-l">
      <div className="c-block-title__left">
        <h1 className="c-block-title__big-text o-margin--bottom-size-xl">
          <Envelope firstColor={"#3C5896"} width={50} height={50}/> {props.title}
        </h1>
        {props.canManage && (
          <button
            type="button"
            className={"c-button"}
            onClick={() => props.goToCreateEmail()}
          >
            <span className="c-button__text">New Email {props.defaultOptionValue ? `in ${props.defaultOptionValue}` : ''}</span>
          </button>
        )}

        <button
          type="button"
          className="c-button c-button--margin-zero c-button--go-back"
          onClick={() => props.goBack ? props.goBack() : ""}
        >
          <div className="c-button__icon c-button__icon-arrow-back">
            <Arrow width={32} height={20} color="#fff"/>
          </div>
          <span className="c-button__text">Go Back</span>
        </button>

        <div className="c-block-title__left-content">
          {/*
            props.changeFolderEnabled && 
            <div className="c-block-title__left-filter">
              {props.organizations.length > 0 && (
                <div className="c-block-title__item ">
                  <p className="c-block-title__small-text">
                    {"Filter by survey:"}
                  </p>
                  <Multiselect
                    key={resetFilter}
                    multiselectClassName="c-multiselect--white-bg c-multiselect--border"
                    multiple={false}
                    options={props.emailsFilter}
                    getOption={(option) => props.changeFolder(option)}
                  />
                </div>
              )}
            </div>          
          */}
          {
            props.changeTagEnabled && 
            <div className="c-block-title__left-filter">
              {props.organizations.length > 0 && (
                <div className="c-block-title__item ">
                  <p className="c-block-title__small-text">{"Filter by tag:"}</p>

                  <Multiselect
                    refItem={refItemTags}
                    multiselectClassName="c-multiselect"
                    multiple={true}
                    options={props.tagsFilter}
                    getOption={(option) => getFilterTags(option)}
                  />
                </div>
              )}
            </div>
          }
          <div className="c-block-title__left-filter">
            <form>
              <Input
                className="c-input"
                wrapperClassName="c-input__wrapper c-input__wrapper--no-size"
                name="search"
                label="Search emails, names and surnames:"
                search={true}
                errorLabel="Este campo no puede estar vacío"
                minCharacters={5}
                type="text"
                value={props.valueSearch}
                onChange={(e) => {
                  props.getValueSearch(e);
                }}
              />
            </form>
          </div>
        </div>
        {filterEmails.length > 0 && (
          <div className="c-filter-box o-margin--bottom-size-l">
            <div className="c-filter-box__wrapper c-filter-box__wrapper--min-width-small c-filter-box__wrapper--align-start">
              <div className="c-filter-box__wrapper c-filter-box__wrapper--align-center">
                <Filter width="40px" height="40px" />
                <p className="c-filter-box__text">
                  Selected filters:
                </p>
              </div>
            </div>
            <div className="c-filter-box__wrapper c-filter-box__wrapper--flex-wrap">
              {filterEmails.map((item, index) => {
                if (
                  typeof item === "number" &&
                  Object.keys(props.tagsFilter).length > 0
                ) {
                  return (
                    <div
                      className="c-filter-box__box"
                      onClick={() => deleteFilterTag(item)}
                    >
                      <p className="c-filter-box__box-text">
                        {props.tagsFilter[item].text}
                      </p>
                      <Close width="10px" height="10px" />
                    </div>
                  );
                } else {
                  return (
                    <div
                      className="c-filter-box__box"
                      onClick={() => {
                        deleteFilterFolder();
                      }}
                    >
                      <p className="c-filter-box__box-text">
                        {item}
                      </p>
                      <Close width="10px" height="10px" />
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailsFilter;
