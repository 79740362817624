import PropTypes from "prop-types";
import React from "react";

const CopyClipboard = (props) => {
    return (
      <svg
        width={props.width ? props.width : "23px"}
        height={props.height ? props.height : "25px"}
        viewBox={"0 0 23 25"}
        className={props.classNames}
      >
        <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Illustration" transform="translate(-125.000000, -1867.000000)">
            <g id="Group-13" transform="translate(125.000000, 1867.000000)">
              <g id="Group-15" transform="translate(0.000000, 0.000000)">
                <path d="M13.2629582,0.190525704 C14.314197,0.268746993 14.6245543,0.577391908 14.7083799,1.48915532 C14.8919858,3.4882058 15.0192437,5.49205446 15.2528664,7.48641836 C15.5687952,10.1829885 15.9738678,12.8714129 16.3143618,15.5657513 C16.3837522,16.1139699 16.3356348,16.6735701 16.3442453,17.2280374 C16.3458914,17.3298032 16.3280373,17.4447361 16.3738755,17.5311031 C16.6741028,18.0981795 16.6133229,18.3445598 15.9265102,18.5149506 C15.203736,18.6942681 14.4439874,18.8268315 13.6960149,18.8463589 C10.3250103,18.9341765 6.95286607,18.9843899 3.59376421,18.611695 C2.99470243,18.5451902 2.38741004,18.5046847 1.78442289,18.5080323 C1.3422492,18.5105988 1.23892339,18.2644416 1.18232212,17.989607 C0.922741344,16.7302554 0.626312761,15.4739167 0.447391963,14.2050803 C-0.142679458,10.0224173 -0.00681108171,5.81933412 0.0713164005,1.61937535 C0.0854983746,0.861153302 0.493356754,0.393610934 1.40936033,0.220207392 C2.12567665,0.0845196779 2.86896404,0.0276111795 3.60300782,0.00373192728 C4.67678586,-0.0311942688 5.26367056,0.190525704 6.33884147,0.190525704 C7.41401238,0.190525704 7.70197017,0.0755928546 7.70513579,0.00373192728 C9.9839649,0.022031915 11.8365724,0.0842965073 13.2629582,0.190525704 Z" id="Path" fill="#121A22"></path>
                <path d="M8.405749,18.3512576 C11.305696,18.2362588 11.7446909,18.1682586 14.6466464,18.144236 C15.1461221,18.1398964 15.3185975,17.9157882 15.2626121,17.311347 C15.1441137,16.0305515 14.7471942,13.1112552 14.6466464,11.82798 C14.3709869,8.3138518 14.1961226,5.40362784 13.9490835,1.88639989 C13.8953576,1.11968946 13.5932588,0.980624572 13.0025242,0.924055073 C12.2325357,0.850437233 10.8376534,0.770736328 10.0665351,0.75694267 C8.16553959,0.722691001 6.26391646,0.727495534 4.36241885,0.727650518 C3.4284908,0.727805503 2.49431168,0.746093725 1.56038363,0.768876509 C1.17149799,0.77864056 0.892072745,0.88558016 0.909897716,1.52566791 C1.00291394,4.87737195 1.05827177,8.23078083 1.13898625,11.5831048 C1.19221011,13.7948947 1.88311591,15.8820496 1.94311828,17.933895 C3.2418306,17.7370642 5.50580197,18.4662564 8.405749,18.3512576 Z" id="Path" fill="#FFFFFF"></path>
              </g>
              <g id="Group-14" transform="translate(5.272727, 5.090909)">
                <path d="M13.2629582,0.190525704 C14.314197,0.268746993 14.6245543,0.577391908 14.7083799,1.48915532 C14.8919858,3.4882058 15.0192437,5.49205446 15.2528664,7.48641836 C15.5687952,10.1829885 15.9738678,12.8714129 16.3143618,15.5657513 C16.3837522,16.1139699 16.3356348,16.6735701 16.3442453,17.2280374 C16.3458914,17.3298032 16.3280373,17.4447361 16.3738755,17.5311031 C16.6741028,18.0981795 16.6133229,18.3445598 15.9265102,18.5149506 C15.203736,18.6942681 14.4439874,18.8268315 13.6960149,18.8463589 C10.3250103,18.9341765 6.95286607,18.9843899 3.59376421,18.611695 C2.99470243,18.5451902 2.38741004,18.5046847 1.78442289,18.5080323 C1.3422492,18.5105988 1.23892339,18.2644416 1.18232212,17.989607 C0.922741344,16.7302554 0.626312761,15.4739167 0.447391963,14.2050803 C-0.142679458,10.0224173 -0.00681108171,5.81933412 0.0713164005,1.61937535 C0.0854983746,0.861153302 0.493356754,0.393610934 1.40936033,0.220207392 C2.12567665,0.0845196779 2.86896404,0.0276111795 3.60300782,0.00373192728 C4.67678586,-0.0311942688 5.26367056,0.190525704 6.33884147,0.190525704 C7.41401238,0.190525704 7.70197017,0.0755928546 7.70513579,0.00373192728 C9.9839649,0.022031915 11.8365724,0.0842965073 13.2629582,0.190525704 Z" id="Path" fill="#121A22"></path>
                <path d="M8.405749,18.3512576 C11.305696,18.2362588 11.7446909,18.1682586 14.6466464,18.144236 C15.1461221,18.1398964 15.3185975,17.9157882 15.2626121,17.311347 C15.1441137,16.0305515 14.7471942,13.1112552 14.6466464,11.82798 C14.3709869,8.3138518 14.1961226,5.40362784 13.9490835,1.88639989 C13.8953576,1.11968946 13.5932588,0.980624572 13.0025242,0.924055073 C12.2325357,0.850437233 10.8376534,0.770736328 10.0665351,0.75694267 C8.16553959,0.722691001 6.26391646,0.727495534 4.36241885,0.727650518 C3.4284908,0.727805503 2.49431168,0.746093725 1.56038363,0.768876509 C1.17149799,0.77864056 0.892072745,0.88558016 0.909897716,1.52566791 C1.00291394,4.87737195 1.05827177,8.23078083 1.13898625,11.5831048 C1.19221011,13.7948947 1.88311591,15.8820496 1.94311828,17.933895 C3.2418306,17.7370642 5.50580197,18.4662564 8.405749,18.3512576 Z" id="Path" fill="#FFFFFF"></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );

}

CopyClipboard.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  classNames: PropTypes.string,
};

export default CopyClipboard;
