import React from "react";

export default class SurveyImagesIpsative extends React.Component {

  render() {
    return (
        <div className="c-ipsative__container">
            {
              Object.entries(this.props.items).map((item) => {
                  return (
                    <div className="c-ipsative__block" key={item[0]}>
                      <div className="c-ipsative__wrapper-title">
                        <p className="c-ipsative__title">{item[1].text}</p>
                      </div>
                      <div className="c-ipsative__wrapper-image">
                        <img src={item[1].image_url} className="c-ipsative__image" />
                      </div>
                    </div>
                  )
              })
            }
        </div>
    );
  }
};

