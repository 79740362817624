import PropTypes from 'prop-types';
import React from 'react';

export default class MenuBalls extends React.Component {
  render() {
    return (
      <svg width="22px" height="22px" viewBox="0 0 22 22" className="c-menu-balls">
        <g className="c-menu-balls--top" transform="translate(9.000000, 0.000000)" fill="#121A22">
          <circle className="ball--bottom" cx="2" cy="20" r="2"></circle>
          <circle className="ball--top" cx="2" cy="2" r="2"></circle>
        </g>
        <g className="c-menu-balls--bottom" transform="translate(9.000000, 0.000000)" fill="#121A22">
          <circle className="ball--bottom" cx="2" cy="20" r="2"></circle>
          <circle className="ball--center" cx="2" cy="11" r="2"></circle>
          <circle className="ball--top" cx="2" cy="2" r="2"></circle>
          </g>
      </svg>
    );
  }

}
