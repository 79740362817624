import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

// Components
import Dot from './Dot';

const RadioButton = (props) => {
  const [radioButtonClasses, setRadioButtonClasses] = useState("c-radio-button");
  const [dotClassName, setDotClassName] = useState("c-radio-button__dot");
  const [spanTextClasses, setSpanTextClasses] = useState("c-radio-button__text");
  const inputRef = useRef();

  useEffect(() => {

    setRadioButtonClasses(
      classNames(
        "c-radio-button",
        props.className,
        props.isSelected && "is-active"
      ));

    setDotClassName(
      classNames(
        "c-radio-button__dot",
        props.dotClassName
      ));

    setSpanTextClasses(
      classNames(
        "c-radio-button__text",
        props.correct !== undefined && "o-font--bold",
        props.textClassName && props.textClassName
      ));

  }, [props]);

  const handleClickUploadFile = (e) => {
    e.preventDefault();

    inputRef.current.click();
  };

  return (
    <div
      className={radioButtonClasses}
      onClick={() => props.editable ? props.onClick(props.text) : {}}
    >
      <Dot
        isActive={props.isSelected}
        className={dotClassName}
      />
      <span className={spanTextClasses}>{props.text}</span>     
    </div>

  )
};

export default RadioButton;
