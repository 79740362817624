import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

export default class LinkAnchor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let linkAnchorClasses = [];
    this.props.className && linkAnchorClasses.push(this.props.className);
    this.props.type === "button" && linkAnchorClasses.push("c-button");
    this.props.type === "submit" &&
      linkAnchorClasses.push("c-button c-button__submit");
    this.props.isDisabled && linkAnchorClasses.push("c-button--disabled");

    let linkAnchorTextClasses = [];
    (this.props.type === "button" || this.props.type === "submit") &&
      linkAnchorTextClasses.push("c-button__text");

    return (
      <Link
        data-cursor-hover
        className={linkAnchorClasses.join(" ")}
        onClick={this.handleClick ? () => this.handleClick() : () => {}}
        to={{pathname: this.props.href}}
        target={this.props.target}
      >
        {this.props.children ? (
          this.props.children
        ) : (
          <span className={linkAnchorTextClasses.join(" ")}>
            {this.props.text}
          </span>
        )}
      </Link>
    );
  }

  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string.isRequired,
    children: PropTypes.node,
    params: PropTypes.object,
    type: PropTypes.string,
    isDisabled: PropTypes.bool,
    target: PropTypes.string,
  };
}
