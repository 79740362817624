import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";

const ModalInfoAction = (props) => {
  let props_temp = {small: true}
  if(props.secondButton == true){
    props_temp = {medium: true}
  }
  return (
    <Modal isVisible={props.showModalInfoAction} {...props_temp}>
      <p className="o-text-align--center">{props.text}</p>
      <div className="c-modal__buttons-wrapper">
        {!props.noButtonCancel && (
          <button
            type="button"
            className="c-button c-button--cancel c-modal__button"
            onClick={() => props.closeModalInfoAction()}
          >
            <span className="c-button__text">Cancel</span>
          </button>
        )}

        {props.secondButton == true && (
          <button
            type="button"
            className="c-button c-modal__button"
            onClick={() => props.secondAction()}
            >
            <span className="c-button__text">{props.secondButtonText}</span>
          </button>
          )
        }
        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.action()}
        >
          <span className="c-button__text">{props.primaryButtonText ? props.primaryButtonText : 'Confirm'}</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalInfoAction;
