// Dependencies
import React from "react";

const Percentage = (props) => {

  return (
      <svg
        width={props.width ? props.width : "138px"}
        height={props.height ? props.height : "165px"}      
        viewBox="0 0 138 165"
        className={props.className}
      >

        <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Illustration" transform="translate(-734.000000, -954.000000)">
            <g id="Group-16" transform="translate(734.017628, 954.287239)">
              <g id="Group-8-Copy" transform="translate(68.982372, 82.212761) rotate(-51.000000) translate(-68.982372, -82.212761) translate(-29.517628, 73.212761)" fill="#121A22">
                <path d="M95.9631245,-89.5 C93.8033223,-89.5 91.6489475,-83.9499077 89.5,-72.8497231 L89.5,-49.0205698 C91.1334247,-25.991868 92.2106122,-3.11902492 92.7315623,19.5979594 C93.5129874,53.6734359 89.5,86.4119444 92.7315623,91.8976277 C95.9631245,97.383311 103.642616,116.09031 106.313112,102.859169 C108.983609,89.6280286 106.313112,65.7104494 106.313112,54.467255 C106.313112,46.971792 105.228676,30.0005616 103.059803,3.5535639 C103.059803,-22.5309966 103.059803,-37.8144924 103.059803,-42.2969234 C103.059803,-46.7793543 103.059803,-56.9636209 103.059803,-72.8497231 C100.488486,-83.9499077 98.1229268,-89.5 95.9631245,-89.5 Z" id="Path-8" transform="translate(98.500000, 9.000000) rotate(-270.000000) translate(-98.500000, -9.000000) "></path>
              </g>
              <path d="M51.5131703,34.2731973 C51.66886,32.7350495 51.8245497,31.1989747 51.9823722,29.6587539 C46.0640302,26.2010308 40.3674928,22.212626 34.0439311,19.7437122 C32.7920151,19.256563 26.9589826,24.6421533 26.9823722,27.28727 C27.0421593,34.6131653 32.1628581,39.7354876 39.7511322,39.7127607 C43.6774988,39.700247 47.5932018,36.1989915 51.5131703,34.2731973 M29.9160773,5.71276072 C36.3828422,6.86525621 40.222748,7.36467091 43.9717811,8.25251929 C59.7963353,11.9981296 67.0260094,21.1263207 64.4836832,33.9510343 C61.9265637,46.8461782 48.9592207,55.3554365 34.7682643,53.445282 C13.7174651,50.6152653 3.21002869,30.3718955 14.7318596,12.6277336 C17.6144306,8.18635751 25.9831852,7.37747642 29.9160773,5.71276072" id="Fill-88" fill="#121A22"></path>
              <path d="M115.51317,145.273197 C115.66886,143.735049 115.82455,142.198975 115.982372,140.658754 C110.06403,137.201031 104.367493,133.212626 98.0439311,130.743712 C96.7920151,130.256563 90.9589826,135.642153 90.9823722,138.28727 C91.0421593,145.613165 96.1628581,150.735488 103.751132,150.712761 C107.677499,150.700247 111.593202,147.198991 115.51317,145.273197 M93.9160773,116.712761 C100.382842,117.865256 104.222748,118.364671 107.971781,119.252519 C123.796335,122.99813 131.026009,132.126321 128.483683,144.951034 C125.926564,157.846178 112.959221,166.355437 98.7682643,164.445282 C77.7174651,161.615265 67.2100287,141.371896 78.7318596,123.627734 C81.6144306,119.186358 89.9831852,118.377476 93.9160773,116.712761" id="Fill-88-Copy" fill="#121A22"></path>
            </g>
          </g>
        </g>
      </svg>
  )
}

export default Percentage
