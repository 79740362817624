import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import LinkAnchor from "../components/LinkAnchor";
import TableData from "../components/TableData";

export default class Policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }
  handleCheckbox = (checkbox, state) => {
    this.setState({ isDisabled: !checkbox });
  };
  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          <div className="o-display--flex o-display--flex-v-center o-display--flex-column-direction">
            <h5 className="o-margin o-margin--bottom-size-l">
              AVISO LEGAL Y POLÍTICA DE PRIVACIDAD
            </h5>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                1) INFORMACIÓN DEL TITULAR
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, a continuación, se exponen los datos identificativos del Titular:
              </p>
              <TableData />
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                2) CONDICIONES DE ACCESO Y USO
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
                Cualquier persona que acceda a la Web de{" "}
                <strong>GSG Metrics</strong> la consideración de Usuario. El Usuario
                se compromete a utilizar la página Web y aquellos servicios
                puestos a su disposición a través de la misma, de manera acorde
                a la Ley, moral, buenas costumbres y orden público, así como con
                lo dispuesto en el presente clausulado. En consecuencia, queda
                obligado a no utilizar la Web con fines o efectos ilícitos y/o
                contrarios a lo establecido, lesivos de derechos y/o intereses
                de terceros o que, de cualquier forma, puedan dañar la Web o
                impedir su normal uso, o de los servicios accesibles a través de
                la misma.
              </p>
              <p>
                La utilización de la Web y/o de sus Servicios, supondrá la aceptación plena y sin reservas, y la validez, de todas y cada una de las cláusulas recogidas en la última versión actualizada de este Aviso Legal, por lo que el Usuario deberá ser consciente de la importancia de leerlas cada vez que visite la Web. 
              </p>
              <p>
                El Usuario declara ser mayor de edad y disponer de la capacidad jurídica suficiente para vincularse por las presentes Condiciones. Por lo tanto, este Sitio Web de FACTHUM no se dirige a menores de edad sin representación. FACTHUM declina cualquier responsabilidad por el incumplimiento de este requisito.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                3) PROPIEDAD INTELECTUAL
              </h6>
              <p>
                El código fuente, los diseños gráficos, las imágenes, las
                fotografías, los vídeos, los sonidos, las animaciones, el
                software, los textos, así como la información y los contenidos
                que se recogen en la Web están protegidos por la legislación
                española sobre los derechos de propiedad intelectual e
                industrial a favor de <strong>GSG Metrics</strong> de terceros
                licenciadores. No se permite la reproducción y/o publicación,
                total o parcial, de la Web, ni su tratamiento informático, su
                distribución, su difusión, ni su modificación, transformación o
                descompilación, ni demás derechos reconocidos legalmente a su
                titular, sin el permiso previo y por escrito del mismo. El
                Usuario, única y exclusivamente, puede utilizar el material que
                aparezca en la Web para su uso personal y privado, quedando
                prohibido su uso con fines comerciales o para incurrir en
                actividades ilícitas. Todos los derechos derivados de la
                propiedad intelectual están expresamente reservados por{" "}
                <strong>GSG Metrics</strong> los terceros licenciadores, que velará
                por el cumplimiento de las anteriores condiciones como por la
                debida utilización de los contenidos presentados en sus páginas
                web, ejercitando todas las acciones civiles y penales que le
                correspondan en el caso de infracción o incumplimiento de estos
                derechos por parte del Usuario.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                4) POLÍTICA DE PRIVACIDAD.
              </h6>
              <p>
                <strong>GSG Metrics</strong> compromete a proteger la privacidad de
                los usuarios que accedan a esta web y/o cualquiera de sus
                servicios. La utilización de la web y/o cualquiera de los
                servicios ofrecidos por <strong>GSG Metrics</strong>, implica la
                aceptación por parte del usuario de las disposiciones contenidas
                en la presente Política de Privacidad y que sus datos personales
                sean tratados según se estipula. Por favor, tenga en cuenta que,
                aunque pueda haber enlaces de nuestra web a otras webs, esta
                Política de Privacidad no se aplica a otras compañías u
                organizaciones a las que la web esté redirigida.{" "}
                <strong>GSG Metrics</strong> controla el contenido de las webs de
                terceros ni acepta ningún tipo de responsabilidad por el
                contenido o las políticas de privacidad de estos sitios web.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                5) CUESTIONES SOBRE PRIVACIDAD{" "}
              </h6>
              <p className="o-margin o-margin--bottom-size-l">
               En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, (RGPD) y de la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de derechos digitales, le ofrecemos la siguiente información sobre el tratamiento de los datos personales que usted pueda facilitarnos:
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>
                  - ¿Quién es el responsable del tratamiento de sus datos? GSG Metrics
                </strong>
              </p>
              <p>
                Nuestros datos se encuentran en la parte superior de este aviso legal. 
              </p>
              <ul className="o-margin o-margin--bottom-size-l">
                <li>
                  • Tratamos la información que se nos facilita para prestar y facturar nuestros servicios y productos. 
                </li>
                <li>
                  • Si nos da su consentimiento también podemos tratar sus datos para enviarle información sobre productos, servicios o actividades.
                </li>
              </ul>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>
                  - ¿Durante cuanto tiempo conservaremos sus datos?
                </strong>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Los datos personales proporcionadas se conservarán durante el tiempo estrictamente necesario y señalado por la normativa vigente. Es decir, mientras sea usuario de nuestros servicios o quiera seguir recibiendo información, o hasta que el Usuario solicite su derecho de cancelación u oposición, o limitación al tratamiento.
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>
                  -¿Qué legitimación tenemos para poder tratar sus datos?
                </strong>
              </p>
              <p>
                La base legal para el tratamiento de sus datos son los consentimientos que Vd. nos dé. {" "}
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                (Con menores de edad) Se entenderá que la información enviada por menores de edad lo ha sido por el consentimiento de sus representantes legales. De no ser así el/la representante legal deberá informárnoslo en cuanto sea posible. {" "}
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>- ¿A quién comunicaremos sus datos?</strong>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Los datos no serán comunicados a terceros, salvo que lo exija una ley o sea necesario para cumplir con la finalidad del tratamiento. {" "}
              </p>
              <ul className="o-margin o-margin--bottom-size-l">
                <li>
                  • Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando o no sus datos personales. 
                </li>
                <li>
                  • Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o solicitar la supresión de los mismos cuando, entre otros motivos, los datos ya no sean necesarios para las finalidades para las cuales se recabaron. 
                </li>
                <li>
                  • En determinadas circunstancias las persones interesadas podrán solicitar la limitación del tratamiento de sus datos, en este caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. 
                </li>
                <li>
                  • En determinadas circunstancias y por motivos relacionados con su situación particular, el/la interesado/a podrá oponerse al tratamiento de sus datos. En este caso dejaremos de tratarlos, excepto por motivos legítimos imperiosos o para el ejercicio o la defensa de posibles reclamaciones. 
                </li>
                <li>
                  • Las personas interesadas también tienen derecho a la portabilidad de sus datos. 
                </li>
                <li>
                  • Finalmente, los/las interesados/as tienen derecho a presentar una reclamación ante la Autoridad de Control competente.
                </li>
              </ul>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>- ¿Cómo puede ejercer sus derechos?</strong>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Enviándonos un escrito adjuntando una copia de un documento que lo identifique a nuestro domicilio o correo electrónico (que aparecen al principio del presente Aviso Legal).
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>- ¿Cómo hemos obtenido sus datos?</strong>{" "}
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Los datos personales que tratamos proceden del propio interesado, quien garantiza que los datos facilitados son ciertos y se hace responsable de comunicarnos cualquier modificación de los mismos. Los datos que estén marcados con un asterisco son obligatorios para poder darle el servicio solicitado.
              </p>
              <p className="o-margin o-margin--bottom-size-m">
                <strong>- ¿Qué datos tratamos?</strong>
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Los datos que tratamos oscilan entre las siguientes categorías:
              </p>
              <ul className="o-margin o-margin--bottom-size-l">
                <li>• Datos de carácter identificativo.</li>
                <li>• Direcciones postales o electrónicas.</li>
                <li>• Otros datos solicitados en nuestros formularios.</li>
              </ul>
              <p className="o-margin o-margin--bottom-size-l">
                Los datos son limitados, dado que únicamente tratamos los datos necesarios para la prestación de nuestros servicios y la gestión de nuestra actividad. {" "}
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                6) ¿QUÉ MEDIDAS DE SEGURIDAD APLICAMOS?
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                Aplicamos las medidas de seguridad establecidas en el artículo 32 del RGPD, por lo tanto hemos adoptado las medidas de seguridad necesarias para garantizar un nivel de seguridad adecuado al riesgo del tratamiento de los datos que realizamos, con mecanismos que nos permiten garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanente de los sistemas y servicios de tratamiento.
              </p>
              <p className="o-margin o-margin--bottom-size-l">
                Algunas de estas medidas son:
              </p>
              <ul>
                <li>
                  • Información de las políticas de tratamiento de los datos al
                  personal.
                </li>
                <li>• Realización de copias de seguridad periódicas.</li>
                <li>• Control de acceso a los datos.</li>
                <li>
                  • Procesos de verificación, evaluación y valoración regulares.{" "}
                </li>
              </ul>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-l">
                7) EXCLUSIÓN DE RESPONSABILIDAD
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                <strong>GSG Metrics</strong> con la máxima diligencia posible para
                que los datos y la información que ofrece en su sitio web esté
                actualizada en todo momento. No garantiza ni se hace responsable
                la exactitud y actualización de los contenidos del sitio web,
                reservándose el derecho a modificar estos contenidos en
                cualquier momento. <strong>GSG Metrics</strong> será responsable de
                la información que se pueda obtener a través de enlaces
                incluidos en el sitio web.
              </p>
              <p>
                Las relaciones comerciales entre clientes se regirán por las
                condiciones generales que, si fuera necesario, se establecerían
                por <strong>GSG Metrics</strong> un documento específico a este
                efecto, o para los pactos concretos que se puedan acordar con
                los clientes.{" "}
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                8) POLÍTICA DE INFORMACIÓN Y PUBLICIDAD COMERCIAL.
              </h6>

              <p className="o-margin o-margin--bottom-size-l">
                <strong>GSG Metrics</strong> compromete a través de este medio a no
                realizar publicidad engañosa. A estos efectos, por lo tanto, no
                serán considerados como publicidad engañosa los errores formales
                o numéricos que puedan encontrarse a lo largo del contenido de
                las distintas secciones de la Web, producidos como consecuencia
                de un mantenimiento y/o actualización incompleta de la
                información contenida en estas secciones.{" "}
                <strong>GSG Metrics</strong>, como consecuencia de lo dispuesto en
                este apartado, se compromete a corregirlo tan pronto como tenga
                conocimiento de dichos errores.{" "}
              </p>
              <p>
                <strong>GSG Metrics</strong> compromete a no remitir comunicaciones
                comerciales sin identificarlas como tales, conforme a lo
                dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la
                Información y de comercio electrónico. A estos efectos no será
                considerado como comunicación comercial toda la información que
                se envíe a los clientes de <strong>GSG Metrics</strong> que tenga
                por finalidad el mantenimiento de la relación contractual
                existente entre cliente y <strong>GSG Metrics</strong>, así como el
                desempeño de las tareas de información y otras actividades
                propias del servicio que el cliente tiene contratado.
              </p>
            </div>
            <div className="o-margin o-margin--bottom-size-xl">
              <h6 className="o-margin o-margin--bottom-size-m">
                9) CONDICIONES DE USO. JURISDICCIÓN Y LEYES APLICABLES.
              </h6>

              <p>
                El uso de este sitio web implica la aceptación plena de los
                términos del presente aviso legal. El presente Aviso Legal y
                todas las relaciones que se establezcan entre{" "}
                <strong>GSG Metrics</strong> el Usuario de la Web y sus servicios se
                regirán por lo establecido en la legislación española.
              </p>
            </div>
            <p>24 de abril de 2023.</p>
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
