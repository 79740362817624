import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Multiselect from "../Multiselect";

const ModalEditUser = (props) => {
  const [editData, setEditData] = useState({});
  const [error, setError] = useState(true);
  const [isEdit, setIsEdit] = useState("");
  const [option, setOption] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.type !== "select") {
      setIsEdit(props.edit);
      setEditData({ email: props.data.email });

      setError(!validateEmail(props.data.email));
    }
  }, []);

  const getEditValues = (e) => {
    if (validateEmail(e.currentTarget.value)) {
      setError(false);
    } else {
      setError(true);
    }
    setEditData({ ...editData, [e.currentTarget.name]: e.currentTarget.value });
  };

  const validateEmail = (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const _action = (option_selected) => {
    setLoading(true)
    if(!loading){
      props.action(option_selected)  
    }
    

  }

  return (
    <Modal isVisible={props.showModal} medium>
      <div className="o-width--full-width">
        {props.type !== "select" && (
          <form>
            <h2 className="o-margin--bottom-size-l">
              {props.title}
            </h2>
            {
              props.description !== undefined && props.description !== "" &&
            <p className="o-margin--bottom-size-l">{props.description}</p>
            }
            
            <Input
              name="email"
              label="Email"
              errorLabel="You must enter a correct email"
              minCharacters={5}
              showError={!validateEmail(editData.email) && editData.email}
              type="text"
              value={editData.email}
              onChange={(e) => getEditValues(e)}
              disabled={!isEdit}
            />
            {
              props.data.invitation_link && props.data.invitation_link !== "" &&
                <Input
                name="invitation_link"
                label="Invitation Link"
                errorLabel="You must enter a correct email"
                minCharacters={5}
                type="text"
                value={props.data.invitation_link}
                onChange={(e) => ""}
                disabled={false}
              />
            }
            
          </form>
        )}

        {props.type === "select" && (
          <>
            <h2 className="o-margin--bottom-size-l">{props.title}</h2>
            <Multiselect
              label={props.label}
              //defaultValue="Informe"
              // multiselectClassName="c-multiselect--white-bg c-multiselect--border"
              multiple={false}
              options={props.options}
              getOption={(option) => {setError(false); setOption(option)}}
            />
          </>
        )}
      </div>

      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        {props.canManage && (
          <button
            type="button"
            className="c-button c-modal__button"
            disabled={error || loading}
            onClick={() =>
              props.type !== "select"
                ? isEdit
                  ? props.action(editData, props.data.user_folder_id)
                  : setIsEdit(true)
                : _action(option)
            }
          >
            <span className="c-button__text">
              {props.type !== "select"
                ? isEdit
                  ? "Confirm"
                  : "Edit"
                : "Send"}
            </span>
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ModalEditUser;
