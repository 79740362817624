// Dependencies
import React from "react";
import classNames from "classnames";

const IllustrationProcedure = (props) => {
	let classNameProcedure = classNames("c-procedure", props.className && props.className);

	return (
    <div style={props.style ? props.style : {}}>
		<svg
			className={classNameProcedure}
			width={props.width ? props.width : "23px"}
			height={props.height ? props.height : "21px"}
			viewBox="0 0 23 21" 
		>
			    <g fill="none" fillRule="evenodd">
      <path
        stroke={props.firstColor ? props.firstColor : "#FDFDFD"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M19 15.25A9.668 9.668 0 0 1 10.645 20C5.318 20 1 15.747 1 10.5S5.318 1 10.645 1c3.57 0 6.687 1.91 8.355 4.75"
      />
      <path
        stroke={props.firstColor ? props.firstColor : "#FDFDFD"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m21.439 19.114-1.83-5.353-5.353 1.83"
      />
      <path
        fill={props.secondColor ? props.secondColor : "#A9C44B"}
        d="M10.821 6c.425 0 .79.279.87.664l.036.172c.14.674.894 1.076 1.596.85l.179-.057c.402-.128.846.024 1.058.364l.321.514a.775.775 0 0 1-.188 1.028l-.143.114a1.07 1.07 0 0 0 0 1.702l.143.114c.322.257.4.688.188 1.028l-.32.514c-.213.34-.657.492-1.059.364l-.179-.058c-.702-.225-1.456.177-1.596.851l-.036.172c-.08.385-.445.664-.87.664h-.642c-.425 0-.79-.279-.87-.664l-.036-.172c-.14-.674-.895-1.076-1.596-.85l-.179.056c-.402.13-.846-.023-1.058-.363l-.321-.514a.775.775 0 0 1 .188-1.028l.143-.114a1.07 1.07 0 0 0 0-1.702l-.143-.114a.775.775 0 0 1-.188-1.028l.32-.514c.213-.34.657-.492 1.059-.364l.179.058c.701.225 1.456-.177 1.596-.851l.036-.172c.08-.385.445-.664.87-.664h.642Zm-.321 5.786c.746 0 1.35-.576 1.35-1.286 0-.71-.604-1.286-1.35-1.286-.746 0-1.35.576-1.35 1.286 0 .71.604 1.286 1.35 1.286Z"
      />
    </g>
		</svg>
</div>
	);
};

export default IllustrationProcedure;
