import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

// Components
import Close from "./Close";

const CloseButton = (props) => {
  let closeButtonClasses = classNames("c-close-button", props.className);

  return (
    <div className={closeButtonClasses} onClick={() => props.action()}>
      <Close
        width={props.width}
        height={props.height}
        className="c-close-button-svg"
        color={props.color}
      />
    </div>
  );
};

CloseButton.propTypes = {
  orientation: PropTypes.string,
  className: PropTypes.string,
  white: PropTypes.bool,
};

export default CloseButton;
