
import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {FETCH_LOGOUT_OK} from '../containers/login/actions';
import {SET_ERRORS_OK} from '../actions';

/** Reducers imports */
import authenticationReducer from '../containers/login/reducers';
import passwordReducer from '../containers/passwords/reducers';
import userReducer from '../containers/users/reducers';
import adminReducer from '../containers/admin/reducers';
import surveyReducer from '../containers/surveys/reducers';

const defaultGlobalState = { errors: {}};

const globalReducer = (state = defaultGlobalState, action) => {
  switch (action.type) {
    case SET_ERRORS_OK:
      return {...state, errors: {...state.errors,
          [action.data.name]: action.data.errors
        }}
    default:
      return state
  };
}

const appReducer = combineReducers({
  routing,
  password: passwordReducer,
  client: authenticationReducer,
  user: userReducer,
  admin: adminReducer,
  survey: surveyReducer,
  global: globalReducer
});

const rootReducer = (state, action) => {
  if (action.type === FETCH_LOGOUT_OK) {
    localStorage.clear();
    const { routing } = state
    state = { routing } 
  }

  return appReducer(state, action)
}

export default rootReducer;
