import PropTypes from "prop-types";
import React from "react";

export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let buttonClasses = ["c-button"];
    this.props.type === "submit" && buttonClasses.push("c-button__submit");
    this.props.big && buttonClasses.push("c-button--big");
    this.props.small && buttonClasses.push("c-button--small");
    this.props.disabled && buttonClasses.push("c-button--disabled");
    this.props.className && buttonClasses.push(this.props.className);

    return (
      <button
        name={this.props.name}
        type={this.props.submit ? "submit" : "button"}
        className={buttonClasses.join(" ")}
        onClick={(e) => this.props.onClick(e)}
        data-cursor-hover
      >

        { this.props.icon && (
          <div className="c-button__icon">
            {this.props.icon}
          </div>

        )}
        <span className="c-button__text">{this.props.text}</span>
      </button>
    );
  }

  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    submit: PropTypes.bool,
    big: PropTypes.bool,
    small: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };
}
