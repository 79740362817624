import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Tick = (props) => {
  const [tickClasses, setTickClasses] = useState("c-tick");
  const [tickIllustrationClasses, setTickIllustrationClasses] = useState(
    "c-tick__illustration"
  );
  const [groupClasses, setGroupClasses] = useState("");

  // Handle classes
  useEffect(() => {
    setTickClasses(
      classNames(
        "c-tick",
        props.medium && "c-tick--medium",
        props.big && "c-tick--big",
        props.background && "c-tick--bg",
        props.className
      )
    );

    setTickIllustrationClasses(
      classNames("c-tick__illustration", props.illustrationClassName)
    );

    setGroupClasses(classNames(props.illustrationClassName));
  }, [props]);

  return (
    <div className={tickClasses}>
      <svg
        width={props.width ? props.width : "117px"}
        height={props.height ? props.height : "89px"}
        viewBox="0 0 117 89"
        className={tickIllustrationClasses}
        style={props.style}
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.color ? props.color : "#6EC1E4"}
          strokeLinecap="round"
          strokeWidth={12}
          d="M6 49.167 36.882 80 111 6"
        />
      </svg>
    </div>
  );
};
Tick.propTypes = {
  className: PropTypes.string,
  groupClass: PropTypes.string,
};

export default Tick;
