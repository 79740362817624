// Dependencies
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

const ProcedureUserBiodata = (props) => {

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
    </motion.div>
  );
};

export default ProcedureUserBiodata;