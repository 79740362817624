// Dependencies
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

// Components
import IllustrationFolder from "./IllustrationFolder";
import IllustrationOval from "./IllustrationOval";
import Edit from "./Edit";
import Info from "./Info";
import IllustrationHead from "./IllustrationHead";

const FolderItem = (props) => {
  let classNameFolder = classNames(
    "c-folder",
    props.alignLeft && "c-folder--align-left"
  );

  const hoverMotionOval = {
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        delay: 0.2,
        type: "spring",
      },
    },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.1,
        type: "spring",
      },
    },
  };

  const hoverMotionFolder = {
    hidden: {
      top: 0,
      transition: {
        delay: 0.22,
        type: "tween",
      },
    },

    show: {
      top: "33px",
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  const hoverMotionHead = {
    hidden: {
      opacity: 0,
      top: "30px",
      transition: {
        delay: 0.14,
        type: "spring",
      },
    },

    show: {
      top: "0",
      opacity: 1,
      transition: {
        delay: 0.4,
        type: "tween",
      },
    },
  };

  const hoverMotionEdit = {
    hidden: {
      opacity: 0,
      top: "30px",
      transition: {
        delay: 0.12,
      },
    },

    show: {
      opacity: 1,
      top: "0",
      transition: {
        delay: 0.6,
        duration: 0.2,
        type: "tween",
      },
    },
  };

  const hoverMotionClose = {
    hidden: {
      opacity: 0,
      top: "30px",
      transition: {
        delay: 0.1,
        type: "spring",
      },
    },
    show: {
      opacity: 1,
      top: "0",
      transition: {
        delay: 0.8,
        type: "tween",
      },
    },
  };

  const goTo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.goTo();
  };

  return (
    <motion.div
      className={classNameFolder}
      initial="hidden"
      animate="hidden"
      onClick={(e) => goTo(e)}
      onHoverStart={() => props.selectFolder(props.index)}
      onHoverEnd={() => props.selectFolder(-1)}
    >
      <div className="c-folder__wrapper">
        <motion.div
          className="c-folder__wrapper-item"
          animate={props.selected}
          variants={hoverMotionFolder}
        >
          {props.canManage && (
            <div
              className="c-folder__wrapper-item-tools"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <motion.div
                className="c-folder__wrapper-item-tools-icon"
                animate={props.selected}
                variants={hoverMotionHead}
                onTap={() => props.invitedFolder()}
              >
                <IllustrationHead />
              </motion.div>
              <motion.div
                className="c-folder__wrapper-item-tools-icon"
                animate={props.selected}
                variants={hoverMotionEdit}
                onTap={() => props.editFolder()}
              >
                <Edit
                  width="22px"
                  height="22px"
                />
              </motion.div>
              <motion.div
                className="c-folder__wrapper-item-tools-icon"
                animate={props.selected}
                variants={hoverMotionClose}
                onTap={() => props.infoStatusFolder()}
              >
                <Info
                  className="c-folder__wrapper-item-tools-icon"
                  width="12px"
                  height="22px"
                />
              </motion.div>
            </div>
          )}
          <IllustrationFolder />
          <h5 className="c-folder__title">{props.name && props.name}</h5>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FolderItem;
