// Dependencies
import React from "react";
import PropTypes from "prop-types";
import anime from "animejs/lib/anime.es.js";
import { motion } from "framer-motion";
import classNames from "classnames";

let prevPercentage = 0;

export default class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      percentage: 0,
    };
  }

  componentDidMount() {
    this._setAmount(this.props.progress, prevPercentage);
  }

  shouldComponentUpdate = (prevProps, prevState) => {
    prevPercentage = this.props.progress;
    if (this.props.progress) return prevProps.progress != this.props.progress;
    else return true;
  };

  componentDidUpdate = () => {
    this._setAmount(this.props.progress, prevPercentage);
  };

  _setAmount = (progress, prevPercentage) => {
    let element = this.percentage;
    const percentage = {
      value: prevPercentage,
    };
    anime({
      targets: percentage,
      value: progress,
      easing: "linear",
      duration: 570,
      delay: 400,
      round: 1,
      update: function () {
        element.innerHTML = `${Object.values(percentage)}%`;
      },
    });
  };

  render() {
    let progressBarClasses = classNames(
      "c-progress-bar",
      this.props.className && this.props.className
    );

    let progressBarPercentageClasses = classNames(
      "c-progress-bar__percentage",
      this.props.admin && "c-progress-bar__percentage--auto o-padding--left-size-s o-padding--right-size-s",
      this.props.admin && this.props.progress === 0 && "o-padding--left-size-zero",
    );

    let progressBarContentClasses = classNames(
      "c-progress-bar__content",
      this.props.contentClasses && this.props.contentClasses
    );

    return (
      <div className={progressBarClasses}>
        {!this.props.noTitle && (
          <h6 className="o-margin--bottom-size-s">
            {this.props.title ? this.props.title : "Progreso:"}
          </h6>
        )}

        <div className={progressBarContentClasses}>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: `${this.props.progress}%` }}
            exit={{ width: `${this.props.progress}%` }}
            transition={{ duration: 0.1 }}
            className="c-progress-bar__progressed"
          ></motion.div>
          <div
            className={progressBarPercentageClasses}
            ref={(r) => (this.percentage = r)}
          ></div>
          <div className="c-progress-bar__will-progress"></div>
        </div>
      </div>
    );
  }

  static propTypes = {
    progress: PropTypes.number.isRequired,
  };
}
