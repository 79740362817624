// Dependencies
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Camera = (props) => {
  const cameraClasses = () => {
    let cameraClass = classNames(
      "c-camera",
      props.className && props.className
    );
    return cameraClass;
  };
  return (
    <svg
      width="18px"
      height="22px"
      viewBox="0 0 18 22"
      className={cameraClasses()}
    >
      <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        stroke="#3C5896"
        d="M9 17.75a8.25 8.25 0 1 0 0-16.5 8.25 8.25 0 0 0 0 16.5Z"
      />
      <path
        stroke="#3C5896"
        d="M9 13.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5ZM13.35 16.475l1.8 1.65c1.05.9.375 2.625-.975 2.625H3.9c-1.35 0-2.025-1.65-.975-2.625l1.8-1.65"
      />
      <path
        stroke="#A9C44B"
        d="M9 10.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
      />
    </g>
  </svg>
  );
};

Camera.propTypes = {
  className: PropTypes.string,
};

export default Camera;
