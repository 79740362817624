import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";

// Components
import Aside from "../../components/Aside";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import Button from "../../components/Button";
import FolderItem from "../../components/FolderItem";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalInfoStatus from "../../components/admin-modals/ModalInfoStatus";
import ModalShowList from "../../components/admin-modals/ModalShowList";
import HighchartWrapper from "../../components/HighchartWrapper";
import Spinner from "../../components/Spinner";
import Edit from "../../components/Edit";
import Roles from "../../config/roles";
import Envelope from "../../components/Envelope";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// Actions
import {
  fetchOrganization,
  fetchUpdateOrganization,
  fetchFolderDestroy,
  fetchOrganizations,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderCreate,
  fetchFolderUpdate,
  fetchFolderCreateUsersFolder,
  fetchFolderUpdateChart,
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "./selector";

const Organization = (props) => {
  const [folderIdSelected, setFolderIdSelected] = useState(undefined);
  const [folderData, setFolderData] = useState(undefined);
  const [modals, setModals] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const TITLES = {"completed": "Completed", "in_progress": "In progress", "invited": "Invited", "pending": "Pending"};
  const COLORS = {"completed": "#B4CEED", "in_progress" :"#78AEDF", "invited": "#788594", "pending": "#585C60"}

  useEffect(() => {
    props.fetchOrganizations();
    props.fetchOrganization(props.match.params.organizationId, 1, 4, () =>
      setLoading(false)
    );
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => {}
    );

  }, []);

  const getClassNameSubtitle = () => {
    let classNameSubtitle = classNames(
      "o-margin--top-size-xxl o-margin--bottom-size-xxl",
      props.organization &&
        props.organization.data.folders.length === 0 &&
        "o-text-align--center"
    );
    return classNameSubtitle;
  };

  const selectFolder = (id) => {
    let selectId = undefined;
    if (folderIdSelected !== id) {
      selectId = id;
    }
    setFolderIdSelected(selectId);
  };

  const actionModals = (folder, type) => {
    if (type === "delete") {
      setModalMsg("Do you want to delete this survey?");
    } else if (type === "createFolder") {
      setModalMsg("The survey has been created successfully!");
    } else if (type === "infoStatusUsers") {
    }

    setModals(type);
    setFolderData(folder);
  };

  const submitEdit = (folder) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;

    props.fetchFolderUpdate(
      organizationId,
      folderId,
      { folder: folder },
      () => {
        successAction("success"),
          setModalMsg("The survey has been edited successfully!");
      }
    );
  };

  const submitCreate = (folder) => {
    let organizationId = props.organization.data.id;

    props.fetchFolderCreate(organizationId, { folder: folder }, () =>
      successAction("success")
    );
  };

  const submitDelete = (folder) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;

    props.fetchFolderDestroy(organizationId, folderId, () => successAction());
  };

  const submitInvitation = (emails, file) => {
    let organizationId = props.organization.data.id;
    let folderId = folderData.id;

    if (emails.length > 0 || file !== undefined ) {
      props.fetchFolderCreateUsersFolder(
        organizationId,
        folderId,
        { emails: emails },
        () => { setModalMsg("The users has been added successfully!"); setModals("success");}
      );
    }
  };

  const successAction = (type) => {
    setModals(type);
    props.fetchOrganization(props.match.params.organizationId, 1, 4);
  };

  const selectAction = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const getAssessments = () => {
    setModals("createOrganization");
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId  }, () => {
      setModals("success"),
        setModalMsg("The organization has been created successfully!");
      props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const actionModal = (type) => {
    if (type === "delete") {
      submitDelete();
    } else if (type === "closeSession") {
      closeSession();
    } else {
      successAction();
    }
  };

  const getDataOrganization = () => {
    return {
      name: props.organization.data.name,
      primary_color: props.organization.data.primary_color,
      secondary_color: props.organization.data.secondary_color,
      biodataOptions: props.organization.data.biodata_required,
      assessments: props.assessments,
      logo: props.organization.data.logo,
    };
  };

  const submitUpdateOrganization = (data) => {
    props.fetchUpdateOrganization(
      props.match.params.organizationId,
      data,
      () => {
        setModals("success"),
          setModalMsg("The organization has been updated successfully!");
      }
    );
  };

  const getTitleAndActionModal = (data) => {
    switch (modals) {
      case "createFolder":
        return { title: "New assessment", action: () => submitCreate(data) };
      case "edit":
        return { title: "Edit assessment", action: () => submitEdit(data) };
      case "createOrganization":
        return {
          title: "New organization",
          action: () => submitCreateOrganization(data),
        };
      case "editOrganization":
        return {
          title: "Edit organization",
          action: () => submitUpdateOrganization(data),
        };
      default:
        break;
    }
  };

  const getDataChart = (folderId, typeModal) => {
    props.fetchFolderUpdateChart(
      props.match.params.organizationId,
      folderId,
      "",
      (totals) => {
        setModals(typeModal), setModalMsg(totals), setLoading(false);
      },
      () => {}
    );
  };

  return (
    <>
      <motion.section
        className="o-wrapper o-wrapper--row"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {props.organization &&
          props.organization.data &&
          props.organizations.length > 0 && (
            <Aside
              history={props.history}
              label={"Select organization:"}
              showSelect={true}
              organizations={props.organizations}
              organization={props.organization}
              selectAction={(id) => selectAction(id)}
              createOrganization={() => getAssessments()}
              selectOrganization={() => setModals("selectOrganization")}
              showModalSession={() => {
                setModals("closeSession"),
                  setModalMsg("Are you sure you want to logout?");
              }}
            />
          )}
        {loading && <Spinner />}

        {props.organization && props.organization.data && (
          <div className="o-container">
            <div className="c-block-title o-margin--bottom-size-xxl">
              <div className="c-block-title__left">
                <div className="c-block-title__left-content o-margin--bottom-size-xl">
                 
                  
                  <div className="c-block-title__left-content-info">
                    <div className="c-wrapper-image o-margin--right-size-l">
                      <img
                        className="c-wrapper-image__image"
                        src={props.organization.data.logo}
                        alt="logo"
                      />
                    </div>

                    <h1 className="c-block-title__big-text">
                      {props.organization.data.name}
                    </h1>
                  </div>
                  {
                    props.organization &&
                    props.organization.user_role !== Roles.admin.READER &&
                      <div
                      className="c-block-title__left-content-icon"
                      onClick={() =>
                        actionModals(getDataOrganization(), "editOrganization")
                      }
                    >
                      <Edit />
                    </div>
                  }
                </div>

                <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
                  {props.assessments && props.assessments.length > 0 && (
                    <button
                      type="button"
                      className={"c-button c-button--margin-zero"}
                      onClick={() => {
                        setModals("showAssessments");
                      }}
                    >
                      <span className="c-button__text">Show assessments</span>
                    </button>
                  )}
                  {props.organization &&
                    props.organization.user_role !== Roles.admin.READER && 
                    <button
                      type="button"
                      className={"c-button c-button--margin-zero"}
                      onClick={() => actionModals({}, "createFolder")}
                    >
                      <span className="c-button__text">New assessment</span>
                    </button>
                  }
                  {props.organization && props.organization.data.emails == true && (
                    <button
                      type="button"
                      className={"c-button c-button--margin-zero"}
                      onClick={() => {
                        props.history.push(`/admin/organizations/${props.organization.data.id}/custom_mails`)
                      }}
                    >
                      <div className="c-button__icon">
                        <Envelope width="20px" height="30px" />
                      </div>
                      <span className="c-button__text">Emails</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <h3 className="o-margin--bottom-size-l">Info users status</h3>
            <div className="c-total-users o-margin--bottom-size-xxl">
              
              {props.organization && Object.keys(props.organization.totals).length > 0 &&
                Object.keys(props.organization.totals).map((item, index) => {
                  return (
                    <div key={`total-users-${index}`} className="c-total-users__box">
                      <p className="c-total-users__box-title">
                        <span className="c-total-users__box-point" style={{backgroundColor: COLORS[item]}}></span>
                        {TITLES[item]}
                        </p>
                      <p className="c-total-users__box-info">
                        {props.organization.totals[item]}
                      </p>
                      <p className="c-total-users__box-info">
                        {props.organization.totals_description[item]}
                      </p>
                    </div>
                  );
                })}
            </div>
            {props.organization.data.folders.length > 0 &&
              props.organization.graphs.length > 0 && (
                <HighchartWrapper data={props.organization.graphs} />
              )}

            {props.organization.data.folders.length === 0 && (
              <div className="o-padding--left-size-kilo o-padding--right-size-kilo">
                <IllustrationLineSeparator />
              </div>
            )}

            <h2 className={getClassNameSubtitle()}>
              {props.organization.data.folders.length > 0
                ? "Last surveys"
                : "Currently there are no folders"}
            </h2>

            <AnimatePresence exitBeforeEnter>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="o-grid o-grid--two-four o-margin--bottom-size-xl"
              >
                {props.organization.data.folders.map((folder, index) => {
                  let selected = folderIdSelected === index ? "show" : "hidden";
                  return (
                    <FolderItem
                      key={"folderItem-" + index}
                      index={index}
                      name={folder.name}
                      goTo={() =>
                        props.history.push(
                          `/admin/organizations/${props.organization.data.id}/surveys/${folder.id}`
                        )
                      }
                      canManage={
                        props.organization &&
                        props.organization.user_role !== Roles.admin.READER
                      }
                      selected={selected}
                      selectFolder={(index) => selectFolder(index)}
                      deleteFolder={() => actionModals(folder, "delete")}
                      editFolder={() => actionModals(folder, "edit")}
                      invitedFolder={() => actionModals(folder, "invite")}
                      infoStatusFolder={() =>
                        {setLoading(true)
                        getDataChart(folder.id, "infoStatusUsers")}
                      }
                    />
                  );
                })}
              </motion.div>
            </AnimatePresence>

            {props.organization.data.folders.length === 0 && (
              <div className="o-padding--left-size-kilo o-padding--right-size-kilo">
                <IllustrationLineSeparator />
              </div>
            )}
          </div>
        )}

        {modals === "invite" && (
          <ModalInvitationFolder
            showModalInvitationFolder={modals === "invite"}
            closeInvitationFolder={() => setModals("")}
            invitationAction={(data, file) => submitInvitation(data, file)}
          />
        )}

        {modals === "infoStatusUsers" && (
          <ModalInfoStatus
            showModal={modals === "infoStatusUsers"}
            title={"Total users:"}
            text={modalMsg}
            closeModal={() => {
              setModals(""), setModalMsg("");
            }}
          />
        )}
        {
          modals === "selectOrganization" && (
            <ModalSelectOrganization
              history={props.history}
              organizations={props.organizations_select}
              closeModal={() => setModals("")}
            />
          )
        }
        {[
          "createFolder",
          "createOrganization",
          "edit",
          "editOrganization",
        ].includes(modals) && (
          <ModalCreate
            showModal={true}
            closeModal={() => {
              setModals("");
              setFolderData(undefined);
            }}
            editAction={(data) => getTitleAndActionModal(data).action()}
            defaultData={folderData}
            type={modals}
            title={getTitleAndActionModal().title}
            assessments={
              ["createFolder", "edit"].includes(modals)
                ? props.organization.data.assessments
                : props.assessments
            }
            biodataOptions={props.organization.data.biodata_options}
          />
        )}

        {["showAssessments"].includes(modals) && (
          <ModalShowList
            showModal={true}
            title={"Assessments:"}
            assessments={props.assessments}
            action={() => setModals("")}
          />
        )}

        {["delete", "success", "closeSession"].includes(modals) && (
          <ModalInfoAction
            showModalInfoAction={true}
            noButtonCancel={["success"].includes(modals)}
            closeModalInfoAction={() => setModals("")}
            text={modalMsg}
            action={() => actionModal(["closeSession"].includes(modals) ? "closeSession" : "")}
          />
        )}
      </motion.section>
    </>
  );
};

Organization.propTypes = {
  organization: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    state,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    chart: state.admin.chart,
    organizations_select: state.admin.organizations_select
  };
};

const mapDispatchToProps = {
  fetchOrganizations,
  fetchOrganization,
  fetchUpdateOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderDestroy,
  fetchFolderUpdate,
  fetchFolderCreate,
  fetchFolderCreateUsersFolder,
  fetchFolderUpdateChart,
  fetchLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
