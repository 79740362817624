import React, { useState, useEffect } from "react";

// Components
import Input from "./Input";
import Close from "./Close";

const CreateTags = (props) => {
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);

  useEffect(() => {
    if (props.value) {
      typeof props.value === "string"
        ? setTags(props.value.split(","))
        : setTags(props.value);
    }
  }, [props.value]);

  const addTag = (e) => {
    e.preventDefault();

    if (tag !== "") {
      setTags([...tags, tag]);
      setTag("");

      props.getTags([...tags, tag]);
    }
  };

  const deleteTag = (index) => {
    let deleteTag = tags;
    deleteTag.splice(index, 1);
    setTags([...deleteTag]);

    props.getTags([...deleteTag]);
  };

  return (
    <div className="c-tags">
      <div className="c-tags__input">
        <form className="c-tags__input-text">
          <Input
            className="c-input"
            wrapperClassName="c-input__wrapper--no-size"
            name="tag"
            label={props.label}
            search={false}
            errorLabel="Este campo no puede estar vacío"
            minCharacters={5}
            type="text"
            value={tag}
            onChange={(e) => setTag(e.currentTarget.value)}
            onKeyPress={(e) => {
              e.key === "Enter" ? addTag(e) : setTag(e.currentTarget.value);
            }}
            showHint={true}
            hintLabel={
              "These labels will help you filter and categorize emails"
            }
          />
        </form>
        <button type="button" className="c-button">
          <span className="c-button__text" onClick={(e) => addTag(e)}>
            Add Tag
          </span>
        </button>
      </div>
      <div className="c-tags__box">
        {tags.map((tag, index) => {
          return (
            <div
              key={`tags-${index}`}
              className="c-tags__box-item"
              onClick={() =>
                tag !== "email_de_invitacion" ? deleteTag(index) : {}
              }
            >
              <p className="c-tags__box-item-text">{tag}</p>
              {tag !== "email_de_invitacion" && (
                <Close width="10px" height="10px" />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreateTags;
