import React, { useState } from "react";

// Components
import Modal from "../../../components/Modal";
import UploadFileButton from "../../../components/UploadFileButton";
import Select from "../../../components/Select";
import File from "../../../components/File";

const ProcedureModalUploadFile = (props) => {
  const { showModal, documentOptions, closeModal, saveData } = props;

  const [editData, setEditData] = useState({});
  return (
    <Modal isVisible={showModal} medium>
      <div className="u-width--full">
        <h3 className="o-margin--bottom-size-l">
          Upload new additional document:
        </h3>
        <div className="o-display--flex o-display--flex-v-center">
          <p className="o-margin--right-size-l">
            From here you can import a file:
          </p>
          {editData["file"] === undefined && (
            <UploadFileButton
              label={"Organization logo"}
              imageName={"nameLogo()"}
              image={false}
              textButton={"Upload file"}
              getFile={(file) => setEditData({ ...editData, ["file"]: file })}
              classNameUploadButton={"c-upload-button--max-width"}
            />
          )}
        </div>
        {editData["file"] && (
          <div className="o-display--flex o-display--flex-v-center o-margin--top-size-l o-margin--bottom-size-l">
            <File width={"36px"} height={"46px"} />
            <span className="c-upload-file-input__add-file-hint">
              {editData["file"]["original_filename"]}
            </span>
          </div>
        )}
        <div>
          {editData["file"] && (
            <React.Fragment>
              <p className="o-font-p--small">Select file type</p>
              <Select
                className="c-select c-select--border"
                errorLabel=""
                withoutInput={true}
                tick={false}
                valueSelected={editData["category"]}
                options={documentOptions}
                optionClick={(text, value) => {
                  setEditData({
                    ...editData,
                    ["category"]: value,
                    ["category_title"]: text,
                  });
                }}
                wrapperClassName={"c-select__wrapper--height-zero"}
              />
            </React.Fragment>
          )}
        </div>
      </div>

      <div className="c-modal__buttons-wrapper o-margin--top-size-l">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => closeModal()}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        <button
          type="button"
          className="c-button c-modal__button"
          disabled={editData["file"] === undefined}
          onClick={() => saveData(editData)}
        >
          <span className="c-button__text">Confirm</span>
        </button>
      </div>
    </Modal>
  );
};

export default ProcedureModalUploadFile;
