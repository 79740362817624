import React from "react";
import Highcharts from "highcharts";

//Components
import Highchart from "./Highchart";

//helpers
import highchartResize from "../helpers/highchartResize";

const HighchartWrapper = (props) => {
  highchartResize(Highcharts);

  return (
    <div className="c-charts">
      {props.data.map((item, index) => {
        return (
          <Highchart
            key={`highcharts-${index}`}
            title={item.title}
            data={[
              {
                showInLegend: false,
                data:
                  item.graph_type === "spline"
                    ? Object.values(item.data)
                    : item.data,
                color:
                  item.graph_type === "spline"
                    ? item.colors[index]
                    : item.colors,
              },
            ]}
            categories={item.categories}
            type={item.graph_type}
          />
        );
      })}
    </div>
  );
};

export default HighchartWrapper;
