import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";

// Components
import TableFilter from "./TableFilter";
import TableReport from "./TableReport";
import ModalInfoAction from "./admin-modals/ModalInfoAction";
import ModalShowUser from "./admin-modals/ModalShowUser";
import ModalInput from "./admin-modals/ModalInput";

// Actions
import {
  fetchFolderDestroyUser,
  fetchFolderSendInvitationUser,
  fetchFolderUpdateUsersFolder,
  fetchUser,
  fetchUserUpdate,
  fetchShowAdmin,
  fetchAdminUpdate,
  fetchAdminDestroy,
  fetchEmailsSend,
  fetchAdminSendEmail,
} from "../containers/admin/actions";

const FolderTableTools = (props) => {
  const [emailSearch, setEmailSearch] = useState("");
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState({});
  const [showUserModal, setShowUserModal] = useState({});
  const [userId, setUserId] = useState("");
  const [isEditUser, setIsEditUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState({});
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [allIds, setAllIds] = useState([]);

  let OPTIONS = [
    { text: "Actions", value: 0, action: () => {} },
    {
      text: "Send email",
      value: 1,
      action: () => {
        ids.length > 0
          ? props.type === "administrators"
            ? openModal({
                text: "The invitation will be sent to the administrators",
                action: () => invitedAdmin(getOnlyIds()),
              })
            : setShowInvitationModal(true)
          : {};
      },
    },
  ];

  if (props.canManage === undefined ? true : props.canManage) {
    OPTIONS.push({
      text: "Remove",
      value: 2,
      action: () => {
        checkStatus() || props.type === "administrators"
          ? openModal({
              text:
                props.type === "administrators"
                  ? "Administrators will be removed"
                  : "Users will be removed",
              action: () =>
                props.type === "administrators"
                  ? deleteAdmin(getOnlyIds())
                  : deleteUser(getOnlyIds()),
            })
          : openModal({
              text: "You can only remove users with status: Pending",
              action: () => setShowModal({}),
              type: "error",
            });
      },
    });
  }

  useEffect(() => {
    let ids = []
    if(props.type === "ranked_users"){

    }else{
      props.folder && props.folder.user.map((item) => item.user_folder_id);
    }
    
    setAllIds(ids);

    return () => {
      setAllIds([])
    };
  }, [props.folder && props.folder.id]);

  const userFiltered = () => {
    let users = [];
    props.data && (users = props.data.user);
    users = users.filter((value) =>
      (value.email || value.name) ? 
      (value.email ? value.email.toLowerCase().indexOf(emailSearch.toLowerCase(), 0) !== -1 : false) ||
      (value.name ? value.name.toLowerCase().indexOf(emailSearch.toLowerCase(), 0) !== -1 : false)
        : true
    );
    if (props.filterStatus !== "") {
      users = users.filter((value) => value.status === props.filterStatus);
    }
    console.log('users', users)
    return users;
  };

  const getIds = (ids) => {
    setIds(ids);
  };

  const actions = (value) => {
    OPTIONS[value].action();
  };

  const openModal = (data) => {
    if (ids.length > 0) {
      setShowModal(data);
    }
  };

  const checkStatus = () => {
    return ids.every((item) => item.status === "pending");
  };

  const deleteUserFromIcon = (item) => {
    if (item.status === "pending") {
      setIds([item.user_folder_id]);
      setShowModal({
        text: "Do you want to remove this user?",
        action: () => deleteUser([item.user_folder_id]),
      });
    } else {
      setShowModal({
        text: "You can only remove users with status: Pending",
        action: () => {
          setShowModal({});
          setIds([]);
        },
        type: "error",
      });
    }
  };

  const deleteAdminFromIcon = (item) => {
    setIds([item.id]);
    setShowModal({
      text: "Do you want to remove this administrator?",
      action: () => deleteAdmin([item.id]),
    });
  };

  const invitedUserFromIcon = (item) => {
    setIds([item]);
    setIds([{ userFolderId: item.user_folder_id }]);
    // setShowModal({
    //   text: "Se va a enviar la invitación al usuario",
    //   action: () => invitedUser([item.user_folder_id]),
    // });
    setShowInvitationModal(true);
  };

  const invitedAdminFromIcon = (item) => {
    setIds([item.id]);
    setShowModal({
      text: "The invitation will be sent to the administrator",
      action: () => invitedAdmin([item.id]),
    });
  };

  const deleteAdmin = (ids) => {
    props.fetchAdminDestroy(
      props.data.organization_id,
      { admin: { user_ids: ids } },
      () => {
        {
          setShowModal({});
          setIds([]);
        }
      }
    );
  };

  const deleteUser = (ids) => {
    props.fetchFolderDestroyUser(
      props.data.organization_id,
      props.data.id,
      { folder: { user_folder_ids: ids } },
      () => {
        {
          setShowModal({});
          setIds([]);
        }
      }
    );
  };

  const getOnlyIds = () => {
    return ids.map((item) =>
      props.type === "administrators" ? item.id : item.userFolderId
    );
  };

  const invitedUser = (ids) => {
    props.fetchFolderSendInvitationUser(
      props.data.organization_id,
      props.data.id,
      { folder: { user_folder_ids: ids } },
      () => {
        setShowModal({});
        setIds([]);
      }
    );
  };

  const invitedAdmin = (ids) => {
    props.fetchAdminSendEmail(
      props.data.organization_id,
      { admin: { admin_ids: ids } },
      (data) => {
        setShowModal({
          text: "Emails have been sent successfully!",
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "success",
        });
      },
      (data) => {
        setShowModal({
          text: "There has been an error, the emails could not be sent",
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "error",
        });
      }
    );
  };

  const showUserFromIcon = (item, edit) => {
    setIsEditUser(edit ? true : false);
    setUserId({ userFolderId: item.user_folder_id, userId: item.id });
    if (item.status !== "pending") {
      props.fetchUser(
        props.data.organization_id,
        item.user_folder_id,
        item.id,
        (data) => {
          setShowUserModal(data);
        }
      );
    } else {
      setShowEditUser({
        data: item,
        action: (data, userFolderId) => {
          editEmailUser(data, userFolderId);
        },
      });
    }
  };

  const editEmailUser = (data, userFolderId) => {
    props.fetchFolderUpdateUsersFolder(
      props.data.organization_id,
      props.data.id,
      userFolderId,
      data,
      () => {
        setShowEditUser({}), props.getUsers();
      }
    );
  };

  const showAdminFromIcon = (item, edit) => {
    setIsEditUser(edit ? true : false);
    setUserId(item.id);
    props.fetchShowAdmin(item.organization_id, item.id, (data) => {
      setShowUserModal(data);
    });
  };

  const saveUser = (formUser) => {
    if (Object.keys(formUser).length > 0) {
      props.fetchUserUpdate(
        props.data.organization_id,
        userId.userFolderId,
        userId.userId,
        { profile: formUser },
        () => {
          setShowUserModal({}), setUserId("");
        }
      );
    } else {
      setShowUserModal({});
      setUserId("");
    }
  };

  const saveAdmin = (formUser, id) => {
    if (Object.keys(formUser).length > 0) {
      props.fetchAdminUpdate(
        props.data.organization_id,
        id,
        { profile: formUser },
        () => {
          setShowUserModal({}), setUserId("");
        }
      );
    } else {
      setShowUserModal({});
      setUserId("");
    }
  };

  const showReport = (url) => {
    window.open(url, "_blank");
  };

  const optionEmails = () => {
    return props.data.emails.map((email, index) => {
      return { value: email.id, text: email.name };
    });
  };

  const sendEmail = (idEmail) => {
    props.fetchEmailsSend(
      props.data.organization_id,
      props.data.id,
      idEmail,
      { custom_mail: { user_folder_ids: getOnlyIds() } },
      (data) => {
        setShowInvitationModal(false);
        setShowModal({
          text: data,
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "success",
        });
      },
      (data) => {
        setShowInvitationModal(false);
        setShowModal({
          text: data,
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "error",
        });
      }
    );
  };

  const updateChart = (status) => {
    props.getDataChart(status);
  };
  return (
    <>
      {
        props.type !== "ranked_users" && 
        <TableFilter
          optionsActions={OPTIONS}
          getAction={(value) => actions(value)}
          filters={
            props.data
              ? props.type === "administrators"
                ? []
                : props.data.scopes
              : []
          }
          getFilters={(value) => {
            updateChart(value);
            props.setFilterStatus(value);
          }}
          search={(value) => setEmailSearch(value)}
          value={emailSearch}
          titleTableFilter={props.titleTableFilter}
          type={props.type}
          tableFilterClassName={"c-table-filter--four-columns"}
          deleteFilter={() => {
            updateChart("");
            props.setFilterStatus("");
          }}
          results={userFiltered().length}
          filterStatus={props.filterStatus}
          openExportModal={() => props.openExportModal()}
        />
      }
      <TableReport
        allIds={allIds}
        getIds={(ids) => getIds(ids)}
        data={userFiltered()}
        ids={ids}
        canManage={props.canManage === undefined ? true : props.canManage}
        filterAction={props.filterStatus !== ""}
        type={props.type}
        openDeleteModal={(value) =>
          props.type === "administrators"
            ? deleteAdminFromIcon(value)
            : deleteUserFromIcon(value)
        }
        openInvitedModal={(item) =>
          props.type === "administrators"
            ? invitedAdminFromIcon(item)
            : invitedUserFromIcon(item)
        }
        openShowUserModal={(item) =>
          props.type === "administrators"
            ? showAdminFromIcon(item, false)
            : showUserFromIcon(item, false)
        }
        openEditUserModal={(item) =>
          props.type === "administrators"
            ? showAdminFromIcon(item, true)
            : showUserFromIcon(item, true)
        }
        showReport={(url) => showReport(url)}
      />

      {Object.keys(showModal).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          closeModalInfoAction={() => {
            setShowModal({});
            setIds([]);
          }}
          noButtonCancel={["error", "success"].includes(showModal.type)}
          text={showModal.text}
          action={() => showModal.action()}
        />
      )}

      {Object.keys(showUserModal).length > 0 && (
        <ModalShowUser
          showModal={true}
          closeModal={() => setShowUserModal({})}
          title={
            props.type === "administrators"
              ? "Administrator information"
              : "User information"
          }
          data={showUserModal}
          saveUser={(formUser, id) =>
            props.type === "administrators"
              ? saveAdmin(formUser, id)
              : saveUser(formUser)
          }
          optionsAdmin={props.data.rol}
          edit={isEditUser}
          type={props.type}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}

      {Object.keys(showEditUser).length > 0 && (
        <ModalInput
          title={isEditUser ? "Edit email" : "Email"}
          data={showEditUser.data}
          showModal={true}
          closeModal={() => setShowEditUser(false)}
          action={(data, id) => showEditUser.action(data, id)}
          type={"user"}
          edit={isEditUser}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}

      {showInvitationModal && (
        <ModalInput
          title={"Send email"}
          label={"Select the email you want to send"}
          options={optionEmails()}
          showModal={true}
          closeModal={() => {
            setShowInvitationModal(false), setIds([]);
          }}
          action={(option) => sendEmail(option)}
          type={"select"}
          canManage={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, props) => {
  return {
    folder: state.admin.folder,
  };
};

const mapDispatchToProps = {
  fetchFolderDestroyUser,
  fetchFolderSendInvitationUser,
  fetchFolderUpdateUsersFolder,
  fetchUser,
  fetchUserUpdate,
  fetchShowAdmin,
  fetchAdminUpdate,
  fetchAdminDestroy,
  fetchEmailsSend,
  fetchAdminSendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderTableTools);
