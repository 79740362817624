import React from "react";
import classNames from "classnames";


const Export = (props) => {
  return (
    <svg
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : "20px"}
      viewBox={"0 0 20 20"}
      className={"c-export"}
    >
       <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    >
      <path stroke={props.secondaryColor ? props.secondaryColor : "#A9C44B"} d="M15 1h4v4M9 11 19 1" />
      <path
        className="icon-first-color-stroke"
        stroke={props.primaryColor ? props.primaryColor : "#FDFDFD"}
        d="M17 11.89V18a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6.11"
      />
    </g>
</svg>
  );
};

export default Export;
