// Dependencies
import React from "react";
import classNames from "classnames";

const ArrowDouble = (props) => {
  const arrowDoubleClasses = () => {
    let arowDoubleClass = classNames(
      "c-arrow-double",
      props.className && props.className
    );
    return arowDoubleClass;
  };
  return (
    <svg
      width="24px"
      height="22px"
      viewBox="0 0 24 22"
      className={arrowDoubleClasses()}
    >
        <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        stroke="#FDFDFD"
        d="M20 16.25A9.668 9.668 0 0 1 11.645 21C6.318 21 2 16.747 2 11.5"
      />
      <path stroke="#FDFDFD" d="m22.439 20.114-1.83-5.353-5.353 1.83" />
      <path
        stroke="#A9C44B"
        d="M3.2 5.75A9.669 9.669 0 0 1 11.555 1c5.327 0 9.645 4.253 9.645 9.5"
      />
      <path stroke="#A9C44B" d="m.761 1.886 1.83 5.353 5.353-1.83" />
    </g>
    </svg>
  );
};

export default ArrowDouble;
