// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IllustrationFolderDouble = (props) => {
  return (
    <svg 
      className="c-illustration--folder" 
      width={props.width ? props.width : "24px"} 
      height={props.height ? props.height : "21px"} 
      viewBox="0 0 24 21"
    >
      <g fill="none" fillRule="evenodd">
      <path
        fill="#A9C44B"
        d="M8.599.853h4.433c.613 0 1.2.243 1.634.677l.187.186a2.31 2.31 0 0 0 1.633.677h2.894c.425 0 .788.15 1.089.451.3.3.45.664.45 1.09v8.47c0 .425-.15.788-.45 1.089-.301.3-.664.45-1.09.45H8.6c-.425 0-.788-.15-1.09-.45-.3-.301-.45-.664-.45-1.09V2.394c0-.425.15-.788.45-1.089.302-.3.665-.451 1.09-.451Z"
      />
      <path
        className="icon-first-color-fill"
        fill={props.color ? props.color : "#fff"}
        d="M10.139 12.404v-6.93c0-.426.15-.789.451-1.09.3-.3.664-.45 1.09-.45h10.78c.425 0 .788.15 1.089.45.3.301.45.664.45 1.09v6.93c0 .425-.15.788-.45 1.089-.3.3-.664.45-1.09.45H8.6c.425 0 .788-.15 1.089-.45.3-.301.451-.664.451-1.09Z"
      />
      <path
        fill="#A9C44B"
        d="M1.818 5.188h5.234c.723 0 1.417.287 1.929.798l.22.22a2.727 2.727 0 0 0 1.929.8h3.415c.503 0 .931.177 1.286.532.355.355.533.784.533 1.286v10c0 .502-.178.93-.533 1.285a1.752 1.752 0 0 1-1.286.533H1.818c-.502 0-.93-.177-1.285-.532A1.752 1.752 0 0 1 0 18.823V7.006c0-.502.178-.93.533-1.286a1.752 1.752 0 0 1 1.285-.532Z"
      />
      <path
      className="icon-first-color-fill"
        fill={props.color ? props.color : "#fff"}
        d="M3.636 18.824v-8.182c0-.502.178-.93.533-1.286a1.752 1.752 0 0 1 1.285-.532h12.728c.502 0 .93.177 1.285.532.355.355.533.784.533 1.286v8.182c0 .502-.178.93-.533 1.285a1.752 1.752 0 0 1-1.285.533H1.818c.502 0 .93-.177 1.286-.532.355-.355.532-.784.532-1.286Z"
      />
    </g>
    </svg>
  );
};

export default IllustrationFolderDouble;
