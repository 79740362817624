import React, { useEffect, useState, useRef } from "react";
import classNames from "classnames";

//Components
import Chevron from "./Chevron";
import Tick from "./Tick";

//custom hook
import useOutsideClick from "../hooks/useClickOutside";

const Multiselect = (props) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [multipleValues, setMultipleValues] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [value, setValue] = useState("");

  const refContainer = useRef();
  


  useEffect(() => {
    if (props.defaultValue) {
      setDefaultValue(props.defaultValue);
    }
  }, []);

  useOutsideClick(refContainer, () => {
    setOpenSelect(false);
  });

  const selectOption = (e, option) => {
    if (props.multiple) {
      let allValues = multipleValues;
      if (allValues.length > 0) {
        if (allValues.find((item) => item === option.value) === undefined) {
          allValues = [...multipleValues, option.value];
        } else {
          allValues = multipleValues.filter((item) => item !== option.value);
        }
      } else {
        allValues = [...multipleValues, option.value];
      }

      setMultipleValues(allValues);
      props.getOption(allValues);
    } else if (props.noSelect) {
      setDefaultValue(props.defaultValue);
      setOpenSelect(false);
    } else {
      setOpenSelect(false);
      setValue(option.value);
      setDefaultValue(option.text);
      props.getOption(option.value);
    }
  };

  let multiselectWrapperClasses = classNames(
    "c-multiselect__wrapper",
    props.multiselectWrapperClassName && props.multiselectWrapperClassName
  );

  let multiselectClasses = classNames(
    "c-multiselect",
    props.multiselectClassName && props.multiselectClassName
  );

  return (
    <div
      className={
        openSelect
          ? `${multiselectWrapperClasses} c-multiselect__wrapper--open`
          : multiselectWrapperClasses
      }
    >
      {props.label && (
        <label className="c-multiselect__label">
          {
            props.isRequired &&
              <span class="o-color--red">*{" "}</span>
          }
        {props.label}
        </label>
      )}

      <div
        ref={refContainer}
        className={
          openSelect
            ? `${multiselectClasses} c-multiselect--open`
            : multiselectClasses
        }
        onClick={() => setOpenSelect(!openSelect)}
      >
        <div className="c-multiselect__value">
          <p>{defaultValue}</p>
          {!props.noSelect && (
            <Chevron
              orientation={openSelect ? "up" : "down"}
              dark={props.dark}
            />
          )}
        </div>

        <div className="c-multiselect__option-wrapper">
          <div className="c-multiselect__option">
            {props.options &&
              props.options.map((option, index) => {
                return (
                  <div
                    id={option.value}
                    ref={(el) => props.refItem ? props.refItem.current[index] = el : props.refItem}
                    className="c-multiselect__option-item"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      selectOption(e, option);
                    }}
                  >
                    <p>{option.text}</p>

                    {props.multiple &&
                      multipleValues.includes(option.value) && (
                        <Tick 
                          className="c-tick--bare" 
                          width="17px" 
                          height="12"
                          color={"#A9C44B"}  
                        />
                      )}
                  </div>
                );
              })}
          </div>
        </div>
        {props.error && (
          <p className="c-multiselect__error">Campo obligatorio</p>
        )}
      </div>
    </div>
  );
};

export default Multiselect;
