// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IllustrationWebcam = (props) => {

  let svgClass = classNames(
    "c-illustration--webcam",
    props.className,
  );

  return (
    <svg
      className={svgClass}
      style={props.style ? props.style : ''}
      width={props.width ? props.width : 47}
      height={props.height ? props.height : 63}
      viewBox="0 0 47 63"
    >
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustration" transform="translate(-662.000000, -1282.000000)">
          <g id="webcam" transform="translate(665.000000, 1284.000000)">
            <path d="M25.0425,37.9756 C24.7605,37.9706 24.4505,38.0456 24.2005,37.9546 C18.7355,35.9866 13.0365,34.4826 7.8795,31.8936 C1.4555,28.6676 -1.4895,19.0716 0.7365,11.2286 C2.7515,4.1286 8.6255,-0.2894 16.4275,0.0146 C20.5065,0.1736 24.7615,0.5676 28.5685,1.9076 C36.7435,4.7846 41.9875,12.8836 42.0005201,21.3826 C42.0145,31.0156 34.7305,38.1416 25.0425,37.9756 Z" id="Stroke-4" stroke="#121921" strokeWidth="4"></path>
            <path d="M8.504,30.3773435 C13.415,32.7603128 16.4512227,36.2868109 25.6782227,36.4394531 C34.9052227,36.5920954 41.014,29.5684367 41.0002706,20.6947685 C40.988,12.8672344 35.995,5.40720846 28.208,2.75687213 C24.582,1.52309487 20.53,1.15947572 16.645,1.01363917 C16.404,1.00488897 16.164,1.001 15.928,1.001 C8.854,1.001 3.558,5.0163665 1.701,11.3427563 C-0.418,18.5655548 2.386,27.4061667 8.504,30.3773435 Z" id="Clip-13" fill="#FFFFFF"></path>
            <path d="M20.024,39.468 C14.175,46.127 9.454,48.305 5.274,55.683 L5.274,55.683 C5.046,56.086 5.024,56.624 4.968,57.104 L4.968,57.104 C4.662,59.758 5.829,61.081 8.516,60.805 L8.516,60.805 C11.747,60.474 15.64,60.607 19.548,60.741 L19.548,60.741 C23.679,60.882 27.83,61.025 31.237,60.615 L31.237,60.615 C33.268,60.371 35.255,59.778 36.554,59.496 L36.554,59.496 C39.766,57.384 38.601,55.599 37.353,53.809 L37.353,53.809 C33.327,48.036 28.922,45.493 26.427,40.432 L26.427,40.432 C25.6,38.755 24.348,37.887 23.032,37.887 L23.032,37.887 C22.013,37.887 20.956,38.406 20.024,39.468 L20.024,39.468 Z" id="Clip-7" fill="#000000"></path>
            <path d="M13,18.50125 C13,22.6425 16.3575,26 20.5,26 L20.5,26 C24.64125,26 28,22.6425 28,18.50125 L28,18.50125 C28,14.3575 24.64125,11 20.5,11 L20.5,11 C16.3575,11 13,14.3575 13,18.50125 L13,18.50125 Z" id="Clip-10" fill="#000000"></path>
            <path d="M20.5,22 C22.4331667,22 24,20.4325833 24,18.5 C24,16.5674167 22.4331667,15 20.5,15 C18.5668333,15 17,16.5674167 17,18.5 C17,20.4325833 18.5668333,22 20.5,22 Z" id="Stroke-9" fill="#FFFFFF"></path>
          </g>
        </g>
      </g>
    </svg>
);
};

IllustrationWebcam.propTypes = {
  className: PropTypes.string,
  width:  PropTypes.number,
  height:  PropTypes.number,
};

export default IllustrationWebcam;
