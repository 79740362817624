// Dependencies
import React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

// Components
import IllustrationProcedure from "../../../components/IllustrationProcedure";
import IllustrationOval from "../../../components/IllustrationOval";

const ProcedureItem = (props) => {
  let classNameProcedure = classNames(
    "c-procedure",
    props.alignLeft && "c-procedure--align-left"
  );

  const hoverMotionOval = {
    hidden: {
      opacity: 0,
      scale: 0,
      y: 24,
      transition: {
        delay: 0.2,
        type: "spring",
      },
    },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        delay: 0.1,
        type: "spring",
      },
    },
  };

  const hoverMotionProcedure = {
    hidden: {
      top: 0,
      transition: {
        delay: 0.22,
        type: "tween",
      },
    },

    show: {
      top: "16px",
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  const goTo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.goTo();
  };

  return (
    <motion.div
      className={classNameProcedure}
      initial="hidden"
      animate="hidden"
      onClick={(e) => goTo(e)}
      onHoverStart={() => props.selectProcedure(props.index)}
      onHoverEnd={() => props.selectProcedure(-1)}
    >
      <div className="c-procedure__wrapper">
        <motion.div
          className="c-procedure__wrapper-item"
          animate={props.selected}
          variants={hoverMotionProcedure}
        >
          <IllustrationProcedure width="161px" height="140px" firstColor={"#3C5896"}/>
          <h5 className="c-procedure__title">
            {props.name && props.name}
          </h5>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ProcedureItem;
