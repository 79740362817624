// Dependencies
import React from "react";
import PropTypes from "prop-types";

const TableData = (props) =>  {

  return (
    <table className="c-table-data c-table-data--striped"> 
      <tbody className="c-table-data__body">
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Web:</td>
          <td className="c-table-data__cell">www.gsgmetrics.com</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Titular:</td>
          <td className="c-table-data__cell">GSG METRICS S.L</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Domicilio:</td>
          <td className="c-table-data__cell">Avda. de Europa, 24. Edificio A, 1ºD, Parque Empresarial La Moraleja, 28108 Alcobendas, Madrid
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">C.I.F:</td>
          <td className="c-table-data__cell">B72909815</td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Teléfono:</td>
          <td className="c-table-data__cell"></td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">Correo electrónico:</td>
          <td className="c-table-data__cell">info@gsgmetrics.com</td>
        </tr>
      </tbody>
    </table>
  );

}

export default TableData;
