// Dependencies
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Components
import Star from "./Star";

const RatingStar = (props) => {
  let ratingStarWrapper = useRef(null);

  const handleStars = (event, index, item) => {
    
    const allStars = [...ratingStarWrapper.current.children];
    
    allStars.map((star, index_star) => {
      parseInt(index_star) <= parseInt(index)
        ? star.classList.add("selected")
        : star.classList.remove("selected");
    });

    props.onClick(event, index, item);
  };

  const renderStars = (objects) => {
    let stars = [];
    let classes = (condition) => classNames(
      "c-rating-star",
      props.small && "c-rating-star--small",
      (condition ? "selected" : '')
    );
    let index_currentValue = -1;
    Object.keys(objects).map((key, index) => {
      if(objects[key].value === props.currentValue.value){
        index_currentValue = index
        return;
      }
    })
    
    Object.entries(objects).map((obj, index) => {
      stars.push(
        <div
          key={`star-${obj[0]}`}
          className={classes((props.currentValue && (index <= index_currentValue)))}
          data-value={obj[1].value}
          onClick={(event) => handleStars(event, obj[0], obj[1])}
        >
          <Star className="c-rating-star__svg" />
        </div>
      );
    })

    return stars;
  };

  return (
    <div ref={ratingStarWrapper} className="c-rating-star__wrapper">
      {renderStars(props.values)}
    </div>
  );
};

RatingStar.propTypes = {
  currentValue: PropTypes.object,
  values: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RatingStar;
