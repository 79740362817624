import React, { useState } from "react";

// Components
import SideBar from "./SideBar";


const Aside = (props) => {
  const [asideClasses, setAsideClasses] = useState("c-aside");


  return (
    <aside className={asideClasses}>
      <SideBar
          history={props.history}
          organizationId={props.organization.data.id}
          organizations={props.organizations}
          organization={props.organization}
          showSelect={props.showSelect}
          createOrganization={() => props.createOrganization()}
          selectOrganization={() => props.selectOrganization()}
          selectAction={(id) => props.selectAction(id)}
          showModalSession={() => props.showModalSession()}
        />
    </aside>
  );
};

export default Aside;
