import React from "react";

const Filter = (props) => {
  return (
    <svg
      width={props.width ? props.width : "399px"}
      height={props.height ? props.height : "594px"}
      viewBox={"0 0 399 594"}
      className={"c-file"}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-2" transform="translate(10.000000, 9.029684)">
          <path
            d="M268.129091,1.66749802 C264.859246,-3.85143046 258.476957,4.3291648 248.982225,26.2092838 L224.025794,66.6308812 C208.130509,102.387646 189.731562,135.134183 168.828953,164.870493 C137.475039,209.474957 96.6222451,276.617848 93.3836958,295.377018 C90.1451466,314.136189 97.8029726,325.325551 105.392323,305.675532 C112.981673,286.025513 132.41232,270.294543 147.117985,245.023028 C156.921762,228.175352 171.551703,204.13057 191.007808,172.888682 C209.629455,136.578967 220.922626,115.376616 224.887321,109.281631 C228.852015,103.186645 241.160975,82.9564708 261.8142,48.5911075 C269.293973,22.8276297 271.398936,7.1864265 268.129091,1.66749802 Z"
            id="Path-8"
            stroke="#000000"
            strokeWidth="13.5"
            fill="#121A22"
            transform="translate(181.121036, 158.000000) rotate(59.000000) translate(-181.121036, -158.000000) "
          ></path>
          <path
            d="M275.508056,129.637814 C272.23821,124.118885 265.855922,132.299481 256.36119,154.1796 L231.404759,194.601197 C215.509474,230.357962 197.110527,263.104499 176.207918,292.840808 C144.854004,337.445273 104.00121,404.588164 100.76266,423.347334 C97.524111,442.106504 105.181937,453.295867 112.771287,433.645848 C120.360637,413.995829 139.791284,398.264859 154.49695,372.993344 C164.300727,356.145667 178.930668,332.100885 198.386772,300.858998 C217.00842,264.549282 228.301591,243.346932 232.266285,237.251946 C236.23098,231.156961 248.53994,210.926787 269.193165,176.561423 C276.672937,150.797945 278.777901,135.156742 275.508056,129.637814 Z"
            id="Path-8"
            stroke="#000000"
            strokeWidth="13.5"
            fill="#121A22"
            transform="translate(188.500000, 285.970316) rotate(59.000000) translate(-188.500000, -285.970316) "
          ></path>
          <path
            d="M285.508056,260.637814 C282.23821,255.118885 275.855922,263.299481 266.36119,285.1796 L241.404759,325.601197 C225.509474,361.357962 207.110527,394.104499 186.207918,423.840808 C154.854004,468.445273 114.00121,535.588164 110.76266,554.347334 C107.524111,573.106504 115.181937,584.295867 122.771287,564.645848 C130.360637,544.995829 149.791284,529.264859 164.49695,503.993344 C174.300727,487.145667 188.930668,463.100885 208.386772,431.858998 C227.00842,395.549282 238.301591,374.346932 242.266285,368.251946 C246.23098,362.156961 258.53994,341.926787 279.193165,307.561423 C286.672937,281.797945 288.777901,266.156742 285.508056,260.637814 Z"
            id="Path-8"
            stroke="#000000"
            strokeWidth="13.5"
            fill="#121A22"
            transform="translate(198.500000, 416.970316) rotate(59.000000) translate(-198.500000, -416.970316) "
          ></path>
          <g id="Group" transform="translate(28.000000, 112.970316)">
            <path
              d="M17.900177,15.4132361 C17.900177,15.4132361 40.9699469,15.4095351 87.1094868,15.4021331 L87.099823,75.5867639 L17.8905132,75.5978669 C17.8969557,35.4747797 17.900177,15.4132361 17.900177,15.4132361 Z"
              id="Path-2"
              fill="#FFFFFF"
              transform="translate(52.500000, 45.500000) rotate(100.000000) translate(-52.500000, -45.500000) "
            ></path>
            <path
              d="M78.5010491,57.1232335 C78.7961001,54.0675187 79.0911511,51.0159222 79.3902439,47.9560892 C68.1742642,41.0869084 57.3786311,33.1634648 45.3947104,28.258672 C43.0221771,27.2908917 31.9678691,37.9900115 32.0121951,43.2448525 C32.1254991,57.7986205 41.8298477,67.9747272 56.2105525,67.9295775 C63.6514961,67.9047175 71.0722308,60.9490542 78.5010491,57.1232335 M38.0552552,0 C50.4008973,2.18493935 57.7316267,3.13174641 64.8888715,4.81495894 C95.0993841,11.9160118 108.901489,29.2215407 104.047957,53.5350603 C99.1661839,77.982104 74.4103472,94.1142395 47.3185213,90.4929048 C7.13063189,85.1276649 -12.9290194,46.7496098 9.06720321,13.1096361 C14.5702934,4.68952724 30.5470068,3.15602351 38.0552552,0"
              id="Fill-88"
              fill="#000000"
            ></path>
          </g>
          <g id="Group" transform="translate(234.000000, 240.970316)">
            <path
              d="M17.900177,15.4132361 C17.900177,15.4132361 40.9699469,15.4095351 87.1094868,15.4021331 L87.099823,75.5867639 L17.8905132,75.5978669 C17.8969557,35.4747797 17.900177,15.4132361 17.900177,15.4132361 Z"
              id="Path-2"
              fill="#FFFFFF"
              transform="translate(52.500000, 45.500000) rotate(100.000000) translate(-52.500000, -45.500000) "
            ></path>
            <path
              d="M78.5010491,57.1232335 C78.7961001,54.0675187 79.0911511,51.0159222 79.3902439,47.9560892 C68.1742642,41.0869084 57.3786311,33.1634648 45.3947104,28.258672 C43.0221771,27.2908917 31.9678691,37.9900115 32.0121951,43.2448525 C32.1254991,57.7986205 41.8298477,67.9747272 56.2105525,67.9295775 C63.6514961,67.9047175 71.0722308,60.9490542 78.5010491,57.1232335 M38.0552552,0 C50.4008973,2.18493935 57.7316267,3.13174641 64.8888715,4.81495894 C95.0993841,11.9160118 108.901489,29.2215407 104.047957,53.5350603 C99.1661839,77.982104 74.4103472,94.1142395 47.3185213,90.4929048 C7.13063189,85.1276649 -12.9290194,46.7496098 9.06720321,13.1096361 C14.5702934,4.68952724 30.5470068,3.15602351 38.0552552,0"
              id="Fill-88"
              fill="#000000"
            ></path>
          </g>
          <g id="Group" transform="translate(111.000000, 371.970316)">
            <path
              d="M17.900177,15.4132361 C17.900177,15.4132361 40.9699469,15.4095351 87.1094868,15.4021331 L87.099823,75.5867639 L17.8905132,75.5978669 C17.8969557,35.4747797 17.900177,15.4132361 17.900177,15.4132361 Z"
              id="Path-2"
              fill="#FFFFFF"
              transform="translate(52.500000, 45.500000) rotate(100.000000) translate(-52.500000, -45.500000) "
            ></path>
            <path
              d="M78.5010491,57.1232335 C78.7961001,54.0675187 79.0911511,51.0159222 79.3902439,47.9560892 C68.1742642,41.0869084 57.3786311,33.1634648 45.3947104,28.258672 C43.0221771,27.2908917 31.9678691,37.9900115 32.0121951,43.2448525 C32.1254991,57.7986205 41.8298477,67.9747272 56.2105525,67.9295775 C63.6514961,67.9047175 71.0722308,60.9490542 78.5010491,57.1232335 M38.0552552,0 C50.4008973,2.18493935 57.7316267,3.13174641 64.8888715,4.81495894 C95.0993841,11.9160118 108.901489,29.2215407 104.047957,53.5350603 C99.1661839,77.982104 74.4103472,94.1142395 47.3185213,90.4929048 C7.13063189,85.1276649 -12.9290194,46.7496098 9.06720321,13.1096361 C14.5702934,4.68952724 30.5470068,3.15602351 38.0552552,0"
              id="Fill-88"
              fill="#000000"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Filter;
