import React, { useState, useEffect } from "react";
import classNames from "classnames";

// Components
import Select from "./Select";
import Input from "./Input";
import Filter from "./Filter";
import Close from "./Close";
import Export from "./Export";

const TableFilter = (props) => {
  const [resetFilter, setResetFilter] = useState(0);
  let tableFilterClasses = classNames(
    "c-table-filter",
    props.type !== "administrators" && props.tableFilterClassName
  );

  const deleteFilter = () => {
    setResetFilter(resetFilter + 1);
    props.deleteFilter();
  };

  return (
    <div className={tableFilterClasses}>
      <div className="c-table-filter__filters">
        <div className="c-table-filter__filters-item">
          <p className="c-table-filter__filters-label">Actions:</p>
          <Select
            className="c-select"
            errorLabel=""
            withoutInput={true}
            defaultValue={""}
            options={props.optionsActions}
            optionClick={(text, value) => props.getAction(value)}
          />
        </div>
        {props.filters.length > 0 && (
          <div className="c-table-filter__filters-item">
            <p className="c-table-filter__filters-label">Filter:</p>
            <Select
              key={`${resetFilter}-filter`}
              className="c-select"
              errorLabel=""
              withoutInput={true}
              defaultValue={""}
              options={props.filters}
              optionClick={(text, value) => {
                props.getFilters(value);
              }}
            />
          </div>
        )}
        <div className="c-table-filter__filters-item">
          <form>
            <Input
              className="c-input"
              wrapperClassName="c-input__wrapper--no-size"
              name="search"
              label="Search emails, names and surnames:"
              search={true}
              errorLabel="Este campo no puede estar vacío"
              minCharacters={5}
              type="text"
              value={props.value}
              onChange={(e) => {
                props.search(e.currentTarget.value);
              }}
            />
          </form>
        </div>
      </div>
      {props.filterStatus !== "" && props.type !== "administrators" && (
        <React.Fragment>
          <div className="c-filter-box">
            <div className="c-filter-box__wrapper c-filter-box__wrapper--min-width c-filter-box__wrapper--align-start">
              <div className="c-filter-box__wrapper c-filter-box__wrapper--align-center">
                <Filter width="40px" height="40px" />
                <p className="c-filter-box__text">Selected filters:</p>
              </div>
            </div>
            <div className="c-filter-box__wrapper">
              <div
                className="c-filter-box__box"
                onClick={() => {
                  deleteFilter();
                }}
              >
                <p className="c-filter-box__box-text">{props.filterStatus}</p>
                <Close width="10px" height="10px" />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="o-margin--bottom-size-l">
        <p>
          Total users: <span className="o-font--semibold">{props.results}</span>
        </p>
      </div>
    </div>
  );
};

export default TableFilter;
