import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const IllustrationMessageBox = (props) => {
  let illustrationClasses = classNames(
    "c-illustration--message-box",
    props.className
  );

  return (
    <svg
      width="460px"
      height="129px"
      viewBox="0 0 460 129"
      preserveAspectRatio="none"
      className={illustrationClasses}
    >
      <g
        id="Styleguide"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Illustration" transform="translate(-85.000000, -976.000000)">
          <g id="Group-8" transform="translate(520.000000, 837.000000)"></g>
          <path
            d="M360.760944,820.469566 C369.062944,822.366833 371.513944,829.853053 372.175944,851.967986 C373.625944,900.455199 374.630944,949.058792 376.475944,997.432332 C378.970944,1062.83797 374.849944,1123.57412 377.538944,1188.92563 C378.086944,1202.22273 377.706944,1215.7959 377.774944,1229.24458 C377.787944,1231.71292 377.646944,1234.50063 378.008944,1236.59547 C380.379944,1250.34998 379.899944,1256.32596 374.475944,1260.45881 C368.767944,1264.80818 362.767944,1268.02352 356.860944,1268.49716 C330.238944,1270.62719 303.607944,1271.84512 277.079944,1262.80536 C272.348944,1261.19228 267.552944,1260.20981 262.790944,1260.29101 C259.298944,1260.35326 258.482944,1254.38269 258.035944,1247.71654 C255.985944,1217.17081 253.644944,1186.69816 252.231944,1155.92238 C247.571944,1054.47138 255.964944,956.997 256.581944,855.126489 C256.693944,836.73572 259.914944,825.395423 267.148944,821.189499 C272.805944,817.898377 278.675944,816.518055 284.472944,815.93886 C292.952944,815.091721 301.451944,815.722339 309.942944,815.722339 C309.967944,813.976637 309.993944,812.233642 310.018944,810.490646 C326.934944,813.75741 343.863944,816.604663 360.760944,820.469566 Z"
            id="Path"
            fill={props.color ? props.color : "#FF6262"}
            transform="translate(315.096659, 1040.254690) rotate(-90.000000) translate(-315.096659, -1040.254690) "
          ></path>
        </g>
      </g>
    </svg>
  );
};

IllustrationMessageBox.propTypes = {
  color: PropTypes.string
}

export default IllustrationMessageBox;

