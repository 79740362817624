import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationRescuePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width="425px"
        height="414px"
        viewBox="0 0 425 414"
      >
        <g fill="none" fillRule="nonzero">
          <path
            fill="#F2F2F2"
            d="M271.072 92.804h-22.614V285.53a23.013 23.013 0 0 1-6.746 16.282 23.031 23.031 0 0 1-16.287 6.744H52.537v.22a23.023 23.023 0 0 0 14.22 21.274 23.042 23.042 0 0 0 8.814 1.752h195.501a23.04 23.04 0 0 0 16.287-6.744 23.024 23.024 0 0 0 6.746-16.282V115.83a23.024 23.024 0 0 0-6.746-16.282 23.037 23.037 0 0 0-16.287-6.744ZM378.269 399.077a1.96 1.96 0 0 0 1.893-1.198 1.954 1.954 0 0 0-.477-2.189l-.134-.532.054-.128a5.251 5.251 0 0 1 4.852-3.201 5.25 5.25 0 0 1 4.829 3.237c1.584 3.813 3.6 7.632 4.096 11.663a15.504 15.504 0 0 1-.272 5.338 62.225 62.225 0 0 0 5.663-25.847 60.01 60.01 0 0 0-.373-6.701 62.24 62.24 0 0 0-.857-5.433 62.935 62.935 0 0 0-12.488-26.681 16.757 16.757 0 0 1-6.97-7.229 12.789 12.789 0 0 1-1.163-3.493c.339.045 1.28-5.121 1.024-5.438.473-.718 1.319-1.074 1.835-1.774 2.569-3.482 6.108-2.874 7.955 1.857 3.947 1.991 3.985 5.293 1.563 8.469-1.54 2.021-1.752 4.755-3.104 6.918.139.178.284.351.423.528a63.343 63.343 0 0 1 6.615 10.477 26.348 26.348 0 0 1 1.573-12.234c1.506-3.631 4.328-6.689 6.813-9.828a5.41 5.41 0 0 1 9.633 2.655l.015.139c-.369.208-.731.429-1.084.663a2.647 2.647 0 0 0-.34 4.137c.39.365.881.604 1.409.685l.054.009a26.358 26.358 0 0 1-.696 3.943c3.189 12.325-3.695 16.814-13.523 17.016-.217.111-.428.222-.645.328a64.64 64.64 0 0 1 3.482 16.383 61.33 61.33 0 0 1-.044 9.904l.016-.117a16.208 16.208 0 0 1 5.535-9.359c4.26-3.498 10.278-4.786 14.873-7.598a3.05 3.05 0 0 1 3.275-.008 3.038 3.038 0 0 1 1.385 2.968l-.018.122c-.685.279-1.352.601-1.997.962-.37.209-.731.43-1.084.663a2.65 2.65 0 0 0 1.068 4.823l.055.009.111.016a26.376 26.376 0 0 1-4.857 7.602c-1.993 10.76-10.555 11.781-19.714 8.647h-.006a64.662 64.662 0 0 1-4.344 12.679h-15.52c-.056-.172-.106-.35-.156-.522 1.437.09 2.879.004 4.294-.256-1.151-1.413-2.303-2.836-3.454-4.249a.808.808 0 0 1-.072-.083c-.584-.723-1.174-1.44-1.758-2.163l-.001-.001a25.804 25.804 0 0 1 .758-6.578Z"
          />
          <path
            fill="#A0616A"
            d="M207.327 66.588a7.101 7.101 0 0 0 8.101 2.848 7.097 7.097 0 0 0 2.635-1.535l32.268 14.553 6.221-7.932-38.451-17.005a7.093 7.093 0 0 0-11.867 4.153 7.089 7.089 0 0 0 1.093 4.918Z"
          />
          <path
            fill="#6C63FF"
            d="m345.804 75.662 3.2 18.13a6.145 6.145 0 0 1-1.195 4.84 6.176 6.176 0 0 1-1.944 1.65 6.178 6.178 0 0 1-2.446.721l-54.379 4.275-63.92-28.598 7.634-14.488 55.489 17.566 57.561-4.096Z"
          />
          <path
            fill="#F2F2F2"
            d="M218.535 62.67H23.034C10.313 62.67 0 72.979 0 85.696v192.947c0 12.717 10.313 23.026 23.034 23.026h195.501c12.721 0 23.033-10.309 23.033-23.026V85.696c0-12.717-10.312-23.026-23.033-23.026Z"
          />
          <path
            fill="#FFF"
            d="M23.034 67.405A18.302 18.302 0 0 0 6.13 78.696a18.286 18.286 0 0 0-1.393 7v192.947a18.29 18.29 0 0 0 18.297 18.291h124.773a89.04 89.04 0 0 0 62.95-26.066 88.985 88.985 0 0 0 26.074-62.929V85.696a18.277 18.277 0 0 0-5.359-12.934 18.294 18.294 0 0 0-12.938-5.357h-195.5Z"
          />
          <path
            fill="#F2F2F2"
            d="M175.436 125.961H65.706a3.797 3.797 0 0 1 0-7.59h109.73a3.795 3.795 0 1 1 0 7.59ZM175.436 206.893H65.706a3.797 3.797 0 0 1 0-7.59h109.73a3.795 3.795 0 1 1 0 7.59ZM219.995 166.454H21.145a3.796 3.796 0 0 1 0-7.591h198.85a3.795 3.795 0 1 1 0 7.591Z"
          />
          <path
            fill="#A0616A"
            d="m338.934 155.568-19.809 42.188 76.202-7.716-15.907-40.499zM356.65 398.048l-7.225-.062-3.198-28.507 10.662.09z"
          />
          <path
            fill="#2F2E41"
            d="M333.508 409.532a3.234 3.234 0 0 0 3.202 3.258l14.365.119 2.515-5.095.923 5.121 5.42.05-1.368-18.268-1.886-.125-7.692-.53-2.481-.166-.044 5.168-11.539 7.824a3.236 3.236 0 0 0-1.415 2.644Z"
          />
          <path
            fill="#A0616A"
            d="m276.695 398.08-6.889-2.181 5.322-28.188 10.166 3.218z"
          />
          <path
            fill="#2F2E41"
            d="M251.198 402.261a3.234 3.234 0 0 0 2.104 4.054l13.696 4.334 3.901-4.132-.622 5.167 5.166 1.638 4.061-17.862-1.766-.674-7.196-2.766-2.323-.888-1.561 4.928-13.33 4.089a3.236 3.236 0 0 0-2.13 2.112ZM391.856 178.176l-69.286 12.692s-11.113 8.31-8.14 11.286c2.973 2.976 3.434 2.515 1.481 4.468-1.954 1.952-6.177 4.562-6.218 6.271-.042 1.708-31.047 78.335-31.047 78.335s3.445-1.722.861.861c-2.584 2.583-2.584 4.717-2.584 4.717l-12.058 81.381 19.583 1.152 19.174-74.193 42.77-69.312s4.244 61.809 4.422 65.92c.178 4.111 2.762-1.055.178 4.111-2.584 5.166-4.307 1.722-2.584 5.166 1.723 3.444-6.205 73.557-6.205 73.557h17.874l38.334-145.326s13.639-41.547-6.555-61.086Z"
          />
          <path
            fill="#6C63FF"
            d="m371.445 61.934-27.754-2.883-7.19 9.333-8.377 4.46a20.9 20.9 0 0 0-11.037 19.77l9.789 56.927s-8.269 18.613-2.583 23.246c5.685 4.633-4.396 16.107 7.751 10.332 12.147-5.775 64.595.861 64.595.861l-11.197-31.856 8.364-31.303.921-26.248a22.231 22.231 0 0 0-15.795-22.064l-1.17-.353-6.317-10.222Z"
          />
          <path
            fill="#2F2E41"
            d="M409.655 40.664c.182 7.82-2.742 15.393-6.942 22.044-1.248-3.883-3.491-7.417-5.831-10.786a33.563 33.563 0 0 1-3.574 21.263c-.123.088-.251.17-.383.245-3.423 2.059-7.763 2.892-11.467 1.406 7.652-8.246 8.626-21.907 2.211-31.155-1.816-2.625-4.165-4.946-5.29-7.932-1.776-4.765-.069-10.012 1.008-14.98 1.007-4.624 1.183-10.126-1.92-13.515a3.265 3.265 0 0 1 2.989-1.375c1.913.27 3.306 1.932 4.193 3.644.887 1.713 1.472 3.61 2.708 5.09 2.289 2.767 6.187 3.422 9.473 4.88 7.956 3.543 12.634 12.47 12.825 21.171Z"
          />
          <path
            fill="#CCC"
            d="M204.68 412.975a1.035 1.035 0 0 0 .299.726c.096.095.209.17.333.222.125.051.258.077.393.077h218.149a1.026 1.026 0 1 0 0-2.049H205.705a1.02 1.02 0 0 0-1.025 1.024Z"
          />
          <path
            fill="#6C63FF"
            d="M411.372 221.915a7.75 7.75 0 0 0 7.752-7.748 7.75 7.75 0 0 0-7.752-7.749 7.75 7.75 0 0 0-7.751 7.749 7.75 7.75 0 0 0 7.751 7.748Z"
          />
          <path
            fill="#2F2E41"
            d="M342.685 36.193c.454-1.59-2.679 8.133-1.167 8.309 6.887.79 16.341 4.901 21.138 6.82.216-.026.432-.05.642-.082a19.458 19.458 0 0 0 1.512-.232c11.17-2.113 15.465-19.265 15.465-19.421 0-.771-1.851-21.95-4.768-22.262a21.49 21.49 0 0 0-2.306-.126h-7.237a33.63 33.63 0 0 0-4.542-.658h-.013c-14.569-1.11-26.056 7.767-24.982 18.568.006.013.019.025.025.038.312.464.585.903.826 1.323.236.395.432.77.604 1.128 1.328 2.758-2.085 6.23.178 6.877 3.01.862-7.917 29.182 3.998 29.182-5.796 1.647-.627-25.076.627-29.464Z"
          />
          <path
            fill="#A0616A"
            d="M355.69 52.81c9.463 0 17.134-7.669 17.134-17.128 0-9.46-7.671-17.128-17.134-17.128-9.463 0-17.134 7.668-17.134 17.128s7.671 17.128 17.134 17.128Z"
          />
          <path
            fill="#2F2E41"
            d="M336.427 28.043c.286.15.566.289.851.427.299.144.598.288.896.42 5.738 2.601 10.407 2.89 13.241-.847.076-1.966.518-3.9 1.302-5.704a9.166 9.166 0 0 0 1.513 5.704h5.705c5.877 3.9 8.895 4.307 4.816 17.108-1.137 3.567-5.659 24.323-3.88 27.401.514-.056 5.989-22.79 6.485-22.885 11.169-2.112 18.266-20.508 17.358-21.549.004-2.959-.677-5.879-1.989-8.531a19.625 19.625 0 0 0-7.459-8.112 34.619 34.619 0 0 0-5.96-2.238l-.146-.038a35.21 35.21 0 0 0-5.928-1.066c-.095-.006-.19-.012-.279-.012a3.025 3.025 0 0 0-1.531.42c-.007 0-.007.006-.013.006a2.87 2.87 0 0 0-.851.759 2.91 2.91 0 0 0-.623 1.811h-6.353c-.236 0-.471.007-.706.02a17.036 17.036 0 0 0-16.424 16.01c-.019.3-.025.595-.025.896Z"
          />
          <path
            fill="#2F2E41"
            d="M373.889 22.568c6.234 0 11.287-5.052 11.287-11.284C385.176 5.052 380.123 0 373.889 0c-6.234 0-11.288 5.052-11.288 11.284 0 6.232 5.054 11.284 11.288 11.284Z"
          />
          <path
            fill="#A0616A"
            d="M411.459 217.362a7.104 7.104 0 0 0 .787-5.904 7.112 7.112 0 0 0-3.988-4.425l.056-35.387-9.787-2.422.241 42.031a7.086 7.086 0 0 0 3.632 8.689 7.1 7.1 0 0 0 9.059-2.582Z"
          />
          <path
            fill="#6C63FF"
            d="m362.991 87.37 15.231-10.348a6.157 6.157 0 0 1 4.908-.894 6.162 6.162 0 0 1 3.961 3.032l26.186 47.835.1 70.006-16.349-1.024-6.711-57.797-27.326-50.81Z"
          />
          <path
            fill="#FFF"
            d="M246.016 246.134H83.237a13.35 13.35 0 0 0-13.35 13.346 13.35 13.35 0 0 0 13.35 13.345h162.779a13.352 13.352 0 0 0 13.35-13.345 13.346 13.346 0 0 0-13.35-13.346Z"
          />
          <path
            fill="#6C63FF"
            d="M246.016 273.686H83.237a14.214 14.214 0 0 1-10.048-4.161 14.204 14.204 0 0 1 10.048-24.252h162.779a14.206 14.206 0 1 1 0 28.413ZM83.237 246.995a12.492 12.492 0 0 0-8.83 3.657 12.483 12.483 0 0 0 8.83 21.312h162.779a12.489 12.489 0 0 0 12.488-12.484 12.482 12.482 0 0 0-12.488-12.485H83.237ZM255.614 304.373H183.78a3.796 3.796 0 0 1-3.797-3.795 3.793 3.793 0 0 1 3.797-3.795h71.834a3.797 3.797 0 0 1 3.797 3.795 3.795 3.795 0 0 1-3.797 3.795Z"
          />
        </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
