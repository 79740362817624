// Dependencies
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

//Components
import Pagination from "./Pagination";

// CustomHook
import { usePagination } from "../hooks/usePagination";

const TableVideos = (props) => {
  const {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset,
  } = usePagination(props.data ? props.data.videos : [], 10);

  return (
    <React.Fragment>
      <div className="c-table-video o-grid o-grid--four">
        <div className="c-table-video__column">
          {Object.keys(props.data).length > 0 &&
            props.data.videos
              .slice(
                paginationManage.paginationStart,
                paginationManage.paginationEnd
              )
              .map((video, index) => {
                return (
                  <motion.div
                    key={`date-${video.created_at}-${index}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-video__cell"
                  >
                    {video.created_at}
                  </motion.div>
                );
              })}
        </div>
        <div className="c-table-video__column">
          {Object.keys(props.data).length > 0 &&
            props.data.videos
              .slice(
                paginationManage.paginationStart,
                paginationManage.paginationEnd
              )
              .map((video, index) => {
                return (
                  <motion.div
                    key={`source-${video.source}-${index}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-video__cell"
                  >
                    {video.source}
                  </motion.div>
                );
              })}
        </div>
        <div className="c-table-video__column">
          {Object.keys(props.data).length > 0 &&
            props.data.videos
              .slice(
                paginationManage.paginationStart,
                paginationManage.paginationEnd
              )
              .map((video, index) => {
                return (
                  <motion.div
                    key={video.video}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-video__cell"
                  >
                    <video width="100%" height="100%" controls>
                      <source src={video.video} type="video/webm" />
                    </video>
                  </motion.div>
                );
              })}
        </div>
        <div className="c-table-video__column">
          {Object.keys(props.data).length > 0 &&
            props.data.videos
              .slice(
                paginationManage.paginationStart,
                paginationManage.paginationEnd
              )
              .map((video, index) => {
                return (
                  <motion.div
                    key={`${video.video}-${index}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-table-video__cell"
                  >
                    <div className="c-button c-button--margin-zero">
                      <a className="c-button__text" href={video.video} download>
                        Download
                      </a>
                    </div>
                  </motion.div>
                );
              })}
        </div>
      </div>
      {props.data && props.data.videos.length > itemsForPage && (
        <Pagination
          marginTop={true}
          paginationManage={paginationManage}
          pagination={props.data.videos}
          goToPage={(index) => goToPage(index)}
          goToLastPage={() => goToLastPage()}
          goToFirstPage={() => goToFirstPage()}
          goToNextPage={() => goToNextPage()}
          goToPreviousPage={() => goToPreviousPage()}
          itemsForPage={itemsForPage}
          isFirst={() => checkFirst()}
          isLast={() => checkLast()}
        />
      )}
    </React.Fragment>
  );
};

export default TableVideos;
