import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import FolderInfo from "../../components/FolderInfo";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalRanking from "../../components/admin-modals/ModalRanking";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalInput from "../../components/admin-modals/ModalInput";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import TableToolsNew from "../../components/TableToolsNew";
import Spinner from "../../components/Spinner";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import Roles from "../../config/roles";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// Actions
import {
  fetchFolder,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderUpdate,
  fetchFolderCreateUsersFolder,
  fetchFolderDestroy,
  fetchFolderUpdateChart,
  fetchFolderDownloadScoreUsers,
  fetchFolderDownloadReports,
  fetchOrganizations,
  startSurveyFolder,
  fetchCopyFolder,
  fetchSwitchConfigFolder,
  fetchRankingProfileFolder,
  fetchUpdateRankingProfileFolder,
  fetchDestroyRankingProfileFolder,
  fetchCreateRankingProfileFolder  
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const Folder = (props) => {
  const [modals, setModals] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterStatus, setFilterStatus] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    _fetchFolder()
    props.organizations.length === 0 && props.fetchOrganizations();
    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);
  }, []);

  useEffect(() => {
    if(props.folder){
      setLoading(true);
      props.fetchFolder(
      props.match.params.organizationId,
      props.match.params.folderId,
      1,
      20,
      search,
      filterStatus,
      undefined,
      undefined,
      () => setLoading(false)
      )  
    }
    
  },  [search, filterStatus])
  const _fetchFolder = () => {
    props.fetchFolder(
      props.match.params.organizationId,
      props.match.params.folderId,
      1,
      20,
      "",
      "",
      undefined,
      undefined,
      () => {
        // getDataChart();
        setLoading(false);
        setModals({});
      }
    );    
  }

 
  const submitEditFolder = (data) => {
    let organizationId = props.organization.data.id;
    let folderId = props.folder.id;

    props.fetchFolderUpdate(organizationId, folderId, { folder: data }, () => {
      setModals({
        type: "success",
        text: "The survey has been edited successfully!",
        action: () => setModals({}),
      });
      _fetchFolder()
      getDataChart();
    });
  };

  const actionModals = (type) => {
    let modal = {};
    if (type === "invite") {
      if (props.folder.survey !== "") {
        modal.type = type;
        modal.action = (data, file) => submitInvitation(data, file);
      } else {
        modal.type = "success";
        modal.text =
          "You must have a assessment assigned to be able to invite users. You can assign a assessment by editing the folder";
        modal.action = () => setModals({});
      }
    } else if (type === "navigation") {
      modal.type = type;
      modal.text = "Do you want to navigate to associated emails?";
      modal.action = () => submitNavigation();
    } else if (type === "createOrganization") {
      modal.type = type;
      modal.text = "New organization:";
      modal.action = (data) => submitCreateOrganization(data);
    } else if (type === "start_survey") {
      modal.type = type;
      modal.text = "Are you sure you want to start the survey?";
      modal.action = () => startSurvey();
    } else if (type === "edit") {
      modal.type = type;
      modal.text = "Edit survey";
      modal.action = (data) => submitEditFolder(data);
    } else if(type === "export"){
      modal.type = type;
      modal.text = "Export data";
      modal.action = (data) => {};
    } else if(type === "copy_folder"){
      getAssessments()
    } else if(type === "export_all"){
      modal.type = type;
      modal.text = "Download all reports";
      modal.action = (data, id) => downloadReports(data,id);
    } else if(type === "collective_report"){
      modal.type = type;
      modal.text = "Do you want to open collective reports?";
      modal.action = (type) => showCollectiveReport(type);      
    } else if(type === "ranking_report"){
      modal.type = type;
      modal.text = "Ranking";
      modal.action = (selected_profile) => filterByProfile(selected_profile)  
    } else {
      modal.type = type;
      modal.action = () => {};
    }

    setModals(modal);
  };

  const filterByProfile = (selected_profile) => {
    const { id, name, organization_id } = props.folder;
    props.history.push({
      pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${id}/ranking_profiles/${selected_profile}`,
      state: {
        folderId: id,
        folderName: name,
        selectedProfileId: selected_profile
      },
    });
  }

  const startSurvey = () => {
    let organizationId = props.organization.data.id;
    let folderId = props.folder.id;
    props.startSurveyFolder(
      organizationId,
      folderId,
      () => startSurveySuccess(),
      () => startSurveyError()
    );
  };

  const startSurveySuccess = () => {
    successAction("successStartSurvey");
  };

  const startSurveyError = () => {
    alert("There was an error, please try again");
  };

  const submitInvitation = (emails, file) => {
    let organizationId = props.organization.data.id;
    let folderId = props.folder.id;

    if (emails.length > 0 || file !== undefined) {
      props.fetchFolderCreateUsersFolder(
        organizationId,
        folderId,
        { emails: emails, file: file },
        () => successAction("successInvitation")
      );
    }
  };

  const submitDelete = () => {
    let organizationId = props.organization.data.id;
    let folderId = props.folder.id;

    props.fetchFolderDestroy(organizationId, folderId, () =>
      successAction("delete")
    );
  };

  const successAction = (type) => {
    let modal = {};

    if (type === "successInvitation") {
      modal.type = type;
      modal.text = "Users added successfully!";
      modal.action = () => {
        _fetchFolder()       
        getDataChart();
      };
    } else if (type === "successStartSurvey") {
      modal.type = type;
      modal.text = "Emails sent successfully!";
      modal.action = () => {
        setLoading(false);
        setModals({});
      };
    } else {
      props.history.push(
        "/admin/organizations/" + props.match.params.organizationId
      );
    }
    setModals(modal);
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const submitNavigation = () => {
    const { id, name, organization_id } = props.folder;
    props.history.push({
      pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${id}/emails`,
      state: {
        folderId: id,
        folderName: name,
      },
    });
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => {
        let modal = {};
        modal.type = "copy_folder";
        modal.text = "Copy Survey";
        modal.description = "This will copy all user to the new survey. It could take a while depending on the number of users."
        modal.action = (data) => {};
        setModals(modal);
      }
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals({
        type: "success",
        text: "The organization has been created successfully!",
        action: () => setModals({}),
      }),
        props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const getDataChart = (status = filterStatus) => {
    props.fetchFolderUpdateChart(
      props.match.params.organizationId,
      props.match.params.folderId,
      status,
      () => {},
      () => {}
    );
  };

 const downloadScoreUsers = (email,id) => {
  props.fetchFolderDownloadScoreUsers(
    props.match.params.organizationId,
    props.match.params.folderId,
    email.email,
    "crude",
    () => setModals({
      type: "success",
      text: "The email has been sent successfully!",
      action: () => setModals({}),
    })
  )
 };

 const downloadReports = (email,id) => {
  props.fetchFolderDownloadReports(
    props.match.params.organizationId,
    props.match.params.folderId,
    email.email,
    () => setModals({
      type: "success",
      text: "The petition has been processed, after finishing we will send an email with a link to download all reports!",
      action: () => setModals({}),
    })
  )
 };

 const showCollectiveReport = (type) => {
  setModals({})
  let url = `/t3/collective_reports/${props.folder.crypted_report_folder}${type == 'pdf' ? '.pdf' : ''}`
  window.open(url,'_blank');
 };
 const createCopyFolder = (data) => {
  props.fetchCopyFolder(
    props.match.params.organizationId,
    props.match.params.folderId,
    data,
    (repsonseJSON) => successCopy()
  )
 }

 const successCopy = (repsonseJSON) => {
  setModals({
    type: "success",
    text: "The folder has been copied successfully!",
    action: () => setModals({}),
  }); 
 }

 const switchConfig = (key) => {  
    props.fetchSwitchConfigFolder(
      props.match.params.organizationId,
      props.match.params.folderId,
      {key: key},
      (repsonseJSON) => {
        setModals({
          type: "success",
          text: "Configuration successfully updated!",
          action: () => {
            setLoading(true);
            _fetchFolder()
          },
        });
      }
    )
 }

 const goToEmails = () => {
  props.history.push({
      pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.folder.id}/emails`,
      state: {
        folderId: props.folder.id,
        folderName: props.folder.name,
        title: props.folder.name
      },
    });
 }

const goToConsent = () => {
  props.history.push({
      pathname: `/admin/organizations/${props.match.params.organizationId}/surveys/${props.folder.id}/consent`,
      state: {
        folderId: props.folder.id,
        folderName: props.folder.name,
        title: props.folder.name
      },
    });
 }

const createRanking = (data, success) => {
  props.fetchCreateRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, {...data, folder_id: props.match.params.folderId }, () => success(), () => {})
} 

const destroyRanking = (id, success) => {
  props.fetchDestroyRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, id, () => success(), () => {})
}


  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >

      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}            
            showModalSession={() =>
              setModals({
                type: "closeSession",
                text: "Are you sure you want to logout?",
                action: () => closeSession(),
              })
            }
          />
        )}

      <div className="o-container o-container--overflow-disabled">
        <FolderInfo
          totalUsers={props.folder && props.folder.user.length}
          data={props.folder && props.folder}
          chart={props.chart}
          canManage={props.organization && props.organization.user_role !== Roles.admin.READER}
          startSurvey={() => actionModals("start_survey")}
          editFolder={() => actionModals("edit")}
          openExportModal={() => actionModals("export")}
          openCopyFolder={() => actionModals("copy_folder")}
          openDownloadReportsModal={() => actionModals("export_all")}
          openCollectiveReportsModal={() => actionModals("collective_report")}
          openRankingReportsModal={() => actionModals("ranking_report")}
          switchConfig={(key) => switchConfig(key)}
          collective_report_enabled={props.folder && props.folder.collective_report}
          goToEmails={() => goToEmails()}
          goToConsent={() => goToConsent()}
          has_procedures={props.folder && props.folder.has_procedures ? true : false}
          axis_ranking_enabled={props.folder && props.folder.axis_ranking_enabled}
        />

        <IllustrationLineSeparator />
        <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center">
          <h4 className="o-margin--right-size-l">Users list</h4>
          {
            props.organization &&
                props.organization.user_role !== Roles.admin.READER && 
            <button
              type="button"
              className={"c-button c-button--margin-zero"}
              onClick={() => {
                props.folder && props.folder.has_procedures ? 
                  props.history.push(`/admin/organizations/${props.match.params.organizationId}/procedures/${props.folder.procedure_id}`)
                  :
                  actionModals("invite");
              }}
            >
              <span className="c-button__text">{props.folder && props.folder.has_procedures ? "Survey linked to procedure" : "Add users"}</span>
            </button>
                
          }
        </div>

        {props.folder ? (
          <TableToolsNew
            newPagination={true}
            data={props.folder}
            getDataChart={(status) => getDataChart(status)}
            titleTableFilter="Users list"
            canManage={
              props.organization &&
              props.organization.user_role !== Roles.admin.READER
            }
            getUsers={() =>
              props.fetchFolder(
                props.match.params.organizationId,
                props.match.params.folderId,
                1,
                20,
                search,
                filterStatus,
                undefined,
                undefined,
                () => setLoading(false)
              )
            }
            toPage={ (page, typeOrder, modeOrder) => {
                setLoading(true);
                props.fetchFolder(
                  props.match.params.organizationId,
                  props.match.params.folderId,
                  page,
                  20,
                  search,
                  filterStatus,
                  typeOrder, 
                  modeOrder,
                  () => setLoading(false)
                )}
            }
            search={search}
            setSearch={(search) => setSearch(search)}
            organizationId={props.match.params.organizationId}
            folderId={props.match.params.folderId}
            setFilterStatus={(status) => { setFilterStatus(status)} }
            filterStatus={filterStatus}
            organizations={props.organizations_select}
            survey={props.folder.survey}
          />
        ) : (
          <>
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
            No users yet
            </h2>
            <IllustrationLineSeparator />
          </>
        )}
      </div>

      {["createOrganization", "edit"].includes(modals.type) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) => modals.action(data)}
          defaultData={props.folder}
          type={modals.type}
          title={modals.text}
          assessments={props.folder.assessments}
          biodataOptions={props.organization.data.biodata_options}
        />
      )}

      {modals.type === "invite" && (
        <ModalInvitationFolder
          showModalInvitationFolder={modals.type === "invite"}
          closeInvitationFolder={() => setModals("")}
          invitationAction={(data, file) => modals.action(data, file)}
        />
      )}
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
      {
        modals.type === "ranking_report" && (
         <ModalRanking
          data={modals}
          ranking_data={props.folder.ranking_data}
          showModalInvitationFolder={modals.type === "invite"}
          closeInvitationFolder={() => setModals("")}
          selectedProfile={(profiled_selected) => modals.action(profiled_selected)}
          createRanking = {(data, success) => createRanking(data, success)}    
          destroyRanking = {(id, success) => destroyRanking(id, success)}      
          fetchRankingProfile={(success, error) => props.fetchRankingProfileFolder(props.match.params.organizationId, props.match.params.folderId, (repsonseJSON) => success(repsonseJSON), () => error())}
        /> 
      )}

      {[
        "navigation",
        "successInvitation",
        "success",
        "start_survey",
        "successStartSurvey",
        "closeSession",
        "errorImport",
        "collective_report",
      ].includes(modals.type) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={[
            "successStartSurvey",
            "successInvitation",
            "success",
            "errorImport",
          ].includes(modals.type)}
          closeModalInfoAction={() => setModals("")}
          text={modals.text}
          secondButton={modals.type === "collective_report"}
          secondButtonText={"PDF"}
          primaryButtonText={modals.type === "collective_report" ? "Interactive" : "Confirm"}
          secondAction={() => modals.action('pdf')}
          action={() => modals.action('html')}
        />
      )}

      {["export"].includes(modals.type) && (
        <ModalInput
          title={"Export data"}
          description="Enter your email in order to send you the exported file."
          data={{}}
          showModal={true}
          closeModal={() => setModals("")}
          action={(data, id) => downloadScoreUsers(data,id)}
          type={"user"}
          edit={true}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}

      {["export_all"].includes(modals.type) && (
        <ModalInput
          title={modals.text}
          description="Enter your email in order to send you the reports link file."
          data={{}}
          showModal={true}
          closeModal={() => setModals("")}
          action={(data, id) => modals.action(data,id)}
          type={"user"}
          edit={true}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}

      {["copy_folder"].includes(modals.type) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) => createCopyFolder(data)}
          defaultData={undefined}
          type={modals.type}
          title={"Copy Survey"}
          description={modals.description}
          assessments={props.organization.data.assessments}
        />
      )}      

      {loading && <Spinner />}
    </motion.section>
  );
};

Folder.propTypes = {
  folder: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    folder: state.admin.folder,
    chart: state.admin.chart,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchFolder,
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchFolderUpdate,
  fetchFolderCreateUsersFolder,
  fetchFolderDestroy,
  fetchFolderUpdateChart,
  fetchFolderDownloadScoreUsers,
  fetchFolderDownloadReports,
  startSurveyFolder,
  fetchLogout,
  fetchCopyFolder,
  fetchSwitchConfigFolder,
  fetchRankingProfileFolder,
  fetchUpdateRankingProfileFolder,
  fetchDestroyRankingProfileFolder,
  fetchCreateRankingProfileFolder
};

export default connect(mapStateToProps, mapDispatchToProps)(Folder);
