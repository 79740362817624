import React from "react";
import PropTypes from "prop-types";

const SurveyIpsativeTooltip = (props) => {
  return (
    <div className="c-ipsative__tooltip">
      <p className="c-ipsative__tooltip-text">{props.text}</p>
    </div>
  )
};

SurveyIpsativeTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SurveyIpsativeTooltip;