// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IllustrationFolder = (props) => {
  return (
    <div style={props.style ? props.style : {}}>
    <svg 
      className="c-illustration--folder" 
      width={props.width ? props.width : "198px"} 
      height={props.height ? props.height : "154px"}
      viewBox="0 0 198 154"
      
    >
         <g fill="none" fillRule="evenodd">
      <path
        fill={props.firstColor ? props.firstColor : "#A9C44B"}
        d="M18 .656h51.816c7.16 0 14.028 2.845 19.092 7.908l2.184 2.184a27 27 0 0 0 19.091 7.908H144c4.971 0 9.213 1.758 12.728 5.272 3.514 3.515 5.272 7.758 5.272 12.728v99c0 4.971-1.758 9.214-5.272 12.728-3.515 3.515-7.757 5.272-12.728 5.272H18c-4.97 0-9.213-1.757-12.728-5.272C1.757 144.87 0 140.627 0 135.656v-117c0-4.97 1.757-9.213 5.272-12.728C8.787 2.414 13.03.656 18 .656Z"
      />
      <path
        fill={props.secondaryColor ? props.secondaryColor : "#3C5896"}
        d="M36 135.656v-81c0-4.97 1.757-9.213 5.272-12.728 3.515-3.514 7.757-5.272 12.728-5.272h126c4.97 0 9.213 1.758 12.728 5.272 3.515 3.515 5.272 7.758 5.272 12.728v81c0 4.971-1.757 9.214-5.272 12.728-3.515 3.515-7.758 5.272-12.728 5.272H18c4.97 0 9.213-1.757 12.728-5.272C34.243 144.87 36 140.627 36 135.656Z"
      />
    </g>
    </svg>
</div>
  );
};

export default IllustrationFolder;
