// Dependencies
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Chart = (props) => {
  const chartClasses = () => {
    let chartClass = classNames("c-chart", props.className && props.className);
    return chartClass;
  };
  return (
    <svg
      width={props.width ? props.width : "112px"}
      height={props.height ? props.height : "171px"}
      viewBox="0 0 112 171"
      className={chartClasses()}
    >

    <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Illustration-Copy-3" transform="translate(-308.000000, -1672.000000)" fill="#121A22">
            <path d="M359.955853,1775.43791 C358.807785,1774.2974 356.222641,1774.59024 352.200421,1776.31644 L346.017994,1782.4582 C340.911508,1789.2562 335.549785,1795.7203 329.932824,1801.8505 C321.507384,1811.04581 310.880291,1817.36477 311.174816,1820.48512 C311.469341,1823.60548 310.697969,1832.4823 315.550297,1830.48228 C320.402625,1828.48226 325.18846,1820.90751 328.105485,1818.00968 C330.050168,1816.07779 333.876876,1811.13095 339.585609,1803.16916 C346.353198,1796.44609 350.318471,1792.5069 351.481429,1791.35159 C352.644387,1790.19628 355.286675,1787.57137 359.408293,1783.47687 C360.921401,1779.25807 361.103921,1776.57842 359.955853,1775.43791 Z" id="Path-8" stroke="#121A22" strokeWidth="9" transform="translate(335.933809, 1802.764951) rotate(-45.000000) translate(-335.933809, -1802.764951) "></path>
            <path d="M401.251085,1756.83368 C399.692992,1755.28498 396.184583,1755.68263 390.725856,1758.02663 L382.335418,1766.36649 C375.405186,1775.59746 368.128561,1784.37503 360.505543,1792.6992 C349.071016,1805.18545 334.648532,1813.76593 335.048244,1818.00305 C335.447956,1822.24016 334.401094,1834.29396 340.986395,1831.57814 C347.571697,1828.86232 354.066761,1818.57662 358.025581,1814.64166 C360.664794,1812.01835 365.858184,1805.30107 373.605751,1794.4898 C382.790336,1785.36057 388.171779,1780.01157 389.750079,1778.44278 C391.328379,1776.874 394.914342,1773.30965 400.507967,1767.74974 C402.561471,1762.02106 402.809177,1758.38237 401.251085,1756.83368 Z" id="Path-8" stroke="#121A22" transform="translate(368.649737, 1793.940927) rotate(-45.000000) translate(-368.649737, -1793.940927) "></path>
            <path d="M443.249071,1734.64089 C441.267284,1732.68402 436.804834,1733.18648 429.86172,1736.14828 L419.189683,1746.68625 C410.374926,1758.3502 401.119581,1769.44125 391.423647,1779.95939 C376.879747,1795.73661 358.535371,1806.57863 359.043782,1811.93251 C359.552192,1817.28638 358.220673,1832.51714 366.596711,1829.08552 C374.97275,1825.65389 383.234001,1812.65722 388.269337,1807.68514 C391.626228,1804.37042 398.231847,1795.88268 408.086194,1782.22191 C419.76833,1770.68653 426.613141,1763.9277 428.620626,1761.94543 C430.628111,1759.96317 435.1892,1755.45937 442.303892,1748.43406 C444.915797,1741.19549 445.230857,1736.59777 443.249071,1734.64089 Z" id="Path-8" transform="translate(401.782485, 1781.528386) rotate(-45.000000) translate(-401.782485, -1781.528386) "></path>
            <g id="Group-7" transform="translate(360.399548, 1723.265831) rotate(-20.000000) translate(-360.399548, -1723.265831) translate(313.399548, 1706.765831)" stroke="#121A22" strokeWidth="3">
                <path d="M78.6040775,0.438439482 C76.2138028,6.06625173 61.8497546,9.5963735 56.0824746,13.474033 C37.2181601,21.9642367 24.0319589,21.9858666 13.9143488,21.9858666 C3.79673879,21.9858666 -0.726471211,25.48212 0.344718829,32.4746267 L22.208001,32.4746267 C47.3736583,24.6452535 62.1766243,19.1827703 66.6168988,16.0871769 C73.2773105,11.4437869 93.327731,4.84846496 93.327731,0.0435921743 L78.6040775,0.438439482 Z" id="Path"></path>
            </g>
            <g id="Chevron" transform="translate(394.826467, 1695.962010) rotate(-321.000000) translate(-394.826467, -1695.962010) translate(375.326467, 1684.962010)" stroke="#121A22" strokeWidth="3">
                <path d="M20.8096286,1.07225816 C20.3312673,0.651939891 19.2543086,0.759699462 17.5787524,1.39553687 L15.0035312,3.65828821 C12.8765921,6.16291102 10.6432991,8.54448478 8.30365222,10.8030095 C4.7941819,14.1907966 0.367313189,16.51859 0.490213058,17.6683886 C0.613112927,18.8181872 0.292326863,22.0890555 2.31369063,21.3523668 C4.3350544,20.6156782 6.32835998,17.8248258 7.54341418,16.7572028 C8.35345032,16.0454541 9.94734938,14.2228706 12.3251114,11.2894525 C15.1440749,8.8125338 16.7957652,7.36125476 17.2801824,6.93561536 C17.7645996,6.50997595 18.8652153,5.5429058 20.5820294,4.0344049 C21.2121234,2.47995867 21.2879898,1.49257642 20.8096286,1.07225816 Z" id="Path-8"></path>
                <path d="M18.4105342,0.473600241 C17.9903453,0.952108748 18.0980717,2.02939903 18.7337134,3.70547108 L20.9957683,6.28148515 C23.4996202,8.40907912 25.880461,10.6430597 28.1382906,12.9834269 C31.5250349,16.4939778 33.8521119,20.9222094 35.0015566,20.7992717 C36.1510013,20.676334 39.4208628,20.9972188 38.6844009,18.9752327 C37.9479391,16.9532466 35.1579457,14.9593273 34.0906512,13.743899 C33.3791216,12.9336135 31.5570991,11.3392237 28.6245838,8.96072966 C26.1484275,6.14089824 24.6975952,4.48869937 24.2720868,4.00413304 C23.8465784,3.51956671 22.8798059,2.41861219 21.3717693,0.70126948 C19.8178015,0.0709814805 18.8307231,-0.0049082659 18.4105342,0.473600241 Z" id="Path-8"></path>
            </g>
        </g>
    </g>
</svg>
  );
};

export default Chart;
