// Dependencies
import React from "react";

const Link = ({ width, height, color, waveColor }) => {
  return (
    <svg
      width={width ? width : "20px"}
      height={height ? height : "20px"}
      viewBox="0 0 32 32"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="external-link-symbol-(1)" fill={color ? color : "white"}>
          <path
            d="M24.024597,18.4341299 L22.9070772,18.4341299 C22.7438584,18.4341299 22.6099321,18.4931628 22.5057265,18.6117809 C22.4006647,18.7297086 22.3483784,18.8808467 22.3483784,19.0649188 L22.3483784,25.3728771 C22.3483784,26.2402809 22.0750221,26.9826454 21.5280036,27.600385 C20.9809852,28.2179864 20.3232177,28.5265455 19.5549458,28.5265455 L5.02828882,28.5265455 C4.26007803,28.5265455 3.60237169,28.2179864 3.05523095,27.600385 C2.50821252,26.9827145 2.23473388,26.2403499 2.23473388,25.3728771 L2.23473388,8.97201993 C2.23473388,8.10468516 2.50815136,7.36238966 3.05523095,6.74471916 C3.60237169,6.12697962 4.26007803,5.81828246 5.02828882,5.81828246 L17.3197838,5.81828246 C17.4830026,5.81828246 17.6169289,5.75897338 17.7215626,5.64083855 C17.8261963,5.52256563 17.8784826,5.3715657 17.8784826,5.18742449 L17.8784826,3.92563952 C17.8784826,3.74184353 17.8261963,3.59056743 17.7215626,3.47250164 C17.6169289,3.35422871 17.4830026,3.29519582 17.3197838,3.29519582 L5.02828882,3.29519582 C3.64328341,3.29519582 2.45886161,3.85045025 1.47514574,4.96089007 C0.491796783,6.07126085 0,7.40851127 0,8.97222706 L0,25.3731533 C0,26.9368 0.491796783,28.2742575 1.47520689,29.3842831 C2.45892276,30.494792 3.64334456,31.0503226 5.02834998,31.0503226 L19.5549458,31.0503226 C20.93989,31.0503226 22.1244341,30.494792 23.1080277,29.3842831 C24.0916824,28.2742575 24.5834792,26.936869 24.5834792,25.3731533 L24.5834792,19.0654021 C24.5834792,18.8811228 24.5311318,18.7299158 24.42607,18.6117809 C24.321314,18.4931628 24.1873877,18.4341299 24.024597,18.4341299 Z"
            id="Path"
          ></path>
          <path
            d="M30.9558617,1.14660558 C30.7346694,0.896872271 30.472443,0.772109182 30.1700387,0.772109182 L21.2307363,0.772109182 C20.9281485,0.772109182 20.6661056,0.896872271 20.4450967,1.14660558 C20.2239044,1.3962008 20.1131553,1.69191763 20.1131553,2.03361798 C20.1131553,2.37531833 20.2239044,2.6711042 20.4450967,2.92083751 L23.5178787,6.39017657 L12.1343304,19.242846 C12.0177717,19.3742374 11.9596759,19.5254445 11.9596759,19.6959839 C11.9596759,19.8671448 12.0179552,20.0182828 12.1343304,20.1496742 L14.1247559,22.3966526 C14.2411311,22.5280439 14.3750573,22.593567 14.5263512,22.593567 C14.677645,22.593567 14.8116324,22.5281129 14.9279465,22.3966526 L26.311556,9.54419022 L29.3845826,13.0135293 C29.6055915,13.2632626 29.8675733,13.3881638 30.1702222,13.3881638 C30.472871,13.3881638 30.7348528,13.2632626 30.9561063,13.0135293 C31.1772987,12.763865 31.2878031,12.4682172 31.2878031,12.1265169 L31.2878031,2.03361798 C31.287742,1.6917105 31.1768706,1.3962008 30.9558617,1.14660558 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Link;
