import React from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";

// Actions
import { redirectTo } from "../../../actions";

// Config
import routes from "../../../config/routes";

// Components
import SurveyItem from "./SurveyItem";
import IllustrationLineSeparator from "../../../components/IllustrationLineSeparator";

const SurveyModule = (props) => {
  return (
    <motion.div
      className="c-test"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.75 }}
    >
      <SurveyItem
        current_user_folder_language={props.current_user_folder_language}      
        image_url={props.module.image_url}
        title={props.module.title}
        description={props.module.description}
        ellipsis={true}
        is_module={true}
        enabled={true}
        internalization={props.internalization}
        closed={props.module.progress === 100}
        progress={props.module.progress}
        handleClick={() => { props.history.push(`/survey/${props.survey_token}/${props.module_key}`)}} 
      />
      {
        !props.last &&
          <IllustrationLineSeparator className="c-test__separator" />
      }
    </motion.div>
  );
};

const mapStateToProps = (state) => {
  return {
    survey_token: state.survey.token
  };
};

const mapDispatchToProps = {
  redirectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyModule);
