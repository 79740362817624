import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import Roles from "../../config/roles";
import Spinner from "../../components/Spinner";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import ProcedureUserBiodata from "./components/ProcedureUserBiodata";
import ProcedureUserKillerQuestions from "./components/ProcedureUserKillerQuestions";
import ProcedureUserAdditionalDocuments from "./components/ProcedureUserAdditionalDocuments"
import ProcedureUserResults from "./components/ProcedureUserResults";
import ProcedureUserEvaluation from "./components/ProcedureUserEvaluation";
import ProcedureUserSettings from "./components/ProcedureUserSettings";

import ModalShowVideo from "../../components/admin-modals/ModalShowVideo";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalInput from "../../components/admin-modals/ModalInput";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

// Actions
import {
  fetchProcedureUser,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchOrganizations,
  fetchKillerExport,
  uploadProcedureUserDocument,
  deleteDocumentProcedureUser,
  fetchKillerFiles,
  fetchUpateProcedureUser
} from "../admin/actions";

import { fetchLogout } from "../login/actions";
import {postAnswerScore} from './actions'
// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const BIODATA = 'biodata';
const ADDITIONAL_DOCUMENTS = 'additional_documents';
const KILLER_QUESTIONS = 'killer_questions';
const RESULT = 'result';
const EVALUATION = 'evaluation';
const SETTINGS = "settings"

const ProceduresUserShow = (props) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(BIODATA);
  const [videoModalData, setVideoModalData] = useState({})
  const [modals, setModals] = useState("");
  const [modalMsg, setModalMsg] = useState(""); 


  useEffect(() => {
    props.fetchProcedureUser(
      props.match.params.organizationId,
      props.match.params.procedureId,
      props.match.params.userId,
      () => {
        setLoading(false);
      }
    );
    props.organizations.length === 0 && props.fetchOrganizations();
    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);

  }, []);

  useEffect(() => {
    if(props.procedureUser && props.procedureUser.tabs.length > 0){
      setStep(props.procedureUser.tabs[0].key)  
    }
  }, [props.procedureUser])

  const downloadFilesModal = (id) => {
    let modal = {};
    modal.type = "export_all"
    modal.text = "Download all files";
    modal.action = (data, id_2) => props.fetchKillerFiles(props.match.params.organizationId, props.match.params.procedureId, id, data["email"], () => setModals({}), () => alert("ko"));
    setModals(modal)
  }

  const _fetch = (key) => {
    props.fetchProcedureUser(
      props.match.params.organizationId,
      props.match.params.procedureId,
      props.match.params.userId,
      () => {
        setLoading(false);
        setStep(key)
      }
    );
  }
  console.log(step)
  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}            
            showModalSession={() =>
              setModals({
                type: "closeSession",
                text: "Are you sure you want to logout?",
                action: () => closeSession(),
              })
            }
          />
        )}

      <div className="o-container o-container--overflow-disabled">
        <div className="c-folder-info">
          <div className="c-folder-info__wrapper">
            <div className="c-folder-info__data">
              <div className="c-folder-info__data-title o-margin--bottom-size-l">
                <h1 className="c-folder-info__data-title-text">
                  {props.procedureUser && Object.values(props.procedureUser.user_info).length > 0 ? props.procedureUser.user_info.email : "Loading..."}
                </h1>
              </div>
            </div>
            <h4 className="c-folder-info__data-title-text">
              {props.procedureUser && Object.values(props.procedureUser.user_info).length > 0 ? props.procedureUser.user_info.procedure_name : "Loading..."}
            </h4>
          </div>
        </div>
        <IllustrationLineSeparator />

        <div className="c-folder-info__button o-margin--bottom-size-xl">
          <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
            {
              props.procedureUser && Object.values(props.procedureUser.tabs).map((item, index) => {
                if (typeof (item) !== 'boolean') {
                  return (
                    <button
                      key={item.key}
                      type="button"
                      className={`c-button c-button--margin-zero ${step === item.key ? 'c-button--active' : ''}`}
                      onClick={() => setStep(item.key)}
                    >
                      <span className="c-button__text">{item.title}</span>
                    </button>
                  )
                }
              })
            }
          </div>
        </div>

        {
          props.procedureUser && step === BIODATA &&
          <ProcedureUserBiodata
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === BIODATA)[0]}
          />
        }
        {
          props.procedureUser && step === ADDITIONAL_DOCUMENTS &&
          <ProcedureUserAdditionalDocuments
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === ADDITIONAL_DOCUMENTS)[0]}
            uploadDocument={(data) => props.uploadProcedureUserDocument(data, props.match.params.organizationId, props.match.params.procedureId, props.match.params.userId, () => _fetch(ADDITIONAL_DOCUMENTS))}
            deleteDocument={(id) => props.deleteDocumentProcedureUser(id,props.match.params.organizationId, props.match.params.procedureId, props.match.params.userId, () => _fetch(ADDITIONAL_DOCUMENTS))}
          />
        }
        {
          props.procedureUser && step === KILLER_QUESTIONS &&
          <ProcedureUserKillerQuestions
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === KILLER_QUESTIONS)[0]}
            fetchKillerExport={(id) => props.fetchKillerExport(props.match.params.organizationId, props.match.params.procedureId, id, () => alert("ok"), () => alert("ko"))}
            fetchKillerFiles={(id) => downloadFilesModal(id)}
          />
        }

        {
          props.procedureUser && step === RESULT &&
          <ProcedureUserResults
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === RESULT)[0]}
            openVideoModal={(video_data) => setVideoModalData(video_data)}
          />
        }

        {
          props.procedureUser && step === EVALUATION &&
          <ProcedureUserEvaluation
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === EVALUATION)[0]}
            setAnswer={(organization_id, folder_id, user_folder_id, data, success, error) => props.postAnswerScore(organization_id, folder_id, user_folder_id, data, () => {setModals("success"),setModalMsg("The evaluation has been saved successfully!"), success()}, error)}
          />
        }

        {
          props.procedureUser && step == SETTINGS &&
          <ProcedureUserSettings 
            data={Object.values(props.procedureUser.tabs).filter((data, index) => data.key === SETTINGS)[0]}
            fetchUpateProcedureUser = {(data) => props.fetchUpateProcedureUser(props.match.params.organizationId, props.match.params.procedureId, props.match.params.userId, data, () => {setModals("success"),setModalMsg("The settings have been updated successfully!")}, () => alert("Something went wrong!"))}
          />
        }
        { Object.keys(videoModalData).length > 0 && 
          <ModalShowVideo
            showModal={true}
            closeModal={() => setVideoModalData({})}
            title={
              "Video"
            }

            data={videoModalData}
            // optionsAdmin={props.data.rol}
            // edit={isEditUser}
            // type={props.type}
            canManage={props.canManage === undefined ? true : props.canManage}
          />

        }
        {
          modals === "selectOrganization" && (
            <ModalSelectOrganization
              history={props.history}
              organizations={props.organizations_select}
              closeModal={() => setModals("")}
            />
          )
        }
        {["success"].includes(modals) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals)}
          closeModalInfoAction={() => setModals("")}
          text={modalMsg}
          primaryButtonText={"Ok"}
          action={() => setModals("")}
        />
      )}
        {["export_all"].includes(modals.type) && (
        <ModalInput
          title={modals.text}
          description="Enter your email in order to send you the link to download the files."
          data={{}}
          showModal={true}
          closeModal={() => setModals("")}
          action={(data, id) => modals.action(data,id)}
          type={"user"}
          edit={true}
          canManage={props.canManage === undefined ? true : props.canManage}
        />
      )}
      </div>
    </motion.section>
  )
};

ProceduresUserShow.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
    procedure: state.admin.procedure,
    procedureUser: state.admin.procedureUser,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchProcedureUser,
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchLogout,
  postAnswerScore,
  fetchKillerExport,
  uploadProcedureUserDocument,
  deleteDocumentProcedureUser,
  fetchKillerFiles,
  fetchUpateProcedureUser
};

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresUserShow);
