import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// Components
import Button from "../../components/Button";
import IllustrationMonitor from "../../components/IllustrationMonitor";
import Close from "../../components/Close";

const Permission = (props) => {
  return (
    <div className="o-container o-display--flex@lg-and-up js-proctoring-container">
      <div className="o-col--1/2@lg">
        <h1 className="o-margin--bottom-size-l">
          {props.title}
        </h1>
        <p>
          {props.description}
        </p>
      </div>
      <div className="o-col--1/2@lg o-pos-rel">
        <div className="c-proctoring">
          <Close extraBig color="#fc5c65" className="c-proctoring__close" />
          <div className="c-proctoring__illustration">
            <div className="c-proctoring__video-wrapper">
              <div className="c-proctoring__video o-display--flex o-display--flex-h-center o-display--flex-v-center">
              </div>
            </div>
            <IllustrationMonitor
              className="c-proctoring__illustration-monitor"
              cameraOn={false}
            />
          </div>
          <div className="c-button__wrapper">
            <Button
              name="relaod"
              className="o-margin--right-auto o-margin--left-auto"
              type="button"
              text={props.internalization.buttons.refresh}
              onClick={() => props.buttonAction()}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Permission.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonAction: PropTypes.func
}

export default Permission;
