import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationLineSeparator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration c-illustration--separator",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width={this.props.width ? this.props.width : "1127px"}
        height={this.props.height ? this.props.height : "1px"}
        preserveAspectRatio="none"
        viewBox="0 0 1127 1"
      >
           <path fill="none" fillRule="evenodd" stroke="#54595F" d="M0 .5h1127" />

      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
