export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_AUTH_OK = 'FETCH_AUTH_OK';
export const FETCH_LOGIN_OK = 'FETCH_LOGIN_OK';
export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_LOGOUT_OK = 'FETCH_LOGOUT_OK';
export const FORBIDDEN_ACCESS = 'FORBIDDEN_ACCESS';

/** Actions to reducers */
export function fetchLoginOk(data) {
  return {
    type: FETCH_LOGIN_OK,
    ...data
  }
}

export function fetchLogoutOk() {
  return {
    type: FETCH_LOGOUT_OK
  }
}

/** Actions to sagas */
export function fetchLogin(data, success, error) {
  return {
    type: FETCH_LOGIN,
    data: {
      user: data,
      success: success,
      error: error
    }
  }
}

export function forbiddenAccess() {
  return {
    type: FORBIDDEN_ACCESS
  }
}

export function fetchLogout(success, error) {
  return {
    type: FETCH_LOGOUT,
    data: {
      success: success,
      error: error
    }
  }
}
