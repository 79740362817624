import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"

//Components
import Button from "../../components/Button";
import IllustrationHandConfirmation from "../../components/IllustrationHandConfirmation";

const Success = (props) => {
  return (

    <div className="o-container o-display--flex@lg-and-up js-proctoring-container">
      <div className="o-col--1/2@lg">
        <h1 className="o-margin--bottom-size-l">
          {props.title}
         
        </h1>
        <p dangerouslySetInnerHTML={{__html: props.description}}></p>
        <div className="c-button__wrapper">
          <Button
            name="finish"
            className="o-margin--right-auto o-margin--left-auto o-margin--top-size-xxl"
            type="button"
            text={props.button_text}
            onClick={() => props.buttonAction()}
          />
        </div>
      </div>
      <div className="o-col--1/2@lg o-display--flex o-display--flex-v-center o-display--flex-h-right u-hide@sm-and-down o-margin--top-size-xxl@md-only">
        <IllustrationHandConfirmation />
      </div>
    </div>
  )
}

Success.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default Success;
