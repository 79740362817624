// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Lock = (props) => {
  let classNameLock = classNames("c-lock", props.className && props.className);

  return (
    <svg 
      className={classNameLock}
      width={props.width ? props.width : "69px"}
      height={props.height ? props.height : "90px"}

      viewBox="0 0 20 24" 
    >
        <g fill="none" fillRule="nonzero">
      <path
        fill="#FDFDFD"
        d="M16.75 9H16V6.138C16 2.696 13.51 0 9.972 0 6.42 0 4 2.753 4 6.138V9h-.75c-1.655 0-3 1.345-3 3v9c0 1.654 1.345 3 3 3h13.5c1.654 0 3-1.346 3-3v-9c0-1.655-1.346-3-3-3ZM5.5 6.138C5.5 3.581 7.247 1.5 9.972 1.5c2.697 0 4.528 2.037 4.528 4.638V9h-9V6.138ZM18.25 21c0 .826-.674 1.5-1.5 1.5H3.25c-.827 0-1.5-.674-1.5-1.5v-9c0-.826.673-1.5 1.5-1.5h13.5c.826 0 1.5.674 1.5 1.5v9Z"
      />
      <path
        fill="#A9C44B"
        d="M8.5 15a1.5 1.5 0 0 1 3 0c0 .553-.304 1.033-.75 1.292v2.458a.75.75 0 0 1-1.5 0v-2.458A1.495 1.495 0 0 1 8.5 15Z"
      />
    </g>
    </svg>
  );
};

export default Lock;
