// Dependencies
import React from "react";

const Info = (props) => {

  return (
    <svg
      width={props.width ? props.width : "8px"}
      height={props.height ? props.height : "16px"}
      viewBox="0 0 8 16"
    >
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
    >
      <path stroke="#3C5896" d="M2 5h2v10m0 0h3m-3 0H1" />
      <path stroke="#A9C44B" d="M3 1v1h1V1z" />
    </g>
</svg>
  );
};

export default Info;
