import { initialState }  from "../../config/initState";
import {FETCH_RESET_PASSWORD_OK, FETCH_RESET_PASSWORD_ERROR} from './actions';

export default function passwordReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_RESET_PASSWORD_OK:
      return {}
    case FETCH_RESET_PASSWORD_ERROR:
      return state
    default:
      return state;
  }
}
