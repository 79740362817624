import React from "react";
import { motion } from "framer-motion";

import Eye from "../../../components/Eye";
import Close from "../../../components/Close";

const ProcedureTableDocuments = (props) => {

  const { data, deleteDocument } = props;

  return (
    <div className="c-table-report o-grid o-grid--three o-margin--bottom-size-giga">
            <div className="c-table-report__column">
              <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
                <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                  Filename
                </p>
              </div>

              {data &&
                data.data &&
                data.data.map((document, index) => {
                  return (
                    <motion.div
                      key={"table-report-name-" + document.original_name}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                    >
                      <p className="c-table-report__cell__item c-table-report__cell-text">
                        {document.original_name}
                      </p>
                    </motion.div>
                  );
                })}
            </div>
            <div className="c-table-report__column">
              <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
                <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                  Category name
                </p>
              </div>

              {data &&
                data.data &&
                data.data.map((document, index) => {
                  return (
                    <motion.div
                      key={"table-report-name-" + document.category_name}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                    >
                      <p className="c-table-report__cell__item c-table-report__cell-text">
                        {document.category_name}
                      </p>
                    </motion.div>
                  );
                })}
            </div>
            <div className="c-table-report__column">
              <div className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center">
                <p className="c-table-report__cell-text c-table-report__cell-text--bold">
                  Actions
                </p>
              </div>

              {data &&
                data.data &&
                data.data.map((document, index) => {
                  return (
                    <motion.div
                      key={"table-report-name-icons"+index}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="c-table-report__cell c-table-report__cell--vertical-align c-table-report__cell--vertical-align-center"
                    >
                      <div className="c-table-report__cell-icons">
                        <div
                          className="c-table-report__cell-icons-item"
                          onClick={() => {window.open(document.url_document, '_blank').focus();}}
                        >
                          <Eye slashed={false} />
                        </div>
                        <div
                          className="c-table-report__cell-icons-item"
                          onClick={() => {deleteDocument(document.id)}}
                        >
                          <Close color="#fc5c65" />
                        </div>
                      </div>
                    </motion.div>
                  );
                })}
            </div>
          </div>
  )

}

export default ProcedureTableDocuments;
