// Dependencies
import React from "react";
import PropTypes from "prop-types";

const Table = (props) => {
  return (
    <table className="c-table-data">
      <thead className="c-table-data__header">
        <tr className="c-table-data__row">
          <th
            colSpan="2"
            className="c-table-data__cell c-table-data__cell--border-radius c-table-data__cell--bg-dark c-table-data__cell--border-none"
          >
            <h5 className="c-table-data__title c-table-data__title--white">
              Información sobre protección de datos
            </h5>
          </th>
        </tr>
      </thead>
      <tbody className="c-table-data__body">
        <tr className="c-table-data__row">
          <td className="c-table-data__cell c-table-data__cell--width-thirty">
            <strong>Responsable del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text c-table-data__text--bottom-ten">GSG Metrics</p>
            <p className="c-table-data__text c-table-data__text--bottom-ten">
              
            </p>
            <p className="c-table-data__text c-table-data__text--bottom-ten">CIF: B72909815</p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Finalidades del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
             Sus datos se tratan en el contexto del proceso de evaluación del que usted forma parte y al que ha sido invitado por parte de su empresa. Los datos evaluados pueden ser rasgos de personalidad, competencias, aptitudes, nivel de inglés, etc. El objetivo de la evaluación es facilitar la toma de decisiones por parte de la empresa que le ha invitado.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Legitimación del tratamiento</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
             Sus datos personales serán tratados por el consentimiento expresamente otorgado por usted.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Conservación de los datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
              Los criterios que GSG Metrics utiliza para fijar los plazos de
              conservación de sus datos han sido determinados de acuerdo con
              los requisitos establecidos en la legislación, reglamentos y
              directrices normativas aplicables, así como los requisitos
              operacionales de GSG Metrics relacionados con las necesidades de la
              empresa que le ha invitado.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Comunicación de los datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
              Sus datos personales pueden ser cedidos por GSG Metrics a la empresa
              que ha solicitado su evaluación, cuando así se requiera para las
              finalidades del tratamiento indicadas anteriormente.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Transferencia Internacional de datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">No se realiza Transferencia Internacional de Datos.</p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Derechos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text c-table-data__text--bottom-ten">
            </p>
            <p className="c-table-data__text">
              En caso de que no se haya satisfecho el ejercicio de sus derechos puede presentar una reclamación ante la Autoridad de Control. Obtenga más información en www.agpd.es.
            </p>
          </td>
        </tr>
        <tr className="c-table-data__row">
          <td className="c-table-data__cell">
            <strong>Como hemos obtenido sus datos</strong>
          </td>
          <td className="c-table-data__cell">
            <p className="c-table-data__text">
              Los datos necesarios para el envío de esta evaluación (nombre y
              correo electrónico) han sido cedidos a GSG Metrics por la empresa
              que solicita su evaluación.
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}


// static propTypes = {
//   isVisible: PropTypes.bool.isRequired,
//   children: PropTypes.node.isRequired,
// };

export default Table;
