import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

//Components
import IllustrationCongratulationsTest from "../components/IllustrationCongratulationsTest";
import IllustrationCongratulationsModule from "../components/IllustrationCongratulationsModule";
import Button from "../components/Button";

const Congratulations = (props) => {
  const [title, setTitle] = React.useState("¡Genial!");
  const [subtitle, setSubtitle] = React.useState("¡Prueba finalizada!");
  const [buttonText, setButtonText] = React.useState("Aceptar");

  React.useEffect(() => {
    props.title && setTitle(props.title);
    props.subtitle && setSubtitle(props.subtitle);
    props.buttonText && setButtonText(props.buttonText);
  }, []);

  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="o-container">
        <div className="o-display--flex o-display--flex-v-center o-display--flex-column-direction">
          <h1 className="o-margin--bottom-size-l o-text-align--center">
            {title}
          </h1>
          {props.isModule ? (
            <IllustrationCongratulationsModule className="o-margin--bottom-size-xl" />
          ) : (
            <IllustrationCongratulationsTest className="o-margin--bottom-size-xl" />
          )}
          <p className="o-color--black o-margin--bottom-size-l o-text-align--center">
            {subtitle}
          </p>
          <Button
            name="finish"
            className="o-margin--right-auto o-margin--left-auto"
            type="button"
            text={buttonText}
            onClick={props.handleClick}
          />
        </div>
      </div>
    </motion.section>
  );
};

Congratulations.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func
};

export default Congratulations;
