import React from "react";


const Dashboard = (props) => {
  return (
    <svg
      width={props.width ? props.width : "36px"}
      height={props.height ? props.height : "29px"}
      viewBox="0 0 24 24"
      className={"c-dashboard"}
    >
       <g fill="none" fillRule="nonzero">
      <path fill="#A9C44B" d="M7 12a4 4 0 0 0 4-4H7V4a4 4 0 1 0 0 8Z" />  
      <path
        fill="#FDFDFD"
        d="M15.1 9.91a1 1 0 1 0-2 0v2h-1a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2h-1V6.24a1 1 0 1 0-2 0v5.67h-2v-2Z"
      />
      <path
        fill="#FDFDFD"
        d="M22.04.706H1.96C.88.706 0 1.586 0 2.667v13.626c0 1.082.88 1.961 1.96 1.961h6.732v2.629H7.629a1.206 1.206 0 0 0 0 2.411h8.742a1.206 1.206 0 0 0 0-2.411H15.31v-2.629h6.73c1.081 0 1.961-.88 1.961-1.96V2.666c0-1.081-.88-1.96-1.96-1.96Zm-2.865 16.342a.546.546 0 1 1-.002-1.091.546.546 0 0 1 .002 1.091Zm2.411.132a.677.677 0 1 1 0-1.355.677.677 0 0 1 0 1.355Zm.806-2.038H1.608V2.667c0-.195.158-.353.353-.353h20.078c.195 0 .353.158.353.353v12.475Z"
      />
    </g>
</svg>
  );
};

export default Dashboard;
