import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Checkbox from "../Checkbox";
import Select from "../Select"
import Close from "../Close";

const ModalNotification = (props) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({email: "", when_started: false, when_ended: false, not_started_in: 7})
  let props_temp = {small: true}
  if(props.secondButton == true){
    props_temp = {medium: true}
  }

  const validateData = () => {
    if(data.email === ""){
      return false
    }
    return true
  }

  const action = (data) => {
    props.action(data)
    setData({email: "", when_started: false, when_ended: false, not_started_in: 7})
  }
  return (
    <Modal isVisible={true} medium>
      <p style={{marginBottom: 10}}className="o-text-align--center">Configure Notification</p>
      <div className="c-folder-info__button o-margin--bottom-size-xl">
          <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
            {
              [{title: "Current Notifications", key: 0}, {title: "New Notification", key: 1}].map((item, index) => {
                  return (
                    <button
                      key={item.key}
                      type="button"
                      className={`c-button c-button--margin-zero ${step === item.key ? 'c-button--active' : ''}`}
                      onClick={() => setStep(item.key)}
                    >
                      <span className="c-button__text">{item.title}</span>
                    </button>
                  )
              })
            }
          </div>
        </div>

      {
          step === 1 &&
        <div>
          <form>
          <Input
            name="email"
            label="Email"
            errorLabel="You must enter a correct email"
            minCharacters={5}
            showError={""}
            type="text"
            value={data.email}
            onChange={(e) => setData({...data, email: e.target.value})}
          />
          <div style={{display: 'flex', justifyContent: 'space-around', marginBottom: 10}}>
          <div style={{textAlign: 'center'}}>
          <p className="c-rating-checkbox__label">When Started</p>
            <Checkbox 
              tickClassName="o-margin--right-zero"
              checked={data.when_started}
              value={true}
              item_format_style={11}
              onClick={(text, checked, value) => 
                {
                   setData({...data, when_started: !data.when_started})}
                }
            />
            </div>
            <div style={{textAlign: 'center'}}>
            <p className="c-rating-checkbox__label">When Ended</p>
            <Checkbox 
              tickClassName="o-margin--right-zero"
              checked={data.when_ended}
              value={true}
              item_format_style={11}

              onClick={(text, checked, value) => 
                {
                   setData({...data, when_ended: !data.when_ended})}
                }
            />
            </div>
            </div>
             <p className="c-rating-checkbox__label">If not started in (days)</p>
            <Select
              withoutInput={true}
              hasError={(value) => {}}
              erroLabel='Error'
              valueSelected={data.not_started_in}
              options={Array.from([1, 2, 3,4,5,6,7,8,9,10]).map((x) => { 
                return { value: x, text: x }
              })}
              optionClick={(text, value, data_t) => setData({...data, not_started_in: value})}
              wrapperClassName='c-procedure__select'
            />
          </form>
          <div className="c-modal__buttons-wrapper">

            {!props.noButtonCancel && (
              <button
                type="button"
                className="c-button c-button--cancel c-modal__button"
                onClick={() => props.closeModalInfoAction()}
              >
                <span className="c-button__text">Cancel</span>
              </button>
            )}
            <button
              type="button"
              disabled={!validateData()}
              className="c-button c-modal__button"
              onClick={() => action(data)}
            >
              <span className="c-button__text">{'Confirm'}</span>
            </button>
          </div>
          </div>
        } 

         {
          step === 0 &&
          <div>
          <p> Notifications </p>
          {
            props.notifications && props.notifications.map((noti) => {
              return(
                <div style={{display: 'flex'}}>
                <li>{noti.email} -> {noti.when_started ? "when started" : "-"}{noti.when_ended ? "when ended" : "-"} Not started in {noti.not_started_in} </li>
                  <span style={{marginLeft: 20}} onClick={() => props.deleteUserFolderNotification(noti.id)}><Close color="#fc5c65" /></span>
                </div>
              )
            })
          }
          {!props.noButtonCancel && (
              <button
                type="button"
                className="c-button c-button--cancel"
                onClick={() => props.closeModalInfoAction()}
              >
                <span className="c-button__text">Close</span>
              </button>
            )}
          </div>
        }

    </Modal>
  );
};

export default ModalNotification;
