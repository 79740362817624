// Dependencies
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";

// Components
import Dialog from "../../components/Dialog";
import Tick from "../../components/Tick";
import Close from "../../components/Close";
import Button from "../../components/Button";
import IllustrationMonitor from "../../components/IllustrationMonitor";
import { initScreenRecord } from "../../lib/proctoring";

const ScreenRecorder = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [screenRecorderStatus, setScreenRecorderStatus] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState("");
  const [hasError, setHasError] = useState(false);

  const screenRecording = () => {
    var that = this;
    navigator.mediaDevices
      .getDisplayMedia({ video: true })
      .then(function (screen) {
        let video = document.querySelector("video");
        setScreenRecorderStatus(true);
        video.srcObject = screen;
        window.recorderScreen = initScreenRecord(screen)
        video.onloadedmetadata = (e) => {
          video.play();
        };
        window.screen_record = screen;
        screen.addEventListener('ended', function() {
            props.onPermissionDenied && props.onPermissionDenied()
        }, false);
        screen.addEventListener('inactive', function() {
            props.onPermissionDenied && props.onPermissionDenied()
        }, false);
        screen.getTracks().forEach(function(track) {
          track.addEventListener('ended', function() {
               props.onPermissionDenied && props.onPermissionDenied()
          }, false);
          track.addEventListener('inactive', function() {
              props.onPermissionDenied && props.onPermissionDenied()
          }, false);
        });        
      })
      .catch(function (err) {
        props.trackError && props.trackError({name: "Proctoring Error", "error_value": err.toString(), type: "screenRecording"})        
        setHasError(true);
      });
  }

  // if record screen show ok dialog
  useEffect(()=> {
    screenRecorderStatus && setIsDialogVisible("is-visible");
  }, [screenRecorderStatus])

  // componentWillUnmount
  useEffect(()=> {
    return () => {
      stop();
    }
  }, [])

  // if error show error dialog
  useEffect(()=> {
    if(hasError) {
      setIsDialogVisible("is-visible") 
      setTimeout(()=> location.reload(), 7000)
    } else {
      setIsDialogVisible("")
    }
  }, [hasError])

  const startChecking = () => {
    setIsDisabled(true);
    screenRecording();
  }

  const stop = () => {
    if (window.screen_record) {
      window.screen_record
        .getTracks()
        .forEach((track) => track.stop());
    }
    window.screen_record = undefined;
  }

  const checkAll = () => screenRecorderStatus;

  const buttonAction = (status) => {
    if(checkAll()) {
      props.buttonAction();
    }else {
      startChecking();
    }
  }

  return (
    <div className="o-container o-display--flex@lg-and-up js-proctoring-container">
      <div className="o-col--1/2@lg">
        <h1 className="o-margin--bottom-size-l">
          {props.title}
        </h1>
        <p dangerouslySetInnerHTML={{__html: props.description}}></p>
      </div>
      <div className="o-col--1/2@lg">
        <div className="c-proctoring">
          <div className="c-proctoring__illustration">
            <div className="c-proctoring__video-wrapper">
              <video
                className="c-proctoring__video"
                srcobject={videoSource}
                autoPlay={true}
              ></video>
            </div>
            <IllustrationMonitor
              className="c-proctoring__illustration-monitor"
              cameraOn={false}
              withoutSpeakers
            />
          </div>
          <div className="c-button__wrapper">
            <Button
              disabled={checkAll() ? false : isDisabled }
              name="finish"
              className="o-margin--right-auto o-margin--left-auto"
              type="button"
              text={checkAll() ? props.internalization.buttons.continue : props.internalization.buttons.check}
              onClick={() => buttonAction(true)}
            />
          </div>
        </div>
      </div>
      <Dialog 
        classNameDialog={isDialogVisible} 
        action={() => setIsDialogVisible("")}
      >

        <div className="c-dialog__text">
          {
            hasError &&
              <p className="o-font--semibold">               
                {props.internalization.proctoring.error_title}
              </p>
          }
          {
            !hasError &&
              <p className="o-font--semibold">                 
                {props.internalization.proctoring.screen_recording.success}
              </p>
          }
        </div>
        {
          hasError &&
            <Close 
              medium  
              background
              color="red"
              className="c-dialog__icon"
            />
        }
        {
          !hasError &&
            <Tick 
              medium 
              background 
              className="c-dialog__icon"
            />
        }
      </Dialog>
    </div>
  );
};

ScreenRecorder.propTypes = {
  buttonAction : PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default ScreenRecorder;
