import PropTypes from "prop-types";
import React from "react";

export default class Arrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        onClick={() => this.props.action && this.props.action()}
        width={this.props.width ? this.props.width : "17px"}
        height={this.props.height ? this.props.height : "32px"}
        viewBox="0 0 17 32"
        className={this.props.className}
      >
        <path
          className="icon-second-color-fill"
          // fill={this.props.color ? this.props.color : "#3C5896"}
          fillRule="evenodd"
          d="M16.56 14.94a1.5 1.5 0 0 1 0 2.12l-14 14a1.5 1.5 0 0 1-2.12-2.12L13.378 16 .439 3.06A1.5 1.5 0 0 1 2.561.94l14 14Z"
        />
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
