import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { motion } from "framer-motion";

const Eye = (props) => {
  let eyeClasses = classNames("c-eye", props.slashed && "c-eye--slashed");

  return (
    <svg
      width={props.width ? props.width : "28px"}
      height={props.height ? props.height : "27px"}
      viewBox={"0 0 22 16"}
      className={eyeClasses}
    >
      {!props.slashed ? (
        <g
          id="Styleguide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="illustration"
            className="filled"
            transform="translate(-559.000000, -841.000000)"
            fill={props.color ? props.color : "#121A22"}
          >
            <g id="Group-9" transform="translate(559.359670, 841.000000)">
              <path
                className="c-eye__path"
                fill="#3C5896"
                fillRule="evenodd"
                d="M5.301 11.577C3.778 10.268 2.691 8.773 2.18 8c.51-.773 1.598-2.268 3.121-3.577C6.874 3.072 8.816 2 11 2c2.184 0 4.126 1.072 5.699 2.423C18.222 5.732 19.309 7.227 19.82 8c-.51.773-1.598 2.268-3.121 3.577C15.126 12.928 13.184 14 11 14c-2.184 0-4.126-1.072-5.699-2.423ZM11 0C8.148 0 5.757 1.395 3.998 2.906 2.233 4.423 1.008 6.138.464 6.97a1.876 1.876 0 0 0 0 2.06c.544.832 1.769 2.547 3.534 4.064C5.758 14.605 8.148 16 11 16c2.852 0 5.243-1.395 7.002-2.906 1.765-1.517 2.99-3.232 3.534-4.064.411-.628.411-1.431 0-2.06-.544-.832-1.769-2.547-3.534-4.064C16.242 1.395 13.852 0 11 0ZM9 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
              />
            </g>
          </g>
        </g>
      ) : (
        <path
          fill="#3C5896"
          fillRule="evenodd"
          d="M18.707 1.707A1 1 0 0 0 17.293.293l-4.261 4.26a4 4 0 0 0-5.478 5.478l-4.261 4.262a1 1 0 1 0 1.414 1.414l4.261-4.26a4 4 0 0 0 5.478-5.478l4.261-4.262Zm-7.189 4.36a2 2 0 0 0-2.45 2.45l2.45-2.45Zm-1.036 3.865 2.45-2.45a2 2 0 0 1-2.45 2.45ZM14.765.821C13.63.32 12.367 0 11 0 8.148 0 5.757 1.395 3.998 2.906 2.233 4.423 1.008 6.138.464 6.97a1.876 1.876 0 0 0 0 2.06 20.304 20.304 0 0 0 2.748 3.344l1.414-1.414A18.315 18.315 0 0 1 2.18 8c.51-.773 1.598-2.268 3.121-3.577C6.874 3.072 8.816 2 11 2a7.06 7.06 0 0 1 2.22.367L14.764.82ZM11 14a7.06 7.06 0 0 1-2.22-.367L7.236 15.18C8.371 15.68 9.633 16 11 16c2.852 0 5.243-1.395 7.002-2.906 1.765-1.517 2.99-3.232 3.534-4.064.411-.628.411-1.431 0-2.06a20.303 20.303 0 0 0-2.748-3.344L17.374 5.04A18.312 18.312 0 0 1 19.82 8c-.51.773-1.598 2.268-3.121 3.577C15.126 12.928 13.184 14 11 14Z"
        />
      )}
    </svg>
  );
};

Eye.propTypes = {
  slashed: PropTypes.bool.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Eye;
