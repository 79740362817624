import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Dot = (props) => {

  const [dotClasses, setDotClasses] = useState("c-dot");
  const [dotIllustrationClasses, setDotIllustrationClasses] = useState("c-dot__illustration");

  useEffect(() => {

    setDotClasses(classNames(
      "c-dot",
      props.className
    ))

    setDotIllustrationClasses(classNames(
      "c-dot__illustration",
      props.illustrationClassName
    ))

  }, [props])

  return (
    <div className={dotClasses}>
      <svg
        width="16px"
        height="15px"
        viewBox="0 0 16 15"
        className={dotIllustrationClasses}
      >
        <g id="Image-recopilation" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M16,8.08826522 C16.0053723,11.7318355 13.2307345,14.427276 9.53996904,14.3644817 C9.43258175,14.3625529 9.31464942,14.3910568 9.21909841,14.3567663 C7.13742062,13.6120215 4.96621756,13.0432294 3.00161128,12.0640239 C0.55430022,10.8439282 -0.567348146,7.21385978 0.280559483,4.24752506 C1.04819565,1.56172876 3.28568185,-0.109500063 6.25830827,0.00558712293 C7.81187303,0.0655953391 9.43301216,0.214544304 10.8832785,0.721399416 C13.997725,1.8099056 15.9952577,4.87332503 16,8.08826522" id="Fill-12" fill={props.color ? props.color : "#76b0eb"}></path>
        </g>
      </svg>
    </div>
  );
};

Dot.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Dot;