// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Star = (props) => {
  return (
    <svg
      width={props.width ? props.width : "25px"}
      height={props.height ?  props.height: "24px"}
      viewBox="0 0 25 24"
      className={props.className}
    >
      <path
        d="M24.6383047,8.86072719 C24.3409586,7.95407228 23.5292984,7.36824186 22.5705602,7.36824186 L16.3878682,7.36824186 L14.4409085,1.48686021 C14.141629,0.58275343 13.3316605,0 12.3756773,0 C12.3726323,0 12.3694906,0 12.3664456,0 C11.4065474,0.00379816195 10.5969172,0.593763291 10.3038728,1.50306249 L8.41365626,7.36824186 L2.17948941,7.36824186 C1.21833452,7.36824186 0.406046014,7.95604348 0.110149892,8.86567923 C-0.185794563,9.77531497 0.126341529,10.7250478 0.90537676,11.2851565 L5.94948247,14.9118723 L4.00793604,20.7769555 C3.70744826,21.6846681 4.01436437,22.6357471 4.78982294,23.1998943 C5.17929222,23.4832661 5.6263264,23.625 6.07365057,23.625 C6.51691476,23.625 6.9604206,23.485718 7.34795656,23.207058 L12.4008106,19.5741401 L17.3936347,23.200952 C18.1682233,23.7636569 19.1713798,23.7663493 19.9491583,23.2080195 C20.7269369,22.6496897 21.0402813,21.7018319 20.7475269,20.7932058 L18.8519937,14.9118723 L23.8532278,11.2789544 C24.6275264,10.716538 24.9356992,9.76733403 24.6383047,8.86072719 Z"
        className={`${props.className}-path`}
        fill={props.color ? props.color : "#B2BEC3"}
      ></path>
    </svg>
  );
};

Star.propTypes = {
  className: PropTypes.string,
};

export default Star;
