import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleWare from 'redux-saga';
import promise from 'redux-promise';
import {routerMiddleware} from 'react-router-redux';
import logger from 'redux-logger';

import rootReducer from './packs/reducers';

/** Middleware imports */
import redirectMiddleware from './packs/middlewares/redirect';

/** Sagas */
import * as Authentication from './packs/containers/login/sagas';
import * as Passwords from './packs/containers/passwords/sagas';
import * as Surveys from './packs/containers/surveys/sagas';
import * as Users from './packs/containers/users/sagas';
import * as Admin from './packs/containers/admin/sagas'


const sagas = {
  ...Authentication,
  ...Passwords,
  ...Surveys,
  ...Users,
  ...Admin
};

export default function configureStore(history) {
  const sagaMiddleWare = createSagaMiddleWare();
  const redirectMW = redirectMiddleware(history);
  const routerMW = routerMiddleware(history);
  const middleWares = [promise, sagaMiddleWare, redirectMW, routerMW, logger];
  let store = createStore(rootReducer, compose(
    applyMiddleware(...middleWares),
    window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true}) : f => f)
  );

  // Register sagas
  for (let saga in sagas) {
    sagaMiddleWare.run(sagas[saga])
  };

  return store;
}
