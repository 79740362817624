import PropTypes from "prop-types";
import React from "react";

export default class Gear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        style={this.props.style}
        className={this.props.className}
        width={this.props.width ? this.props.width : "19px"}
        height={this.props.height ? this.props.height : "20px"}
        viewBox="0 0 19 20"
      >
        <path
          fill="#3C5896"
          fillRule="evenodd"
          d="M10.178 0c.897 0 1.668.62 1.837 1.476l.075.381c.295 1.497 1.889 2.392 3.37 1.891l.378-.127c.847-.286 1.785.053 2.234.808l.677 1.141a1.783 1.783 0 0 1-.396 2.285l-.302.254a2.455 2.455 0 0 0 0 3.782l.302.254c.678.57.845 1.53.396 2.285l-.677 1.14c-.45.756-1.387 1.095-2.234.809l-.377-.128c-1.482-.5-3.076.394-3.37 1.892l-.076.38c-.169.858-.94 1.477-1.837 1.477H8.822c-.897 0-1.668-.62-1.837-1.476l-.075-.381c-.295-1.498-1.889-2.392-3.37-1.892l-.378.128c-.847.286-1.785-.053-2.234-.809l-.677-1.14a1.783 1.783 0 0 1 .396-2.285l.302-.254a2.454 2.454 0 0 0 0-3.782l-.302-.254A1.783 1.783 0 0 1 .251 5.57l.677-1.14c.45-.756 1.387-1.095 2.234-.809l.377.127c1.482.5 3.076-.393 3.37-1.89l.076-.382C7.154.62 7.925 0 8.822 0h1.356ZM9.5 12.857c1.574 0 2.85-1.28 2.85-2.857A2.854 2.854 0 0 0 9.5 7.143 2.854 2.854 0 0 0 6.65 10a2.854 2.854 0 0 0 2.85 2.857Z"
        />
      </svg>
    );
  }

  static propTypes = {};
}
