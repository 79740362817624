import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import Dialog from "../../components/Dialog";
import Tick from "../../components/Tick";
import Close from "../../components/Close";
import Button from "../../components/Button";
import IllustrationMonitor from "../../components/IllustrationMonitor";

let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const CameraSpeakers = (props) => {

  //State
  const [isDisabled, setIsDisabled] = useState(false);
  const [cameraStatus, setCameraStatus] = useState(false);
  const [microphoneStatus, setMicrophoneStatus] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [isDialogVisible, setIsDialogVisible] = useState("");
  const [hasError, setHasError] = useState(false);

  // Check not IE11 and set attribute to script src
  useEffect(()=> {
    if (!isIE11) {
      const script = document.createElement("script");
      script.crossOrigin = "anonymous";
      script.setAttribute(
        "src",
        "https://webrtc.github.io/adapter/adapter-latest.js"
      );
      script.async = true;
      document.body.appendChild(script);
    }
  }, [])

  // componentWillUnmount
  useEffect(()=> {
    return () => {
      stop();
    }
  }, [])

  const cameraRecording = () => {
    var that = this;
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then((stream_video) => {
        window.camera = stream_video;
        let video = document.querySelector("video");
        setCameraStatus(true);
        setHasError(false);
        video.srcObject = stream_video;
        video.onloadedmetadata = (e) => {
          video.play();
        };
      })
      .catch((err) =>  {
        props.trackError && props.trackError({name: "Proctoring Error", "error_value": err.toString(), type: "cameraRecording"})
        setHasError(true);
      });
  }

  const audioRecording = () => {
    var that = this;
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function (stream) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        window.audio_record = stream;
        let audioContext = new AudioContext();
        let analyser = audioContext.createAnalyser();
        let microphone = audioContext.createMediaStreamSource(stream);
        let javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
        setMicrophoneStatus(true);  
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = function () {
          let array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;

          let length = array.length;
          for (var i = 0; i < length; i++) {
            values += array[i];
          }
          var average = values / length;
          colorPids(average);
        };
      })
      .catch(function (err) {
        props.trackError && props.trackError({name: "Proctoring Error", "error_value": err.toString(), type: "audioRecording"})
        setHasError(true);
      });
  }

  const startChecking = () => {
    setIsDisabled(true);
    cameraRecording();
    audioRecording();
  }

  // if cam and mic is ok show dialog
  useEffect(()=> {
    microphoneStatus && cameraStatus  && setIsDialogVisible("is-visible");
  }, [microphoneStatus, cameraStatus])

  // if error show dialog
  useEffect(()=> {
    if(hasError) {
      setIsDialogVisible("is-visible") 
      setTimeout(()=> location.reload(), 7000)
    } else {
      setIsDialogVisible("")
    }
  }, [hasError])

  const stop = () => {
    if (window.camera) {
      window.camera
        .getTracks()
        .forEach((track) => track.stop());
    }
    if (window.audio_record) {
      window.audio_record
        .getTracks()
        .forEach((track) => track.stop());
    }
    window.audio_record = undefined;
    window.camera = undefined;
  }
  const colorPids = (vol) => {
    let volumeNormalized = ((Math.trunc(vol) / 100) * 1.2);
    let volume = Math.min(volumeNormalized, 1);
    $(".c-monitor__speaker").css("transform", `scaleY(${volume})`);
  }

  const checkAll = () =>{
    return  (microphoneStatus && cameraStatus);
  }

  const buttonAction = (e) => {
    if(checkAll()) {
      props.buttonAction();
    }else {
      startChecking();
    }
  }

  return (
    <div className="o-container o-display--flex@lg-and-up js-proctoring-container">
      <div className="o-col--1/2@lg">
        <h1 className="o-margin--bottom-size-l">
          {props.title}
        </h1>
        <p dangerouslySetInnerHTML={{__html: props.description}}>
        </p>
      </div>
      <div className="o-col--1/2@lg">
        <div className="c-proctoring">
          <div className="c-proctoring__illustration">
            <div className="c-proctoring__video-wrapper">
              <video
                className="c-proctoring__video is-reverse"
                srcobject={videoSource}
                autoPlay={true}
              ></video>
            </div>
            <IllustrationMonitor
              className="c-proctoring__illustration-monitor"
              cameraOn={cameraStatus}
            />
          </div>
          <div className="c-button__wrapper">
            <Button
              disabled={checkAll() ? false : isDisabled }
              name="finish"
              className="o-margin--right-auto o-margin--left-auto"
              type="button"
              text={checkAll() ? props.internalization.buttons.continue : props.internalization.buttons.check}
              onClick={() => buttonAction()}
            />
          </div>
        </div>
      </div>
      <Dialog 
        classNameDialog={isDialogVisible} 
        action={() => setIsDialogVisible("")}
      >

        <div className="c-dialog__text">
          {
            hasError &&
              <p className="o-font--semibold">               
                {props.internalization.proctoring.error_title}
              </p>
          }
          {
            !hasError &&
              <p className="o-font--semibold">                 
                {props.internalization.proctoring.camera_speakers.success}
              </p>
          }
        </div>
        {
          hasError &&
            <Close 
              medium  
              background
              color="red"
              className="c-dialog__icon"
            />
        }
        {
          !hasError &&
            <Tick 
              medium 
              background 
              className="c-dialog__icon"
            />
        }
      </Dialog>
    </div>
  )
}

CameraSpeakers.propTypes = {
  buttonAction : PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default CameraSpeakers;
