import PropTypes from "prop-types";
import React from "react";
import {motion} from "framer-motion";

// Components
import IllustrationMessageBox from "./IllustrationMessageBox";

const ServerError = (props) => {
  return (
    <motion.div
      className="c-server-error"
      initial={{ opacity: 0, left: -100 }}
      animate={{ left: 0, opacity: 1 }}
      exit={{ opacity: 0, left: -100 }}
    >
      <IllustrationMessageBox className="c-server-error__box" />
      <h6 className="c-server-error__text">{props.message}</h6>
    </motion.div>
  );
};

ServerError.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ServerError;
