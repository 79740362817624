const roles = {
  user: {
    superAdmin: 1,
    admin: 2,
    user: 3
  },
  organization: {
    clientAdmin: 1,
    client: 2,
    user: 3
  },
  admin: {
   ADMIN_CREATOR: 0,
   ADMIN_MANAGEMENT: 1,
   READER:2
  }
};

export default roles;
