import {useState} from 'react'

export const usePagination = (pagination, items) => {

  const itemsForPage = items ? items : 8;

  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [paginationManage, setPaginationManage] = useState({
    currentPagination: 0,
    paginationStart: 0,
    paginationEnd: itemsForPage,
  });

  const checkFirst = () => 
    paginationManage.currentPagination === 0 ? true : false;

  const checkLast = () => 
    Math.ceil(pagination.length / itemsForPage) - 1 === paginationManage.currentPagination ? true : false;

  const goToPreviousPage = () => {
    let { currentPagination, paginationStart } = paginationManage;

    if (currentPagination > 0) {
      let newStart = paginationStart - itemsForPage;

      setPaginationManage({
        currentPagination: currentPagination - 1,
        paginationStart: newStart,
        paginationEnd: newStart + itemsForPage,
      });
    }
  };

  const goToNextPage = () => {
    
    let { currentPagination, paginationEnd } = paginationManage;
  
    if (currentPagination < Math.ceil(pagination.length / itemsForPage) - 1) {
      let newEnd = paginationEnd + itemsForPage;

      setPaginationManage({
        currentPagination: currentPagination + 1,
        paginationStart: paginationEnd,
        paginationEnd: newEnd,
      });
    }
  };

  const goToFirstPage = () => {
    setPaginationManage({
      currentPagination: 0,
      paginationStart: 0,
      paginationEnd: itemsForPage,
    });
  };

  const goToLastPage = () => {
    goToPage((Math.ceil(pagination.length / itemsForPage) - 1))
  };

  const goToPage = (index) => {
    let current = itemsForPage * index;

    setPaginationManage({
      currentPagination: index,
      paginationStart: current,
      paginationEnd: current + itemsForPage,
    });
  };

  const reset = () => {
    setPaginationManage({
      currentPagination: 0,
      paginationStart: 0,
      paginationEnd: itemsForPage,
    });
  }

  return {
    goToPreviousPage,
    goToNextPage,
    goToFirstPage,
    goToLastPage,
    goToPage,
    paginationManage,
    itemsForPage,
    checkFirst,
    checkLast,
    reset
  }
}
