// Dependencies
import React, { useState, useEffect } from "react";
import classNames from "classnames";

const PaginationNew = (props) => {

  const ALL_PAGES = props.total_pages

  const renderPages = () => {
    let pages = [];

    for ( let i = 1; i <= ALL_PAGES; i++){
      pages.push(i);
    }

    return pages;
  };

  const isSelect = (index) => {

    let currentPage =  props.current_page;

    if(currentPage < ALL_PAGES){
      currentPage++
    }
    
    let classNameIndicatorText = classNames(
      "c-pagination__indicator-text",
      props.current_page === index  && "is-select"
    );

    return classNameIndicatorText;
  };

  const isFirst = () => {
    let firstClass = classNames(
      "c-pagination__indicator-text",
      props.isFirst() && "is-selected"
    );
    return firstClass;
  };
  const isLast = () => {
    let lastClass = classNames(
      "c-pagination__indicator-text",
      props.isLast() && "is-selected"
    );
    return lastClass;
  };

  const classPagination = () => {
    let classPagination = classNames(
      "c-pagination",
      props.marginTop && "o-margin--top-size-mega"
    );
    return classPagination;
  }

  return (
    <div className={classPagination()}>
      <div
        className="c-pagination__indicator"
        onClick={() => props.goToFirstPage()}
      >
        <h4 className={isFirst()}>{"<<"}</h4>
      </div>
      <div
        className="c-pagination__indicator"
        onClick={() => props.goToPreviousPage()}
      >
        <h4 className={isFirst()}>{"<"}</h4>
      </div>
      {renderPages()
        .slice(
          props.current_page > 4
            ? props.current_page - 4
            : 0,
          props.current_page > 4
            ? props.current_page + 3
            : props.current_page + 5
        )
        .map((item, index) => {
          return (
            <div
              key={"pagination-" + index}
              className={"c-pagination__indicator"}
              onClick={(e) => {props.goToPage(item-1)}}
            >
              <h4 className={isSelect(item)}>{item}</h4>
            </div>
          );
        })}

      <div
        className="c-pagination__indicator"
        onClick={() => props.goToNextPage()}
      >
        <h4 className={isLast()}>{">"}</h4>
      </div>
      <div
        className="c-pagination__indicator"
        onClick={() => props.goToLastPage()}
      >
        <h4 className={isLast()}>{">>"}</h4>
      </div>
    </div>
  );
};

export default PaginationNew;
