import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Actions
import { fetchResetPassword } from "./actions";
import { redirectTo } from "../../actions";

// Selectors
import { isAuthenticated } from "../../selector";

//Components
import Input from "../../components/Input";
import Button from "../../components/Button";
import IllustrationHandDrops from "../../components/IllustrationHandDrops";
import Modal from "../../components/Modal";

// Config
import routes from "../../config/routes";

import internalization from "../../config/localize"

var languageUserAgent = /^es/.test(navigator.language || navigator.userLanguage || "en") ? "es" : "en";
internalization.setLanguage(languageUserAgent)

let timeoutPassword = null;

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        password: "",
        password_confirmation: "",       
        reset_password_token: null,
      },
      validations: {
        password: {
          errorLabel: null,
          min: 8,
        },
      },
      showModal: false,
      modalData: {title: "", action: ""},      
      error_object:{},
      isSubmitButtonDisabled: true,
    };
  }

  componentDidMount = () => {
    if (
      this.props.location.search.length &&
      new URLSearchParams(this.props.location.search).get("reset_token")
    )
      this.setState({
        data: {
          ...this.state.data,
          reset_password_token: new URLSearchParams(
            this.props.location.search
          ).get("reset_token"),
        },
      });
    else this.props.redirectTo(routes.client.home);
  };

  _setValues = (value, type, state) => {
    this.setState({
      data: { ...state.data, [type]: value },
    });
  };

  _validate = (type, value, state) => {
    clearTimeout(timeoutPassword);
    if (value && value.length > state.validations.password.min) {
      this.setState({ [type]: value }, () => {
        if (
          this.state.data.password === this.state.data.password_confirmation
        ) {
          this.setState({
            isSubmitButtonDisabled: false,
            error: null,
          });
        } else {
          timeoutPassword = setTimeout(() => {
            this.setState({
              isSubmitButtonDisabled: true,
              error: internalization.form.password_mismatch,
            });
          }, 1000);
        }
      });
    } else {
      timeoutPassword = setTimeout(() => {
        this.setState({
          isSubmitButtonDisabled: true,
          error:  internalization.form.password_length,
        });
      }, 1000);
    }
  };

  _checkPasswordValidation = (state, type, value) => {
    let error_object = state.error_object
    if(value.length === 0 || value.length < 8){
      error_object[type+"_show_error"] = true
      error_object[type+"_error_label"] = internalization.form.password_length
    }
    else{
      delete error_object[type+"_show_error"]
      delete error_object[type+"_error_label"]
    }

    if( (type === "password" && state.data.password_confirmation !== value) || (type === "password_confirmation" && state.data.password !== value)) {

      error_object["password_confirmation_show_error"] = true
      error_object["password_confirmation_error_label"] =  internalization.form.password_mismatch
    }
    else{
      delete error_object["password_confirmation_show_error"]
      delete error_object["password_confirmation_error_label"]

    }

    this.setState({error_object: error_object, isSubmitButtonDisabled: Object.keys(error_object).length !== 0 })    
  }

  _submit = (data) => {
    this.setState({ isSubmitButtonDisabled: true });
    this.props.fetchResetPassword(data, () => this._success(), () => this._error());
  };

  _success(){
    this.props.history.replace(routes.client.home);
  }
  _error(){
    this.setState({showModal: true, modalData: {title:  internalization.errors.unexpected, action: () => this.setState({showModal: false})}})
  }

  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container o-display--flex@lg-and-up o-display--flex-space-between@md-only o-display--flex-row-reverse@lg-and-up">
          <div className="o-col--1/2@lg">
            <h1 className="o-margin--bottom-size-l">{internalization.signin.change_password}</h1>
            <form>
              <Input
                className="c-input--white-bg"
                label={internalization.form.password}
                isRequired={true}
                errorLabel={this.state.error_object["password_error_label"]}
                showError={this.state.error_object["password_show_error"]}              
                hintLabel={internalization.form.password_length}
                showHint={this.state.error_object["password_show_error"] ? false : true}  
                type="password"
                value={this.state.data.password}
                onChange={(e) => {
                  this._setValues(e.currentTarget.value, "password", this.state)
                  this._checkPasswordValidation(this.state, "password", e.currentTarget.value)
                }
                }
              />
              <Input
                className="c-input--white-bg"
                label={internalization.form.password_confirmation}
                errorLabel={internalization.form.empty}
                errorLabel={this.state.error_object["password_confirmation_error_label"]}
                showError={this.state.error_object["password_confirmation_show_error"]}
                hintLabel={internalization.form.password_length}
                showHint={this.state.error_object["password_confirmation_show_error"] ? false : true} 
                isRequired={true}
                minCharacters={8}
                type="password"
                value={this.state.data.password_confirmation}
                onChange={(e) =>{
                  this._setValues(
                    e.currentTarget.value,
                    "password_confirmation",
                    this.state
                  )
                  this._checkPasswordValidation(this.state, "password_confirmation", e.currentTarget.value)
                }
                }
              />
              {(this.props.errors && this.props.errors.length) ? (
                <ServerError message={this.props.errors} />
              ) : ([])}
              <div className="c-button__wrapper">
                <Button
                  name="login"
                  type="submit"
                  text={internalization.buttons.send}
                  disabled={this.state.isSubmitButtonDisabled}
                  onClick={() => this._submit(this.state.data)}
                />
              </div>
            </form>
          </div>
          <div className="o-col--1/2@lg o-display--flex o-display--flex-h-right@md-only u-hide@sm-and-down o-margin--top-size-xxl@md-only">
            <IllustrationHandDrops />
          </div>
        </div>
        <Modal isVisible={this.state.showModal}>
          <p>{this.state.modalData.title}</p>
          <div className="c-modal__buttons-wrapper">
            <button 
              type="button" 
              className="c-button c-modal__button"
              onClick={() =>
                {this.state.modalData.action()}
              }
            >
              <span
                className="c-button__text"
              >
                {internalization.buttons.accept}
              </span>
            </button>
          </div>
        </Modal>        
      </motion.section>
    );
  }

  static propTypes = {};
}

const mapStateToProps = (state) => {
  return {
    authenticated: isAuthenticated(state),
    errors: state.global.errors && state.global.errors.resetPassword,
  };
};

const mapDispatchToProps = {
  fetchResetPassword,
  redirectTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
