import React, { useEffect, useState } from "react";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Multiselect from "../Multiselect";

const ModalDeleteModules = (props) => {
  const [option, setOption] = useState(undefined)
  return (
    <Modal isVisible={props.showModalInfoAction} small>
      <h2 className="o-text-align--left o-margin--bottom-size-l">
      Delete answers by module
      </h2>
      <Multiselect
        label={"Module list:"}
        defaultValue="Select module to remove"
        multiple={false}
        options={props.options}
        getOption={(option) => setOption(option)}
      />
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModalInfoAction()}
        >
          <span className="c-button__text">Cancel</span>
        </button>

        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.deleteAnswerModule(option)}
          disabled={option ? false : true}
        >
          <span className="c-button__text">Confirm</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalDeleteModules;
