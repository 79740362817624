export const highchartResize = (Highcharts) => {
  const getBody = document.querySelector(".s-admin");
  const callback = (mutations) => {
    resizeCharts();
  };

  const resizeCharts = () => {
    let getContainerChart = document.querySelector(".c-charts__chart-wrapper");

    setTimeout(() => {
      Highcharts.charts
        .filter((item) => item)
        .map((item, index) => {
          item.renderTo.style.height = getContainerChart.clientHeight + "px";
          item.reflow();
        });
    }, 500);
  };

  const config = {
    attributes: true,
  };

  const observer = new MutationObserver(callback);
  if (getBody) {
    observer.observe(getBody, config);
  }
};

export default highchartResize;
