import PropTypes from "prop-types";
import React from "react";

export default class IllustrationWebPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        className="c-illustration c-illustration--warning"
        width={this.props.width ? this.props.width : "338px" }
        height={this.props.height ? this.props.height : "299px"}
        viewBox="0 0 338 299"
      >
          <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Illustration" transform="translate(-797.000000, -1208.000000)" fill="#121921">
              <g id="Warning" transform="translate(797.000000, 1208.000000)">
                <path d="M173.419,15.895 C115.535,87.06 62.568,162.253 7.91,236.039 L7.91,236.039 C4.921,240.067 3.978,245.811 2.743,250.908 L2.743,250.908 C-4.047,279.009 6.745,294.779 35.99,295.354 L35.99,295.354 C108.314,296.772 180.647,298.111 252.979,298.304475 L252.979,298.304475 C275.115,298.353 297.251,294.596 311.586,293.289 L311.586,293.289 C348.894,274.821 338.736,254.102 327.679,233.223 L327.679,233.223 C292.027,165.872 254.413,99.453 221.586,30.756 L221.586,30.756 C212.313,11.35 204.716,0.172 195.379,0.172 L195.379,0.172 C189.165,0.172 182.181,5.124 173.419,15.895 L173.419,15.895 Z M21.602,269.895 C15.357,257.6 53.117,215.835 96.578,157.477 L96.578,157.477 C135.984,104.562 180.339,34 196,34 L196,34 C213.535,34 237.389,111.05 266,172.207 L266,172.207 C291.126,225.914 340,259.863 314,269.895 L314,269.895 C288.142,279.87 198.784,272.785 121.087,269.895 L121.087,269.895 C105.088,269.3 87.221,270.381 71.077,271.462 L71.077,271.462 C58.112,272.33 46.262,273.198 37.381,273.198 L37.381,273.198 C28.699,273.198 22.857,272.367 21.602,269.895 L21.602,269.895 Z" id="Clip-2"></path>
                <path d="M194.973,222.72 C187.146,222.733 179.686,227.726 177.035,235.513 L177.035,235.513 C175.801,239.139 175.438,243.191 175.293,247.075 L175.293,247.075 C175.284,247.317 175.279,247.557 175.279,247.793 L175.279,247.793 C175.279,254.867 179.295,260.162 185.621,262.02 L185.621,262.02 C192.844,264.139 201.685,261.334 204.656,255.216 L204.656,255.216 C207.039,250.306 210.566,247.269 210.718,238.042 L210.718,238.042 C210.871,228.828 203.863,222.721 195.005,222.72 L195.005,222.72 C194.994,222.72 194.984,222.72 194.973,222.72 L194.973,222.72 Z" id="Clip-8"></path>
                <path d="M180,76.172 L180,93.589 C182.451,110.422 184.066,127.141 184.847,143.746 L184.847,143.746 C186.019,168.655 180,192.586 184.847,196.595 L184.847,196.595 C189.695,200.605 201.214,214.279 205.22,204.608 L205.22,204.608 C209.226,194.936 205.22,177.453 205.22,169.235 L205.22,169.235 C205.22,163.756 203.592,151.351 200.34,132.019 L200.34,132.019 L200.34,98.504 L200.34,76.172 C196.482,68.057 192.935,64 189.695,64 L189.695,64 C186.455,64 183.224,68.057 180,76.172 L180,76.172 Z" id="Clip-5"></path>
              </g>
            </g>
          </g>
        </svg>
    );
  }

  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
  };

}
