// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Less = (props) => {
  let classNameLess = classNames("c-less", props.className && props.className);

  return (
    <svg className={classNameLess} width="24px" height="5px" viewBox="0 0 24 5">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M11.3685999,-9.76 C10.8310491,-9.76 10.2948491,-9.08384662 9.76,-7.73153986 L9.76,-4.82849582 C10.1665413,-2.02296869 10.4346413,0.763570568 10.5642999,3.53112196 C10.758788,7.68244904 9.76,11.6708968 10.5642999,12.3392034 C11.3685999,13.00751 13.2799399,15.2865352 13.9445969,13.6746196 C14.6092539,12.062704 13.9445969,9.14888724 13.9445969,7.77915797 C13.9445969,6.86600511 13.6746927,4.79844406 13.1348844,1.57647479 C13.1348844,-1.60133969 13.1348844,-3.46328841 13.1348844,-4.00937137 C13.1348844,-4.55545434 13.1348844,-5.79617717 13.1348844,-7.73153986 C12.4949122,-9.08384662 11.9061507,-9.76 11.3685999,-9.76 Z"
          id="Path-8"
          fill="#FC5C65"
          transform="translate(12.000000, 2.240000) rotate(-270.000000) translate(-12.000000, -2.240000) "
        ></path>
      </g>
    </svg>
  );
};

Less.propTypes = {
  className: PropTypes.string,
};

export default Less;
