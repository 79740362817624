import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

// Selectors
import { isAuthenticated } from "../selector";

// Config
import routes from "../config/routes";

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getToken() {
    let token = this.props.token;

    if (token == undefined || token == null) {
      let localToken = localStorage.getItem('client')
      if(localToken) { 
        token = JSON.parse(localToken).token
      }
      else{
        token = undefined
      }
    }
    
    return token
  }

  render = () => {
    console.log("authroute")
    const unauthenticatedPreviousPath = this.props.location.pathname.includes("admin")
      ? routes.admin.signin
      : routes.client.signin;

    return this.getToken() !== undefined ? (
      <Route {...this.props} key={this.props.location.key} />
    ) : (
      <Redirect
        to={{
          pathname: unauthenticatedPreviousPath,
          state: { ...this.props.location.state, from: this.props.location },
        }}
      />
    );
  };

  static propTypes = {
    component: PropTypes.object,
    location: PropTypes.object,
    exact: PropTypes.bool,
    path: PropTypes.string,
  };
}

const mapStateToProps = (state) => {
  return {
    authenticated: isAuthenticated(state),
    token: state.client.token
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
