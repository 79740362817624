import React from "react";

const EmailShow = (props) => {
  return (
    <React.Fragment>
      <div className="c-email__template">
        <p className="c-email__template-title">
          <span className="c-email__template-title-label">Subject:</span>
          {props.email.name}
        </p>
        <div dangerouslySetInnerHTML={{ __html: props.email.body }}></div>
      </div>
      {
        props.email.label && props.email.label.length > 0 && 
        <React.Fragment>
          <div className="c-tags">
            <p className="c-tags__title">Tags:</p>
            <div className="c-tags__box">
              {props.email.label && props.email.label.map((tag, index) => {
                return (
                  <div className="c-tags__box-item">
                    <p key={`tag-${index}`} className="c-tags__box-item-text o-margin--zero">{tag}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </React.Fragment>  
      }
    
      <div className="c-tags">
          <p className="c-tags__title">Language:</p>
          <div className="c-tags__box">
            <div className="c-tags__box-item">
              <p  className="c-tags__box-item-text o-margin--zero">{props.email.language}</p>
            </div>
          </div>
        </div>
      <div className="c-button__wrapper c-button__wrapper-has-separation">
        {props.canManage && props.email.type_mail !== "invitation" && (
          <button
            type="button"
            className={"c-button c-button--cancel"}
            onClick={() => props.delete()}
          >
            <span className="c-button__text">Delete</span>
          </button>
        )}
        {props.canManage && 
          <button
            type="button"
            className={"c-button "}
            onClick={() => props.edit()}
            >
            <span className="c-button__text">Edit</span>
          </button>
        }
        {props.canManage && 
          <button
            type="button"
            className={"c-button "}
            onClick={() => props.sendTestModal()}
            >
            <span className="c-button__text">Send test</span>
          </button>
        }
        
      </div>
    </React.Fragment>
  );
};

export default EmailShow;
