import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

// Components
import Chevron from "./Chevron";
import Tick from "./Tick";

class Select extends React.Component {

  constructor(props) {
    super(props);
    this.selectInput = React.createRef();

    this.state = {
      open: false,
      valueSelected: "",
      orientation: "down",
      showError: false,
      optionsList: [],
      zIndex: 0,
    };
  }

  componentDidMount = () => {
    if (this.props.options && this.props.options.length > 0) {
      this._addHasSelectClass();
      this._setZindex();
      this._closeSelects();
      this.setState({
        optionsList: this.props.options,
        valueSelected: this.props.defaultValue || "-1",
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.options !== this.props.options) {
      this.setState({
        optionsList: this.props.options,

      });
    }

    if ((prevState.valueSelected !== this.props.valueSelected && this.props.valueSelected !== "")) {
      this.setState({
        valueSelected: this.props.valueSelected,
      });
    }
  }

  _addHasSelectClass = () => {
    let wrapper = document.querySelector(".o-wrapper");
    wrapper.classList.add("has-selects");
  };

  _setZindex = () => {
    let allSelects = [
      ...document.querySelectorAll(".o-wrapper .c-select__wrapper"),
    ];
    allSelects.forEach((select, index) => {
      select.style.zIndex = 1000 + allSelects.length - index;
    });
  };

  _closeSelects = () => {
    let hasSelect = document.querySelector(".has-selects");
    hasSelect.addEventListener("click", (e) => {
      this._clickOutsideElement(this.state);
    });
  };

  _clickOutsideElement = (state) => {
    this.setState(
      {
        open: false,
        orientation: "down",
      },
      () => {
        !this.props.withoutInput && this._inputFocus(this.state.open);
        !this.props.withoutInput && this._filteredOptionsList(this.state);
      }
    );
  };

  _onClickOptionHandler = (event, text, value, option) => {
    event.stopPropagation();
    this.setState(
      {
        orientation: "down",
        open: false
      },
      () => {
        this.setState({
          valueSelected: value || "-1",
          showError:
            this.state.valueSelected === "-1" || this.state.valueSelected === null
              ? true
              : false,
        });
        this.props.optionClick(text, value, option);
      }
    );
  };

  _handleSelect = (event, state) => {
    event.stopPropagation();
    this.setState(
      {
        open: !state.open,
        orientation: state.orientation === "down" ? "up" : "down",
      },
      () => !this.props.withoutInput && this._inputFocus(this.state.open)
    );
  };

  _inputFocus = (isOpen) => {
    if (isOpen) {
      this.selectInput.current.focus();
    } else {
      this.selectInput.current.blur();
    }
  };

  _onChangeHandler = (e, state) => {
    this.setState({ valueSelected: e.currentTarget.value }, () => {
      this._filteredOptionsList(this.state);
    });
  };

  _filteredOptionsList = (state) => {
    if (state.valueSelected.length > 0) {
      this.setState(
        {
          optionsList: this.props.options.filter((option) =>
            option.value
              .toLowerCase()
              .includes(state.valueSelected.toLowerCase())
          ),
        },
        () => this._renderOptionsList(this.state)
      );
    } else {
      this.setState({ optionsList: this.props.options }, () =>
        this._renderOptionsList(this.state)
      );
    }
  };

  _renderOptionsList = (state) => {
    return state.optionsList.map((option, index) => {

      return (
        <div
          data-cursor-hover
          key={"option-select" + index}
          data-cursor-hover
          className={
            option.value === this.state.valueSelected && this.props.withoutInput
              ? "c-select__option-item  c-select__option-item-selected"
              : "c-select__option-item"
          }
          onClick={(event) => {
            this._onClickOptionHandler(event, option.text, option.value, option);
          }}
        >
          {option.value === this.state.valueSelected &&
          this.props.withoutInput ? (
            <p>
              {option.text}
              {this.props.tick && (
                <Tick className="c-select__option-tick c-tick--bare" />
              )}
            </p>
          ) : (
            <div className="c-select__option-item__text">
              {this.props.dot && (
                <span className="c-select__option-item__text-dot"></span>
              )}
              <p>{option.text}</p>
            </div>
          )}
        </div>
      );
    });
  };

  _getItemTextFromValue(state){
    let value = state.optionsList.filter((item) => { return item.value == state.valueSelected })
    if (value && value.length > 0){
      return value[0].text
    }
    else{
      return ""
    }

  }

  render() {
    let selectClasses = classNames(
      "c-select",
      this.state.open && "c-select--open",
      this.props.className && this.props.className,
      this.props.dark && "c-select--grey-dark-bg",
      this.props.withoutPaddingLeft && "c-select--padding-left-zero",
      this.props.disabled && "o-disabled"
    );

    let wrapperSelectClasses = classNames(
      "c-select__wrapper",
      this.props.wrapperClassName && this.props.wrapperClassName
    );

    let labelClasses = classNames(
      "c-select__label",
      this.props.disabled && "o-disabled"
    );

    let chevronClasses = classNames(
      "c-select__chevron",
      this.props.disabled && "c-select__chevron--disabled"
    )

    return (
      <div
        className={wrapperSelectClasses}
        style={this.props.style ? this.props.style : {}}
      >
        <label className={labelClasses}>
          {
            this.props.isRequired &&
              <span class="o-color--red">*{" "}</span>
          }
          {this.props.label}
        </label>
        <div
          data-cursor-hover
          className={selectClasses}
          onClick={(event) => {
            !this.props.disabled ? this._handleSelect(event, this.state) : {}
          }}
        >
          {!this.props.withoutInput && (
            <input
              ref={this.selectInput}
              className="c-select__value"
              onChange={(e) => {
                this._onChangeHandler(e, this.state);
              }}
              type="text"
              value={this._getItemTextFromValue(this.state)}
            />
          )}
          {this.props.withoutInput && (
            <div className="c-select__value">{this._getItemTextFromValue(this.state)}</div>
          )}

          <Chevron
            orientation={this.state.orientation}
            className={chevronClasses}
            dark={this.props.dark}
            color={this.props.disabled && '#6D6D6D'}
          />

          <div className="c-select__option-wrapper">
            <div className="c-select__option">
              {this._renderOptionsList(this.state)}
            </div>
          </div>
        </div>

        {this.props.error && this.state.showError && (
          <span className="c-select__error">{this.props.errorLabel}</span>
        )}
      </div>
    );
  }

  static propTypes = {
    label: PropTypes.string,
    isRequired: PropTypes.bool,
    errorLabel: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    optionClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    valueSelected: PropTypes.string,
    disabled: PropTypes.bool,
  };
}
export default React.memo(Select)
