// Dependencies
import React from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

// Components


const Dialog = (props) => {

  return (
    <div 
      className={`c-dialog ${props.classNameDialog}`} 
      onClick={()=> props.action ? props.action() : {}}
    >
      {props.children}
    </div>
  );
};

export default Dialog;
