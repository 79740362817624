import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

// Components
import LinkAnchor from "../components/LinkAnchor";
import IllustrationProcedure from "../components/IllustrationProcedure";

const ProcedureNotPassed = (props) => {
  const [title, setTitle] = React.useState(
    "Ups, parece que tenemos un problema"
  );
  const [code, setCode] = React.useState("404");

  React.useEffect(() => {
    props.title && setTitle(props.title);
    props.subtitle && setSubtitle(props.subtitle);
  }, []);

  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <div className="o-container o-display--flex@lg-and-up o-display--flex-row-reverse@lg-and-up">
          <div className="o-col--1/2@lg o-display--flex o-display--flex-column-direction o-display--flex-h-center o-text-align--center ">
            <h2 className="o-margin--bottom-size-l">Gracias</h2>
            <h1 className="o-margin--bottom-size-l">Estamos procesando su solicitud</h1>
            <div className="c-button__wrapper">
              <LinkAnchor href="/" className="c-button">
                <span className="c-button__text">Aceptar</span>
              </LinkAnchor>
            </div>
          </div>
          <div className="o-col--1/2@lg o-display--flex o-display--flex-h-right@sm-only o-display--flex-h-right@md-only u-hide@sm-and-down o-margin--top-size-xxl@md-only">
            <IllustrationProcedure width={400} height={350} />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

ProcedureNotPassed.propTypes = {
  title: PropTypes.string,
  code: PropTypes.string,
};

export default ProcedureNotPassed;
