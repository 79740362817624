// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Download = (props) => {

  let classNameDownload = classNames(
    "c-download", 
    props.className && props.className
  );

  return (
    <svg
      className={classNameDownload}
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : "15px"}
      viewBox="0 0 20 15"
    >
      <title>download</title>
      <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    >
      <path
        className="icon-secondary-color-stroke" 
        stroke={props.secondaryColor ? props.secondaryColor : "#3C5896"} 
        d="M10 9V1M7 6l2.913 2.913a.123.123 0 0 0 .174 0L13 6"
      />
      <path
      className="icon-first-color-stroke"
      stroke={props.firstColor ? props.firstColor : "#FDFDFD"}
        
        d="M1 8v4a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V8" />
    </g>
    </svg>
  );
};

Download.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Download;
