// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Envelope = (props) => {
  let classNameEnvelope = classNames(
    "c-envelope",
    props.envelopClassName && props.envelopClassName
  );

  return (
    <svg
      className={classNameEnvelope}
      width={props.width ? props.width : "24px"}
      height={props.height ? props.height : "17px"}
      viewBox="0 0 24 17"
    >
      <g fill="none" fillRule="nonzero">
      <path
        className="icon-first-color-fill"
        fill={props.firstColor ? props.firstColor : "#FDFDFD"}
        d="M0 2.902V15.3c0 .942.892 1.7 2 1.7h20c1.108 0 2-.758 2-1.7V2.902l-9.879 8.397c-1.163.989-3.08.989-4.242 0L0 2.902Z"
      />
      <path
        className="icon-secondary-color-fill"
        fill={props.secondaryColor ? props.secondaryColor : "#A9C44B"}
        d="M.324.774C.68.308 1.296 0 2 0h20c.704 0 1.32.308 1.676.774l-10.969 9.323a1.1 1.1 0 0 1-1.414 0L.324.774Z"
      />
    </g>
    </svg>
  );
};

Envelope.propTypes = {
  className: PropTypes.string,
};

export default Envelope;
