import React, { useRef, useState } from "react";
import classNames from "classnames";

//Components
import Camera from "./Camera";

const UploadFileButton = (props) => {
  const [file, setFile] = useState("")
  const inputRef = useRef(null);
  let fileResult = [];

  const handleClickUploadFile = (e) => {
    e.preventDefault();

    inputRef.current.click();
  };
  

  const handleUploadFile = (e) => {
    const fr = new FileReader();
    let fileIntermediate;
    if (e.target.files[0] !== undefined) {
      fileIntermediate = {
        original_filename: e.target.files[0].name,
        content_type: e.target.files[0].type,
      };
    }
    
    fr.addEventListener("load", () => {
      fileIntermediate.data = fr.result.split("base64,")[1];
      fileResult.push(fileIntermediate);
      setFile(fileIntermediate)
      props.getFile(fileIntermediate);
    });

    if (e.target.files[0] !== undefined) {
      fr.readAsDataURL(e.target.files[0]);
    }
  };

  let classNameUploadButton = classNames(
    "c-upload-button",
    props.classNameUploadButton && props.classNameUploadButton
  );

  return (
    <div className={classNameUploadButton}>
      <input
        className="c-upload-button__input"
        ref={inputRef}
        type="file"
        accept={props.image ? "image/*" : ".xlsx,.xls,.pdf"}
        onChange={(e) => handleUploadFile(e)}
      />
      {props.image && (
        <div className="c-upload-button__wrapper">
          <p className="c-upload-button__label">{props.label}</p>
          <div
            className="c-upload-button__image"
            onClick={(e) => handleClickUploadFile(e)}
          >
            <p className="c-upload-button__image-text">{file === "" && props.imageName ? props.imageName : file.original_filename}</p>
            <Camera />
          </div>
        </div>
      )}
      {!props.image && (
        <button
          type="button"
          className="c-button"
          onClick={(e) => handleClickUploadFile(e)}
        >
          <span className="c-button__text">{props.textButton}</span>
        </button>
      )}
    </div>
  );
};

export default UploadFileButton;
