import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

//Components
import Button from "../../components/Button";

const Titles = (props) => {
  return (
    <div className="o-container js-proctoring-container">
      <h1 className="o-margin--bottom-size-l o-text-align--center">
        {props.title ? props.title : props.internalization.proctoring.start.title}
      </h1>
      <p className="o-text-align--center" dangerouslySetInnerHTML={{__html: props.description ? props.description : props.internalization.proctoring.start.description}}></p>
        
      <div className="c-browsers" style={{textAlign: "center", marginTop: 10}}>
        <p className="o-text-align--center" dangerouslySetInnerHTML={{__html: props.internalization.proctoring.start.browsers_compatibility}}></p>
        <a href="https://www.google.com/intl/es_es/chrome/" className="c-browsers__link" target="_blank">
          <img style={{width: '50px', margin: 5}} src="/images/chrome.png" className="c-browsers__link-image" />
        </a>
        <a href="https://www.mozilla.org/es-ES/firefox/new/" className="c-browsers__link" target="_blank">
          <img style={{width: '50px', margin: 5}} src="/images/firefox.png" className="c-browsers__link-image" />
        </a>
        <a href="https://www.microsoft.com/es-es/edge" className="c-browsers__link" target="_blank">
          <img style={{width: '50px', margin: 5}} src="/images/edge.png" className="c-browsers__link-image" />
        </a>
      </div>
      <div className="c-button__wrapper">
        <Button
          className="o-margin--right-auto o-margin--left-auto o-margin--top-size-xxl"
          type="button"
          text={props.internalization.buttons.continue}
          onClick={() => props.buttonAction()}
        />
      </div>
    </div>
  )
}

Titles.propTypes = {
  buttonAction : PropTypes.func,
}

export default Titles;
