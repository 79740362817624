import React, { useState, useEffect, useMemo } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import ArrowButton from "./ArrowButton";
import CloseButton from "./CloseButton";

const ItemImageList = (props) => {
  let classNameCloseButton = classNames(
    "c-close-button--medium",
    "c-close-button--margin-r-twenty-four",
    "c-close-button--danger"
  );

  let classNameTestTags = classNames(
    "c-test__data__tags",
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="c-test"
      >
        <div className="c-test__content c-test__content--align-center">
          <div className="c-test__image-wrapper c-test__image-wrapper--small">
            <img
              className="c-test__image c-test__image--small"
              src="https://images.unsplash.com/photo-1606822350112-b9e3caea2461?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1122&q=80"
              alt=""
            />
          </div>

          <div className="c-test__data">
            <h4 className="o-color--primary o-margin--bottom-size-s">
              {props.title}
            </h4>
            <div className="o-margin--bottom-size-s">
              <LinesEllipsis
                text={props.subtitle}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            <div className={classNameTestTags}>
              {props.tags &&
                props.tags.map((tag, index) => {
                  return <p key={`tag-${index}`} className="c-test__data__tags-item">{tag}</p>;
                })}
            </div>
            <p>Created: {props.date}</p>
          </div>
          {props.canManage && !props.typeEmail && props.typeEmail !== "invitation" && (
            <CloseButton
              action={() => props.openModalDelete()}
              className={classNameCloseButton}
              width="38px"
              height="38px"
              color="#fc5c65"
            />
          )}

          <ArrowButton
            className="o-margin--zero"
            handleClick={() => props.handleClick()}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ItemImageList;
