import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

//Libs
import { DICTIONARY } from '../../lib/dictionaries';

// Components
import Modal from "../Modal";
import Input from "../Input";
import Select from "../Select";
import Datepicker from "../Datepicker";
import Multiselect from "../Multiselect";
import TableVideos from "../TableVideos";
import ModalInfoAction from "./ModalInfoAction";
import ModalDeleteModules from "./ModalDeleteModules";

// Actions
import {
  fetchUserDeleteAllAnswers,
  fetchUserDeleteModuleAnswer,
  fetchUserUpdate,
} from "../../containers/admin/actions";

// Localize
import internalization from '../../config/localize';

const ModalShowUser = (props) => {
  const OPTIONS = [
    {
      value: 0,
      text: "Eliminar todas las respuestas",
      action: () =>
        setShowModalInfo({
          text: "Se van a eliminar todas las respuestas",
          action: () =>
            props.fetchUserDeleteAllAnswers(props.data.actions[0].link, () => {
              setShowModalInfo({}),
                setShowModalSuccess({
                  text: "Se han eliminado las respuestas correctamente",
                  action: () => setShowModalSuccess({}),
                });
            }),
        }),
    },
    {
      value: 1,
      text: "Eliminar respuestas por módulo",
      action: () =>
        setShowModaDelete({
          options: props.data.actions[1].links,
        }),
    },
  ];
  const [isEdit, setIsEdit] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState({});
  const [showModalDelete, setShowModaDelete] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [formUser, setFormUser] = useState({
    name: props.data.name,
    surname: props.data.surname,
    lastname: props.data.lastname,
    language: props.data.language,
    gender: props.data.gender,
    birthdate: props.data.birthdate,
    country: props.data.country,
    personal_identifier: props.data.personal_identifier,
    department: props.data.department,
    training: props.data.training,
    job_position: props.data.job,
    liability: props.data.liability,
    sector: props.data.sector,
  });

  const getOption = (option) => {
    OPTIONS[option].action();
  };

  useEffect(() => {
    setIsEdit(props.edit);
  }, []);

  const deleteAnswerModule = (option) => {
    props.fetchUserDeleteModuleAnswer(option, () => {
      setShowModaDelete({}),
        setShowModalSuccess({
          text: "Se han eliminado las respuestas correctamente",
          action: () => setShowModalSuccess({}),
        });
    });
  };

  const checkForm = () => {
    if (isEdit) {
      return formUser.name !== undefined
        ? formUser.name !== ""
          ? false
          : true
        : false;
    }
  };

  const showRole = () => {
    return isEdit ? "role" : "";
  };

  return (
    <Modal isVisible={props.showModal} medium="c-modal--medium">
      <div className="c-modal-user">
        <h2 className="o-margin--bottom-size-l">{props.title}</h2>
        <div className="o-display--flex@lg-and-up">
          <div className="o-col--1/2@lg o-padding--right">
            <form>
              <Input
                label={internalization.form.name}
                isRequired={true}
                errorLabel={internalization.form.empty}
                minCharacters={5}
                type="text"
                value={formUser.name}
                onChange={(e) => setFormUser({ ...formUser, name: e.currentTarget.value })}
                disabled={!isEdit}
              />
              <Input
                label={internalization.form.surname}
                isRequired={true}
                errorLabel={internalization.form.empty}
                minCharacters={5}
                type="text"
                value={formUser.surname}
                onChange={(e) => setFormUser({ ...formUser, surname: e.currentTarget.value })}
                disabled={!isEdit}
              />
              <Input
                label={internalization.form.lastname}
                isRequired={true}
                errorLabel={internalization.form.empty}
                minCharacters={5}
                type="text"
                value={formUser.lastname}
                onChange={(e) => setFormUser({ ...formUser, lastname: e.currentTarget.value })}
                disabled={!isEdit}
              />
              <Input
                label={internalization.form.personal_identifier}
                isRequired={true}
                errorLabel={internalization.form.empty}
                minCharacters={5}
                type="text"
                value={formUser.personal_identifier}
                onChange={(e) => setFormUser({ ...formUser, personal_identifier: e.currentTarget.value })}
                disabled={!isEdit}
              />
              <Datepicker
                label={internalization.form.birthdate}
                errorLabel={internalization.form.empty}
                isRequired={true}
                initDateValue={formUser.birthdate ? new Date(formUser.birthdate) : null}
                onChange={(dateValue) => setFormUser({...formUser, birthdate: dateValue})}
                disabled={!isEdit}
              />
              <Select
                label={internalization.form.gender}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.gender}              
                options={internalization.dictionaries.genders.map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, gender: value})}
                disabled={!isEdit}
              />
              <Select
                label={internalization.form.country}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.country}              
                options={DICTIONARY["profile"]["countries"].map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, country: value})}
                disabled={!isEdit}
              />
            </form>
          </div>
          <div className="o-col--1/2@lg o-padding--left">
            <form>
              <Select
                label={internalization.form.language}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.language}              
                options={internalization.dictionaries.languages.map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, language: value})}
                disabled={!isEdit}
              />
              <Select
                label={internalization.form.training}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.training}              
                options={internalization.dictionaries.trainings.map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, training: value})}
                disabled={!isEdit}
              />
              <Select
                label={internalization.form.job_position}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.job_position}              
                options={internalization.dictionaries.job_positions.map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, job_position: value})}
                disabled={!isEdit}
              />
              <Input
                label={internalization.form.department}
                isRequired={true}
                errorLabel={internalization.form.empty}
                minCharacters={5}
                type="text"
                value={formUser.department}
                onChange={(e) => setFormUser({ ...formUser, department: e.currentTarget.value })}
                disabled={!isEdit}
              />
              <Select
                label={internalization.form.liability}
                tick={true}
                isRequired={true}
                withoutInput={true}
                hasError={(value) => {}}
                errorLabel={internalization.form.empty}
                valueSelected={formUser.liability}              
                options={internalization.dictionaries.liabilities.map((value) => { return { value: value[0], text: value[1] }})}
                optionClick={(text, value) => setFormUser({...formUser, liability: value})}
                disabled={!isEdit}
              />
            </form>
          </div>
        </div>
        {isEdit && (
          <React.Fragment>
            <h2 className="o-text-align--left o-margin--bottom-size-l">
              {props.type === "administrators" ? "Edit rol" : "Answers"}
            </h2>
            <Multiselect
              label={
                props.type === "administrators"
                  ? "Change rol:"
                  : "Remove answers:"
              }
              defaultValue={props.type !== "administrators" ? "Select an option" : props.data.role}
              multiple={false}
              options={
                props.type === "administrators" ? props.optionsAdmin : OPTIONS
              }
              getOption={(option) =>
                props.type === "administrators"
                  ? setFormUser({
                      ...formUser,
                      ["role"]: option,
                    })
                  : getOption(option)
              }
            />
          </React.Fragment>
        )}

        {!isEdit && props.type !== "administrators" && (
          <React.Fragment>
            <h2 className="o-text-align--left o-margin--bottom-size-l">
              Videos
            </h2>
            <TableVideos data={props.data} />
          </React.Fragment>
        )}
      </div>

      <div className="c-button__wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        {
          props.canManage && 
            <button
              type="button"
              className="c-button c-modal__button"
              disabled={isEdit ? checkForm() : false}
              onClick={() => (isEdit ? props.saveUser(formUser,props.data.id) : setIsEdit(true))}
            >
              <span className="c-button__text">
                {isEdit ? "Confirm" : "Edit"}
              </span>
            </button>
        }
      </div>

      {Object.keys(showModalInfo).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={false}
          closeModalInfoAction={() => setShowModalInfo({})}
          text={showModalInfo.text}
          action={() => showModalInfo.action()}
        />
      )}

      {Object.keys(showModalSuccess).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={true}
          closeModalInfoAction={() => showModalSuccess.action()}
          text={showModalSuccess.text}
          action={() => showModalSuccess.action()}
        />
      )}

      {Object.keys(showModalDelete).length > 0 && (
        <ModalDeleteModules
          showModalInfoAction={true}
          closeModalInfoAction={() => setShowModaDelete({})}
          options={showModalDelete.options}
          deleteAnswerModule={(option) => deleteAnswerModule(option)}
          action={() => showModalDelete.action()}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
  fetchUserDeleteAllAnswers,
  fetchUserDeleteModuleAnswer,
  fetchUserUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalShowUser);
