// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Clock = (props) => {
  let classNameClock = classNames("c-clock", props.className && props.className);

  return (
    <svg 
      className={classNameClock}
      width={props.width ? props.width : "58px"}
      height={props.height ? props.height : "63px"}
      viewBox="0 0 58 63"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Clip-26" transform="translate(0.250803, 0.189300)" fill="#121921">
          <path d="M29.2781969,7.296 C20.8921969,7.856 13.1561969,10.238 6.12219694,15.561 L6.12219694,15.561 C0.618196938,19.724 2.20319694,24.93 1.29619694,30.096 L1.29619694,30.096 C-1.96780306,48.653 9.62419694,61.882 29.3181969,61.478 L29.3181969,61.478 C50.7621969,61.039 60.8661969,48.425 55.1911969,29.173 L55.1911969,29.173 C51.3521969,16.145 41.5871969,7.25 30.6311969,7.251 L30.6311969,7.251 C30.1821969,7.251 29.7301969,7.266 29.2781969,7.296 L29.2781969,7.296 Z M4.23019694,33.875 C4.02419694,24.781 7.50119694,17.431 16.3421969,13.296 L16.3421969,13.296 C25.7951969,8.877 36.1951969,10.263 43.2801969,16.851 L43.2801969,16.851 C49.6551969,22.779 52.9371969,30.193 53.2071969,38.677 L53.2071969,38.677 C53.5921969,50.764 46.3371969,56.525 30.9661969,56.736 L30.9661969,56.736 C30.7831969,56.738 30.6021969,56.739 30.4201969,56.739 L30.4201969,56.739 C15.0521969,56.739 4.53819694,47.712 4.23019694,33.875 L4.23019694,33.875 Z" id="Clip-2"></path>
          <path d="M34.2741969,28.557 C33.2621969,29.309 32.3891969,30.251 31.4601969,31.117 L31.4601969,31.117 L31.5241969,31.185 C30.5371969,32.106 29.4801969,32.967 28.6001969,33.979 L28.6001969,33.979 C28.2851969,34.341 28.3971969,35.067 28.3141969,35.626 L28.3141969,35.626 C28.4771969,35.626 28.6581969,35.648 28.8391969,35.669 L28.8391969,35.669 C29.2331969,35.716 29.6281969,35.762 29.8331969,35.586 L29.8331969,35.586 C31.9381969,33.788 33.9961969,31.928 35.9671969,29.984 L35.9671969,29.984 C36.2461969,29.709 35.9731969,28.878 35.9561969,28.305 L35.9561969,28.305 C35.3861969,28.376 34.6761969,28.257 34.2741969,28.557 L34.2741969,28.557 Z" id="Mask"></path>
          <path d="M16.8131969,25.326 C19.8491969,28.643 22.9511969,31.915 26.1291969,35.097 L26.1291969,35.097 C26.5781969,35.546 27.5651969,35.424 28.3031969,35.567 L28.3031969,35.567 C28.0191969,34.788 27.9361969,33.88 27.4121969,33.258 L27.4121969,33.258 C26.0991969,31.697 24.5851969,30.265 23.1521969,28.781 L23.1521969,28.781 L23.0841969,28.845 C21.5601969,27.268 20.0891969,25.614 18.4831969,24.151 L18.4831969,24.151 C17.9101969,23.629 17.0061969,23.568 16.2541969,23.296 L16.2541969,23.296 C16.4281969,23.983 16.3811969,24.853 16.8131969,25.326 L16.8131969,25.326 Z" id="Clip-8"></path>
          <path d="M26.3831969,14.667 C26.5591969,15.653 27.5451969,16.469 28.6661969,17.999 L28.6661969,17.999 C29.4311969,16.47 30.2591969,15.564 30.1091969,14.931 L30.1091969,14.931 C29.9031969,14.066 28.9811969,12.861 28.3101969,12.819 L28.3101969,12.819 C28.3001969,12.818 28.2901969,12.818 28.2801969,12.818 L28.2801969,12.818 C27.6181969,12.818 26.2941969,14.16 26.3831969,14.667 L26.3831969,14.667 Z" id="Clip-11"></path>
          <path d="M7.94219694,32.513 C7.07619694,32.719 5.87119694,33.641 5.82919694,34.311 L5.82919694,34.311 C5.78719694,34.964 7.16219694,36.33 7.67819694,36.239 L7.67819694,36.239 C8.66319694,36.063 9.48019694,35.076 11.0091969,33.956 L11.0091969,33.956 C9.60319694,33.253 8.72319694,32.495 8.10019694,32.495 L8.10019694,32.495 C8.04519694,32.495 7.99319694,32.501 7.94219694,32.513 L7.94219694,32.513 Z" id="Clip-14"></path>
          <path d="M48.5061969,32.499 C47.5201969,32.675 46.7031969,33.661 45.1731969,34.782 L45.1731969,34.782 C46.7031969,35.547 47.6091969,36.376 48.2421969,36.225 L48.2421969,36.225 C49.1061969,36.019 50.3121969,35.097 50.3551969,34.426 L50.3551969,34.426 C50.3951969,33.796 49.1081969,32.495 48.5581969,32.495 L48.5581969,32.495 C48.5401969,32.495 48.5231969,32.496 48.5061969,32.499 L48.5061969,32.499 Z" id="Clip-17"></path>
          <path d="M26.3971969,53.365 C26.6031969,54.231 27.5251969,55.436 28.1951969,55.478 L28.1951969,55.478 C28.8471969,55.52 30.2141969,54.145 30.1231969,53.63 L30.1231969,53.63 C29.9471969,52.644 28.9611969,51.827 27.8391969,50.298 L27.8391969,50.298 C27.0751969,51.827 26.2461969,52.733 26.3971969,53.365 L26.3971969,53.365 Z" id="Clip-20"></path>
          <path d="M21.7951969,4.754 C20.0751969,6.822 21.2241969,9.701 24.4811969,10.042 L24.4811969,10.042 C24.3271969,9.471 24.1021969,9.006 24.0881969,8.517 L24.0881969,8.517 C24.0271969,6.36 25.5451969,3.849 27.7951969,4.144 L27.7951969,4.144 C28.2351969,4.201 30.5391969,4.1 30.6731969,5.602 L30.6731969,5.602 C30.8091969,7.105 30.2071969,7.626 29.3191969,7.999 L29.3191969,7.999 C28.8641969,8.189 28.0091969,8.171 27.1101969,8.154 L27.1101969,8.154 C26.2491969,8.136 25.3471969,8.119 24.7131969,8.283 L24.7131969,8.283 C25.8091969,10.285 27.5091969,10.281 29.2711969,9.984 L29.2711969,9.984 C31.2951969,9.641 32.3131969,8.304 32.7201969,6.46 L32.7201969,6.46 C33.0721969,4.865 32.7261969,3.563 31.2841969,2.749 L31.2841969,2.749 C30.5241969,2.32 29.5221969,2.123 28.4431969,2.123 L28.4431969,2.123 C25.9921969,2.123 23.1381969,3.138 21.7951969,4.754 L21.7951969,4.754 Z" id="Mask"></path>
          <path d="M26.3411969,0.513 C25.5801969,0.977 25.3241969,2.403 25.9091969,2.685 L25.9091969,2.685 C26.2731969,2.861 26.9401969,2.477 27.7391969,2.277 L27.7391969,2.277 C27.9541969,2.279 28.5221969,2.391 29.0321969,2.234 L29.0321969,2.234 C29.2371969,2.171 29.5201969,1.409 29.4051969,1.157 L29.4051969,1.157 C29.1901969,0.682 28.7741969,0.076 28.3311969,0.017 L28.3311969,0.017 C28.2451969,0.005 28.1551969,0 28.0631969,0 L28.0631969,0 C27.4971969,0 26.8401969,0.21 26.3411969,0.513 L26.3411969,0.513 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default Clock;
