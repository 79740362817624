import React from "react";

// Components
import Select from "./Select";

const EmailsFilterShow = (props) => {
  return (
    <div className="c-email__filter">
      <div className="c-email__filter-select">
        <p className="o-font-p--small">Enviar a usuarios</p>
        <Select
          errorLabel=""
          withoutInput={true}
          tick={false}
          defaultValue={"Selecciona una opción"}
          options={props.optionsFilter}
          optionClick={(text, value) => props.getOptionSend(text, value)}
          className="c-select--white-bg c-select--border"
          wrapperClassName={"c-select__wrapper--height-zero"}
        />
      </div>
      <button
        disabled={!props.isSelectOption}
        type="button"
        className={"c-button "}
        onClick={() => props.sendEmail()}
      >
        <span className="c-button__text">Enviar email</span>
      </button>
    </div>
  );
};

export default EmailsFilterShow;
