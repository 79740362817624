import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationCongratulationsTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration, c-illustration--congratulations-test",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width="425px"
        height="247px"
        viewBox="0 0 425 247"
      >
        <g fill="none" fillRule="nonzero">
          <path
            fill="#E6E6E6"
            d="M15.693 221.92a11.842 11.842 0 0 0 11.45-2.02c4.01-3.371 5.268-8.923 6.29-14.065l3.026-15.211-6.335 4.369c-4.556 3.142-9.215 6.384-12.37 10.935-3.154 4.55-4.53 10.763-1.996 15.687"
          />
          <path
            fill="#F2F2F2"
            d="M16.67 241.395c-.797-5.818-1.617-11.712-1.057-17.592.497-5.222 2.088-10.322 5.327-14.506a24.105 24.105 0 0 1 6.182-5.61c.618-.391 1.187.59.572.979a22.931 22.931 0 0 0-9.061 10.949c-1.973 5.024-2.29 10.502-1.95 15.84.206 3.228.642 6.435 1.08 9.637a.588.588 0 0 1-.396.698.57.57 0 0 1-.696-.396v.001Z"
          />
          <path
            fill="#E6E6E6"
            d="M22.41 232.059a8.738 8.738 0 0 0 3.288 2.964 8.712 8.712 0 0 0 4.317.968c3.851-.183 7.06-2.873 9.95-5.428l8.545-7.557-5.656-.271c-4.067-.195-8.24-.378-12.113.879-3.874 1.258-7.447 4.28-8.155 8.296"
          />
          <path
            fill="#F2F2F2"
            d="M14.416 244.739c3.84-6.803 8.292-14.363 16.248-16.779a18.09 18.09 0 0 1 6.834-.707c.725.063.544 1.183-.18 1.12-3.859-.321-7.71.7-10.906 2.89a28.372 28.372 0 0 0-7.496 8.101c-1.241 1.893-2.353 3.866-3.465 5.837-.355.63-1.394.175-1.035-.462Z"
          />
          <path
            fill="#E6E6E6"
            d="M339.324 187.73a29.211 29.211 0 0 0 14.684 1.198 29.138 29.138 0 0 0 13.388-6.118c9.832-8.212 12.915-21.736 15.422-34.262l7.419-37.052-15.532 10.642c-11.17 7.653-22.591 15.552-30.325 26.636-7.733 11.085-11.107 26.217-4.896 38.212"
          />
          <path
            fill="#F2F2F2"
            d="M341.098 235.247c-1.971-14.281-3.999-28.746-2.613-43.18 1.228-12.819 5.161-25.338 13.167-35.607a59.433 59.433 0 0 1 15.28-13.771c1.528-.959 2.934 1.449 1.413 2.404a56.387 56.387 0 0 0-22.397 26.874c-4.876 12.334-5.659 25.78-4.819 38.882.507 7.923 1.586 15.795 2.67 23.657a1.444 1.444 0 0 1-.978 1.712 1.41 1.41 0 0 1-1.722-.973l-.001.002Z"
          />
          <path
            fill="#E6E6E6"
            d="M355.727 212.487a21.314 21.314 0 0 0 8.027 7.241 21.255 21.255 0 0 0 10.538 2.366c9.4-.447 17.234-7.02 24.286-13.263l20.861-18.461-13.806-.663c-9.929-.476-20.114-.923-29.571 2.149s-18.178 10.455-19.907 20.266"
          />
          <path
            fill="#F2F2F2"
            d="M335.558 243.27c9.493-16.429 20.503-34.688 40.178-40.524a45.781 45.781 0 0 1 16.898-1.707c1.794.152 1.346 2.857-.444 2.706-9.542-.775-19.066 1.689-26.968 6.978-7.605 5.063-13.525 12.102-18.536 19.565-3.07 4.573-5.819 9.339-8.569 14.098-.878 1.522-3.448.423-2.559-1.116Z"
          />
          <path
            fill="#CBCBCB"
            d="M317.295 72.078a2.485 2.485 0 0 1-1.381-2.999c.029-.09.047-.182.054-.276a1.247 1.247 0 0 0-.81-1.243 1.24 1.24 0 0 0-1.422.419 1.157 1.157 0 0 0-.138.244 2.481 2.481 0 0 1-2.995 1.383 1.182 1.182 0 0 0-.274-.054 1.242 1.242 0 0 0-1.268 1.592c.075.257.231.482.444.643.076.056.158.102.244.138a2.48 2.48 0 0 1 1.381 3c-.029.088-.047.18-.054.274a1.247 1.247 0 0 0 .81 1.243 1.24 1.24 0 0 0 1.422-.418c.055-.076.102-.158.138-.244a2.477 2.477 0 0 1 2.994-1.383c.089.029.182.047.275.054a1.243 1.243 0 0 0 1.268-1.593 1.25 1.25 0 0 0-.444-.643 1.218 1.218 0 0 0-.244-.137Z"
          />
          <path
            fill="#F1F1F1"
            d="M117.025 117.999a2.483 2.483 0 0 1-1.381-2.999c.029-.09.047-.182.054-.276a1.239 1.239 0 0 0-.81-1.243 1.246 1.246 0 0 0-1.422.419 1.22 1.22 0 0 0-.137.244 2.477 2.477 0 0 1-2.995 1.383 1.165 1.165 0 0 0-.275-.054 1.242 1.242 0 0 0-1.267 1.593c.075.256.231.482.444.642.075.056.157.102.244.138a2.48 2.48 0 0 1 1.38 2.999 1.24 1.24 0 0 0-.054.275 1.248 1.248 0 0 0 .811 1.243 1.24 1.24 0 0 0 1.422-.418 1.22 1.22 0 0 0 .137-.244 2.479 2.479 0 0 1 2.995-1.383c.089.029.181.047.275.054a1.242 1.242 0 0 0 1.267-1.593 1.246 1.246 0 0 0-.444-.643 1.193 1.193 0 0 0-.244-.137ZM220.265 78.522a4.048 4.048 0 0 0 4.045-4.051 4.048 4.048 0 0 0-4.045-4.052 4.049 4.049 0 0 0-4.046 4.052 4.049 4.049 0 0 0 4.046 4.051Z"
          />
          <path
            fill="#CBCBCB"
            d="M121.816 48.809a4.049 4.049 0 0 0 4.046-4.052 4.049 4.049 0 0 0-4.046-4.052 4.048 4.048 0 0 0-4.045 4.052 4.048 4.048 0 0 0 4.045 4.052Z"
          />
          <path
            fill="#2F2E41"
            d="M106.257 232.96c16.039 0 29.041-13.021 29.041-29.084 0-16.062-13.002-29.084-29.041-29.084-16.038 0-29.04 13.022-29.04 29.084 0 16.063 13.002 29.084 29.04 29.084Z"
          />
          <path
            fill="#2F2E41"
            d="M101.846 226.702h-8.823v15.83h8.823zM119.491 226.702h-8.822v15.83h8.822z"
          />
          <path
            fill="#2F2E41"
            d="M100.376 245.477c4.06 0 7.352-1.236 7.352-2.761 0-1.525-3.292-2.761-7.352-2.761-4.06 0-7.353 1.236-7.353 2.761 0 1.525 3.292 2.761 7.353 2.761ZM118.021 245.109c4.06 0 7.352-1.236 7.352-2.761 0-1.525-3.292-2.761-7.352-2.761-4.06 0-7.352 1.236-7.352 2.761 0 1.525 3.292 2.761 7.352 2.761ZM137.552 191.167c6.289-6.303 9.786-13.016 7.81-14.994-1.976-1.977-8.676 1.53-14.966 7.834-6.289 6.303-9.785 13.016-7.81 14.993 1.976 1.978 8.677-1.529 14.966-7.833ZM82.85 201.026c1.976-1.977-1.52-8.69-7.81-14.993-6.29-6.304-12.99-9.811-14.965-7.834-1.976 1.978 1.52 8.691 7.81 14.994 6.289 6.304 12.99 9.811 14.965 7.833Z"
          />
          <path
            fill="#FFF"
            d="M105.036 209.695c5.481 0 9.925-4.451 9.925-9.94 0-5.49-4.444-9.94-9.925-9.94-5.482 0-9.926 4.45-9.926 9.94 0 5.489 4.444 9.94 9.926 9.94Z"
          />
          <path
            fill="#3F3D56"
            d="M105.036 203.068a3.31 3.31 0 0 0 3.308-3.313 3.311 3.311 0 0 0-3.308-3.314 3.311 3.311 0 0 0-3.308 3.314 3.31 3.31 0 0 0 3.308 3.313Z"
          />
          <path
            fill="#FFF"
            d="M112.914 218.138a6.473 6.473 0 0 1-1.043 4.837 6.444 6.444 0 0 1-8.98 1.637 6.467 6.467 0 0 1-2.678-4.158l-.002-.013c-.635-3.513 2.077-4.756 5.585-5.392 3.508-.636 6.483-.424 7.118 3.089Z"
          />
          <path
            fill="#E5E5E5"
            d="M134.733 234.401a2.46 2.46 0 0 0-1.742.724 2.465 2.465 0 0 0-.723 1.745v5.157c0 .655.26 1.283.723 1.745a2.46 2.46 0 0 0 1.742.724h39.669a2.473 2.473 0 0 0 2.466-2.469v-5.157a2.473 2.473 0 0 0-2.466-2.469h-39.669Z"
          />
          <path
            fill="#6C63FF"
            d="M157.546 230.966a1.065 1.065 0 0 0-1.461.392l-1.474 2.557-1.444-2.86a1.07 1.07 0 0 0-1.908.967l1.124 2.226h-.412v10.401h5.499v-10.401h-.581l1.048-1.82a1.067 1.067 0 0 0-.391-1.462ZM105.51 184.8a51.257 51.257 0 0 1-15.216-2.328 1.707 1.707 0 0 1-1.182-1.619v-11.839a1.693 1.693 0 0 1 1.686-1.688h29.67a1.69 1.69 0 0 1 1.685 1.688v11.832a1.687 1.687 0 0 1-1.199 1.62 53.333 53.333 0 0 1-15.444 2.334Z"
          />
          <path
            fill="#000"
            d="M121.816 169.014v1.979l-16.055 5.639c-.3.104-.627.099-.924-.014l-15.388-5.787v-1.817a1.351 1.351 0 0 1 1.349-1.35h29.669a1.352 1.352 0 0 1 1.349 1.35Z"
            opacity={0.2}
          />
          <path
            fill="#6C63FF"
            d="M105.312 174.344c-.202 0-.403-.036-.592-.107l-22.995-8.648a1.686 1.686 0 0 1-.772-2.572c.204-.282.49-.493.819-.606l21.635-7.412c.35-.119.728-.12 1.079-.005l24.147 8.061a1.69 1.69 0 0 1 .846 2.573c-.203.288-.49.506-.822.622l-22.788 7.999a1.676 1.676 0 0 1-.557.095Z"
          />
          <path
            fill="#3F3D56"
            d="M84.392 173.742h-.674v-9.792h20.566v.675H84.392zM48.248 175.768a1.35 1.35 0 0 0 1.349-1.351 1.35 1.35 0 0 0-1.349-1.351 1.35 1.35 0 0 0-1.349 1.351 1.35 1.35 0 0 0 1.349 1.351Z"
          />
          <path
            fill="#F1F1F1"
            d="M269.156 94.436a3.77 3.77 0 0 1-2.1-4.562c.044-.136.071-.276.082-.418a1.893 1.893 0 0 0-2.419-1.931c-.39.114-.732.351-.977.676-.084.115-.154.24-.209.372a3.77 3.77 0 0 1-4.555 2.103 1.805 1.805 0 0 0-.418-.082 1.886 1.886 0 0 0-1.888 1.234 1.898 1.898 0 0 0 .636 2.166c.115.085.239.155.371.21a3.774 3.774 0 0 1 2.1 4.562 1.81 1.81 0 0 0-.082.418 1.894 1.894 0 0 0 1.232 1.891 1.884 1.884 0 0 0 2.163-.637 1.76 1.76 0 0 0 .209-.371 3.775 3.775 0 0 1 1.878-1.92 3.763 3.763 0 0 1 2.677-.183c.136.043.276.071.418.082a1.888 1.888 0 0 0 1.888-1.234 1.895 1.895 0 0 0-.635-2.167 1.828 1.828 0 0 0-.371-.21Z"
          />
          <path
            fill="#2F2E41"
            d="M222.669 226.438c24.395 0 44.172-19.806 44.172-44.238s-19.777-44.238-44.172-44.238c-24.396 0-44.173 19.806-44.173 44.238s19.777 44.238 44.173 44.238Z"
          />
          <path
            fill="#2F2E41"
            d="M215.959 216.919h-13.42v24.079h13.42zM242.797 216.919h-13.419v24.079h13.419z"
          />
          <path
            fill="#2F2E41"
            d="M213.722 245.477c6.176 0 11.183-1.88 11.183-4.199 0-2.32-5.007-4.2-11.183-4.2s-11.183 1.88-11.183 4.2c0 2.319 5.007 4.199 11.183 4.199ZM240.561 244.918c6.176 0 11.183-1.881 11.183-4.2 0-2.32-5.007-4.2-11.183-4.2s-11.183 1.88-11.183 4.2c0 2.319 5.007 4.2 11.183 4.2ZM270.269 162.869c9.566-9.588 14.884-19.799 11.879-22.807-3.005-3.007-13.197 2.327-22.763 11.915-9.567 9.588-14.885 19.799-11.88 22.806 3.006 3.008 13.197-2.326 22.764-11.914ZM183.241 203.932c7.719-11.133 11.148-22.126 7.658-24.553-3.49-2.427-12.577 4.631-20.296 15.764-7.719 11.134-11.148 22.127-7.658 24.554 3.49 2.426 12.577-4.632 20.296-15.765Z"
          />
          <path
            fill="#FFF"
            d="M220.809 191.05c8.338 0 15.097-6.769 15.097-15.119s-6.759-15.12-15.097-15.12-15.097 6.77-15.097 15.12 6.759 15.119 15.097 15.119Z"
          />
          <path
            fill="#3F3D56"
            d="M225.939 175.835a5.036 5.036 0 0 0 5.032-5.04 5.036 5.036 0 0 0-5.032-5.04 5.036 5.036 0 0 0-5.033 5.04 5.037 5.037 0 0 0 5.033 5.04Z"
          />
          <path
            fill="#FFF"
            d="M238.91 203.893c1.481 5.343-3.914 10.463-12.049 11.435-8.136.973-15.931-2.57-17.412-7.913l-.006-.019c-1.473-5.343 4.819-7.234 12.956-8.201 8.137-.968 15.038-.645 16.511 4.698Z"
          />
          <path
            fill="#6C63FF"
            d="M207.212 156.164a77.967 77.967 0 0 1-22.792 5.362 2.594 2.594 0 0 1-2.585-1.614l-6.707-16.707a2.573 2.573 0 0 1 1.422-3.34l41.871-16.861a2.56 2.56 0 0 1 1.962.021 2.574 2.574 0 0 1 1.374 1.403l6.703 16.697a2.58 2.58 0 0 1-.774 2.969 81.216 81.216 0 0 1-20.474 12.07Z"
          />
          <path
            fill="#000"
            d="m221.281 124.62 1.121 2.792-19.463 17.082a2.065 2.065 0 0 1-1.311.506l-24.995.577-1.03-2.563a2.063 2.063 0 0 1 1.138-2.673l41.871-16.861a2.059 2.059 0 0 1 2.669 1.14Z"
            opacity={0.2}
          />
          <path
            fill="#6C63FF"
            d="M201.009 141.52c-.286.115-.59.178-.897.186l-37.351.864a2.566 2.566 0 0 1-1.734-4.511l26.332-22.757a2.59 2.59 0 0 1 1.52-.619l38.645-2.347a2.56 2.56 0 0 1 1.517.388 2.57 2.57 0 0 1 .328 4.107l-27.627 24.239c-.218.19-.466.343-.733.45Z"
          />
          <path
            fill="#3F3D56"
            d="m171.145 152.559-.952.383-5.547-13.819 29.024-11.688.382.953-28.073 11.305z"
          />
          <path
            fill="#3F3D56"
            d="M171.052 155.758a2.054 2.054 0 0 0 0-4.109c-1.133 0-2.051.92-2.051 2.055 0 1.134.918 2.054 2.051 2.054Z"
          />
          <path
            fill="#6C63FF"
            d="M260.719 76.152a76.222 76.222 0 0 1-17.358-14.896 2.54 2.54 0 0 1-.221-2.97l9.279-14.925a2.508 2.508 0 0 1 3.449-.803l37.404 23.324a2.509 2.509 0 0 1 .802 3.453l-9.273 14.916a2.507 2.507 0 0 1-2.782 1.1 79.23 79.23 0 0 1-21.3-9.199Z"
          />
          <path
            fill="#6C63FF"
            d="M268.664 62.814a2.507 2.507 0 0 1-.663-.6l-22.212-28.978a2.507 2.507 0 0 1-.119-2.884 2.504 2.504 0 0 1 2.669-1.086l33.086 7.663c.534.125 1.012.42 1.363.842l24.125 29.145a2.508 2.508 0 0 1-2.474 4.047l-34.998-7.83a2.498 2.498 0 0 1-.777-.319Z"
          />
          <path
            fill="#3F3D56"
            d="m242.762 45.61-.85-.53 7.675-12.345 25.929 16.168-.53.85-25.079-15.637z"
          />
          <path
            fill="#3F3D56"
            d="M241.808 48.202a2.004 2.004 0 0 0 2.003-2.005 2.004 2.004 0 1 0-4.005 0c0 1.107.896 2.005 2.002 2.005Z"
          />
          <path
            fill="#2F2E41"
            d="M365.056 172.252c23.819 0 43.128-19.338 43.128-43.192 0-23.855-19.309-43.193-43.128-43.193s-43.128 19.338-43.128 43.193c0 23.854 19.309 43.192 43.128 43.192Z"
          />
          <path
            fill="#2F2E41"
            d="m394.955 151.456-11.18 6.842 12.242 20.06 11.18-6.842zM372.595 165.141l-11.18 6.843 12.242 20.06 11.179-6.843z"
          />
          <path
            fill="#2F2E41"
            d="M400.157 180.95c5.145-3.149 8.361-7.269 7.181-9.201-1.179-1.932-6.306-.946-11.451 2.203-5.146 3.15-8.361 7.269-7.182 9.201 1.18 1.933 6.307.946 11.452-2.203ZM377.513 194.169c5.146-3.15 8.361-7.269 7.182-9.201-1.179-1.933-6.306-.946-11.452 2.203-5.145 3.149-8.36 7.269-7.181 9.201 1.179 1.932 6.306.946 11.451-2.203ZM340.806 121.819c2.934-2.937-2.259-12.906-11.599-22.267-9.34-9.362-19.29-14.57-22.225-11.634-2.934 2.937 2.259 12.906 11.599 22.268 9.34 9.361 19.29 14.569 22.225 11.633ZM423.367 165.67c3.407-2.37.06-13.103-7.477-23.973s-16.409-17.761-19.816-15.392c-3.407 2.37-.06 13.103 7.477 23.973 7.536 10.87 16.408 17.761 19.816 15.392Z"
          />
          <path
            fill="#FFF"
            d="M351.849 131.683c8.141 0 14.74-6.609 14.74-14.762 0-8.152-6.599-14.762-14.74-14.762-8.14 0-14.74 6.61-14.74 14.762 0 8.153 6.6 14.762 14.74 14.762Z"
          />
          <path
            fill="#3F3D56"
            d="M346.842 116.828a4.917 4.917 0 0 0 4.913-4.921 4.916 4.916 0 0 0-4.913-4.921 4.917 4.917 0 0 0-4.913 4.921c0 2.717 2.2 4.921 4.913 4.921Z"
          />
          <path
            fill="#FFF"
            d="M332.868 141.546c-2.795 4.586.929 10.791 8.318 13.858 7.389 3.067 15.644 1.836 18.439-2.75l.01-.016c2.788-4.59-2.627-8.023-10.018-11.086-7.392-3.063-13.962-4.596-16.749-.006Z"
          />
          <path
            fill="#E6E6E6"
            d="M350.585 74.826c3.852-15.532 20.85-24.672 37.969-20.415 17.118 4.257 27.873 20.3 24.022 35.831-3.851 15.532-16.627 15.582-33.745 11.325-17.119-4.257-32.097-11.209-28.246-26.74Z"
          />
          <path
            fill="#000"
            d="m293.649 69.07-1.551 2.495-24.661-5.513a2.019 2.019 0 0 1-1.154-.743L251.42 45.917l1.424-2.29a2.009 2.009 0 0 1 2.759-.643l37.404 23.323a2.01 2.01 0 0 1 .642 2.763Z"
            opacity={0.2}
          />
          <path
            fill="#CBCBCB"
            d="M412.735 246H.51c-.136 0-.265-.093-.36-.259a1.284 1.284 0 0 1-.15-.626c0-.235.054-.46.15-.626.095-.166.224-.259.36-.259h412.225c.135 0 .265.093.36.259.096.166.15.391.15.626s-.054.46-.15.626c-.095.166-.225.259-.36.259Z"
          />
          <path
            fill="#6C63FF"
            d="M307.563 8.104a4.049 4.049 0 0 0 4.046-4.052A4.049 4.049 0 0 0 307.563 0a4.048 4.048 0 0 0-4.045 4.052 4.048 4.048 0 0 0 4.045 4.052Z"
          />
        </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
