// Dependencies
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Modal from "./Modal";
import internalization from "../config/localize"

const ModalCloseSession = (props) => {

  return (
    <Modal isVisible={props.isVisible} small>
      <h6 className="o-text-align--center o-color--black">{internalization.header.close_session_modal}</h6>
      <div className="c-button__wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => props.cancelAction()}
        >
          <span className="c-button__text">{internalization.buttons.cancel}</span>
        </button>
        <button 
          type="button" 
          className="c-button c-modal__button" 
          onClick={() => props.acceptAction()}
        >
          <span className="c-button__text">{internalization.buttons.accept}</span>
        </button>
      </div>
    </Modal>
  );

}

ModalCloseSession.propTypes = {
  acceptAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
};

export default ModalCloseSession;
