import PropTypes from "prop-types";
import React from "react";

export default class IllustrationShapesRect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <svg
        className="c-illustration c-illustration--shapes"
        width="464px"
        height="579px"
        viewBox="0 0 464 579"
      >
        <rect x="162.57" width="160.165" height="155.544" transform="rotate(54.4613 162.57 0)" fill="#656C7E"/>
        <rect x="409.816" y="405" width="92.1436" height="89.4856" transform="rotate(54.4613 409.816 405)" fill="#D9D9D9"/>
        <circle cx="132" cy="317" r="96" fill="#D9D9D9"/>
        <circle cx="315" cy="227" r="51" fill="#6E6E6E"/>
        <path d="M0 579L129 413L264.5 579H0Z" fill="#6E6E6E"/>
        <path d="M274 341.327L364.182 320.854L345.465 412.792L274 341.327Z" fill="#656C7E"/>
      </svg>
    );
  }

  static propTypes = {};
}
