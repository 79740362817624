import React, { useEffect, useState } from "react";
import Tree from "./Tree";
import classNames from "classnames";
import Chevron from '../components/Chevron'
import Arrow from '../components/Arrow'

function TreeNode(props) {

  const [showChildren, setShowChildren] = useState(false);
  const [open, setOpen] = useState(false);
  const [rotate, setRotate] = useState(false);

  const handleClick = () => {
    setRotate(!showChildren)
    setTimeout(() => {
      setOpen(!showChildren)
    }, 500)
    setTimeout(() => {
      setShowChildren(!showChildren);
    }, 700)
  };

  const childListClass = () => {
    let cl = classNames(
      "c-tree__child-list",
      open && 'is-open'
    )
    return cl
  }

  return (
    <li className="c-tree__node">
      <div className="c-tree__node-block">
        <span onClick={props.node.childs.length > 0 ? handleClick : () => props.goTo(props.node)}>{props.node.organization.name}</span>
        {props.node.childs.length > 0 && <Chevron action={() => handleClick()} width={14} height={8} orientation={rotate ? 'up' : 'down'}/>}
        <Arrow width={20} height={10} action={() => props.goTo(props.node)}/>
      </div>
      <ul className={childListClass()}>
        {showChildren && (
          <Tree
            treeData={props.node.childs} 
            goTo={(node)=> props.goTo(node)}
          />
        )}
      </ul>
    </li>
  );
}

export default TreeNode;
