import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

export default class IllustrationNotFound extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let illustrationClasses = classNames(
      "c-illustration, c-illustration--notFound",
      this.props.className
    );

    return (
      <svg
        className={illustrationClasses}
        width="431px"
        height="242px"
        viewBox="0 0 431 242"
      >
        <g fill="none" fillRule="nonzero">
      <path
        fill="#3F3D56"
        d="m269.385 238.923-102.514-2.844s7.716-45.068-17.041-57.574c-16.874-8.531-31.85-48.379-27.59-75.493.058-.361.116-.721.181-1.075 2.159-12.139 8.332-21.54 20.135-24.094 38.154-8.248 65.382-1.853 65.382-1.853s6.303.078 15.642 1.79c.334.056.667.12 1 .19 26.438 5.058 75.308 23.068 77.424 87.016 2.912 87.858-32.619 73.923-32.619 73.923v.014Z"
      />
      <path
        fill="#6C63FF"
        d="M223.581 77.787c-.174 4.584-1.239 9.14-3.181 13.468-4.029 8.977-11.375 15.832-20.678 19.305-27.866 10.413-60.383.085-76.83-11.113-.065.354-1.369 7.067-1.427 7.427 7.282 4.867 42.921 9.557 49.681 9.557 10.267 0 20.149-1.662 28.938-4.951 9.557-3.573 17.099-10.618 21.236-19.836 1.978-4.4 3.065-9.019 3.26-13.674-.333-.07-.666-.134-.999-.19v.007Z"
      />
      <path
        fill="#3F3D56"
        d="m231.159 83.623-16.338-40.117s19.613-32.822 4.202-36.465c-15.411-3.65-31.285 15.951-31.285 15.951s-29.416-10.03-40.617-1.825c0 0-18.679-25.53-29.88-20.514-11.209 5.015 3.985 41.156 3.985 41.156s-23.127 33.148-5.383 52.29c17.743 19.149 84.509 34.648 115.323-10.484l-.007.008ZM.484 238.199h429.738v-1.267H.484c-.266 0-.484.285-.484.634 0 .348.218.633.484.633Z"
      />
      <path
        fill="#3F3D56"
        d="M266.205 214.914c5.564 2.419 11.375 4.881 17.461 4.648 6.086-.234 12.483-4.061 13.432-9.932.493-3.035-.478-6.282.711-9.125 1.601-3.82 6.759-5.299 10.766-3.99 4.014 1.309 6.956 4.69 9.071 8.262 3.956 6.692 5.536 15.478 1.297 22-3.673 5.652-10.643 8.213-17.121 10.406-8.636 2.921-18.272 5.814-26.641 2.228-8.411-3.608-12.998-14.028-9.882-22.467"
      />
      <path
        fill="#6C63FF"
        d="M174.16 65.591s-10.057-3.926-16.585.983l7.543 11.29 9.049-12.266-.007-.007Z"
      />
      <path
        fill="#3F3D56"
        d="m121.378 117.117 18.599 96.686s-28.148 27.978 8.042 27.483c36.191-.489 25.634-18.647 25.634-18.647l-11.563-83.925"
      />
      <path
        fill="#2F2E41"
        d="M147.106 242c-9.803 0-15.729-2.193-17.628-6.529-3.456-7.895 7.796-19.927 9.724-21.908l-12.506-72.281 1.427-.234 12.629 72.989-.261.255c-.13.127-12.998 13.051-9.68 20.62 1.703 3.891 7.478 5.794 17.201 5.659 13.512-.184 22.221-2.95 25.192-7.993 2.673-4.542-.152-9.55-.181-9.6l-.066-.12-.021-.134-8.043-58.402 1.435-.191 8.028 58.274c.478.885 2.978 5.985.116 10.866-3.282 5.588-12.18 8.524-26.438 8.715h-.921l-.007.014Z"
      />
      <path
        fill="#3F3D56"
        d="m168.625 117.117 18.598 96.686s-28.148 27.978 8.043 27.483c36.19-.489 25.634-18.647 25.634-18.647l-11.564-83.925"
      />
      <path
        fill="#2F2E41"
        d="M194.352 242c-9.803 0-15.729-2.193-17.627-6.529-3.456-7.881 7.774-19.899 9.715-21.901l-7.469-38.906 1.427-.262 7.607 39.628-.268.262c-.13.127-12.998 13.051-9.679 20.62 1.702 3.891 7.484 5.801 17.2 5.659 13.52-.184 22.236-2.95 25.199-8.008 2.674-4.555-.159-9.535-.188-9.585l-.073-.12-11.578-84.059 1.435-.191 11.542 83.797c.478.885 2.978 5.985.116 10.866-3.282 5.588-12.18 8.524-26.438 8.715h-.921V242Z"
      />
    </g>
      </svg>
    );
  }

  static propTypes = {
    className: PropTypes.string,
  };
}
