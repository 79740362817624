import { initialState }  from "../../config/initState";
import {FETCH_LOGIN_OK, FETCH_LOGOUT_OK, FORBIDDEN_ACCESS} from './actions';

const initState = {...initialState.client};

export default function authenticateReducer(state = initState, action) {
  switch (action.type) {
    case FETCH_LOGIN_OK:
      localStorage.setItem('client', JSON.stringify({token: action.data.attributes.token}));
      localStorage.setItem('admin', action.data.attributes.admin);
      return {...state, ...action.data.client,
        token: action.data.attributes.token,
        user_id: action.data.attributes.user_id,
        admin: action.data.attributes.admin,
        authenticated: true
      };
    case FETCH_LOGOUT_OK:
      localStorage.removeItem("client");
      localStorage.removeItem("admin");
      return {};
    case FORBIDDEN_ACCESS:
      alert("No tienes permisos suficientes")
      window.location.href = "/admin"
      return {...state}
    default:
      return state;
  }
}
