import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

//Components
import Checkbox from "./Checkbox";
import File from "./File";

const MultiAnswer = (props) => {
  const [selected, setSelected] = useState([]);
  const inputRef = useRef(null);
  const refs = useRef([]); // or an {}
  refs.current = []; // or an {}


  const handleSelected = (index) => {
    let newSelected = selected.slice(0);

    if (!newSelected.includes(index)) {
      newSelected.push(index);
    } else {
      newSelected = newSelected.filter(i => i !== index);
    };

    setSelected(newSelected);
    props.selectedElements(newSelected)
  };

  const isChecked = (index) => {
    if (props.editable) {
      return selected.indexOf(index) !== -1
    } else {
      return
    }
  };

  const handleClickUploadFile = (e, index) => {
    e.preventDefault();
    refs.current[index].click();
  };

  let classNameMultiAnswer = classNames(
    "c-multi-answer",
    props.className && props.classNameMultiAnswer
  );

  let classLabel = classNames(
    "c-multi-answer__label",
    props.classNameLabel && props.classNameLabel
  );

  const addToRefs = el => {
   if (el && !refs.current.includes(el)) {
      refs.current.push(el);
   }
  };

  const isAcreditable = (option) => {
    if (option.acreditable == false){
      return false
    }
    else{
      return props.editable && props.data.acreditable      
    }
  }
  return (
    <div className={classNameMultiAnswer}>
      <label className={classLabel}>
        {props.data.title}
      </label>
      <div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="c-multi-answer__answers"
        >
          {
            props.data.options.length > 0 && props.data.options.map((option, index) => {
              return (
                <div key={index} className="c-multi-answer__answer">
                  <Checkbox
                    className={props.editable ? "" : "c-multi-answer__checkbox--not-editable"}
                    textClassName={
                      isChecked(index)
                        ? "c-checkbox__text--report c-checkbox__text--bold c-checkbox__text--blue c-multi-answer__checkbox-text"
                        : "c-checkbox__text--report c-multi-answer__checkbox-text"
                    }
                    text={option.title}
                    onClick={() => props.editable ? handleSelected(option.key) : {}}
                    checked={isChecked(option.key)}
                  />
                  {
                    !props.editable &&
                    <div className="c-multi-answer__add-file" onClick={() => { }}>
                      <File width="20px" height="23px" />
                      <span className="c-multi-answer__add-file-hint">{option.file}</span>
                    </div>
                  }
                  {
                    isAcreditable(option) && 
                    <div style={!isChecked(option.key) ? {opacity: 0.4} : {}}>
                      <input ref={addToRefs} type='file' className="c-multi-answer__add-file-input" onChange={(e) => {isChecked(option.key) && props.handleFiles(e, option.key); }} />
                      <div className="c-multi-answer__add-file" onClick={(e) => isChecked(option.key) && handleClickUploadFile(e, index)}>

                        <File width="20px" height="23px" color={props.filesUplodaded && props.filesUplodaded[option.key] ? "#76b0eb" : "#121A22"} />
                        <span className="c-multi-answer__add-file-hint">{props.filesUplodaded && props.filesUplodaded[option.key] ? props.filesUplodaded[option.key]["original_filename"] : "Añade un archivo"}</span>
                        {
                          props.filesUplodaded && props.filesUplodaded[option.key] &&
                          <span onClick={(e) => {e.preventDefault();e.stopPropagation(); props.deleteFile(option.key);}} className="c-multi-answer__add-file-hint">X</span>
                        }
                        
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }
        </motion.div>
      </div>
    </div>
  );
};

export default MultiAnswer;
