import {call, put, takeLatest, all} from 'redux-saga/effects';
import {FETCH_PASSWORD, FETCH_RESET_PASSWORD, fetchPasswordOk, fetchResetPasswordOk, fetchResetPasswordError} from './actions';
import { fetchLoginOk } from '../login/actions';

import Api from '../../services/api';
import { setErrors } from "../../actions";

// Actions
import { redirectTo } from "../../actions";

// Config
import routes from '../../config/routes';

function * fetchPasswordSaga(action) {
  try {
    const response = yield call(Api.handlePost, routes.passwords.create, {}, action.data);
    if(response.status) {
      yield put(fetchPasswordOk(response));
      action.data.success()
    }
    else{
      action.data.error()
    }
  } catch (error) {
      action.data.error()
  }
}

function * fetchResetPasswordSaga(action) {
  try {
    const response = yield call(Api.handlePut, routes.passwords.update, {}, action.data);

    if(response.status) {
      yield put(fetchLoginOk(response));
      yield put(fetchResetPasswordOk(response));
      action.data.success()
    } else {
      yield put(setErrors('resetPassword', response.message))
      action.data.error()
    }
  } catch (error) {
    if(error.status == 404) {
      yield put(fetchResetPasswordError())
      action.data.error()
    }
  }
}

export function * watchPasswords() {
  yield all([
    takeLatest(FETCH_PASSWORD, fetchPasswordSaga),
    takeLatest(FETCH_RESET_PASSWORD, fetchResetPasswordSaga)
  ])
}
