// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Close = (props) => {

  const [closeClasses, setCloseClasses] = useState("c-close");
  const [closeClassesIllustration, setCloseClassesIllustration] = useState("c-close__illustration");

  useEffect(()=> {
    setCloseClasses(classNames(
      "c-close", 
      props.background && "c-close--bg",
      props.rotation  && "c-close--rotation",
      props.medium && "c-close--medium", 
      props.big && "c-close--big", 
      props.extraBig && "c-close--extra-big", 
      props.width && props.height && "c-close--size-auto",
      props.className
    ))
    setCloseClassesIllustration(classNames(
      "c-close__illustration", 
      props.closeClasses && props.closeClasses
    ))
  }, [])

  return (
    <div className={closeClasses} style={props.style ? props.style : {}}>
      <svg
        width={props.width ? props.width : "25px"}
        height={props.height ? props.height : "24px"}
        viewBox="0 0 25 24"
        className={closeClassesIllustration}
      >
        <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Illustration" transform="translate(-964.000000, -802.000000)" fill={props.color ? props.color : "#121A22"}>
            <g id="Close" transform="translate(964.000000, 802.000000)">
              <path d="M11.290945,-2.22870994 C10.6516497,-2.22935618 10.0147715,-1.42841205 9.38031043,0.174122426 L9.38379078,3.61571992 C9.87064327,6.94219873 10.1928284,10.2460007 10.350346,13.5271258 C10.5866225,18.4488134 9.40357125,23.1759702 10.3609057,23.9692239 C11.3182401,24.7624776 13.5940797,27.4665912 14.3826067,25.5564431 C15.1711337,23.6462949 14.377181,20.1911269 14.3755389,18.5672945 C14.3744442,17.4847396 14.0509754,15.0332938 13.4051326,11.212957 C13.4013228,7.44561546 13.3990906,5.23825038 13.3984359,4.59086174 C13.3977812,3.9434731 13.3962938,2.47257961 13.3939736,0.178181278 C12.6312498,-1.42576612 11.9302403,-2.22806319 11.290945,-2.22870994 Z" id="Path-8" transform="translate(12.055956, 11.998577) rotate(-45.000000) translate(-12.055956, -11.998577) "></path>
              <path d="M11.3200597,-2.22820207 C10.6807644,-2.22755532 10.0422649,-1.42532146 9.40456139,0.178499506 L9.40108105,3.620097 C9.88120667,6.94559795 10.1967104,10.248755 10.3475922,13.5295682 C10.573915,18.450788 9.38130057,23.1803473 10.3370326,23.9716664 C11.2927646,24.7629855 13.5631398,27.4625016 14.3555317,25.5507548 C15.1479236,23.639008 14.3609574,20.1854386 14.3625995,18.5616063 C14.3636943,17.4790514 14.0451829,15.0282547 13.4070655,11.2092164 C13.4108753,7.44187484 13.4131075,5.23450976 13.4137622,4.58712112 C13.4144168,3.93973247 13.4159043,2.46883899 13.4182245,0.174440655 C12.6587432,-1.4279674 11.9593549,-2.22884831 11.3200597,-2.22820207 Z" id="Path-8" transform="translate(12.055130, 11.996832) rotate(-315.000000) translate(-12.055130, -11.996832) "></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

Close.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Close;
