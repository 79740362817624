import React, { useRef } from "react";
import { motion } from "framer-motion";
import classNames from "classnames";

//Components
import File from "./File";

const UploadFileInput = (props) => {
  const inputRef = useRef(null);

  const handleClickUploadFile = (e) => {
    e.preventDefault();

    inputRef.current.click();
  };

  let classNameUploadFileInput = classNames(
    "c-upload-file-input",
    props.className && props.classNameUploadFileInput
  );

  let classNameLabel = classNames(
    "c-upload-file-input__label",
    props.classNameLabel && props.classNameLabel
  );

  return (
    <div className={classNameUploadFileInput}>
      <label className={classNameLabel}>
        {props.data.title}
      </label>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {
          props.editable ?
            <div>
              <input ref={inputRef} type='file' className="c-upload-file-input__add-file-input" onChange={(e) => props.handleFiles(e)} />
              <div className="c-upload-file-input__add-file" onClick={(e) => handleClickUploadFile(e)}>

                <File width="20px" height="23px" />
                <span className="c-upload-file-input__add-file-hint">{props.fileText}</span>
              </div>
            </div>
            :
            <div className="c-upload-file-input__add-file" onClick={() => { }}>
              <File width="20px" height="23px" />
              <span className="c-upload-file-input__add-file-hint">{option.file}</span>
            </div>
        }
      </motion.div>
    </div>
  );
};

export default UploadFileInput;
