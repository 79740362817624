import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// Components
import LinkAnchor from "./LinkAnchor";
import Arrow from "./Arrow";

export default class ArrowButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let arrowButtonClasses = classNames(
      "c-arrow-button",
      this.props.className,
      this.props.disabled && "c-arrow-button--disabled"
    );

    return (
      <>
        {this.props.handleClick ? (
          <div className={arrowButtonClasses} onClick={this.props.handleClick}>
            <Arrow className="c-arrow-button-svg" />
          </div>
        ) : (
          <LinkAnchor href={this.props.href}>
            <span className={arrowButtonClasses}>
              <Arrow className="c-arrow-button-svg" />
            </span>
          </LinkAnchor>
        )}
      </>
    );
  }

  static propTypes = {
    className: PropTypes.string,
    href: PropTypes.string,
    handleClick: PropTypes.func,
  };
}
