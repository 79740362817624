import {FETCH_UPDATE_PROFILE_OK, SET_USER_LANGUAGE} from './actions';

export default function userReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_UPDATE_PROFILE_OK:
    console.log(action.data.document.language !== "")
      return {...state, ...state.user, ...{
          profile: {...action.data.document}
        }
      };
    case SET_USER_LANGUAGE: 
      return {...state, ...state.user, ...{
          user_language: action.user_language,
        }
      };
    default:
      return state
  }
}
