import React, { useEffect, useState, useRef, useCallback } from "react";
import {gsap} from 'gsap';
import { motion } from "framer-motion";

// Components
import Modal from "../Modal";
import Input from "../Input";
import Close from '../Close';
import Arrow from "../Arrow";
import Percentage from "../Percentage";
import Less from "../Less";
import File from "../File";
import UploadFileButton from "../UploadFileButton";
import Select from "../Select";
import IllustrationStar from '../IllustrationStar';
import IllustrationBorder from '../IllustrationBorder';
import Checkbox from '../Checkbox';

const ModalRanking = (props) => {

  const modalRankingDeleteRef = useRef(null)

  const [disabledButtom, setDisabledButtom] = useState(true);
  const [competencesWeight, setCompetencesWeight] = useState({})
  const [step, setStep] = useState(0);
  const [defaultProfileSelected, setDefaultProfileSelected] = useState(null)
  const [defaultProfiles, setDefaultProfiles] = useState([]);
  const [rankingProfileName, setRankingProfileName] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState({});
  const [selectedCheckbox, setSelectedCheckbox] = useState([]);
  const [selectedSelect, setSelectedSelect] = useState([]);
  const [competences, setCompetences] = useState({});

  useEffect(() => {
    _fetch()
  }, []);

  const _fetch = () => {
    props.fetchRankingProfile((responseJSON) => _successFetch(responseJSON), () => _errorFetch())
  }
  const _successFetch = (responseJSON) => {
    setDefaultProfiles(responseJSON.data)
    setShowDeleteModal({})
    setStep(0)
  }

  const createRanking = () => {
    props.createRanking({ name: rankingProfileName, competences: competences }, () => _fetch())
  }

  const destroyRanking = () => {
    props.destroyRanking(showDeleteModal.id, () => _fetch())
  }

  const totalWeight = () => {
    let total = 0
    try {
      if (Object.keys(competencesWeight).length > 0) {
        Object.keys(competencesWeight).forEach(key => {
          if (competencesWeight[key] === '') {
            delete competencesWeight[key];
          }
        });
        total = Object.values(competencesWeight).reduce((a, b) => parseFloat(a) + parseFloat(b));
      }
    } catch (e) {

    }

    return total;
  }

  const checkCreation = () => {
    let validate_comptences = true; 
    Object.keys(competences).forEach((k) => {validate_comptences = validate_comptences && (competences[k].hasOwnProperty("scope"))} );
    return !(rankingProfileName !== "" && totalWeight() === 100 && Object.keys(competencesWeight).length <= props.ranking_data.ranking_config.max_competences &&  Object.keys(competencesWeight).length >= 5 && validate_comptences)
  }

  useEffect(() => {
    if (Object.keys(showDeleteModal).length > 0) {
      gsap.to(modalRankingDeleteRef.current, {
        opacity: 1,
        delay: .5,
        duration: .5
      })
    }
  }, [showDeleteModal])

  // HandleCheckbox
  const handleCheckbox = (index, competence) => {
    let newSelected = selectedCheckbox.slice(0);
    if (!newSelected.includes(index)) {
      newSelected.push(index);
    } else {
      newSelected = newSelected.filter(i => i !== index);
      if(competence){
        delete competencesWeight[competence.key]
        let new_selected_select = selectedSelect.filter((c) => c.key !== competence.key)
        setSelectedSelect(new_selected_select)
        delete competences[competence.key]        
      }
      
    };

    setSelectedCheckbox(newSelected);
  };

  const isChecked = useCallback((index) => {
    return selectedCheckbox.indexOf(index) !== -1
  }, [selectedCheckbox]);
  // @HandleCheckbox

  // HandleSelect
  const handleSelect = (text, value, option, i, competence) => {
    let newSelected = selectedSelect.slice(0)
    let found = newSelected.filter(element => element.i == i)
    if (found.length === 0) {
      newSelected.push({ i, text, value, key: competence.key })
    } else {
      newSelected = newSelected.filter(item => item.i !== i)
      newSelected.push({ i, text, value, key: competence.key })
    }

    setSelectedSelect(newSelected);
  }

  const isSelected = useCallback((index) => {
    let select = selectedSelect.find(item => item.i == index)
    if (select) return select.value
  }, [selectedSelect]);
  // @HandleSelect

  // {"ANA": '49', "scope": {'low-high'}}

  const setCompetence = (type, competence, value, index) => {
    let current_competences = competences;
    current_competences[competence.key] = {...competences[competence.key], [type]: value}
    // {...competencesWeight, [competence.key]: e.currentTarget.value}
    setCompetences(current_competences)
    if(!selectedCheckbox.includes(index)){
      handleCheckbox(index, competence)
    }

  }

  const cleanState = () => {
    setCompetencesWeight({})
    setRankingProfileName("");
    setShowDeleteModal({});
    setSelectedCheckbox([]);
    setSelectedSelect([]);
    setCompetences({});
  }


  return (
    <Modal isVisible={true} medium>
      <div className="c-modal-ranking__header">
        <h2 className="o-margin--bottom-size-l">{props.data.text ? props.data.text : 'Ranking:'}</h2>
        {
          step !== 0 && (
            <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation c-modal-ranking__button--back"  style={{marginBottom: 20}}>
              <button
                type="button"
                className={`c-button c-modal__button o-margin--zero`}
                onClick={() => {cleanState();setStep(0); }}
              >
                <span className="c-button__text">Back</span>
              </button>
            </div>
          )
        }
      </div>

      {
        Object.keys(showDeleteModal).length > 0 && (
          <div className='c-modal-ranking__delete' ref={modalRankingDeleteRef}>

            <p> Are you sure?</p>
            <h4>Profile name</h4>

            <div className="c-modal__buttons-wrapper c-modal-ranking__delete-buttons">
              <button
                type="button"
                className="c-button c-button--cancel c-modal__button"
                onClick={() => {

                  gsap.to(modalRankingDeleteRef.current, {
                    opacity: 0,
                    duration: .5,
                    onComplete: () => {
                      setShowDeleteModal({})
                    }
                  })
                }}
              >
                <span className="c-button__text">Cancel</span>
              </button>
              {
                step == 0 && (
                  <button
                    type="button"
                    className={`c-button c-modal__button`}
                    onClick={() => {
                      gsap.to(modalRankingDeleteRef.current, {
                        opacity: 0,
                        duration: .5,
                        onComplete: () => {
                          destroyRanking()

                          setShowDeleteModal({})
                        }
                      })
                    }}
                  >
                    <span className="c-button__text">Delete</span>
                  </button>
                )}
              {
                step !== 0 && (
                  <button
                    type="button"
                    disabled={step == 0 ? defaultProfileSelected === null : checkCreation()}
                    className="c-button c-modal__button"
                    onClick={() => createRanking() }
                  >
                    <span className="c-button__text">Save</span>
                  </button>

                )
              }
            </div>

          </div>
        )
      }

      <div className="c-modal-ranking" style={{marginBottom: 20}}>

        {
          step === 0 && 
            <motion.div
              key={"ranking_step_0"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="o-display--flex o-display--flex-column-direction o-display--flex-v-center">
              <p className="c-modal-ranking__subtitle">Select an existing profile or create a new one</p>
              <div className="c-modal-ranking__profiles-list">
                <ul>
                  {
                    defaultProfiles.map((element, index) => {
                      return(
                        <li key={`li-${index}`} className={"c-modal-ranking__profiles-list-row" }>
                          <div className="c-modal-ranking__profiles-list-row-text"><span>{element.name}</span></div>
                          <button 
                            data-alt="Ir al perfil"
                            className="c-modal-ranking__profiles-list-row-button" 
                            onClick={(e) => { 
                              e.preventDefault();
                              e.stopPropagation();
                              setDefaultProfileSelected(element.id)
                              setTimeout(() => {
                                props.selectedProfile(element.id)
                                document.body.style = null
                              }, 1000)
                            }}>
                            <div className='o-text--link-arrow'>
                              <Arrow width={24} height={20}/>
                            </div>
                          </button>
                          <button 
                            data-alt="Eliminar"
                            className="c-modal-ranking__profiles-list-row-button" 
                            onClick={(e) => { 
                              e.preventDefault();
                              e.stopPropagation();
                              setShowDeleteModal({id: element.id})
                            }}>
                            <Close color="#fc5c65"/>
                          </button>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </motion.div>
        }
        {
          step === 1 &&
            <form>
              <div className='c-modal-ranking__total'>
                <p className='c-modal-ranking__texts' dangerouslySetInnerHTML={{__html: props.ranking_data.ranking_config.title}}></p>
                <div>
                  <p><b>Total:</b> {totalWeight() <= 100 ? totalWeight() : 100 } %</p>
                  <p className={(100 - totalWeight()) < 0 ? 'o-color--red' : null }><b>Remaining:</b> {100 - totalWeight()} %</p>
                </div>

                <div className={totalWeight() > 100 ? 'c-modal-ranking__reminder is-show' :'c-modal-ranking__reminder'}>
                  <IllustrationBorder width='100%' height='100%' message={() => (<p className="o-text-align--center"> Hey!, remember that you have already assigned <br/> the 100 points allowed.</p>)} />
                </div>

              </div>
              <Input
                label="Name"
                type="text"
                errorLabel={""}
                isRequired
                showError
                autoCorrect={"off"}
                autoCapitalize={"none"}
                minCharacters={""}
                defaultValue={rankingProfileName}
                value={rankingProfileName}
                onChange={(e) => setRankingProfileName(e.currentTarget.value)}
                onChangeValidation={(value) => {}}
                wrapperClassName="c-modal-ranking__input-name"
              />
              <p className={Object.keys(competencesWeight).length > 10 ? 'o-color--red c-modal-ranking__competences-selected' : 'c-modal-ranking__competences-selected' }><b>Selected competencies:</b> {Object.keys(competencesWeight).length} / {props.ranking_data.ranking_config.max_competences} </p>

              {props.ranking_data.competences.map((competence, index) => {
                let i = index
                return (
                  <motion.div
                    key={"ranking_"+index}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="c-modal-ranking__inputs-wrapper"
                  >
                    <div className="c-modal-ranking__input-label">
                      <Checkbox 
                        text={competence.title}
                        onClick={(text, checked, value) => handleCheckbox(index, competence) }
                        checked={isChecked(index)}
                      />
                    </div>
                    {/*
                      <p className="c-modal-ranking__type">TIPO</p>
                      */}
                    <div className="c-modal-ranking__inputs">
                      <Input
                        label=""
                        type="range"
                        errorLabel={""}
                        showError
                        autoCorrect={"off"}
                        autoCapitalize={"none"}
                        minCharacters={""}
                        defaultValue={competencesWeight[competence.key] || competencesWeight[competence.key] > 0 ? competencesWeight[competence.key] : 0}
                        value={competencesWeight[competence.key] || competencesWeight[competence.key] > 0 ? competencesWeight[competence.key] : 0}
                        onChange={(e) => {
                          setCompetencesWeight({...competencesWeight, [competence.key]: e.currentTarget.value > 0 ? e.currentTarget.value : ''}) 
                          setCompetence('weight', competence, e.currentTarget.value, index)
                        }}
                        onChangeValidation={(value) => {}}
                        wrapperClassName='c-modal-ranking__input c-modal-ranking__input-range' 
                      />
                      <Input
                        label=""
                        type="numeric"
                        errorLabel={""}
                        showError
                        autoCorrect={"off"}
                        autoCapitalize={"none"}
                        minCharacters={""}
                        defaultValue={competencesWeight[competence.key]}
                        value={competencesWeight[competence.key] > 0 ? competencesWeight[competence.key] : ''}
                        onChange={(e) => {
                          setCompetencesWeight({...competencesWeight, [competence.key]: e.currentTarget.value > 0 ? e.currentTarget.value : ''}) 
                          setCompetence('weight', competence, e.currentTarget.value, index)
                        }}
                        onChangeValidation={(value) => {}}
                        wrapperClassName='c-modal-ranking__input c-modal-ranking__input-number' 
                      />
                      <Percentage width={14} height={18} className='o-margin--left-size-s'/>

                      <Select
                        key={`s-${index}`}
                        errorLabel=""
                        withoutInput={true}
                        tick={false}
                        defaultValue={0}
                        valueSelected={isSelected(index)}
                        options={[{value: 'high', text: 'ALTA'}, {value: 'medium-high', text: 'MEDIA-ALTA'}, {value: 'medium', text: 'MEDIA'}, {value: 'medium-low', text: 'MEDIA-BAJA'}, {value: 'low', text: 'BAJA'}]}
                        optionClick={(text, value, option) => {
                          handleSelect(text,value,option, i, competence);
                          setCompetence('scope', competence, value, index);
                        }
                        }
                        className="c-select--white-bg c-select--border "
                        wrapperClassName={"c-select__wrapper--height-zero c-modal-ranking__select"}
                      />
                    </div>
                  </motion.div>

                )
              })
              }
            </form>

        }
      </div>
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-button--cancel c-modal__button"
          onClick={() => {
            props.closeInvitationFolder();
            document.body.style = null
          }}
        >
          <span className="c-button__text">Cancel</span>
        </button>
        {
          step == 0 && (
            <button
              type="button"
              className={`c-button c-modal__button`}
              onClick={() => setStep(1)}
            >
              <span className="c-button__text">Create profile</span>
            </button>
          )}
        {
          step !== 0 && (
            <button
              type="button"
              disabled={step == 0 ? defaultProfileSelected === null : checkCreation()}
              className="c-button c-modal__button"
              onClick={() => createRanking() }
            >
              <span className="c-button__text">Save</span>
            </button>

          )
        }
      </div>
    </Modal>
  );
};

export default ModalRanking;
