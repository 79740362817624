import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const Smile = (props) => {


  return (
    <svg
      width={props.width ? props.width : "72px"}
      height={props.height ? props.height : "72px"}
      viewBox="0 0 72 72"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}

      >

      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">

        <g id="Group">

          {
          props.withOutside === true &&
             <circle id="Oval" fill={props.outsideColor ? props.outsideColor : "#000000"} cx="36" cy="36" r="36"></circle>
          }


          <g id="smile" transform="translate(15.000000, 15.000000)">
          <path d="M21,42 C9.42,42 0,32.58 0,21 C0,9.42 9.42,0 21,0 C32.58,0 42,9.42 42,21 C42,32.58 32.58,42 21,42 Z" id="bg" stroke={props.emojiColor ? props.emojiColor : "#FFFFFF"} strokeWidth="2" fill={props.color ? props.color : "#000000"} fillRule="nonzero"></path>

          {
             props.withoutMouth !== true &&
                 <path d="M30.3710526,26.8829482 C29.94989,26.505752 29.3028126,26.5425516 28.9276508,26.9626807 C26.7952597,29.3454566 23.7203641,30.7111317 20.4890658,30.7111317 C17.2587898,30.7111317 14.1828719,29.3454566 12.0504809,26.9626807 C11.6732746,26.5425516 11.0282416,26.505752 10.6070791,26.8829482 C10.1859165,27.2591221 10.1511604,27.9051601 10.5273444,28.3263114 C13.0471641,31.141483 16.6781579,32.7555556 20.4890658,32.7555556 C24.300996,32.7555556 27.9319897,31.1404607 30.4507872,28.3263114 C30.8269713,27.9051601 30.7911929,27.2591221 30.3710526,26.8829482 Z" id="mouth" fill={props.emojiColor ? props.emojiColor : "#FFFFFF"} fillRule="nonzero"></path>
          }


          {
             props.withCircleEyes === true &&
                 <g id="circles-eyes" transform="translate(11.288889, 13.333333)" fill={props.emojiColor ? props.emojiColor : "#FFFFFF"}>
                     <circle id="left-eye" cx="3.06666667" cy="3.06666667" r="3.06666667"></circle>
                     <circle id="right-eye" cx="16.3555556" cy="3.06666667" r="3.06666667"></circle>
                 </g>
          }

          {
             props.withCircleEyes !== true &&
                 <g id="semicircle-eyes" transform="translate(9.000000, 14.000000)" fill={props.emojiColor ? props.emojiColor : "#FF8200"} fillRule="nonzero">
                     <path d="M18.6315789,0.736842105 C16.1938105,0.736842105 14.2105263,2.66503509 14.2105263,5.03508772 C14.2105263,5.50961404 14.6066526,5.89473684 15.0947368,5.89473684 C15.5828211,5.89473684 15.9789474,5.50961404 15.9789474,5.03508772 C15.9789474,3.61322807 17.1690947,2.45614035 18.6315789,2.45614035 C20.0940632,2.45614035 21.2842105,3.61322807 21.2842105,5.03508772 C21.2842105,5.50961404 21.6803368,5.89473684 22.1684211,5.89473684 C22.6565053,5.89473684 23.0526316,5.50961404 23.0526316,5.03508772 C23.0526316,2.66503509 21.0693474,0.736842105 18.6315789,0.736842105 Z" id="Path"></path>
                     <path d="M8.02105263,5.03508772 C8.02105263,5.50961404 8.41717895,5.89473684 8.90526316,5.89473684 C9.39334737,5.89473684 9.78947368,5.50961404 9.78947368,5.03508772 C9.78947368,2.66503509 7.80618947,0.736842105 5.36842105,0.736842105 C2.93065263,0.736842105 0.947368421,2.66503509 0.947368421,5.03508772 C0.947368421,5.50961404 1.34349474,5.89473684 1.83157895,5.89473684 C2.31966316,5.89473684 2.71578947,5.50961404 2.71578947,5.03508772 C2.71578947,3.61322807 3.90593684,2.45614035 5.36842105,2.45614035 C6.83090526,2.45614035 8.02105263,3.61322807 8.02105263,5.03508772 Z" id="Path"></path>
                 </g>
          }

          </g>
        </g>
      </g>

    </svg>
  );
}

export default Smile;



