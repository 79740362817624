import PropTypes from "prop-types";
import React from "react";
import { motion } from "framer-motion";

// Config
import routes from "../config/routes";

//Components
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import LinkAnchor from "../components/LinkAnchor";
import Table from '../components/Table'

export default class Disclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: true,
    };
  }
  handleCheckbox = (checkbox, state) => {
    this.setState({ isDisabled: !checkbox });
  };
  render() {
    return (
      <motion.section
        className="o-wrapper"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="o-container">
          <div className="o-display--flex o-display--flex-v-center o-display--flex-column-direction">
          <Table />
          </div>
        </div>
      </motion.section>
    );
  }

  static propTypes = {
    // name: PropTypes.string.isRequired, // this is passed from the Rails view
  };
}
