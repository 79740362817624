import {config} from '../config'
import { fetchLogout, forbiddenAccess } from "../containers/login/actions";

const enviroment = process.env.FORCE_NODE_ENV || 'development';

let dispatch;
let state;
let csrfToken;
let api_config = config[enviroment]

function initialize(store, token) {
  dispatch = store.dispatch;
  state = store.getState();
  csrfToken = token
}

const getUrl = (path, api) => {
  return api.url + "/" + path
}

const getToken = () => {
  let token = state.client.token 

  if (token == undefined) {
    let localToken = localStorage.getItem('client')
    if(localToken) { token = JSON.parse(localToken).token}
  }

  if (token) {
    return { Authorization: token }
  } else {
    return null;
  }
}


const buildQueryParams = (paramsObj) => {
  let params = [];

  for (let key in paramsObj) {
    if (Array.isArray(paramsObj[key])) {
      for (let item of paramsObj[key]) {
        let paramString = encodeURIComponent(item && item.toString());
        if (paramString !== '')
          params.push(`${encodeURIComponent(key)}=${paramString}`);
        }
    } else if (paramsObj[key]) {
      let paramString = encodeURIComponent(paramsObj[key] && paramsObj[key].toString());
      if (paramString !== '')
        params.push(`${encodeURIComponent(key)}=${paramString}`);
    }
  }
  return params.length > 0
    ? '?' + params.join('&')
    : '';
}

const handleRequest = (method, url, params = {}, body = {}, headers = {}, api, format = 'application/json') => {
  dispatch;
  // Merge headers
  const _headers = Object.assign({
    "X-CSRF-Token": csrfToken,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    Accept: format,
  }, headers,
    getToken()
  );

  // Set Query String params
  const queryParams = buildQueryParams(params);
  url = getUrl(url, api);
  url += queryParams;

  let newUrl = new URL(url); // Not supported in IE

  // Do the request with native fetch
  body = (method === "GET")
    ? null
    : JSON.stringify(body)

  return fetch(newUrl, {
    method: method,
    headers: _headers,
    body: body
  });
}

const handleResponse = (promise) => {
  return new Promise((resolve, reject) => {
    promise.then((response) => {
      const status = response.status;

      response
        .json()
        .then(response => {
          if (status >= 200 && status < 300) {
            resolve({ ...response, status: true });
          } else if (status === 400) {
            resolve({ ...response, status: false })
          // } else if (status === 401) {
          //   console.log("401")
          //   resolve(dispatch(fetchLogout()));
          } else if (status === 403) {
            //resolve(dispatch(forbiddenAccess()))
            localStorage.removeItem("client");
            localStorage.removeItem("admin");

            window.location.href = "/admin"

            resolve({ ...response, status: false })
          } else if (status === 404) {
            resolve({ ...response, status: false })
          } else {
            reject({
              ...response,
              status: status
            });
          }
        });
      dispatch;
    }).catch((e) => {
      dispatch;
      reject(e);
    })
  });
}

const handleResponseBlob = (promise) => {
  promise.then(response => {
        console.log("reponse", response);
        if (response.ok) {
            return response.blob();
        }
    })
    .then(blob => {
        console.log("blob", blob)
        const data = URL.createObjectURL(blob);
        var anchor = document.createElement('a');
        anchor.download = "result.xlsx";
        anchor.href = data;
        anchor.target = '_blank';
        anchor.click();
        // return file
        // ... do your stuff here ...
    })
    .catch((err) => {
        // handle error
    });
}


export default {
    init : function (store, csrfToken) {
      initialize(store, csrfToken);
    },
    handleGet : function (url, params, body, headers) {
      try {
        return handleResponse(handleRequest("GET", url, params, {}, headers, api_config.client));
      } catch (e) {
        console.error(url);
        console.error(e);
      }
    },
    handleGetBlob : function (url, params, body, headers) {
      console.log("Blob")
      try {
        return handleResponseBlob(handleRequest("GET", url, params, {}, headers, api_config.client));
      } catch (e) {
        console.error(url);
        console.error(e);
      }
    },
    handlePost : function (url, params, body, headers) {
      try {
        return handleResponse(handleRequest("POST", url, params, body, headers, api_config.client));
      } catch (e) {
        console.error(url);
        console.error(e);
      }
    },
    handlePut : function (url, params, body, headers) {
      return handleResponse(handleRequest("PUT", url, params, body, headers, api_config.client));
    },
    handleDelete : function (url, params, body, headers) {
      return handleResponse(handleRequest("DELETE", url, params, body, headers, api_config.client));
    },
    buildQueryParams : function (paramsObj = {}) {
      try {
        return buildQueryParams(paramsObj);
      } catch (e) {
        console.error(e);
      }
    }
};
