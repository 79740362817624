import React from "react";

// Components
import Modal from "../Modal";

const ModalInfoStatus = (props) => {

  const TITLES = ["Completed", "In progress", "Invited", "Pending"];

  return (
    <Modal isVisible={props.showModal} small className="c-modal-info-status">
      <h4 className="c-modal-info-status__title">
        {props.title}
      </h4>
      <div className="c-modal-info-status__content">
        {
          Object.keys(props.text).map((item, index) => {
            return(
              <div className="c-modal-info-status__text"><span className="c-modal-info-status__text--highlighted"><span>{TITLES[index]}:</span> </span>{props.text[item]}</div>
            )
          })
        }
      </div>
      <div className="c-modal__buttons-wrapper">
        <button
          type="button"
          className="c-button c-modal__button"
          onClick={() => props.closeModal()}
        >
          <span className="c-button__text">Close</span>
        </button>
      </div>
    </Modal>
  );
};

export default ModalInfoStatus;
