import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import FolderInfo from "../../components/FolderInfo";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import ModalAddUser from "../../components/admin-modals/ModalAddUser";
import TableTools from "../../components/TableTools";
import Spinner from "../../components/Spinner";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";

import Roles from "../../config/roles";

// Actions
import {
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchOrganizations,
  fetchAdmins,
  fetchAdminCreate,
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const AdministratorsIndex = (props) => {
  const [modals, setModals] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.fetchAdmins(props.match.params.organizationId, () =>
      setLoading(false)
    );

    props.organizations.length === 0 && props.fetchOrganizations();
    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);
  }, []);

  const actionModals = (type) => {
    let modal = {};
    if (type === "addAdmin") {
      modal.type = type;
      modal.text = "Add administrator";
      modal.action = (data) => addAdmin(data);
    } else {
      modal.type = type;
      modal.action = () => {};
    }

    setModals(modal);
  };

  const addAdmin = (data) => {
    props.fetchAdminCreate(
      props.admins.organization_id,
      { profile: data },
      () => {
        setModals({
          type: "success",
          text: "The administrator has been added successfully!",
          action: () => {
            setModals({}),
              props.fetchAdmins(props.match.params.organizationId, () => {});
          },
        });
      }
    );
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () =>
        setModals({
          type: "createOrganization",
          action: (data) => submitCreateOrganization(data),
        })
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate(
      { organization: data, from_organization: props.match.params.organizationId },
      () =>
        setModals({
          type: "succes",
          text: "Organization has been created successfully!",
          action: () => setModals({}),
        }),
      props.fetchOrganizations()
    );
  };

   // Logout
   const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >

      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organization={props.organization && props.organization}
            organizations={props.organizations}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => setModals({
              type: "closeSession",
              text: "Are you sure you want to logout?",
              action: () => closeSession(),
            })
          }
          />
        )}

      <div className="o-container">
        <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center">
          <h4 className="o-margin--right-size-l">Administrators list</h4>
          <button
            type="button"
            className={"c-button c-button--margin-zero"}
            onClick={() => {
              actionModals("addAdmin");
            }}
          >
            <span className="c-button__text">Add administrators</span>
          </button>
        </div>
        {props.admins && props.admins.user.length > 0 ? (
          <TableTools
            data={props.admins}
            canManage={
              props.organization &&
              props.organization.user_role !== Roles.admin.READER
            }
            titleTableFilter="Administrators list"
            type={"administrators"}
          />
        ) : (
          <>
            <IllustrationLineSeparator />
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
              No administrators yet
            </h2>
            <IllustrationLineSeparator />
          </>
        )}
      </div>

      {["createOrganization"].includes(modals.type) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) => modals.action()}
          type={modals.type}
          title={"New organization:"}
          assessments={props.assessments}
          biodataOptions={props.organization.data.biodata_options}
        />
      )}

      {modals.type === "addAdmin" && (
        <ModalAddUser
          title={modals.text}
          showModal={true}
          options={props.admins.rol}
          closeModal={() => setModals("")}
          action={(data) => modals.action(data)}
        />
      )}
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }
      {modals.type === "invite" && (
        <ModalInvitationFolder
          showModalInvitationFolder={modals.type === "invite"}
          closeInvitationFolder={() => setModals("")}
          invitationAction={(data, file) => modals.action(data, file)}
        />
      )}

      {["navigation", "successInvitation", "success","closeSession"].includes(modals.type) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["successInvitation", "success"].includes(
            modals.type
          )}
          closeModalInfoAction={() => setModals("")}
          text={modals.text}
          action={() => modals.action()}
        />
      )}
      {loading && <Spinner />}
    </motion.section>
  );
};

AdministratorsIndex.propTypes = {
  folder: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    admins: state.admin.admins,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchAdmins,
  fetchAdminCreate,
  fetchLogout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministratorsIndex);
