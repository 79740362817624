// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const IllustrationHead = (props) => {

  let classNameHead = classNames(
    "c-illustration--head",
    props.className && props.className
  );


  return (
    <svg className={classNameHead} width={props.width ? props.width : "18px"} height={props.height ? props.height : "23px"} viewBox="0 0 18 23">
      <g fill="#3C5896" fillRule="nonzero">
      <path d="M9 11.75A5.625 5.625 0 1 0 9 .5a5.625 5.625 0 0 0 0 11.25ZM9 13.25c4.986 0 9 2.174 9 4.875V23H0v-4.875c0-2.7 4.014-4.875 9-4.875Z" />
    </g>
    </svg>
  );
};

IllustrationHead.propTypes = {
  className: PropTypes.string,
};

export default IllustrationHead;
