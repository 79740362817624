export const FETCH_PROCEDURE_CLIENT = 'FETCH_PROCEDURE_CLIENT';
export const POST_PROCEDURE_CLIENT = 'POST_PROCEDURE_CLIENT';
export const POST_ANSWER_SCORE = 'POST_EVALUATION_SCORE';

/** Actions to reducers */

export function fetchProcedureClient(slug, success, error) {
  return {
    type: FETCH_PROCEDURE_CLIENT,
    data: {slug:slug, success: success, error: error}
  }
}


export function postProcedureClient(data, success, error) {
  return {
    type: POST_PROCEDURE_CLIENT,
    data: {data:data, success: success, error: error}
  }
}

export function postAnswerScore(organization_id, folder_id, user_folder_id, data, success, error) {
  return {
    type: POST_ANSWER_SCORE,
    data: {organization_id: organization_id, folder_id: folder_id, user_folder_id: user_folder_id, data:data, success: success, error: error}
  }  

}
