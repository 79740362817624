import React, { useState, useEffect } from "react";

let current_time = 0;
let modalTimerShowed = undefined;
const SurveyTimer = (props) => {
  console.log("timer")
  const [timerClasses, setTimerClasses] = useState("c-arrow-button__timer");

  useEffect(() => {
    current_time = props.time;
    let set_interval = setInterval(() => { _timer() }, 1000);
    return () => {
      clearInterval(set_interval)
    }
  }, []);


  useEffect(() => {
    current_time = props.time;
    modalTimerShowed = getCookie("modalTimerShowed")

  }, [props.time]);  

  const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return undefined;
  }
  
  const setCookie = (name, value, minutes)  => {
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (minutes * 60 * 1000)); // Convert minutes to milliseconds

    var expires = "expires=" + expirationDate.toUTCString();
    document.cookie = name + "=" + value + "; " + expires;
  }

  console.log(modalTimerShowed)

  const _timer = () => {
    console.log(current_time, modalTimerShowed)
    if (current_time > 0) {
      current_time = current_time - 1;
      if (current_time <= props.timeLeftToNotice && modalTimerShowed === undefined){
        console.log("modalTimerShowed", modalTimerShowed)
        modalTimerShowed = true
        setCookie("modalTimerShowed", "true", 5)
        props.showModalTimer(current_time)
      }
      if (current_time < 60) { setTimerClasses("c-arrow-button__timer o-color--red") }

      document.getElementById("counter").innerHTML = format_counter(current_time);
      fetch(`/api/answers/timer?`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': "application/json",
          'Authorization': props.token
        },
        body: JSON.stringify({token: props.survey_token, answer: {time_left: current_time, module_id: props.module_id}})
      }).then((response) => {
        if (response.status === 200) {}
        else { console.log("Error") }
      });  
      
      
    }
    else if(current_time == 0){
      if(document.getElementById("counter") !== null){
        setTimerClasses("c-arrow-button__timer o-color--red")
        document.getElementById("counter").innerHTML = format_counter(current_time);
      }     
    }
  }

  const format_counter = (time) => {
    var minutes = Math.max(Math.floor(time / 60), 0);
    var seconds = Math.max(time - minutes * 60, 0);

    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
  }

  return (
    <h2 id="counter" className={timerClasses}></h2>
  );
};

export default SurveyTimer;
