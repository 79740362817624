// Dependencies
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Edit = (props) => {

 let classNameEdit = classNames(
    "c-edit", 
    props.editClassName && props.editClassName
  );


  return (
    <svg
      className={classNameEdit}
      width={props.width ? props.width : "20px"}
      height={props.height ? props.height : "20px"}
      viewBox="0 0 20 20"
    >
      
      <path
        fill="#3C5896"
        d="M1.99 14.854.676 18.358a.75.75 0 0 0 .966.965l3.503-1.314a3 3 0 0 0 1.068-.687L16.36 7.175s-.354-1.061-1.414-2.122c-1.06-1.06-2.122-1.414-2.122-1.414L2.677 13.786a3 3 0 0 0-.687 1.068Z"
      />
      <path
        fill="#A9C44B"
        fillRule="nonzero"
        d="m15.622.841-1.383 1.383s1.061.354 2.121 1.416c1.061 1.06 1.414 2.12 1.414 2.12l1.384-1.383c.248-.248.406-.579.348-.925-.08-.487-.322-1.231-1.025-1.934-.702-.703-1.447-.945-1.934-1.025-.346-.058-.677.1-.925.348Z"
      />
    
    </svg>
  );
};

Edit.propTypes = {
  className: PropTypes.string,
};

export default Edit;
